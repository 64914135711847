<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>
                Book Additional Ticket
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card card-body events-card mb-4">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="col-lg-12 col-md-12">
                  <h5 class="bolder u">Book Additional Ticket</h5>
                  <div class="col-md-12 text-right">
                    <button class="btn" style="background-color: #9cc338" v-on:click="addMoreForm()">
                      Add More
                    </button>
                  </div>
                </div>
                <br />
                <div class="card">
                  <template v-for="(detail, i) in selectedUser" :key="i">
                    <Form>
                      <div class="card-body">
                        <div class="col-lg-12 col-md-12 text-right" v-if="i > 0">
                          <i class="fa fa-trash" style="cursor: pointer" v-on:click="removeForm(i)"></i>
                        </div>
                        <div class="mx-3" id="addMoreForm">
                          <div class="row">
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label class="">First Name</label>
                                <span class="text-danger">*</span>
                                <Field type="text" id="first_name" name="first_name" v-model="detail.first_name"
                                  placeholder="First Name" rules="required:first name" class="form-control filled"
                                  @input="onChangeUser(i)" :validateOnInput="true" />
                                <ul v-show="detail.isOpen" class="autocomplete-results">
                                  <li v-for="(result, index) in checkEmployee" :key="index" class="autocomplete-result"
                                    v-on:click="selectUserInfo(result, i)" v-show="result.has_joined == 0">
                                    {{ result.first_name + " " + result.last_name }}
                                  </li>
                                </ul>
                              </div>
                              <ErrorMessage name="first_name" class="validation-msg" />
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label class="">Last Name</label>
                                <span class="text-danger">*</span>
                                <Field type="text" id="last_name" name="last_name" v-model="detail.last_name"
                                  placeholder="Last Name" rules="required:last name" class="form-control filled"
                                  v-on:keyup="closeOpenList(i)" :validateOnInput="true" />
                              </div>
                              <ErrorMessage name="last_name" class="validation-msg" />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label class="">Email</label>
                                <span class="text-danger">*</span>
                                <Field type="email" id="email" name="email" v-model="detail.email" placeholder="Email"
                                  rules="required:email" class="form-control filled" min="0" :validateOnInput="true" />
                              </div>
                              <ErrorMessage name="email" class="validation-msg" />
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label class="">Phone</label>
                                <span class="text-danger">*</span>
                                <Field type="number" id="phone" name="phone" v-model="detail.phone" placeholder="Phone"
                                  class="form-control filled" rules="required:phone" min="0" :validateOnInput="true" />
                              </div>
                              <ErrorMessage name="phone" class="validation-msg" />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label class="">Amount</label>
                                <span class="text-danger">*</span>
                                <Field type="number" id="price" name="price" v-model="this.detail.non_member_price"
                                  class="form-control filled" placeholder="Amount" min="0" :validateOnInput="true"
                                  :disabled="disabled == 1" />
                              </div>
                              <ErrorMessage name="price" class="validation-msg" />
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </Form>
                  </template>
                  <div class="row" v-if="selectedUser.length > 1">
                    <div class="col-lg-12 col-md-12 text-right">
                      <h6>Total Amount : {{ total_amount }}</h6>
                    </div>
                  </div>

                  <div class="row mx-auto">
                    <div class="col-lg-12 my-3 d-flex justify-content-center">
                      <button type="button" id="register-button" class="btn btn-brand-1" v-on:click="AddTicketInfo()">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <TextModal ref="thankYouModal" class="modal-small">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" v-on:click="$refs.thankYouModal.closeModal">
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <a href="" v-on:click="redirectToBookedTicket()" class="">
        <div class="modal-body text-center px-md-4">
          <h5 class="modal-title font-22 mb-4">Thank you</h5>
          <img src="/images/claping.png" alt="" width="75" class="mb-3" />
          <p class="font-18 mb-0">Thanks for registering<br /></p>
        </div>
      </a>
    </div>
  </TextModal>
</template>
<style>
.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  min-height: 1em;
  max-height: 6em;
  overflow: scroll;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
  z-index: 999;
}

.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
</style>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "../../components/TextModal.vue";
export default {
  name: "BusinessConclaveDetails",
  components: { Field, ErrorMessage, TextModal, Form },
  data() {
    return {
      disabled: 1,
      id: this.$route.params.id,
      loader: true,
      detailList: [],
      selectedUser: [
        {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          dial_code: "+91",
          country_code: "IND",
          user_id: "",
          type: "NEW",
          isOpen: false,
        },
      ],
      detail: {},

      results: [],
      current_user_id: localStorage.getItem("current_user_id"),
      searchObj: {
        keyword: "",
        offset: 0,
      },
      list: [],
      showForm: "1",
      user_price: "",
      type: "",
      checkEmployee: [],
      isOpen: false,
      total_amount: "",
    };
  },
  mounted() {
    this.getDetail();
    this.getInfo();

    this.$store.commit("showSidebar", true);
  },
  methods: {
    removeForm(i) {
      this.selectedUser.splice(i, 1);
    },
    redirectToBookedTicket() {
      this.$router.push("/view-booked-ticket/" + this.id);
    },
    closeOpenList(i) {
      this.selectedUser[i].isOpen = false;
    },
    addMoreForm() {
      var abc = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        user_id: "",
        dial_code: "+91",
        type: "NEW",
        country_code: "IND",
        isOpen: false,
      };
      this.selectedUser.push(abc);
      this.total_amount = this.selectedUser.length * this.detail.non_member_price;
    },

    getEmployee(i) {
      this.$api
        .getAPI({
          _action: "business-conclave/" + this.id + "/search-employees",
          _body: { keyword: this.selectedUser[i].first_name },
        })
        .then((res) => {
          this.checkEmployee = res.list;
          // this.checkEmployee.forEach((obj) => {
          //   if (obj.has_joined === 0) {
          //     this.list.push(obj);
          //   }
          // });
          if (res.list == "") {
            this.selectedUser[i].isOpen = false;
          } else {
            this.selectedUser[i].isOpen = true;
          }
        })
        .catch(() => { });
    },
    AddTicketInfo() {
      this.list = this.selectedUser;

      this.submitAdditionTicket();
      console.log("list Of service list data", this.list);
    },

    showThankyouPopup() {
      this.$refs.thankYouModal.showModal();
    },

    getDetail() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "business-conclave/" + this.id,
        })
        .then((res) => {
          this.loader = false;

          this.detail = res;
          if (this.detail.has_register == 0) {
            this.$router.push("/business-conclave-details/" + this.id);
          }
          this.user_price = res.non_member_price;
          this.type = res.type;
          console.log("this type is", this.type);

          this.speakerList = res.business_conclave_speakers;
          localStorage.setItem("business_conclave_id", this.id);
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    selectUserInfo(data, i) {
      this.selectedUser[i].user_id = data.id;
      this.selectedUser[i].first_name = data.first_name;
      this.selectedUser[i].last_name = data.last_name;
      this.selectedUser[i].email = data.email;
      this.selectedUser[i].type = "EXISTING";
      this.selectedUser[i].phone = data.phone;
      this.selectedUser[i].isOpen = false;
    },

    onChangeUser(i) {
      this.getEmployee(i);
      this.selectedUser[i].isOpen = true;
    },
    getInfo() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.userInfo = res.response;
          console.log(this.userInfo.id, "ME call");
          localStorage.setItem("user_id", this.userInfo.id);
          this.getList(0);
        })
        .catch(() => { });
    },
    checkout(detail) {
      var obj = {};
      obj.event_id = detail.id;
      obj.type = "BUSINESS_CONCLAVE";
      this.$api
        .postAPI({
          _action: "checkout",
          _body: obj,
        })
        .then((res) => {
          if (res) {
            console.log("response", res);
          }
        });
    },
    checkoutSuccess(response, transaction_token) {
      var obj = {};
      // console.log(response)
      (obj.razorpay_payment_id = response.razorpay_payment_id),
        (obj.transaction_token = transaction_token);
      (obj.razorpay_signature = response.razorpay_signature),
        (obj.razorpay_order_id = response.razorpay_order_id),
        //   (obj.amount = amount);
        this.$api
          .postAPI({
            _action: "checkout/success",
            _body: obj,
          })
          .then(() => {
            this.$helperService.getSummary();
            this.showThankyouPopup();
          });
    },
    register(detail) {
      if (detail.price > 0.0) {
        this.checkout(detail);
      } else {
        var obj = {};
        obj.business_conclave_id = detail.id;
        this.$api
          .postAPI({
            _action: "business-conclave/participant",
            _body: obj,
            _buttonId: "register-button",
          })
          .then(() => {
            this.$helperService.getSummary();
            this.showThankyouPopup();
          })
          .catch(() => { });
      }
    },
    submitAdditionTicket() {

      this.$api
        .postAPI({
          _action: "business-conclave/" + this.id + "/checkout",
          _body: { list: this.list },
          _buttonId: "register-button",
        })
        .then((res) => {
          this.subscribe(res);
        })
        .catch(() => { });
    },
    subscribe(data) {
      var $this = this;
      var options = {
        key: data.razorpay_sdk_key, // Enter the Key ID generated from the Dashboard
        amount: data.event_amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Tenx",
        description: "Tenx Description",
        order_id: data.subscription,
        handler: function (response) {
          if (response.razorpay_payment_id) {
            $this.checkoutSuccess(response, data.transaction_token);
          }
        },
        prefill: {
          name: $this.userInfo.first_name,
          email: $this.userInfo.email,
          contact: $this.userInfo.phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function () {
        this.$noty.error("Payment Failed, Please try again");
      });

      rzp1.open();
    },
    showLocation(detail) {
      var url =
        "https://www.google.com/maps?saddr=" +
        this.$api.getLatitude() +
        "," +
        this.$api.getLongitude() +
        "&daddr=" +
        detail.address_lat +
        "," +
        detail.address_lng;

      window.open(url);
    },
    // validateAddCustomInvoiceForm() {
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       this.AddTicketInfo();
    //       return;
    //     }
    //   });
    // },
  },
};
</script>
