<template>
  <div v-show="isShowModal">
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css"
      integrity="sha256-h20CPZ0QyXlBuAw7A+KluUYx/3pK+c7lYEpqLTlxjYQ="
      crossorigin="anonymous"
    />
    <div class="spx-modal h-100 justify-content-center">
      <div class="spx-modal-body mx-auto">
        <div class="modal-header pt-0">
          <button type="button" class="close" v-on:click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">Share</h5>
        </div>
        <div class="modal-body row pl-5">
          <ShareNetwork
            class="col-5"
            v-for="network in networks"
            :network="network.network"
            :key="network.network"
            :style="{ backgroundColor: network.color }"
            :url="network.network === 'email' ? sharing.description : sharing.url"
            :title="sharing.title"
            :description="network.network === 'email' ? '' : sharing.description"
            :quote="sharing.quote"
            :hashtags="sharing.hashtags"
            :twitterUser="sharing.twitterUser"
          >
            <i :class="network.icon"></i>
            <span>{{ network.name }}</span>
          </ShareNetwork>
        </div>
      </div>
    </div>
    <div class="spx-modal-background"></div>
  </div>
</template>

<script>
import ShareNetwork from "./share-network";
export default {
  name: "ShareModal",
  components: {
    ShareNetwork,
  },
  data() {
    return {
      isShowModal: false,
      sharing: {
        url: "https://news.vuejs.org/issues/180",
        title:
          "TenX is organizing a webinar we would be glad if you could join and participate. To save your seat, please register and feel free to share it with your friends.",
        description: "",
        hashtags: "",
        twitterUser: "",
      },
      networks: [
        {
          network: "email",
          name: "Email",
          icon: "far fah fa-lg fa-envelope",
          color: "#333333",
        },
        {
          network: "facebook",
          name: "Facebook",
          icon: "fab fah fa-lg fa-facebook-f",
          color: "#1877f2",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fab fah fa-lg fa-linkedin",
          color: "#007bb5",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fah fa-lg fa-twitter",
          color: "#1da1f2",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fab fah fa-lg fa-whatsapp",
          color: "#25d366",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    showModal(url, title, description) {
      this.isShowModal = true;
      this.sharing.url = url;
      this.sharing.title = title;
      if (description) {
        this.sharing.description = description;
      }
      console.log("URL--", url);
    },
    closeModal() {
      this.isShowModal = false;
      this.removeLoader();
    },
    onClick() {
      this.$emit("remove", this.obj);
      this.showLoader = true;
    },
    removeLoader() {
      this.showLoader = false;
    },
  },
};
</script>

<style>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
h1 {
  text-align: center;
  margin: 50px 0 80px;
}
.share-network-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
}
a[class^="share-network-"] {
  flex: none;
  color: #ffffff;
  background-color: #333;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px 10px 0;
}
a[class^="share-network-"] .fah {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  flex: 0 1 auto;
}
a[class^="share-network-"] span {
  padding: 0 10px;
  flex: 1 1 0%;
  font-weight: 500;
}
</style>
