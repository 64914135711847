<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">My Requirements</h2>
          </div>
          <div class="col-md-6 text-right d-md-flex justify-content-end mb-3">
            <input
              type="text"
              class="search-click"
              name=""
              v-on:keyup.enter="getMyRequirements(0)"
              placeholder="search here..."
              v-model="searchObj.keyword"
            />
          </div>
          <div class="col-12 text-right">
            <button
              type="button"
              class="btn btn-brand-1 btn-sm px-4"
              v-on:click="showAddProductPopup()"
            >
              <img src="images/plus.svg" width="14" alt="" /> Post Requirement
            </button>
          </div>
        </div>
        <ul class="nav nav-pills mb-3 tab-style-1" id="login-tab" role="tablist">
          <li class="nav-item w-50 text-center" role="presentation">
            <a
              class="nav-link"
              id="prod_tab"
              :class="{ active: currentTab == 'PRODUCT' }"
              v-on:click="changeTab('PRODUCT')"
              style="cursor: pointer"
              >Products
            </a>
          </li>
          <li class="nav-item w-50 text-center" role="presentation">
            <a
              class="nav-link"
              id="serv_tab"
              :class="{ active: currentTab == 'SERVICE' }"
              v-on:click="changeTab('SERVICE')"
              style="cursor: pointer"
              >Services
            </a>
          </li>
        </ul>
        <table
          class="table table-style-1 table-responsive"
          id="sorting_tbl"
          v-show="currentTab == 'PRODUCT'"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th
                class="sorting cursor-pointer"
                id="id-title"
                v-on:click="sorting('title', 'id-title')"
              >
                Product
              </th>
              <th
                class="sorting cursor-pointer"
                id="id-date"
                v-on:click="sorting('createdAt', 'id-date')"
              >
                Created Date
              </th>
              <th
                class="sorting cursor-pointer"
                id="id-offer"
                v-on:click="sorting('offer', 'id-offer')"
              >
                Offers
              </th>
              <th>Action</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in productList" :key="index">
              <td>#{{ data.item_id }}</td>
              <td>{{ data.item ? data.item.title : " " }}</td>
              <td>{{ data.created_at }}</td>
              <td>
                <a
                  class="cursor-pointer"
                  v-on:click="routeOffer(data.id, data.offer_count)"
                  >{{ data.offer_count }}</a
                >
              </td>
              <td>
                <button class="btn btn-action mr-2" v-on:click="getRequirement(data)">
                  <img
                    src="images/edit.svg"
                    width="12"
                    alt=""
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Edit"
                  />
                </button>
                <button
                  class="btn btn-action mr-2"
                  width="5px"
                  v-on:click="showDeletePopup(data, 'PRODUCT')"
                >
                  <img src="images/delete.svg" width="12" alt="" />
                </button>
                <button
                  v-if="data.status == 'ACTIVE'"
                  class="btn btn-action mr-2"
                  width="5px"
                  v-on:click="showClosePopUp(data)"
                >
                  <img src="images/close.svg" width="12" alt="" />
                </button>
              </td>
              <td>{{ data.status }}</td>
            </tr>
          </tbody>
        </table>
        <table
          class="table table-style-1 table-responsive"
          id="sorting_tbl"
          v-show="currentTab == 'SERVICE'"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th
                class="sorting cursor-pointer"
                id="id-title"
                v-on:click="sorting('title', 'id-title')"
              >
                Service
              </th>
              <th
                class="sorting cursor-pointer"
                id="id-date"
                v-on:click="sorting('createdAt', 'id-date')"
              >
                Created Date
              </th>
              <th
                class="sorting cursor-pointer"
                id="id-offer"
                v-on:click="sorting('offer', 'id-offer')"
              >
                Offers
              </th>
              <th>Action</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in serviceList" :key="index">
              <td>#{{ data.item_id }}</td>
              <td>{{ data.item ? data.item.title : " " }}</td>
              <td>{{ data.created_at }}</td>
              <td>
                <a
                  class="cursor-pointer"
                  v-on:click="routeOffer(data.id, data.offer_count)"
                  >{{ data.offer_count }}</a
                >
              </td>
              <td>
                <button class="btn btn-action mr-2" v-on:click="getRequirement(data)">
                  <img
                    src="images/edit.svg"
                    width="12"
                    alt=""
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Edit"
                  />
                </button>
                <button
                  class="btn btn-action"
                  v-on:click="showDeletePopup(data, 'SERVICE')"
                >
                  <img src="images/delete.svg" width="12" alt="" />
                </button>
                <button
                  v-if="data.status == 'ACTIVE'"
                  class="btn btn-action mr-2"
                  width="5px"
                  v-on:click="showClosePopUp(data)"
                >
                  <img src="images/close.svg" width="12" alt="" />
                </button>
              </td>
              <td>{{ data.status }}</td>
            </tr>
          </tbody>
        </table>
        <ErrorComponent @retry="getMyRequirements(0)" ref="errorComponent" />
      </div>
    </div>
  </div>

  <div
    style="text-align: center; border-radius: 16px"
    class="col-12 pb-3"
    v-show="offset != -1 && !loader"
  >
    <a
      id="load-button"
      class="txt-success cursor-pointer mb-3"
      v-on:click="getMyRequirements(offset)"
      style="max-width: 110px"
    >
      Load More
    </a>
  </div>

  <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  <!-- Add Req -->
  <TextModal ref="addModal">
    <Form ref="addForm" @submit="checkOut(detail.id)">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            v-on:click="this.$refs.addModal.closeModal()"
          >
            <img src="images/close.svg" width="15" />
          </button>
          <h4 class="font-22 modal-tital text-center mb-4">
            {{ detail.id ? "Edit" : "Post" }} Requirement
          </h4>
        </div>
        <div class="modal-body floating-form" style="max-height: 500px; overflow: auto">
          <div class="row">
            <div
              class="col-md-6 align-self-end"
              v-if="detail.requirement_type == 'GUEST'"
            >
              <div class="floating-form-group">
                <Field
                  type="text"
                  id="guest_name"
                  name="guest_name"
                  :class="detail.guest_name ? 'filled' : ''"
                  v-model="detail.guest_name"
                  rules="required:Guest name"
                  :validateOnInput="true"
                />
                <label for="guest_name">Guest Name</label>
                <span class="text-danger">*</span>
              </div>
              <ErrorMessage name="guest_name" class="validation-msg" />
            </div>
            <div
              class="col-md-6 align-self-end"
              v-if="detail.requirement_type == 'GUEST'"
            >
              <div class="floating-form-group">
                <Field
                  type="text"
                  id="guest_number"
                  name="guest_number"
                  :class="detail.guest_phone_number ? 'filled' : ''"
                  v-model="detail.guest_phone_number"
                  rules="required:Guest Mobile Number|phone"
                  :validateOnInput="true"
                />
                <label for="guest_number">Guest Mobile Number</label>
                <span class="text-danger">*</span>
              </div>
              <ErrorMessage name="guest_number" class="validation-msg" />
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for="product-service"
                  ><span v-if="detail.item_type == 'PRODUCT'">Product</span>
                  <span v-else>Serice</span>
                  <span class="text-danger">*</span></label
                >
                <Multiselect
                  ref="multiselect"
                  name="attributeSetList"
                  mode="single"
                  trackBy="title"
                  label="title"
                  valueProp="id"
                  placeholder="Select"
                  v-model="detail.item_id"
                  :options="list"
                  :searchable="true"
                  :createOption="true"
                />
              </div>
              <ErrorMessage name="item" class="validation-msg" />
            </div>
            <div class="col-md-12 align-self-end" v-if="detail.item_type == 'PRODUCT'">
              <div class="floating-form-group">
                <Field
                  type="text"
                  id="quantity"
                  name="quantity"
                  :class="detail.quantity ? 'filled' : ''"
                  v-model="detail.quantity"
                  rules="required:quantity"
                  :validateOnInput="true"
                />
                <label for="quantity">Quantity</label>
                <span class="text-danger">*</span>
              </div>
              <ErrorMessage name="quantity" class="validation-msg" />
            </div>
            <div class="col-md-6 align-self-end" v-if="detail.item_id == 'OTHER'">
              <div class="floating-form-group">
                <Field
                  type="text"
                  id="quantity"
                  name="item_name"
                  :class="detail.item_name ? 'filled' : ''"
                  v-model="detail.item_name"
                  rules="required:item name"
                  :validateOnInput="true"
                />
                <label for="item_name">Other Item Name</label>
                <span class="text-danger">*</span>
              </div>
              <ErrorMessage name="quantity" class="validation-msg" />
            </div>
            <div class="col-md-12 mb-4">
              <div class="floating-form-group">
                <textarea
                  type="text"
                  id="otherInfo"
                  v-model="detail.description"
                  :class="detail.description ? 'filled' : ''"
                >
                </textarea>
                <label for="otherInfo">Other Info (Optional)</label>
              </div>
            </div>
            <div class="col-12">
              <label class="font-medium">Need delivery by</label>
              <div class="d-md-flex mb-3">
                <div class="custom-control custom-radio mb-2 mr-3">
                  <input
                    type="radio"
                    id="Immediate"
                    name="deliveryBy"
                    class="custom-control-input"
                    value="IMMIDIATE"
                    v-model="detail.delivery_by"
                    v-on:click="selectReason('IMMIDIATE')"
                  />
                  <label class="custom-control-label" for="Immediate">Immediate</label>
                </div>
                <div class="custom-control custom-radio mb-2 mr-3">
                  <input
                    type="radio"
                    id="Within7day"
                    name="deliveryBy"
                    class="custom-control-input"
                    value="WITHIN_7_DAYS"
                    v-model="detail.delivery_by"
                    v-on:click="selectReason('WITHIN_7_DAYS')"
                  />
                  <label class="custom-control-label" for="Within7day"
                    >Within 7 Days</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="Within15day"
                    name="deliveryBy"
                    class="custom-control-input"
                    value="WITHIN_15_DAYS"
                    v-model="detail.delivery_by"
                    v-on:click="selectReason('WITHIN_15_DAYS')"
                  />
                  <label class="custom-control-label" for="Within15day"
                    >Within 15 Days +</label
                  >
                </div>
              </div>
            </div>

            <div class="col-12 custom-select-img mt-2">
              <div class="file-upload">
                <div class="image-upload-wrap p-3 mb-4">
                  <input
                    type="file"
                    name="img[]"
                    id="pickFile"
                    class="file-upload-input"
                    v-on:change="selectedFile($event)"
                    accept="image/*"
                  />

                  <div class="drag-text text-center">
                    <img
                      :src="
                        this.detail.image && this.detail.image != null
                          ? this.detail.image
                          : '/images/Imageplace-holder.svg'
                      "
                      id="preview"
                      class="mb-2"
                      width="60"
                      height="50"
                    />
                    <h3 for="pickFile" class="heading font-20">Select Image</h3>
                    <p class="font-12 text-secondary mb-1">PNG, GIF, JPEG. (Max 10 mb)</p>
                    <p class="font-12 text-secondary mb-0">(Optional)</p>
                  </div>
                </div>
                <div class="file-upload-content">
                  <img class="file-upload-image mb-3" src="#" alt="your image" />
                  <div class="image-title-wrap">
                    <button type="button" onclick="removeUpload()" class="remove-image">
                      <i class="fa fa-times text-danger mr-2" aria-hidden="true"></i>
                      <span class="image-title">Uploaded Image</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mt-0 mb-2">
          <button class="btn btn-brand-1 px-4" id="post-button">
            {{ detail.id ? "save" : "Post" }}
          </button>
        </div>
      </div>
    </Form>
  </TextModal>

  <TextModal ref="productModal">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="this.$refs.productModal.closeModal()"
        >
          <img src="images/close.svg" width="15" />
        </button>
        <h4 class="font-22 modal-tital text-center mb-4">Post Requirement</h4>
      </div>
      <div class="modal-body floating-form" style="max-height: 500px; overflow: auto">
        <div class="row">
          <div class="col-12">
            <div class="d-flex">
              <div
                class="p-4 rounded text-center mr-3 w-100 cursor-pointer"
                style="background: #e5e6e7"
                v-on:click="selectType('PRODUCT')"
              >
                <img src="images/product.svg" width="80" height="80" class="mb-2" />
                <p class="font-18 font-medium mb-0">Product</p>
              </div>
              <div
                class="p-4 rounded text-center w-100 cursor-pointer"
                style="background: #e5e6e7"
                v-on:click="selectType('SERVICE')"
              >
                <img src="images/service.svg" width="80" height="80" class="mb-2" />
                <p class="font-18 font-medium mb-0">Service</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </TextModal>

  <TextModal ref="userModal">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="this.$refs.productModal.closeModal()"
        >
          <img src="images/close.svg" width="15" />
        </button>
        <h4 class="font-22 modal-tital text-center mb-4">Post Requirement</h4>
      </div>
      <div class="modal-body floating-form" style="max-height: 500px; overflow: auto">
        <div class="row">
          <div class="col-12">
            <div class="d-flex">
              <div
                class="p-4 rounded text-center mr-3 w-100 cursor-pointer"
                style="background: #e5e6e7"
                v-on:click="selectRequirement('SELF')"
              >
                <img src="images/requirment.svg" width="80" height="80" class="mb-2" />
                <p class="font-18 font-medium mb-0">
                  I have <br />
                  Requirement
                </p>
              </div>
              <div
                class="p-4 rounded text-center w-100 cursor-pointer"
                style="background: #e5e6e7"
                v-on:click="selectRequirement('GUEST')"
              >
                <img src="images/friend-req.svg" width="80" height="80" class="mb-2" />
                <p class="font-18 font-medium mb-0">My Friend have Requirement</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </TextModal>

  <!--Thank you modal-->
  <TextModal ref="thankyouModal">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="this.$refs.thankyouModal.closeModal()"
        >
          <img src="images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-4 pb-4">
        <h5 class="modal-title font-22 mb-4">Thank you</h5>
        <img src="images/claping.png" alt="" width="75" class="mb-3" />
        <p class="font-18 mb-0">Thanks for Post Requirement</p>
      </div>
    </div>
  </TextModal>
  <TextModal ref="reasonModal">
    <div>
      <Form ref="reasonForm" @submit="closeRequirement()">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            v-on:click="$refs.reasonModal.closeModal()"
            style="cursor: pointer"
          >
            <img src="/img/close.svg" width="15" />
          </button>
        </div>
        <div
          class="modal-body form-style pb-0 pt-2 px-4"
          style="max-height: 500px; overflow: auto"
        >
          <label class="mb-4 font-bold">Reason For Closing Requirement</label>
          <div class="form-group">
            <div
              v-for="(data, index) in reasonList"
              :key="index"
              class="custom-control custom-radio mb-2"
            >
              <input
                type="radio"
                :id="'id_' + index"
                name="reason"
                class="custom-control-input"
                v-on:click="selectedReason(data.id)"
              />

              <label class="custom-control-label" :for="'id_' + index">
                {{ data.name }}</label
              >
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button class="btn btn-brand-1 px-4 w-100" style="cursor: pointer">
            Submit
          </button>
        </div>
      </Form>
    </div>
  </TextModal>
</template>
<script>
// import Dropdown from "vue-simple-search-dropdown";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/ErrorComponent";
import helperService from "../../services/HelperService";
import DeleteModal from "@/components/DeleteModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal.vue";
import moment from "moment";
import $ from "jquery";
export default {
  name: "MyRequirements",
  components: {
    TextModal,
    Form,
    Field,
    ErrorMessage,
    DeleteModal,
    ErrorComponent,
    Multiselect,
    // Dropdown,
  },
  data() {
    return {
      company_id: localStorage.getItem("companyId"),
      id: this.$route.params.id,
      currentTab: "",
      productList: [],
      serviceList: [],
      itemList: [],
      loader: true,
      offset: 0,
      list: [],
      prodDetail: {},
      detail: {
        delivery_by: "IMMIDIATE",
        vendor_range: "WITHIN_CITY",
        item_type: "PRODUCT",
        requirement_type: "GUEST",
      },
      file: "",
      type: "",
      searchObj: {
        keyword: "",
        type: "",
      },
      reasonList: [],
      reasonBody: {
        reason_id: "",
      },
    };
  },
  mounted() {
    this.changeTab("PRODUCT");
    this.getList();
    console.log("company", localStorage.getItem("companyId"));
    this.$store.commit("showSidebar", false);
    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });

    // if (this.currentTab == "PRODUCT") {
    //   this.list =
    // } else if (this.currentTab == "SERVICE") {
    //   this.list = this.getList();
    // }
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "PRODUCT") {
        this.getMyRequirements(0, "PRODUCT");
      } else if (tab == "SERVICE") {
        this.getMyRequirements(0, "SERVICE");
      }
    },
    showAddProductPopup() {
      this.detail = {
        delivery_by: "",
        vendor_range: "WITHIN_CITY",
        item_type: "",
      };
      this.$refs.addForm.resetForm();
      this.$refs.productModal.showModal();
    },
    showAddUserPopup() {
      this.$refs.productModal.closeModal();
      this.$refs.userModal.showModal();
    },
    showAddRequirementPopup() {
      this.$refs.userModal.closeModal();
      this.$refs.addModal.showModal();
    },
    selectType(type) {
      this.type = type;
      if (type == "PRODUCT") {
        this.searchObj.type = type;
        this.detail.item_type = type;
        this.getList();
      } else if (type == "SERVICE") {
        this.searchObj.type = type;
        this.detail.item_type = type;
        this.getList();
      }
      this.showAddUserPopup();
    },
    selectRequirement(type) {
      this.detail.requirement_type = type;
      this.showAddRequirementPopup();
    },
    selectReason(reason) {
      this.detail.delivery_by = reason;
    },
    showClosePopUp(data) {
      // console.log("data",data);
      this.request_id = data.id;
      this.getReasonList();
      this.$refs.reasonForm.resetForm();
      this.$refs.reasonModal.showModal();
    },
    getReasonList() {
      console.log("aaya");
      this.$api
        .getAPI({
          _action: "category/requirement-close-reason",
        })
        .then((res) => {
          if (res) {
            // console.log("res",res);
            this.reasonList = res.list;
          }
        });
    },
    route() {
      this.$refs.addModal.closeModal();
      console.log("object", this.type);
      this.$refs.addProdModal.showModal();
    },
    routeOffer(id, count) {
      if (count > 0) {
        this.$router.push("/offer-view/" + id);
      } else {
        return;
      }
    },
    getList() {
      this.$api
        .getAPI({
          _action: "search/keyword",
          _body: this.searchObj,
        })
        .then((res) => {
          this.itemList = res.list;
          this.list = res.list;
          console.log("list", this.list);
        })
        .catch(() => {});
    },
    getMyRequirements(offset, type, order_by, sort_by) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      var obj = {};
      // if (this.searchObj.keyword) {
      //   obj.keyword = this.searchObj.keyword;
      // }

      if (!type || type == "undefined") {
        obj.type = this.currentTab;
      } else {
        obj.type = type;
      }
      if (order_by) {
        obj.order_by = order_by;
      }
      if (sort_by) {
        obj.sort_by = sort_by;
      }
      if (offset == 0) {
        this.productList = [];
        this.serviceList = [];
      }
      obj.offset = offset;

      // if (this.$refs.errorComponent) {
      //   this.$refs.errorComponent.updateLoader(true);
      // }
      this.$api
        .getAPI({
          _action: "post/requirements",
          _body: obj,
        })
        .then((res) => {
          this.loader = false;

          for (var j in res.list) {
            if (type == "PRODUCT" && res.list[j].company_id == this.company_id) {
              this.productList.push(res.list[j]);
            } else if (type == "SERVICE" && res.list[j].company_id == this.company_id) {
              this.serviceList.push(res.list[j]);
            }
          }
          console.log("product list", this.productList);
          console.log("service list", this.serviceList);

          this.offset = res.next_offset;
          for (var i in res.list) {
            res.list[i].created_at = moment(res.list[i].creradet_at).format("DD-MM-YYYY");
          }
          this.offset = res.next_offset;
          if (this.offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No requirements available");
            this.$refs.errorComponent.updateLoader(false);
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.setData("No requirements available");
          this.$refs.errorComponent.updateLoader(false);
        });
    },
    getRequirement(data) {
      this.detail = data;
      this.$refs.addModal.showModal();
    },
    checkOut(id) {
      var isExist = false;
      for (var i in this.list) {
        if (this.detail.item_id == this.list[i].id) {
          isExist = true;
        }
      }

      if (isExist) {
        this.save(id);
      } else {
        this.add(id);
      }
    },
    closePopup() {
      this.$refs.addModal.closeModal();
    },
    save(id) {
      if (this.detail.item_type == "SERVICE") {
        this.detail.quantity = 1;
      }

      if (id) {
        this.$api
          .putAPI({
            _action: "post/requirementv2/" + id,
            _body: this.detail,
            _buttonId: "post-button",
          })
          .then((res) => {
            if (res) {
              // this.showThankyouPopup();

              this.currentTab = this.detail.item_type;
              // this.$router.go(-1);
              this.$refs.addForm.resetForm();
              this.uploadImage(res.id);

              this.$notify({
                type: "success",
                text: res.message,
              });
            }
          })
          .catch(() => {});
      } else {
        this.detail.company_id = localStorage.getItem("companyId");
        this.$api
          .postAPI({
            _action: "post/requirementv2",
            _body: this.detail,
            _buttonId: "post-button",
          })
          .then((res) => {
            this.currentTab = this.detail.item_type;
            this.$refs.addForm.resetForm();
            this.uploadImage(res.id);
            if (res) {
              this.$notify({
                type: "success",
                text: res.message,
              });
            }
          })
          .catch(() => {});
      }
    },
    showThankyouPopup() {
      this.changeTab(this.currentTab);
      this.$refs.thankyouModal.showModal();
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Challenge",
        "Are you sure you want to delete " + data.item.title,
        data
      );
    },
    deleteRecord(dataObj) {
      var details = new Proxy(dataObj, {});
      console.log(details);
      this.$api
        .deleteAPI({
          _action: "post/requirement/" + details.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getMyRequirements(0, details.item_type);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    sorting(sortBy, id) {
      var obj = helperService.sorting(sortBy, id);
      this.getMyRequirements(0, this.currentTab, obj.order_by, obj.sort_by);
    },
    selectedReason(id) {
      this.reasonBody.reason_id = id;
    },
    closeRequirement() {
      // var new_reason = "";
      // for (var j in this.reasonList) {
      //   if (this.reasonList[j].is_checked == true) {
      //     // console.log("here");
      //     this.reasonBody.reason_id = this.reasonList[j].id;
      //   }
      // }

      this.$api
        .postAPI({
          _action: "post/requirement/close/" + this.request_id,
          _body: this.reasonBody,
          _buttonId: "post-button",
        })
        .then((res) => {
          if (res) {
            this.getMyRequirements(0, this.currentTab);
            // this.changeTab();
            this.$refs.reasonModal.closeModal();
            this.$notify({
              type: "success",
              text: res.message,
            });
            // this.showThankyouPopup();
            // this.$router.push("/add-new-business-address");
          }
        })
        .catch(() => {
          console.log("In catch");
          this.reasonBody.reason_id = "";
          // for (var i in this.reasonList) {
          //   this.reasonList[i].is_checked = false;
          //   // this.detail.reason = ;
          // }
          // this.getReasonList();
        });
    },
    add(id) {
      if (this.type == "PRODUCT") {
        this.url = "/product";
      } else {
        this.url = "/service";
      }
      var obj = {};
      console.log("object", this.detail.item_id);
      obj.title = this.detail.item_id;
      console.log("title", obj);
      this.$api
        .postAPI({
          _action:
            "company/" + localStorage.getItem("companyId") + "/interested" + this.url,
          _body: obj,
        })
        .then((res) => {
          if (this.type == "PRODUCT") {
            if (res) {
              console.log("ressss", res);
              this.detail.item_id = res.product_id;
              this.save(id);
              this.$refs.addForm.resetForm();
            }
          } else if (this.type == "service") {
            if (res) {
              this.detail.item_id = res.service_id;
              this.save(id);
              this.$refs.addForm.resetForm();
            }
          }
        })
        .catch(() => {});
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage(id) {
      if (!this.file) {
        this.$refs.addForm.resetForm();
        this.$refs.addModal.closeModal();
        this.showThankyouPopup();

        return;
      }
      var obj = {};
      obj.request_id = id;
      this.$api
        .uploadImageAPI({
          _action: "requirement/upload/image",
          _key: "image",
          _file: this.file,
          _body: obj,
        })
        .then(() => {
          this.$refs.addForm.resetForm();
          this.$refs.addModal.closeModal();
          this.showThankyouPopup();
        });
    },
  },
};
</script>
