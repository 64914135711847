<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-md-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt=""
                /></a>
                Attendance
              </h2>
            </div>
            <div class="col-md-6 text-md-right text-center">
              <button
                class="btn"
                style="background-color: #9cc338"
                v-on:click="savAttendance()"
              >
                Save Attendance
              </button>
            </div>
          </div>
          <!-- <div
            v-on:scroll="handleScroll($event)"
            id="chat_div"
            style="height: 60vh; overflow: scroll"
          > -->
          <div class="bg-white" id="login-tabContent">
            <div id="tab_1" aria-labelledby=" ">
              <table class="table table-style-1">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th class="text-right">
                      <input
                        class=""
                        type="checkbox"
                        v-model="isCheckAll"
                        v-on:click="selectAll"
                        :value="attendees"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody v-if="!loader">
                  <tr
                    class="border-bottom"
                    v-for="(data, index) in userList"
                    :key="index"
                  >
                    <td width="45" class="p-2">
                      <img
                        :src="
                          data.member.profile_img
                            ? data.member.profile_img
                            : '/images/placeholder-image.jpg'
                        "
                        width="45"
                        alt=""
                      />
                    </td>
                    <td class="font-medium align-middle p-2">
                      {{ data.member.name ? data.member.name : " " }} <br />
                      <span style="font-size: 10px">
                        {{
                          data.business_work_group.name
                            ? data.business_work_group.name
                            : " "
                        }}
                      </span>
                    </td>

                    <td class="p-2 text-right">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="data.member.id"
                          :id="'member_' + data.member.id"
                          name="member_attendance"
                          :checked="data.has_attend_meeting == 1"
                          v-on:click="updateCheck($event, data)"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            </div>

            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
          <div class="row mx-0 mb-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
// import $ from "jquery";
export default {
  name: "MemberList",
  components: {
    ErrorComponent,
    Pagination,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      detail: {},
      userList: [],
      attendees: [],
      isCheckAll: false,
      searchObj: {
        keyword: "",
        offset: 0,
      },
      meetingId: this.$route.params.id,
      group_id: localStorage.getItem("group_id"),
    };
  },
  mounted() {
    this.getList(0);
    this.$store.commit("showSidebar", true);
    // this.groupId = this.$route.query.id;
    // console.log("group_id" + this.group_id);
  },

  methods: {
    savAttendance() {
      var attendance = [];
      this.userList.forEach((obj) => {
        if (obj.has_attend_meeting == 1) {
          attendance.push(obj.id);
        }
      });
      console.log("attendance ids " + attendance.length, attendance);
      if (attendance.length == 0) {
        this.$notify({
          type: "error",
          text: "Please select at-least one member.",
        });
      }

      this.$api
        .postAPI({
          _action:
            "business-work-group/" +
            this.group_id +
            "/meeting/" +
            this.meetingId +
            "/attendees",
          _body: { attendees: attendance },
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.addMeeting.closeModal();
          }
        })
        .catch(() => {});
    },
    updateCheck(event, data) {
      if (data.has_attend_meeting == 1) {
        data.has_attend_meeting = 0;
      } else {
        data.has_attend_meeting = 1;
      }
      var has_all_checked = true;
      this.userList.forEach((obj) => {
        if (obj.has_attend_meeting == 0) {
          has_all_checked = false;
        }
      });

      console.log("Attendees" + this.attendees);
      this.isCheckAll = has_all_checked;
    },

    selectAll(event) {
      console.log(event);
      var checked = 0;
      if (event.target.checked) {
        checked = 1;
      }
      this.userList.forEach((obj) => {
        if (obj.has_attend_meeting != checked) {
          obj.has_attend_meeting = checked;
        }
      });
      console.log("Attendees" + this.attendees);
    },

    pageChange(offset) {
      this.getList(offset - 1);
    },

    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      // this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action:
            "business-work-group/" +
            this.group_id +
            "/meeting/" +
            this.meetingId +
            "/attendees",
          _body: { offset: offset },
        })
        .then((res) => {
          this.loader = false;
          this.userList = res.list;
          this.isCheckAll = false;

          var has_all_checked = true;
          this.userList.forEach((obj) => {
            if (obj.has_attend_meeting == 0) {
              has_all_checked = false;
            }
          });

          this.isCheckAll = has_all_checked;

          this.searchObj.offset = res.next_offset;
          // if (offset == 0) {
          //   if (this.$refs.listPagination) {
          //     if (res.count != null && res.count > 0) {
          //       this.$refs.listPagination.setTotalCount(res.count);
          //     } else {
          //       this.$refs.listPagination.setTotalCount(0);
          //     }
          //   }
          // }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No User available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
  },
};
</script>
