<template>
  <meta property="og:url" :content="location" />
  <meta property="og:description" :content="data.description" />
  <meta property="og:image" :content="data.info.image" />
  <meta property="og:title" :content="data.title" />
  <div class="card card-body events-card mb-4">
    <div class="row mb-md-0 mb-3">
      <div class="col-md-8">
        <p class="font-18 font-medium mb-2">{{ data.info.name }}</p>
      </div>
      <div class="col-md-4 text-md-right">
        <p class="font-14 mb-2 font-xs-12">{{ data.info.ago_time }}</p>
        <p v-show="data.info.isShow" v-on:click="showSharePopup(data)">
          <a><img src="/img/share-icon.png" /></a>
        </p>
        <span class="badge badge-light-yellow mb-2 ml-2">{{
          data.info?.category?.name
        }}</span>
      </div>
    </div>
    <div class="position-relative mb-3 cursor-pointer" v-on:click="goToDetail(data.info.id)">
      <img :src="data.info.image ? data.info.image : '/img/placeholder-image.jpg'" class="img-fluid card-img" alt="" />
    </div>

    <p class="font-24 font-bold mb-md-3 mb-2" v-show="data.info.type != 'FREE'">
      ₹{{ data.info.price }}
    </p>
    <h5 class="text-secondary mb-md-2 mb-1" v-show="data.info.type != 'FREE'">+GST</h5>
    <p class="font-24 font-bold mb-md-3 mb-2" v-show="data.info.type == 'FREE'">
      {{ data.info.type }}
    </p>

    <div class="row">
      <div class="col-md-7 mb-md-0 mb-3">
        <p class="text-secondary mb-md-2 mb-1">
          <img src="/images/calendar.svg" width="14" class="align-baseline mr-1" />
          {{ $helperService.getFormatedDateAndTime(data.info.from_datetime) }}
        </p>
      </div>
      <div class="col-md-5 text-right">
        <button v-show="!data.info.has_register &&
          data.info.isShow &&
          data.info.event_registration_closed == 'N'
          " class="btn btn-brand-1 w-xs-100" id="register-button" v-on:click="register(data.info)">
          Register
        </button>
        <button v-show="data.info.has_register &&
          data.info.isShow &&
          data.info.webinar_link
          " class="btn btn-brand-1 w-xs-100" id="register-button" v-on:click="joinNow(data.info.webinar_link)">
          Join Now
        </button>
        <button v-show="!data.info.isShow && data.info.video_recording" class="btn btn-brand-1 w-xs-100"
          id="register-button" v-on:click="viewRecording(data.info.video_recording)">
          View Recording
        </button>
        <div class="alert alert-success py-1 font-14 d-inline-block px-2" v-if="data.event_type == 'INTERNAL' && data.info.has_register">
          You are already registered for this event
        </div>
        <p v-show="!data.info.has_register &&
          data.info.event_registration_closed == 'Y' &&
          data.info.isShow
          ">
          Event Registration Closed.
        </p>
      </div>
    </div>
  </div>
  <ShareModal ref="shareModal" />
</template>
<script>
import ShareModal from "./SocialShare/ShareModal.vue";
export default {
  name: "EventCard",
  metaInfo: {
    title: "Hello World 2!",
  },
  components: { ShareModal },
  props: ["data", "currentTab"],
  data() {
    return {
      currentDate: new Date(),
      accessToken: localStorage.getItem("access_token"),
      location: "",
    };
  },
  mounted() {
    this.location = window.location.href;
  },
  methods: {
    register(data) {
      if (!this.accessToken) {
        this.$sharedService.setMapValue("event_detail", data);
        this.$router.push("/guest-signup?type=" + "BUSINESS_CONCLAVE" + "&id=" + data.id);
      } else {
        this.$router.push("/webinar-details/" + data.id);
      }
    },
    joinNow(link) {
      window.open(link, "_blank");
    },
    goToDetail(id) {
      localStorage.setItem("currentTab", this.currentTab);
      this.$router.push("/webinar-details/" + id);
    },
    viewRecording(recording) {
      window.open(recording, "_blank");
    },
    showSharePopup(data) {
      console.log("url", window.location.href + "  " + data);

      var url =
        "https://app.tenxindia.com/shared-event?id=" + data.id + "&type=" + "WEBINAR";
      var title =
        "TenX is organizing a webinar we would be glad if you could join and participate. To save your seat, please register and feel free to share it with your friends.";
      this.$refs.shareModal.showModal(url, title);

    },
  },
};
</script>
