<template>
  <div>
    <router-view/>
  </div>
  <notifications position="top right" classes="vue-notification mt-3" />
</template>

<script>
    export default {
        name: 'App'
    }
</script>