<template>
  <div>
    <OuterHeader />
    <!-- <header
      class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100"
      style="z-index: 1"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img
              src="/images/tenx-logo.svg"
              width="80"
              height="26"
              class="img-fluid"
            />
          </div>
          <div class="col-md-9 col-9 text-right">
            <a class="mr-3">Signup</a>
            <a class="header-btn" v-on:click="$router.push('/login')">Login</a>
          </div>
        </div>
      </div>
    </header> -->
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center bg-white">
            <div class="col-md-6 bg-white">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <div class="px-lg-5 px-0 py-4">
                    <div class="card-body pb-0 pt-lg-5">
                      <form class="form-style floating-form">
                        <Form @submit="signup">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="gst_number_id"
                                  v-model="detail.gst_number"
                                  v-on:keyup="getCompany(detail.gst_number)"
                                  name="gst_number"
                                />
                                <label for="gst_number">GST</label>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="floating-form floating-form-error">
                                <div class="floating-form-group">
                                  <Field
                                    type="text"
                                    id="fname"
                                    name="firstname"
                                    v-model="detail.first_name"
                                    rules="required:first name|userName"
                                    :validateOnInput="true"
                                  />
                                  <label for="fname">First Name</label
                                  ><span class="text-danger">*</span>
                                </div>
                              </div>
                              <ErrorMessage name="firstname" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  :class="detail.last_name ? 'filled' : ''"
                                  id="lname"
                                  name="lastname"
                                  v-model="detail.last_name"
                                  rules="required:last name|userName"
                                  :validateOnInput="true"
                                />
                                <label for="lname">Last Name</label
                                ><span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="lastname" class="validation-msg" />
                            </div>
                            <div class="col-12">
                              <div class="floating-form-group">
                                <Field
                                  type="email"
                                  id="email"
                                  name="email"
                                  v-model="detail.email"
                                  rules="required:email"
                                  :validateOnInput="true"
                                />
                                <label for="email">Email</label
                                ><span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="email" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="phone"
                                  name="phone"
                                  v-model="detail.phone"
                                  rules="required:contact number|phone"
                                  :validateOnInput="true"
                                />
                                <label for="phone">Phone</label
                                ><span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="phone" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <div class="floating-form-group with-icon">
                                <vue-google-autocomplete
                                  ref="address"
                                  id="map"
                                  classname="form-control"
                                  placeholder=""
                                  v-model="detail.city_name"
                                  v-on:placechanged="getAddressData"
                                  types="(cities)"
                                  country="in"
                                >
                                </vue-google-autocomplete>
                                <img
                                  src="/images/location-icon.svg"
                                  width="20"
                                  class="icon"
                                />
                                <label for="city">City</label
                                ><span class="text-danger">*</span>
                                <!-- <place-autocomplete-field
                                  placeholder="City"
                                  label="City"
                                  name="city"
                                  style="margin-top: -7%"
                                  api-key="AIzaSyC7dXZ5VVpf5ILgqLTacCyQwPzXLIghKfg"
                                  v-model="detail.city_name"
                                  rules="required:company"
                                  :validateOnInput="true"
                                ></place-autocomplete-field> -->
                              </div>
                              <ErrorMessage name="city" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="compny_name"
                                  placeholder=""
                                  classname="form-control"
                                  :class="detail.company_name ? 'filled' : ''"
                                  v-model="detail.company_name"
                                  rules="required:company"
                                  name="company"
                                  :validateOnInput="true"
                                />
                                <label for="compny_name">Company Name</label
                                ><span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="company" class="validation-msg" />
                            </div>

                            <div class="col-md-12">
                              <div class="floating-form-group mb-4">
                                <Field
                                  :type="newPasswordFieldType"
                                  id="password"
                                  name="password"
                                  v-model="detail.password"
                                  v-on:keydown.space="$event.preventDefault()"
                                  rules="required:password|minLength:6|password"
                                  :validateOnInput="true"
                                />

                                <label for="password">Password</label
                                ><span class="text-danger">*</span>
                                <a class="psw-icon" v-on:click="switchVisibilityNew"
                                  ><img :src="imageNew" width="20"
                                /></a>
                              </div>
                              <ErrorMessage name="password" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group mb-4">
                                <Field
                                  :type="passwordFieldType"
                                  id="conf_password"
                                  name="confirm_password"
                                  v-on:keydown.space="$event.preventDefault()"
                                  rules="required:confirm password|confirmed:@password"
                                  :validateOnInput="true"
                                />
                                <label for="conf_password">Confirm Password</label>
                                <span class="text-danger">*</span>
                                <a class="psw-icon" v-on:click="switchVisibility"
                                  ><img :src="image" width="20"
                                /></a>
                              </div>
                              <ErrorMessage
                                name="confirm_password"
                                class="validation-msg"
                              />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group mb-4">
                                <Field
                                  type="text"
                                  id="refral_code"
                                  classname="form-control"
                                  v-model="detail.referral_code"
                                  :class="detail.referral_code ? 'filled' : ''"
                                  name="refral_code"
                                />
                                <label for="refral_code">Referral Code</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-row mb-5">
                            <vue-recaptcha
                              @verify="verify"
                              sitekey="6LdHzdgkAAAAAPlZTSTvwJeq0Cuhz5gh_BPullAZ"
                              :loadRecaptchaScript="true"
                            >
                            </vue-recaptcha>
                          </div>
                          <label v-show="isError" class="text-danger"
                            >Please verify captcha</label
                          >

                          <button
                            class="btn btn-brand-1 btn-block mb-3"
                            id="signup-button"
                          >
                            Signup
                          </button>
                        </Form>
                      </form>
                    </div>
                    <div class="card-footer text-center bg-white border-0">
                      <div class="links">
                        Already have an account?
                        <a
                          class="ml-2 text-primary font-medium"
                          v-on:click="$router.push('/login')"
                          style="cursor: pointer"
                        >
                          Login</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 login-img-col"></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<style scoped>
.floating-form .floating-form-group input:-webkit-autofill ~ label {
  font-size: 14px;
  position: absolute;
  padding: 5px 15px;
  line-height: 20px;
}
</style>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import OuterHeader from "@/components/OuterHeader";
import { Form, Field, ErrorMessage } from "vee-validate";
import { VueRecaptcha } from "vue-recaptcha";
import $ from "jquery";

import useEventsBus from ".././../../eventBus";
var { bus } = useEventsBus();
var md5 = require("md5");
export default {
  name: "SignUp",
  components: {
    Form,
    Field,
    ErrorMessage,
    OuterHeader,
    VueGoogleAutocomplete,
    VueRecaptcha,
  },
  data() {
    return {
      id: this.$route.params.id,
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      latitude: "",
      city: "",
      longitude: "",
      image: "/images/show.png",
      imageNew: "/images/show.png",
      detail: {
        signup_type: "EMAIL",
        dial_code: "+91",
        country_code: "IN",
        referral_code: this.$route.query.refer_code,
      },
      isVerify: false,
      isError: false,
    };
  },

  watch:
    (() => bus.value.get("sidebarCollapsed"),
    (val) => {
      // destruct the parameters
      alert(val);
    }),

  mounted() {
    console.log(this.bus);
    this.$refs.address.focus();

    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    verify() {
      this.isVerify = true;
      this.isError = false;
    },
    getAddressData: function (addressData, placeResultData) {
      this.detail.city_name = placeResultData.formatted_address;
      this.detail.latitude = addressData.latitude;
      this.detail.longitude = addressData.longitude;
    },
    signup() {
      if (!this.isVerify) {
        this.isError = true;
        return;
      }

      if (!this.detail.latitude || !this.detail.longitude) {
        this.$notify({
          type: "error",
          text: "Please select city from geo location",
        });
        return false;
      }

      this.$api
        .postAPI({
          _action: "signup",
          _body: this.detail,
          _buttonId: "signup-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("access_token", res.access_token);
            this.getMe();
          }
        })
        .catch(() => {});
    },
    getCompany(e) {
      if (e.length == 15) {
        var ENCRYPTION_KEY = "Sph!nx@T@nX";
        var keygstmd5 = md5(e + "-" + ENCRYPTION_KEY);
        this.$api
          .getAPI({
            _action: "search/gst/" + e,
            _header: { "x-api-key": keygstmd5 },
          })
          .then((res) => {
            console.log("company", res);
            this.detail.company_name = res.company_name;
          })
          .catch(() => {});
      }
    },
    getIndustryList() {
      this.$api
        .getAPI({
          _action: "category/industry",
        })
        .then((res) => {
          this.industryList = res.list;
        })
        .catch(() => {});
    },
    getMe() {
      this.$api
        .getAPI({
          _action: "me",
          _buttonId: "signup-button",
        })
        .then((res) => {
          if (
            res &&
            res.response &&
            res.response.companies &&
            res.response.companies.length > 0
          ) {
            localStorage.setItem("companyId", res.response.companies[0].id);
          } else {
            localStorage.removeItem("access_token");
          }
          if (res.response.user_type == "USER") {
            this.$router.push("/home");
          } else {
            this.$router.push("/select-plan");
            if (res && res.response && res.response.user_subscription) {
              this.$router.push("/home");
            }
          }
        })
        .catch(() => {
          localStorage.removeItem("access_token");
        });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
  },
};
</script>
