<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">About TenX</h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card mb-4 rounded-0 border-0 bg-transparent">
            <div class="card-header p-0 border-0">
              <img
                src="/images/about.png"
                class="card-img-top rounded-common"
                alt=""
              />
            </div>
            <div class="card-body p-0">
              <h4 class="font-20 mt-3">{{detail.title}}</h4>
             <div v-html="detail.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutTenx",
  components: {},
  data() {
    return {
      detail:{},
    };
  },
  mounted() {
     this.getDetail();
  },
  methods: {
getDetail(){
  this.$api
  .getAPI({
    _action:"content/about-us"
  })
  .then((res)=>{
    this.detail = res.info;
  })
  .catch(()=>{});
}
  },
};
</script>