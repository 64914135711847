<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-10">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">Webinar</h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end">
              <input type="text" class="search-click" name="" v-on:keyup.enter="getList(0)" placeholder="search here..."
                v-model="keyword" />
            </div>
          </div>
          <ul class="nav nav-pills filter-tabs mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                aria-controls="pills-home" aria-selected="true" :class="currentTab == 'UPCOMING' ? 'active' : ''"
                v-on:click="changeTab('UPCOMING')" style="cursor: pointer">Upcoming
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                aria-controls="pills-profile" aria-selected="false" :class="currentTab == 'PAST' ? 'active' : ''"
                v-on:click="changeTab('PAST')" style="cursor: pointer">Past</a>
            </li>
          </ul>
          <div v-for="(data, index) in eventlist" :key="index">
            <EventCard :data="data" :currentTab="currentTab" />
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 mb-4">
      <div class="col-lg-12 mt-5 text-center">
        <div class="page-bottom-pagination text-right">
          <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange" ref="listPagination" />
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getList()" ref="errorComponent" />
    <TextModal ref="filterModal">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" v-on:click="$refs.filterModal.closeModal()">
            <img src="/images/close.svg" width="15" />
          </button>
        </div>
        <div class="modal-body form-style pb-2 pt-2">
          <h4 class="font-22 modal-tital text-center">Filter</h4>
          <p class="font-medium mb-3">Category</p>
          <div class="text-center interest-tags">
            <button type="button" class="btn btn-outline-rounded-color mr-2 mb-2" v-on:click="checked(data)"
              v-for="(data, index) in categoryList" :key="index" :class="data.isChecked == true ? 'active' : ''">
              {{ data.name }}
            </button>
          </div>
          <div>
            <hr />
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button type="button" class="btn btn-outline-brand-1 px-4" data-dismiss="modal" v-on:click="
            $refs.filterModal.closeModal();
          getList(0);
          ">
            Apply
          </button>
        </div>
      </div>
    </TextModal>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import TextModal from "@/components/TextModal.vue";
import Pagination from "@/components/Pagination";
import EventCard from "@/components/EventCard";
import moment from "moment";

export default {
  name: "TenxEvent",
  components: {
    TextModal,
    ErrorComponent,
    Pagination,
    EventCard,
  },
  data() {
    return {
      loader: true,
      offset: -1,
      categoryList: [],
      currentTab: "UPCOMING",
      keyword: "",
      eventlist: [],
    };
  },
  mounted() {
    if (localStorage.getItem("currentTab") != null) {
      this.currentTab = localStorage.getItem("currentTab");
      localStorage.removeItem("currentTab");
    }

    this.getDetails();
    this.$store.commit("showSidebar", true);
  },
  methods: {
    getDetails() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.getCatetoryList(res.response);
        })
        .catch(() => { });
    },
    changeTab(tab) {
      this.currentTab = tab;
      this.getList(0);
    },
    remove(data) {
      data.isChecked = false;
      this.getList(0);
    },
    checked(data) {
      data.isChecked = !data.isChecked;
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getCatetoryList(detail) {
      this.$api
        .getAPI({
          _action: "category/event",
        })
        .then((res) => {
          for (var i in res.list) {
            var isChecked = false;
            for (var j in detail.interests) {
              if (detail.interests[j].interest_id == res.list[i].id) {
                isChecked = true;
              }
            }

            res.list[i].isChecked = isChecked;
          }

          this.categoryList = res.list;
          this.getList(0);
        })
        .catch(() => { });
    },
    getList(offset, orderBy, sortBy) {
      var searchObj = {
        category_id: "",
      };
      if (this.currentTab == "UPCOMING") {
        searchObj.event_list_type = "UPCOMING";
      } else if (this.currentTab == "PAST") {
        searchObj.event_list_type = "PAST";
      } else {
        searchObj.event_list_type = "ALL";
      }

      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }

      if (offset == 0) {
        this.eventlist = [];
      }
      searchObj.category_id = "";
      searchObj.offset = offset;
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }

      for (var i in this.categoryList) {
        if (this.categoryList[i].isChecked == true) {
          if (searchObj.category_id) {
            searchObj.category_id += "," + this.categoryList[i].id;
          } else {
            searchObj.category_id = this.categoryList[i].id;
          }
        }
      }

      this.$refs.errorComponent.updateLoader(true);

      if (offset == 0) {
        this.setInterest(searchObj.category_id);
      }

      this.$api
        .getAPI({
          _action: "events",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.eventlist = res.list;
          for (var j in this.eventlist) {
            this.eventlist[j].info = this.eventlist[j];
          }
          if (this.eventlist.length > 0) {
            for (var i in this.eventlist) {
              if (this.eventlist[i].info) {

                this.eventlist[i].info.isShow = false;
                if (this.eventlist[i].info.event_type == "INTERNAL") {
                  if (moment(this.eventlist[i].info.from_datetime, "YYYY-MM-DD HH:mm:ss") >= moment(new Date())) {
                    this.eventlist[i].info.isShow = true;
                  }
                } else if (this.eventlist[i].info.event_type == "EXTERNAL") {
                  var date1 = new Date(this.eventlist[i].info.from_datetime);
                  var date2 = new Date();
                  if (moment(String(date1)).format("Y-MM-DD") == moment(String(date2)).format("Y-MM-DD")) {
                    this.eventlist[i].info.isShow = true;
                  }
                }

              }
            }
          }
          this.offset = res.next_offset;
          if (offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No events available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    showFilterPopup() {
      this.$refs.filterModal.showModal();
    },
    setInterest(data) {
      var obj = {};
      obj.interest_ids = data;
      this.$sharedService.setMapValue("interest_ids", data);
      this.$api
        .postAPI({
          _action: "user/interest",
          _body: obj,
        })
        .then(() => { })
        .catch(() => { });
    },
    register(id) {
      this.$router.push("/webinar-details/" + id);
      localStorage.setItem("currentTab", this.currentTab);
      console.log(
        "this.currentTab register",
        localStorage.getItem("currentTab")
      );
      console.log("this.currentTab register", this.currentTab);
    },
  },
};
</script>