<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <ul
              class="nav nav-pills mb-3 tab-style-1"
              id="login-tab"
              role="tablist"
            >
              <li class="nav-item w-40 text-center" role="presentation">
                <a
                  class="nav-link"
                  id="prod_tab"
                  :class="{ active: currentTab == 'PROFILE' }"
                  v-on:click="changeTab('PROFILE')"
                  style="cursor: pointer"
                  >My Profile
                </a>
              </li>
              <li class="nav-item w-60 text-center" role="presentation">
                <a
                  class="nav-link"
                  id="serv_tab"
                  :class="{ active: currentTab == 'COMPANY' }"
                  v-on:click="changeTab('COMPANY')"
                  style="cursor: pointer"
                  >Company Profile
                </a>
              </li>
            </ul>
          </div>
          <div class="card mb-4 rounded-l border-0 shadow-sm">
            <!-- <div class="card-header p-0 border-0 mb-n5">
              <img
                src="/images/contact-banner.png"
                class="card-img-top rounded-l"
                alt=""
              />  
            </div> -->
            <div class="card-body px-md-5 -x3" v-show="currentTab == 'PROFILE'">
              <Form class="form-style floating-form" @submit="update()">
                <div class="row justify-content-center mt-n5">
                  <div class="col-lg-6 col-md-5 mb-4 text-center">
                    <div class="mb-3">
                      <!-- <div class="col-lg-12 col-sm-12 mb-3">
                          <div class="text-center mb-4"> -->
                      <img
                        :src="
                          detail.profile_img && detail.profile_img != null
                            ? detail.profile_img
                            : '/images/profile.svg'
                        "
                        id="preview"
                        class="img-thumbnail object-cover"
                        style="
                          border-radius: 100px;
                          width: 120px;
                          height: 120px;
                        "
                      /><br />
                    </div>
                    <div class="row col-12 justify-content-center">
                      <h6>Preferred Image Size : 500px X 500 px</h6>
                    </div>
                    <div id="image-form" style="margin: 0">
                      <input
                        type="file"
                        name="img[]"
                        class="file"
                        id="pickFile"
                        @change="selectedFile($event)"
                        accept="image/*"
                        style="display: none"
                      />

                      <div class="changePic" style="bottom: -25px">
                        <label for="pickFile" style="color: blue">
                          Change Photo</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row col-12 justify-content-center mb-2"
                  style="height: 50px"
                >
                  <circle-progress
                    :percent="detail.profile_complete_percent"
                    :show-percent="true"
                    :is-shadow="true"
                    :shadow="{
                      inset: false,
                      vertical: 1,
                      horizontal: 1,
                      blur: 4,
                      opacity: 0.4,
                      color: '#000000',
                    }"
                  />
                </div>
                <div
                  class="row col-12 justify-content-center mb-2"
                  style="height: 50px"
                >
                  <a
                    class="ml-2 text-primary font-medium"
                    v-on:click="$refs.viewModal.showModal()"
                    style="cursor: pointer"
                    >View</a
                  >
                </div>
                <div class="row justify-content-center">
                  <div class="col-md-10">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="floating-form floating-form-error">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              id="fname"
                              name="firstname"
                              v-model="detail.first_name"
                              class="filled"
                              rules="required:first name|userName"
                              :validateOnInput="true"
                              disabled
                            />
                            <label for="fname">First Name</label
                            ><span class="text-danger">*</span>
                          </div>
                        </div>
                        <ErrorMessage name="firstname" class="validation-msg" />
                      </div>
                      <div class="col-md-6">
                        <div class="floating-form-group">
                          <Field
                            type="text"
                            class="filled"
                            id="lname"
                            name="lastname"
                            v-model="detail.last_name"
                            rules="required:last name|userName"
                            :validateOnInput="true"
                            disabled
                          />
                          <label for="lname">Last Name</label
                          ><span class="text-danger">*</span>
                        </div>
                        <ErrorMessage name="lastname" class="validation-msg" />
                      </div>
                      <div class="col-md-6">
                        <div class="floating-form-group">
                          <Field
                            type="email"
                            id="email"
                            class="filled"
                            name="email"
                            v-model="detail.email"
                            rules="required:email"
                            :validateOnInput="true"
                          />
                          <label for="email">Email</label
                          ><span class="text-danger">*</span>
                        </div>
                        <ErrorMessage name="email" class="validation-msg" />
                      </div>
                      <div class="col-md-6">
                        <div class="floating-form-group">
                          <Field
                            type="text"
                            id="phone"
                            class="filled"
                            name="phone"
                            v-model="detail.phone"
                            rules="required:phone number|phone"
                            :validateOnInput="true"
                          />
                          <label for="phone">Phone</label
                          ><span class="text-danger">*</span>
                        </div>
                        <ErrorMessage name="phone" class="validation-msg" />
                      </div>
                      <div class="col-md-12">
                        <div class="floating-form-group with-icon">
                          <vue-google-autocomplete
                            ref="address"
                            id="map"
                            class="filled"
                            classname="form-control"
                            v-on:placechanged="getAddressData"
                            types="(cities)"
                            country="in"
                          >
                          </vue-google-autocomplete>
                          <img
                            src="/images/location-icon.svg"
                            width="20"
                            class="icon"
                          />
                          <label for="city">City</label
                          ><span class="text-danger">*</span>
                        </div>
                        <ErrorMessage name="city" class="validation-msg" />
                      </div>
                      <div class="col-12">
                        <div class="floating-form-group">
                          <Field
                            type="text"
                            id="about"
                            class="filled"
                            v-model="detail.about_me"
                            rules="required:about me"
                            name="about"
                            :validateOnInput="true"
                          />
                          <label for="about">About</label
                          ><span class="text-danger">*</span>
                        </div>
                        <ErrorMessage name="about" class="validation-msg" />
                      </div>
                    </div>
                    <div class="row col-12">
                      <div class="col-6 d-flex">
                        <p class="font-medium mr-2">Hide Phone Number</p>
                        <div class="custom-control custom-switch mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="switch_1"
                            true-value="Y"
                            false-value="N"
                            v-model="detail.hide_phone_number"
                            v-on:change="checkPhoneNumber()"
                          />
                          <label
                            class="custom-control-label"
                            for="switch_1"
                          ></label>
                        </div>
                      </div>
                      <div class="col-6 d-flex justify-content-end">
                        <div class="text-right cursor-pointer">
                          <a
                            style="cursor-pointer"
                            v-on:click="changePasswordPopup()"
                            >Change Password</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="text-center mt-3">
                      <button
                        id="update-button"
                        class="btn btn-brand-1 mb-3 px-5"
                      >
                        Update
                      </button>
                    </div>
                    <div class="text-center cursor-pointer">
                      <a
                        style="cursor-pointer"
                        v-on:click="this.showDeleteAccount(detail)"
                        >Delete Account</a
                      >
                    </div>
                  </div>
                </div>
              </Form>
              <DeleteModal @remove="deleteAccount" ref="deleteAccount" />
            </div>
            <div class="card border-0 mb-4" v-show="currentTab == 'COMPANY'">
              <div
                class="card-body"
                v-for="(data, index) in company_detail"
                :key="index"
              >
                <div class="row">
                  <div
                    class="
                      col-md-11
                      d-md-flex
                      align-items-center
                      text-md-left text-center
                    "
                  >
                    <img
                      :src="data.logo ? data.logo : '/images/profile.svg'"
                      alt=""
                      class="object-contain border border-light mb-md-0 mb-2"
                      width="160"
                      height="80"
                    />
                    <div class="pl-3">
                      <p class="font-medium mb-2 font-18 font-xs-16">
                        {{ data.company_name }}
                      </p>
                      <p class="text-dark mb-md-2 mb-2 font-14">
                        {{ data.sales_email }}
                      </p>
                      <p class="text-dark mb-md-2 mb-2 font-14">
                        {{ data.sales_dial_code }}{{ data.sales_contact_no }}
                      </p>
                    </div>
                  </div>
                  <div class="position-absolute" style="right: 15px">
                    <a
                      style="cursor-pointer"
                      v-on:click="
                        this.$router.push('/editCompanyProfile/' + data.id)
                      "
                      ><img src="images/edit-icon.svg" width="20" alt=""
                    /></a>
                    <a
                      style="cursor-pointer"
                      v-show="this.company_id != data.id"
                      v-on:click="showDeletePopup(data)"
                      ><img src="/images/delete.svg" width="20" alt=""
                    /></a>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-3 col-7">
                    <p class="font-14 font-xs-12 text-secondary-2 mb-0">
                      Industry
                    </p>
                    <p class="font-medium mb-0 font-xs-14">
                      {{ data.industry?.name }}
                      <!-- industry -->
                    </p>
                  </div>
                  <div class="col-md-9 col-5">
                    <p class="font-14 font-xs-12 text-secondary-2 mb-0">
                      No of Employees
                    </p>
                    <p class="font-medium font-xs-14 mb-0">
                      {{ data.no_of_employees }}
                      <!-- no of employees -->
                    </p>
                  </div>
                </div>
                <hr />
              </div>

              <div class="text-center pb-4">
                <button
                  type="button"
                  class="btn btn-brand-1 px-4"
                  v-on:click="addCompany()"
                >
                  + Add Company Profile
                </button>
              </div>
              <ErrorComponent @retry="getCompanies(0)" ref="errorComponent" />
              <DeleteModal @remove="deleteRecord" ref="deleteModal" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="textModal" class="modal-small">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="$refs.textModal.closeModal"
        >
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-4">
        <h5 class="modal-title font-22 mb-4">Successful!!</h5>
        <img src="/images/delete-acc.png" alt="" width="75" class="mb-3" />
        <p class="font-18 mb-0">
          Account has been deleted successfully!<br />
          <!-- <span class="font-14"
                        >Please click on below link to join the webinar</span
                      > -->
        </p>
      </div>
      <div
        class="
          modal-footer
          justify-content-center
          text-center
          border-0
          pb-4
          pt-0
          d-block
          font-14
          text-break
          px-md-4
        "
      >
        <a
          ><button
            class="btn btn-brand-1 w-xs-100"
            data-toggle="modal"
            data-target="#modal_thankyou"
            id="register-button"
            v-on:click="redirect()"
          >
            Ok
          </button></a
        >
      </div>
    </div>
  </TextModal>
  <TextModal ref="passwordModal">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="$refs.passwordModal.closeModal()"
        >
          <img src="/images/close.svg" width="15" />
        </button>
        <h5 class="modal-title">Change Password</h5>
      </div>
      <div class="modal-body px-md-4">
        <Form
          @submit="changePassword()"
          ref="passwordForm"
          class="form-style floating-form"
        >
          <div class="floating-form-group mb-4">
            <label>Current Password</label>
            <Field
              :type="currentPasswordFieldType"
              id="curr_password"
              name="current_passwordn"
              placeholder="Current Password"
              v-model="passObj.current_password"
              v-on:keydown.space="$event.preventDefault()"
              rules="required:current password|password"
              :validateOnInput="true"
            />
            <ErrorMessage name="current_passwordn" class="validation-msg" />
            <a class="psw-icon" v-on:click="switchVisibilityNew"
              ><img :src="imageNew" width="20"
            /></a>
          </div>
          <div class="floating-form-group mb-4">
            <label>New Password</label>
            <Field
              :type="newpasswordFieldType"
              id="conf_password"
              placeholder="New Password"
              name="confirm_password"
              v-model="passObj.new_password"
              v-on:keydown.space="$event.preventDefault()"
              rules="required:new password|minLength:6|password"
              :validateOnInput="true"
            />
            <ErrorMessage name="confirm_password" class="validation-msg" />
            <a class="psw-icon" v-on:click="switchVisibility"
              ><img :src="image" width="20"
            /></a>
          </div>
          <button
            class="btn btn-brand-1 btn-block mb-4"
            id="submit-button"
            style="cursor: pointer"
          >
            Submit
          </button>
        </Form>
      </div>
    </div>
  </TextModal>
  <TextModal ref="viewModal" class="modal-small">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="$refs.viewModal.closeModal"
        >
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-left px-md-4">
        <ul class="nav-bar pl-1" id="leftNav" style="list-style-type: none">
          <li class="nav-item">
            <img
              :src="
                profile_fraction.has_product_services
                  ? '/images/check-outline.svg'
                  : '/images/close-outline.svg'
              "
              width="20"
              class="side-nav-icon"
            />&nbsp;&nbsp;<span class="hide-menu">Product / Service</span>
          </li>
          <li class="nav-item">
            <img
              :src="
                profile_fraction.has_company_about
                  ? '/images/check-outline.svg'
                  : '/images/close-outline.svg'
              "
              width="20"
              class="side-nav-icon"
            />&nbsp;&nbsp;<span class="hide-menu">Company About</span>
          </li>
          <li class="nav-item">
            <img
              :src="
                profile_fraction.has_company_logo
                  ? '/images/check-outline.svg'
                  : '/images/close-outline.svg'
              "
              width="20"
              class="side-nav-icon"
            />&nbsp;&nbsp;<span class="hide-menu">Company Logo</span>
          </li>
          <li class="nav-item">
            <img
              :src="
                profile_fraction.has_profile_image
                  ? '/images/check-outline.svg'
                  : '/images/close-outline.svg'
              "
              width="20"
              class="side-nav-icon"
            />&nbsp;&nbsp;<span class="hide-menu">Profile Image</span>
          </li>
          <li class="nav-item">
            <img
              :src="
                profile_fraction.has_susbcription
                  ? '/images/check-outline.svg'
                  : '/images/close-outline.svg'
              "
              width="20"
              class="side-nav-icon"
            />&nbsp;&nbsp;<span class="hide-menu">Subscription</span>
          </li>
          <li class="nav-item">
            <img
              :src="
                profile_fraction.has_company_address
                  ? '/images/check-outline.svg'
                  : '/images/close-outline.svg'
              "
              width="20"
              class="side-nav-icon"
            />&nbsp;&nbsp;<span class="hide-menu">Company Address</span>
          </li>
        </ul>
      </div>
      <div
        class="
          modal-footer
          justify-content-center
          text-center
          border-0
          pb-4
          pt-0
          d-block
          font-14
          text-break
          px-md-4
        "
      >
        <a
          ><button
            class="btn btn-brand-1 w-xs-100"
            data-toggle="modal"
            data-target="#modal_thankyou"
            id="register-button"
            v-on:click="$refs.viewModal.closeModal"
          >
            Ok
          </button></a
        >
      </div>
    </div>
  </TextModal>
</template>
<script>
import DeleteModal from "@/components/DeleteModal";
import ErrorComponent from "@/components/ErrorComponent";
import api from "../../services/WebService";
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal";
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import $ from "jquery";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    ErrorComponent,
    DeleteModal,
    CircleProgress,
    VueGoogleAutocomplete,
  },
  name: "MyProfile",
  data() {
    return {
      file: undefined,
      currentTab: "PROFILE",
      detail: {
        hide_phone_number: "N",
        dial_code: 91,
        country_code: 91,
      },
      type: "upgrade",
      loader: true,
      company_detail: {
        sales_email: "",
      },
      token: localStorage.getItem("access_token"),
      currentPasswordFieldType: "password",
      newpasswordFieldType: "password",
      image: "/images/show.png",
      imageNew: "/images/show.png",
      passObj: {
        current_password: null,
        new_password: null,
      },
      profile_fraction: {},
      city: "",
    };
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    getAddressData: function (addressData, placeResultData) {
      this.city = addressData;
      this.detail.city_name = placeResultData.formatted_address;
      this.detail.latitude = addressData.latitude;
      this.detail.longitude = addressData.longitude;
    },
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "PROFILE") {
        this.getDetails();
      } else if (tab == "COMPANY") {
        this.getCompanies();
      }
    },
    switchVisibility() {
      this.newpasswordFieldType =
        this.newpasswordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    switchVisibilityNew() {
      this.currentPasswordFieldType =
        this.currentPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    getDetails() {
      api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.detail = res.response;
          this.city = this.detail.city_name;
          $("#map").val(this.detail.city_name);
          this.profile_fraction = res.response.profile_complete_fraction;
          this.$refs.address.focus();
        })
        .catch(() => {});
    },
    update() {
      if (!this.detail.latitude || !this.detail.longitude) {
        this.$notify({
          type: "error",
          text: "Please select city from geo location",
        });
        return false;
      }

      api
        .postAPI({
          _action: "/set/profile",
          _body: this.detail,
          _buttonId: "update-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });

            if (this.file) {
              this.uploadImage();
            } else {
              this.$store.commit("setUser", this.detail);
              this.getDetails();
            }
          }
        })
        .catch(() => {});
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage() {
      if (!this.file) {
        this.$router.go(-1);
      }
      api
        .uploadImageAPI({
          _action: "set/profile/image",
          _key: "image",
          _file: this.file,
        })
        .then((res) => {
          this.detail.profile_img = res.path;
          this.$store.commit("setUser", this.detail);
          this.getDetails();
        });
    },
    getCompanies() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "user/companies",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.company_detail = res.list;
          // console.log(this.detail);
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No company profile available"
            );
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    addCompany() {
      if (
        this.detail.user_subscription?.multiple_company_display_count >
        this.company_detail.length
      ) {
        this.$router.push("/addCompanyProfile");
      } else if (
        this.detail.user_subscription?.multiple_company_display_count <=
        this.company_detail.length
      ) {
        this.$router.push("/select-plan/" + this.type);
      }
    },
    deleteRecord(data) {
      this.$api
        .deleteAPI({
          _action: "company/" + data.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getCompanies(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    deleteAccount(data) {
      console.log(data);
      this.$api
        .deleteAPI({
          _action: "account",
          _body: this.token,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteAccount.closeModal();
            this.$refs.textModal.showModal();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    showDeleteAccount(data) {
      this.$refs.deleteAccount.showModal(
        "Delete Account",
        "Are you sure you want to delete Account ",
        data
      );
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Challenge",
        "Are you sure you want to delete Company " + data.company_name,
        data
      );
    },
    redirect() {
      this.$refs.textModal.closeModal();
      this.$router.push("/login");
    },
    changePassword() {
      this.$api
        .postAPI({
          _action: "profile/password",
          _body: this.passObj,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            this.$refs.passwordModal.closeModal();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
    changePasswordPopup() {
      this.passObj.current_password = null;
      this.passObj.new_password = null;
      this.$refs.passwordForm.resetForm();
      this.$refs.passwordModal.showModal();
    },
  },
};
</script>