<template>
  <div>
    <header
      class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100"
      style="z-index: 1"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img
              src="/images/tenx-logo.svg"
              width="80"
              height="26"
              class="img-fluid"
            />
          </div>
          <!-- <div class="col-md-9 col-9 text-right">
            <a class="mr-3">Signup</a>
            <a class="header-btn" v-on:click="$router.push('/login')">Login</a>
          </div> -->
        </div>
      </div>
    </header>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center bg-white">
            <div class="col-md-6 bg-white">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <div class="px-lg-5 px-0 pt-5">
                    <div class="card-body pb-0">
                      <form class="form-style floating-form">
                        <Form @submit="save">
                          <div class="row">
                            <div class="col-12 mb-4 mt-3">
                              <h3 class="signup-title">Add business address</h3>
                            </div>
                            <div class="col-12 mb-4">
                              <div class="steps-count">
                                <div class="step-no active">1</div>
                                <div class="h-line"></div>
                                <div class="step-no">2</div>
                                <div class="h-line"></div>
                                <div class="step-no">3</div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="address"
                                  name="address_1"
                                  v-model="details.address_1"
                                  rules="required:address one"
                                  :validateOnInput="true"
                                />
                                <label for="address">Address Line 1</label>
                              </div>
                              <ErrorMessage
                                name="address_1"
                                class="validation-msg"
                              />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="address_2"
                                  name="address_2"
                                  v-model="details.address_2"
                                  rules="required:address two"
                                  :validateOnInput="true"
                                />
                                <label for="address_2">Address Line 2</label>
                              </div>
                              <ErrorMessage
                                name="address_2"
                                class="validation-msg"
                              />
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group">
                                <!-- <label for=" ">State</label> -->

                                <Field
                                  as="select"
                                  class="custom-select"
                                  v-model="details.state_id"
                                  name="state"
                                  rules="required:state,true"
                                >
                                  <option value="">Select</option>
                                  <option
                                    v-for="(data, index) in stateList"
                                    :key="index"
                                    :value="data.id"
                                  >
                                    {{ data.name }}
                                  </option>
                                </Field>
                              </div>
                              <ErrorMessage
                                name="state"
                                class="validation-msg"
                              />
                            </div>
                            <div class="col-md-6">
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="city"
                                  v-model="details.city"
                                  rules="required:city"
                                  name="city"
                                  :validateOnInput="true"
                                />
                                <label for=" ">City</label>
                              </div>
                              <ErrorMessage
                                name="city"
                                class="validation-msg"
                              />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group mb-4">
                                <Field
                                  type="text"
                                  id="pincode"
                                  name="pincode"
                                  v-model="details.pin_code"
                                  v-on:keydown.space="$event.preventDefault()"
                                  rules="required:pincode|numeric|minLength:6"
                                  :validateOnInput="true"
                                />
                                <label for="pincode">Pincode</label>
                              </div>
                              <ErrorMessage
                                name="pincode"
                                class="validation-msg"
                              />
                            </div>
                            <p
                              class="col-12 font-bold"
                              v-show="isShowLocationOption"
                            >
                              Do you have office in multiple location?
                            </p>
                            <div
                              class="col-12 mb-4"
                              v-show="isShowLocationOption"
                            >
                              <button
                                class="btn btn-outline-rounded-color"
                                v-on:click="
                                  $router.push('/add-new-business-address')
                                "
                              >
                                Yes
                              </button>
                              <button
                                type="button"
                                class="btn btn-rounded-secondary ml-3"
                                v-on:click="
                                  $router.push('/add-new-business-address')
                                "
                              >
                                No
                              </button>
                            </div>
                            <div class="col-md-6">
                              <button
                                type="button"
                                class="btn btn-outline-secondary btn-block"
                                v-on:click="
                                  $router.push('/add-new-business-address')
                                "
                              >
                                Skip
                              </button>
                            </div>
                            <div class="col-md-6">
                              <button class="btn btn-brand-1 btn-block mb-3">
                                Next
                              </button>
                            </div>
                          </div>
                        </Form>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 login-img-col"></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
    import {
        Form,
        Field,
        ErrorMessage
    } from "vee-validate";
    import $ from "jquery";
    export default {
        name: "SignupStep1",
        components: {
            Form,
            Field,
            ErrorMessage,
        },
        data() {
            return {
                id: this.$route.params.id,
                stateList: [],
                isShowLocationOption: false,
                details: {
                    city: "",
                    state_id: "",
                },
            };
        },
        mounted() {
            this.getStateList();
            //floating form
            $(document).ready(function() {
                $(
                    ".floating-form-group input,.floating-form-group textarea,.floating-form select"
                ).change(function() {
                    if ($(this).val() != "") {
                        $(this).addClass("filled");
                    } else {
                        $(this).removeClass("filled");
                    }
                });
            });
            this.$store.commit("showSidebar", false);
        },
        methods: {
            getStateList() {
                this.$api
                    .getAPI({
                        _action: "states",
                    })
                    .then((res) => {
                        this.stateList = res.list;
                    })
                    .catch(() => {});
            },
            save() {
                this.$api
                    .postAPI({
                        _action: "company/" + localStorage.getItem("companyId") + "/address",
                        _body: this.details,
                    })
                    .then((res) => {
                        if (res) {
                            this.isShowLocationOption = true;
                            // this.$router.push("/add-new-business-address");
                        }
                    })
                    .catch(() => {});
            },
        },
    };
</script>