<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-10">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt=""
                /></a>
                Upcoming Event
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div
            class="card card-body events-card mb-4"
            v-if="this.type == 'BUSINESS_CONCLAVE'"
          >
            <div class="row mb-md-0 mb-3">
              <div class="col-md-8">
                <p class="font-18 font-medium mb-2">{{ detail.name }}</p>
              </div>
              <div class="col-md-4 text-md-right">
                <p class="font-14 mb-2 font-xs-12">{{ detail.ago_time }}</p>
                <span class="badge badge-light-yellow">{{ detail.type }}</span>
              </div>
            </div>
            <div class="position-relative mb-3">
              <img
                :src="detail.image ? detail.image : '/img/placeholder-image.jpg'"
                class="img-fluid card-img"
                alt=""
              />
              <span
                class="badge bage-brand position-absolute"
                style="left: 10px; top: 10px"
                >{{
                  detail.mode == "PHYSICAL"
                    ? "Offline"
                    : $helperService.getTitleCase(detail.mode)
                }}</span
              >
            </div>
            <div class="row">
              <div class="col-md-6 mb-md-0 mb-3">
                <p class="text-secondary mb-md-2 mb-1">
                  <img
                    src="/images/calendar.svg"
                    width="14"
                    class="align-baseline mr-1"
                  />
                  {{ detail.from_datetime }}
                </p>
              </div>

              <div class="row col-md-6 text-right" style="justify-content: end">
                <p class="font-24 font-bold mb-0" v-show="detail.type != 'FREE'">
                  ₹{{ detail.price }}
                </p>
                <h5 class="text-secondary mb-md-2 mb-1" v-show="detail.type != 'FREE'">
                  +GST
                </h5>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-8 mb-md-0 mb-3 cursor-pointer"
                v-on:click="showLocation(detail)"
              >
                <p class="text-secondary mb-0">
                  <img
                    src="/images/location-icon.svg"
                    width="14"
                    class="align-baseline mr-1"
                  />{{ detail.address }}
                </p>
              </div>
              <div class="col-md-12 text-md-right">
                <hr />
              </div>
              <div class="col-md-12">
                <p>
                  {{ detail.description }}
                </p>
              </div>
              <div>
                <div class="col-md-12 mb-md-0 mb-3">
                  <div class="mb-2 mt-2" v-show="speakerList.length">
                    <h5 class="">Speakers</h5>
                  </div>
                  <div
                    v-for="(data, index) in speakerList"
                    :key="index"
                    v-on:click="
                      this.$router.push({
                        path: '/speaker-detail/' + data.speaker_id,
                        query: { business_conclave_id: detail.id },
                      })
                    "
                    class="row align-items-center pb-2 px-2 mb-2 mx-0 cursor-pointer"
                    v-show="speakerList.length"
                  >
                    <img
                      :src="
                        data.speaker.image ? data.speaker.image : '/images/profile.svg'
                      "
                      width="45"
                      height="45"
                      class="rounded-circle object-cover mr-2"
                      v-show="data.status != 'DELETED'"
                    />
                    <div class="text-left" v-show="data.speaker.status != 'DELETED'">
                      <p class="mb-0 font-medium">
                        {{ data.speaker.name }}
                      </p>
                      <p class="font-14 mb-0 text-secondary">
                        {{ data.speaker.profession }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-show="
                  (!detail.has_register || detail.has_register == 0) &&
                  detail.can_book_conclave &&
                  detail.is_slot_available
                "
                class="col-md-12 text-center"
              >
                <button
                  class="btn btn-brand-1 w-xs-100"
                  data-toggle="modal"
                  data-target="#modal_thankyou"
                  id="register-button"
                  v-on:click="register(detail)"
                >
                  Register
                </button>
              </div>

              <div v-show="detail?.participant_info?.code" class="col-md-12 text-center">
                <a :href="detail?.participant_info?.code" target="blank">
                  <img
                    :src="detail?.participant_info?.code"
                    alt=""
                    width="75"
                    class="mb-3"
                  />
                </a>
              </div>
            </div>

            <div
              v-if="
                (!detail.has_register || detail.has_register == 0) &&
                !detail.can_book_conclave
              "
              class="alert alert-danger py-1 font-14 d-inline-block px-2 p-4 text-center"
              style="margin: 0 auto"
            >
              <span style="font-size: 24px">Event Registration Closed.</span>
            </div>
            <div
              v-else-if="
                (!detail.has_register || detail.has_register == 0) &&
                !detail.is_slot_available
              "
              class="alert alert-danger py-1 font-14 d-inline-block px-2 p-4 text-center"
              style="margin: 0 auto"
            >
              <span style="font-size: 24px">Sorry, There is no slot available now.</span>
            </div>
            <div
              class="alert alert-success py-1 font-14 d-inline-block px-2 p-4 text-center"
              style="margin: 0 auto"
              v-if="detail.has_register || detail.has_register == 1"
            >
              <span style="font-size: 24px"
                >You are already registered. View your digital pass.</span
              >
            </div>
          </div>
          <div class="card card-body events-card mb-4" v-if="this.type == 'WEBINAR'">
            <div class="row mb-md-0 mb-3">
              <div class="col-md-8">
                <p class="font-18 font-medium mb-2">{{ detail.name }}</p>
              </div>
              <div class="col-md-4 text-md-right">
                <p class="font-14 mb-2 font-xs-12">{{ detail.ago_time }}</p>

                <span class="badge badge-light-yellow ml-2 mb-2">{{
                  detail?.category?.name
                }}</span>
              </div>
            </div>
            <div class="position-relative mb-3">
              <img
                :src="detail.image ? detail.image : '/img/placeholder-image.jpg'"
                class="img-fluid card-img"
                alt=""
              />
            </div>

            <div class="row">
              <div class="col-md-6 mb-md-0 mb-3">
                <p class="text-secondary mb-md-2 mb-1">
                  <img
                    src="/images/calendar.svg"
                    width="14"
                    class="align-baseline mr-1"
                  />
                  {{ detail.from_datetime }}
                </p>
              </div>

              <div class="col-md-6 text-right">
                <p class="font-24 font-bold mb-0" v-show="detail.type != 'FREE'">
                  ₹{{ detail.price }}
                </p>
                <h5 class="text-secondary mb-md-2 mb-1" v-show="detail.type != 'FREE'">
                  +GST
                </h5>
                <p class="font-24 font-bold mb-2" v-show="detail.type == 'FREE'">
                  {{ detail.type }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-md-right">
                <hr />
              </div>
              <div class="col-md-12">
                <p>
                  {{ detail.description }}
                </p>
              </div>
              <div>
                <div class="col-md-12 mb-md-0 mb-3">
                  <div class="mb-2 mt-2" v-show="speakerList.length">
                    <h5 class="">Speakers</h5>
                  </div>

                  <div
                    v-for="(data, index) in speakerList"
                    :key="index"
                    v-on:click="
                      $router.push({
                        path: '/speaker-detail/' + data.id,
                        query: { event_id: detail.id },
                      })
                    "
                    class="row align-items-center pb-2 px-2 mb-2 mx-0 cursor-pointer"
                    v-show="speakerList.length"
                  >
                    <img
                      :src="
                        data.speaker.image ? data.speaker.image : '/images/profile.svg'
                      "
                      width="45"
                      height="45"
                      class="rounded-circle object-cover mr-2"
                    />
                    <div class="text-left">
                      <p class="mb-0 font-medium">
                        {{ data.speaker.name }}
                      </p>
                      <p class="font-14 mb-0 text-secondary">
                        {{ data.speaker.profession }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 text-center">
                <button
                  v-show="
                    (!detail.has_register || detail.has_register == 0) &&
                    fromDateTime > currentDate &&
                    detail.event_registration_closed == 'N'
                  "
                  class="btn btn-brand-1 w-xs-100"
                  data-toggle="modal"
                  data-target="#modal_thankyou"
                  id="register-button"
                  v-on:click="register(detail)"
                >
                  Register
                </button>
                <button
                  v-show="
                    (detail.has_register || detail.has_register == 1) &&
                    fromDateTime > currentDate &&
                    detail.webinar_link
                  "
                  class="btn btn-brand-1 w-xs-100"
                  data-toggle="modal"
                  data-target="#modal_thankyou"
                  id="register-button"
                  v-on:click="joinNow(detail.webinar_link)"
                >
                  Join Now
                </button>
                <p
                  v-show="
                    (detail.has_register || detail.has_register == 1) &&
                    !detail.webinar_link
                  "
                >
                  You are already registered for this event
                </p>
                <p
                  v-show="
                    (!detail.has_register || detail.has_register == 0) &&
                    detail.event_registration_closed == 'Y'
                  "
                >
                  Event Registration Closed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import TextModal from "@/components/TextModal.vue";
import moment from "moment";
export default {
  name: "sharedConclave",
  components: {
    // TextModal,
  },
  data() {
    return {
      id: this.$route.query.id,
      type: this.$route.query.type,
      loader: true,
      userInfo: {},
      detail: { participant_info: {} },
      fromDateTime: new Date(),
      speakerList: [],
      accessToken: localStorage.getItem("access_token"),
      currentDate: new Date(),
      event_type: "",
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    subscribe(id) {
      if (this.detail.event_type == "INTERNAL") {
        if (this.detail.type == "FREE") {
          this.register(id);
        } else {
          var $this = this;
          var options = {
            key: "rzp_test_7TdNdMWzn9dTcQ", // Enter the Key ID generated from the Dashboard
            amount: this.detail.event_amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "Tenx",
            description: "Tenx Description",
            handler: function (response) {
              if (response.razorpay_payment_id) {
                $this.register(id);
              }
            },
            prefill: {
              name: $this.userInfo.first_name,
              email: $this.userInfo.email,
              contact: $this.userInfo.phone,
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
          };
          var rzp1 = new window.Razorpay(options);
          rzp1.on("payment.failed", function () {
            this.$noty.error("Payment Failed, Please try again");
          });

          rzp1.open();
        }
      } else {
        window.open(this.detail.webinar_link, "_blank");
      }
    },

    getDetail() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action:
            this.type == "BUSINESS_CONCLAVE"
              ? "business-conclave/" + this.id
              : "event/" + this.id,
        })
        .then((res) => {
          this.loader = false;
          this.detail = res;
          console.log(
            "from date",
            this.$helperService.getFormatedDateAndTime(this.detail.from_datetime)
          );

          this.fromDateTime = moment(this.detail.from_datetime, "YYYY-MM-DD HH:mm:ss");

          this.detail.from_datetime = moment(this.detail.from_datetime).format(
            "DD/MM/YYYY HH:mm A"
          );

          if (this.type == "BUSINESS_CONCLAVE") {
            this.speakerList = res.business_conclave_speakers;
          } else {
            this.speakerList = res.event_speakers;
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No event available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    register(data) {
      if (!this.accessToken) {
        console.log("access_token not available");
        this.$sharedService.setMapValue("event_detail", data);
        this.$router.push("/guest-signup?type=" + this.type + "&id=" + data.id);
      } else {
        this.$router.push("/business-conclave-details/" + data.id);
      }
    },
    showLocation(detail) {
      var url =
        "https://www.google.com/maps?saddr=" +
        this.$api.getLatitude() +
        "," +
        this.$api.getLongitude() +
        "&daddr=" +
        detail.address_lat +
        "," +
        detail.address_lng;

      window.open(url);
    },
  },
};
</script>
