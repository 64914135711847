<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-4 text-md-left text-center">
              <h2 class="page-title mb-md-0">Manage User</h2>
            </div>
            <div class="col-md-8 text-right d-flex justify-content-end">
              <div class="col-md-5">
                <input
                  type="text"
                  class="search-click"
                  name=""
                  v-on:keyup.enter="getList(0)"
                  placeholder="search here..."
                  v-model="searchObj.keyword"
                />
              </div>
              <!-- </div>
            <div class="col-md-6 text-md-right text-center"> -->
              <button
                class="btn"
                style="background-color: #9cc338; color: white"
                v-on:click="$router.push('/add-user')"
              >
                <i class="fa fa-plus pr-2"></i>
                Add
              </button>
            </div>
          </div>
          <!-- <div
            v-on:scroll="handleScroll($event)"
            id="chat_div"
            style="height: 60vh; overflow: scroll"
          > -->
          <div class="form-style pt-md-4 card card-body bg-white" id="login-tabContent">
            <div id="tab_1" aria-labelledby=" ">
              <table class="table table-style-1">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile Number</th>
                    <th>Designation</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loader">
                  <tr
                    class="border-bottom"
                    v-for="(data, index) in userList"
                    :key="index"
                  >
                    <td>{{ data.user.first_name }} {{ data.user.last_name }}</td>
                    <td>{{ data.user.email }}</td>
                    <td>{{ data.user.phone }}</td>
                    <td>
                      <span v-if="data.designation == null"> - </span>
                      <span v-else> {{ data.designation }} </span>
                    </td>
                    <td>
                      <div class="d-flex flex-row">
                        <button
                          style="background-color: #9cc338; color: white"
                          class="btn mr-2"
                          id="btn"
                          v-on:click="$router.push('/edit-user/' + data.user.uuid)"
                        >
                          Edit
                        </button>
                        <button
                          class="btn btn-danger"
                          id="btn"
                          v-on:click="showDeletePopup(data.user.uuid)"
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            </div>

            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
          <div class="row mx-0 mb-4"></div>
        </div>
      </div>
    </div>
  </div>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import DeleteModal from "@/components/DeleteModal";
export default {
  name: "ManageUser",
  components: {
    ErrorComponent,
    Pagination,
    DeleteModal,
  },
  data() {
    return {
      user_uuid: "",
      id: this.$route.params.id,
      loader: true,
      detail: {},
      userList: [],
      searchObj: {
        keyword: "",
        offset: 0,
      },
      companyId: "",
      showLoader: false,
    };
  },
  mounted() {
    this.getList(0);
    this.$store.commit("showSidebar", true);
    this.companyId = localStorage.getItem("compId");
  },

  methods: {
    showDeletePopup(uuid) {
      this.user_uuid = uuid;
      this.$refs.deleteModal.showModal("Delete User", "Are you sure you want to delete");
    },

    deleteRecord() {
      this.$api
        .deleteAPI({
          _action: "employee/" + this.user_uuid,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action: "/employees",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.userList = res.list;
          this.searchObj.offset = res.next_offset;
          if (offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No User available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sendRequest(data) {
      this.showLoader == true;
      var obj = {
        user_ids: [],
      };
      obj.user_ids.push(data.id);
      this.$api
        .postAPI({
          _action: "company/" + this.companyId + "/reviews",
          _body: obj,
          _buttonId: "send-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.loader = false;
        })
        .catch(() => {});
    },
  },
};
</script>
