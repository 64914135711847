<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">Offers</h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end">
            <input
              type="text"
              class="search-click"
              name=""
              v-on:keyup.enter="getOfferList(0)"
              placeholder="search here..."
              v-model="searchObj.keyword"
            />
          </div>
        </div>
        <ul
          class="nav nav-pills mb-3 tab-style-1"
          id="login-tab"
          role="tablist"
        >
          <li class="nav-item w-50 text-center" role="presentation">
            <a
              class="nav-link"
              id="prod_tab"
              :class="{ active: currentTab == 'OFFERS' }"
              v-on:click="changeTab('OFFERS')"
              style="cursor: pointer"
              >Offers
            </a>
          </li>
          <li class="nav-item w-50 text-center" role="presentation">
            <a
              class="nav-link"
              id="serv_tab"
              :class="{ active: currentTab == 'MYOFFERS' }"
              v-on:click="changeTab('MYOFFERS')"
              style="cursor: pointer"
              >My Offers
            </a>
          </li>
        </ul>
        <div class="row">
          <div
            class="col-lg-4 col-md-6 mb-3"
            v-show="currentTab == 'OFFERS'"
            v-for="(data, index) in offerList"
            :key="index"
          >
            <div class="card rounded-l cursor-pointer border-0 loyalty-card">
              <div class="col-md-12 text-right">
                <div class="mb-0 float-md-none float-right mt-3 mb-n4">
                  <a
                    href=""
                    class="text-secondary p-2"
                    id="dropdown_btn"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    ><i
                      class="fa fa-ellipsis-h"
                      aria-hidden="true"
                      v-show="data.user_id != userId"
                    ></i
                  ></a>

                  <div
                    class="dropdown-menu dropdown-menu-right show-dropdown"
                    style="
                      position: absolute;
                      transform: translate3d(220px, 44px, 0px);
                      top: 0px;
                      left: 0px;
                      will-change: transform;
                    "
                    x-placement="bottom-end"
                  >
                    <a
                      class="dropdown-item"
                      type="button"
                      v-on:click="openReportModal(data)"
                    >
                      Report Offer</a
                    >
                  </div>
                </div>
              </div>
              <div
                class="card-header bg-white text-center"
                v-on:click="showDetailPopup(data)"
              >
                <img
                  :src="
                    data.image ? data.image : '/images/placeholder-image.jpg'
                  "
                  alt=""
                  class="logo-img"
                />
              </div>
              <div
                class="
                  card-body
                  py-2
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <p class="font-medium my-2">{{ data.title }}</p>
                <span
                  class="btn btn-brand-outline rounded-pill font-14 text-dark"
                  style="height: 36px; line-height: 25px"
                  >{{ data.discount }}% OFF</span
                >
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-6 mb-3"
            v-show="currentTab == 'MYOFFERS'"
            v-for="(data, index) in myOfferList"
            :key="index"
          >
            <div class="card rounded-l cursor-pointer border-0 loyalty-card">
              <div class="col-md-12 text-right">
                <div class="mb-0 float-md-none float-right mt-3 mb-n4">
                  <a
                    href=""
                    class="text-secondary p-2"
                    id="dropdown_btn"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
                  ></a>

                  <div
                    class="dropdown-menu dropdown-menu-right show-dropdown"
                    style="
                      position: absolute;
                      transform: translate3d(220px, 44px, 0px);
                      top: 0px;
                      left: 0px;
                      will-change: transform;
                    "
                    x-placement="bottom-end"
                  >
                    <a
                      class="dropdown-item"
                      v-show="data.user_id != userId"
                      type="button"
                      v-on:click="openReportModal(data)"
                    >
                      Report Offer</a
                    >
                    <!-- <a
                      class="dropdown-item"
                      v-show="data.user_id == userId"
                      type="button"
                      v-on:click="showAddOfferPopup(data.id)"
                    >
                      Edit</a
                    > -->
                    <a
                      class="dropdown-item"
                      v-show="data.user_id == userId"
                      type="button"
                      v-on:click="showDeletePopup(data.id)"
                    >
                      Delete</a
                    >
                  </div>
                </div>
              </div>
              <div
                class="card-header bg-white text-center"
                v-on:click="showDetailPopup(data)"
              >
                <img
                  :src="
                    data.image ? data.image : '/images/placeholder-image.jpg'
                  "
                  alt=""
                  class="logo-img"
                />
              </div>
              <div
                class="
                  card-body
                  py-2
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <p class="font-medium my-2">{{ data.title }}</p>
                <span
                  class="btn btn-brand-outline rounded-pill font-14 text-dark"
                  style="height: 36px; line-height: 25px"
                  >{{ data.discount }}% OFF</span
                >
              </div>
            </div>
          </div>
          <div
            v-show="currentTab == 'OFFERS'"
            style="text-align: center; border-radius: 16px"
            class="col-12 pb-3"
          >
            <div
              style="text-align: center; border-radius: 16px"
              class="col-12 pb-3"
              v-show="offset != -1 && this.offerLoader != true"
            >
              <a
                id="load-button"
                class="txt-success cursor-pointer mb-3"
                v-on:click="getOfferList(offset)"
                style="max-width: 110px"
              >
                Load More
              </a>
            </div>
          </div>
          <div
            v-show="currentTab == 'MYOFFERS'"
            style="text-align: center; border-radius: 16px"
            class="col-12 pb-3"
          >
            <div v-show="offset != -1 && this.myOfferLoader != true">
              <a
                id="load-button"
                class="txt-success cursor-pointer mb-3"
                v-on:click="getMyOfferList(offset)"
                style="max-width: 110px"
              >
                Load More
              </a>
            </div>
          </div>
          <div class="col-12 text-center mt-4">
            <div v-show="currentTab == 'OFFERS'">
              <ErrorComponent @retry="getOfferList(0)" ref="offerComponent" />
            </div>
            <div v-show="currentTab == 'MYOFFERS'">
              <ErrorComponent
                @retry="getMyOfferList(0)"
                ref="myOfferComponent"
              />
            </div>
            <button
              type="button"
              class="btn btn-brand-1 mr-3"
              v-on:click="showAddOfferPopup()"
            >
              <img src="/img/plus.svg" width="14" class="mr-1" /> Add Offer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <TextModal ref="offerDetail">
    <div class="modal-content">
      <div class="modal-header text-left">
        <span
          class="btn btn-brand-outline rounded-pill font-14 text-dark"
          style="height: 36px; line-height: 25px"
          >{{ offerPopupDetails.discount }}% OFF</span
        >
        <button
          type="button"
          class="close"
          v-on:click="this.$refs.offerDetail.closeModal()"
        >
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-5">
        <img
          :src="
            offerPopupDetails.image
              ? offerPopupDetails.image
              : '/images/placeholder-image.jpg'
          "
          width="200"
          class="mb-3"
        />

        <p class="font-14" v-show="offerPopupDetails.contact_number">
          Contact Us :
          <a :href="'tel:' + offerPopupDetails.contact_number">{{
            offerPopupDetails.contact_number
          }}</a>
        </p>
        <p class="font-14">
          <a v-on:click="openLink(offerPopupDetails.website)" style="cursor: pointer;" rel="noopener noreferrer">{{
            offerPopupDetails.website
          }}</a>
        </p>
      </div>
    </div>
  </TextModal>
  <TextModal ref="addoffer">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="this.$refs.addoffer.closeModal()"
        >
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body px-4">
        <h5 class="modal-title font-22 mb-0 text-center mb-3">Add Offer</h5>
        <form class="form-style floating-form">
          <Form @submit="save(detail.id)">
            <div class="row mb-md-0 mb-4">
              <div class="col-12">
                <div class="custom-select-img">
                  <div class="file-upload">
                    <div class="image-upload-wrap p-3 mb-4">
                      <input
                        class="file-upload-input"
                        type="file"
                        name="img[]"
                        id="pickFile"
                        v-on:change="selectedFile($event)"
                        accept="image/*"
                      />
                      <div class="drag-text text-center">
                        <img
                          :src="
                            detail.image && detail.image != null
                              ? detail.image
                              : '/images/Imageplace-holder.svg'
                          "
                          id="preview"
                          width="40"
                        />
                        <h3 for="pickFile" class="heading font-20">
                          Upload a file
                        </h3>
                        <p class="font-12 text-secondary mb-1">
                          PNG, GIF, JPEG. (Max 10 mb)
                        </p>
                        <p class="font-12 text-secondary mb-0">(Optional)</p>
                      </div>
                    </div>
                    <div class="file-upload-content">
                      <img
                        class="file-upload-image mb-3"
                        src="#"
                        alt="your image"
                      />
                      <div class="image-title-wrap">
                        <button
                          type="button"
                          onclick="removeUpload()"
                          class="remove-image"
                        >
                          <i
                            class="fa fa-times text-danger mr-2"
                            aria-hidden="true"
                          ></i>
                          <span class="image-title">change Image</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-2">
                <div class="floating-form-group">
                  <Field
                    type="text"
                    id="title"
                    v-model="detail.title"
                    rules="required:title"
                    name="title"
                    :validateOnInput="true"
                  />
                  <label for="title">Title</label>
                  <span class="text-danger">*</span>
                </div>
                <ErrorMessage name="title" class="validation-msg" />
                <div class="floating-form-group with-icon">
                  <Field
                    type="text"
                    id="percentage"
                    v-model="detail.percentage"
                    rules="required:percentage"
                    name="percentage"
                    :validateOnInput="true"
                  />
                  <label for="percentage">Percentage (%)</label>
                  <span class="text-danger">*</span>

                  <img src="/img/th.jpg" width="15" class="icon" />
                </div>
                <ErrorMessage name="percentage" class="validation-msg" />
                <div class="floating-form-group">
                  <Field
                    type="text"
                    v-model="detail.website"
                    name="website"
                    id="website"
                  />
                  <label for="website">Website (Optional)</label>
                </div>

                <div class="floating-form-group">
                  <Field
                    type="text"
                    id="phone"
                    name="phone"
                    v-model="detail.contact_number"
                  />
                  <label for="phone">Contact Number (Optional)</label
                  >
                </div>

                <p class="font-14" style="color: #9cc338">
                  Note: This offer valid till
                  {{ this.validity ? this.validity : "30" }} days
                </p>
                <button class="btn btn-brand-1 w-100 mb-4" id="post-button">
                  Post
                </button>
              </div>
            </div>
          </Form>
        </form>
      </div>
    </div>
  </TextModal>
  <TextModal ref="reportModal">
    <div class="modal-content">
      <div class="modal-header pb-0">
        <button
          type="button"
          class="close"
          v-on:click="this.$refs.reportModal.closeModal()"
        >
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body px-4">
        <h5 class="modal-title font-22 mb-0 text-center mb-3">Report</h5>
        <Form @submit="submit()">
          <div class="floating-form mb-4">
            <div class="floating-form-group mt-4" style="height: 130px">
              <Field
                as="textarea"
                type="text"
                rows="4"
                name="message"
                rules="required:message"
                :validateOnInput="true"
                v-model="reportDetail.message"
              />

              <label for="message">Please write your problem/suggestion</label>
              <span class="text-danger">*</span>
            </div>
            <ErrorMessage name="message" class="text-danger" />
          </div>
          <div class="text-center">
            <button class="btn btn-brand-1" id="submit-button">Submit</button>
          </div>
        </Form>
      </div>
    </div>
  </TextModal>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
  <!-- <TextModal refs="filterModal">
    <div
      class="dropdown-menu dropdown-menu-right"
      aria-labelledby="navbarDropdown"
      style="
        width: 245px;
        position: absolute;
        transform: translate3d(-205px, 48px, 0px);
        top: 0px;
        left: 0px;
        will-change: transform;
      "
      id="dropdown-menu-id"
    >
      <a class="dropdown-item cursor-pointer">High To Low</a>
      <a class="dropdown-item cursor-pointer"> Low To High</a>
    </div>
  </TextModal> -->
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import DeleteModal from "@/components/DeleteModal";
import TextModal from "@/components/TextModal.vue";
import ErrorComponent from "@/components/ErrorComponent";
import $ from "jquery";
export default {
  name: "MyOffers",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    DeleteModal,
    ErrorComponent,
  },
  data() {
    return {
      id: "",
      offerLoader: false,
      myOfferLoader: false,
      offerList: [],
      detail: {
        dial_code: "+91",
        country_code: "IN",
      },
      currentTab: "OFFERS",
      validity: "",
      reportDetail: {},
      offset: 0,
      myOfferList: [],
      userId: localStorage.getItem("userId"),
      offerPopupDetails: {},
      searchObj: {
        keyword: "",
      },
      file: "",
    };
  },
  mounted() {
    this.changeTab("OFFERS");
    this.$store.commit("showSidebar", false);
    //floating form
    this.support();

    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "OFFERS") {
        this.getOfferList(0);
      } else if (tab == "MYOFFERS") {
        this.getMyOfferList(0);
      }
    },
    getOfferList(offset) {
      this.offerLoader = true;
      if (this.$refs.offerComponent) {
        this.$refs.offerComponent.updateLoader(true);
      }
      if (offset == 0) {
        this.offerList = [];
      }
      this.searchObj.offset = this.offset;
      this.$api
        .getAPI({
          _action: "loyalty-promos",
          _body: this.searchObj,
        })
        .then((res) => {
          this.offerLoader = false;
          for (var i in res.list) {
            this.offerList.push(res.list[i]);
          }
          this.offset = res.next_offset;
          if (this.$refs.offerComponent) {
            this.$refs.offerComponent.setData(res, "No offer available");
            this.$refs.offerComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.offerLoader = false;
          if (this.$refs.offerComponent) {
            this.$refs.offerComponent.setData(0, undefined, "");
            this.$refs.offerComponent.updateLoader(false);
          }
        });
    },
    support() {
      this.$api
        .getAPI({
          _action: "support",
        })
        .then((res) => {
          this.validity = res.info.offer_active_till;
        })
        .catch(() => {});
    },
    getMyOfferList(offset) {
      this.myOfferLoader = true;
      if (this.$refs.myOfferComponent) {
        this.$refs.myOfferComponent.updateLoader(true);
      }
      if (offset == 0) {
        this.myOfferList = [];
        // console.log("inside", this.myOfferList);
      }
      this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action: "posted/loyalty-promos",
          _body: this.searchObj,
        })
        .then((res) => {
          this.myOfferLoader = false;
          for (var i in res.list) {
            this.myOfferList.push(res.list[i]);
          }
          this.offset = res.next_offset;
          if (this.$refs.myOfferComponent) {
            this.$refs.myOfferComponent.setData(res, "No offer available");
            this.$refs.myOfferComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.myOfferLoader = false;
          if (this.$refs.myOfferComponent) {
            this.$refs.myOfferComponent.setData(0, undefined, "");
            this.$refs.myOfferComponent.updateLoader(false);
          }
        });
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    showAddOfferPopup(id) {
      if (id) {
        this.getOfferById(id);
      }
      this.$refs.addoffer.showModal();
      // this.$refs.addoffer.resetForm();
    },
    getOfferById(id) {
      this.$api
        .getAPI({
          _action: "loyalty-promo/" + id,
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
    },
    showDetailPopup(data) {
      this.offerPopupDetails = data;
      this.$refs.offerDetail.showModal();
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        "Delete Event",
        "Are you sure you want to delete this offer",
        id
      );
    },
    save(id) {
      if (id) {
        this.$api
          .putAPI({
            _action: "loyalty-promo",
            _body: this.detail,
            _buttonId: "post-button",
          })
          .then((res) => {
            this.uploadImage(res.id);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "loyalty-promo",
            _body: this.detail,
            _buttonId: "post-button",
          })
          .then((res) => {
            this.uploadImage(res.id);
          })
          .catch(() => {});
      }
    },
    uploadImage(id) {
      if (!this.file) {
        this.$refs.addoffer.closeModal();
        this.getOfferList(0);
        this.getMyOfferList(0);
        return;
      }
      var obj = {};
      obj.promo_id = id;
      this.$api
        .uploadImageAPI({
          _action: "loyalty-promo/image",
          _key: "image",
          _file: this.file,
          _body: obj,
        })
        .then(() => {
          this.$refs.addoffer.closeModal();
          this.getOfferList(0);
          this.getMyOfferList(0);
        });
    },
    openReportModal(data) {
      this.id = data.id;
      this.reportDetail = {};
      this.$refs.reportModal.showModal();
    },
    submit() {
      this.reportDetail.promo_id = this.id;
      this.$api
        .postAPI({
          _action: "loyalty-promo/report",
          _body: this.reportDetail,
          _buttonId: "submit-button",
        })
        .then((res) => {
          this.$refs.reportModal.closeModal();
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "loyalty-promo/" + id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getMyOfferList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    showDropdown() {
      var dropdownId = document.getElementById("dropdown-id");
      if (dropdownId) {
        if (dropdownId.className.includes("show")) {
          dropdownId.classList.remove("show");
        } else {
          dropdownId.classList.add("show");
        }
      }
      // var dropdownMenuId = document.getElementById("dropdown-menu-id");
      // if (dropdownMenuId) {
      //   if (dropdownMenuId.className.includes("show")) {
      //     dropdownMenuId.classList.remove("show");
      //   } else {
      //     dropdownMenuId.classList.add("show");
      //   }
      // }
    },
    openLink(url)
    {
      window.open(url , '_blank')
    }
  },
};
</script>