<template>
  <div>
    <OuterHeader />
    <div>
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-10" style="margin-top: 90px">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">Upcoming Events</h2>
            </div>
            <div class="col-md-6 text-right">
              <input
                type="text"
                class="search-click"
                name=""
                placeholder="search here..."
                v-model="searchObj.keyword"
                v-on:keyup.enter="getList(0)"
              />
            </div>
          </div>
          <div v-for="(data, index) in list" :key="index">
            <div v-show="data.type == 'BUSINESS_CONCLAVE'">
              <BusinessConclaveCard :data="data" />
            </div>
            <div v-show="data.type == 'WEBINAR'">
              <EventCard :data="data" />
            </div>
            <div v-show="data.type == 'HEALTH_WELL_BEING'">
              <HealthCard
                :data="data.info"
                :isVisible="false"
                @interested-list="getInterestedList"
                @notInterested="notIntrested"
                @interested="interested"
                @delete="showDeletePopup"
                @edit="showActivityPopup"
                @report="report"
              />
            </div>
          </div>
          <div style="text-align: center" v-show="offset != -1 && !loader">
            <a
              id="load-button"
              class="txt-success cursor-pointer mb-3"
              v-on:click="getList(offset)"
              style="max-width: 110px"
            >
              Load More
            </a>
          </div>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
  </div>

  <TextModal ref="contactModal">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="$refs.contactModal.closeModal()"
          aria-label="Close"
        >
          <img src="images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-5">
        <h5 class="modal-title font-22 mb-0">Contact Number</h5>
        <img src="images/contact	.png" alt="" width="180" class="mb-3" />
        <p class="">You can contact organizer using below contact number.</p>
        <p class="font-medium font-18">
          <a :href="'tel:+91' + this.contact">+91{{ this.contact }}</a>
        </p>
      </div>
    </div>
  </TextModal>
  <TextModal ref="reportModal">
    <div class="modal-content">
      <div class="modal-header pb-0">
        <button
          type="button"
          class="close"
          v-on:click="this.$refs.reportModal.closeModal()"
        >
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body px-4">
        <h5 class="modal-title font-22 mb-0 text-center mb-3">Report</h5>
        <Form ref="reportForm" @submit="reportAPI()">
          <div class="floating-form mb-4">
            <div class="floating-form-group mt-4" style="height: 130px">
              <Field
                as="textarea"
                type="text"
                rows="4"
                name="message"
                rules="required:message"
                :validateOnInput="true"
                v-model="obj.message"
              />

              <label for="message">Please write your problem/suggestion</label>
              <span class="text-danger">*</span>
            </div>
            <ErrorMessage name="message" class="text-danger" />
          </div>
          <div class="text-center">
            <button class="btn btn-brand-1" id="submit-button">Submit</button>
          </div>
        </Form>
      </div>
    </div>
  </TextModal>
  <TextModal ref="textModal" class="modal-small">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-5">
        <h5 class="modal-title font-22 mb-4">Thank you</h5>
        <img src="/images/claping.png" alt="" width="75" class="mb-3" />
        <p class="font-18">
          Thanks for showing <br />
          interest in this activity.
        </p>
      </div>
      <div class="modal-footer justify-content-center border-0 pb-4 pt-0">
        <button
          type="button"
          class="btn btn-brand-outline px-4"
          style="min-width: 115px"
          v-on:click="showContactModal()"
        >
          Contact Organizer
        </button>
      </div>
    </div>
  </TextModal>
  <TextModal ref="interestedModal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" v-on:click="$refs.interestedModal.closeModal">
          <img src="images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body form-style floating-form pb-2 pt-2 px-md-4">
        <h4 class="font-22 modal-tital text-center mb-4">Interested People</h4>
        <div style="height: 500px; overflow-y: auto">
          <div
            class="row mb-2 align-items-center border-bottom pb-2"
            v-for="(data, index) in interestedList"
            :key="index"
            v-on:click="this.$router.push('/member-info/' + data?.user.uuid)"
          >
            <div class="col-md-9 d-flex align-items-center cursor-pointer">
              <img
                :src="
                  data.user.profile_img
                    ? data.user.profile_img
                    : '/img/placeholder-image.jpg'
                "
                alt=""
                class="img-fluid rounded-circle object-cover"
                style="width: 40px; height: 40px"
              />
              <div class="pl-2">
                <p class="font-medium mb-0 font-xs-14">
                  {{ data.user.first_name }} {{ data.user.last_name }}
                </p>
                <p class="font-12 text-color-2 mb-0 font-xs-12">
                  {{ data.ago_time }}
                </p>
              </div>
            </div>
            <div class="col-md-3 text-right font-14">Interested</div>
          </div>
        </div>
      </div>
    </div>
  </TextModal>
  <TextModal ref="activityModal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" v-on:click="$refs.activityModal.closeModal()">
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body px-4">
        <h5 class="modal-title font-22 mb-0 text-center mb-3">Plan An Activity</h5>
        <Form ref="activityForm" class="form-style floating-form" @submit="save()">
          <div class="row mb-md-0 mb-4">
            <div class="col-md-12 mt-2">
              <div class="form-group">
                <label for="state">Select Activity</label>
                <span class="text-danger">*</span>
                <Field
                  as="select"
                  v-model="detail.activity_id"
                  class="custom-select"
                  rules="required:activity,true"
                  name="activity"
                  @change="checkLocation(detail.activity_id)"
                >
                  <option value="">Select</option>
                  <option
                    v-for="(data, index) in activityList"
                    :key="index"
                    :value="data.id"
                  >
                    {{ data.name }}
                  </option>
                </Field>
              </div>
              <ErrorMessage name="activity" class="validation-msg" />
              <div class="floating-form-group with-icon">
                <Datepicker
                  v-model="detail.start_date_time"
                  hideInputIcon
                  :clearable="false"
                  format="yyyy-MM-dd HH:mm:ss"
                  placeholder="Select Date"
                ></Datepicker>
                <img src="/images/calendar.svg" width="20" class="icon" />
              </div>
              <ErrorMessage name="name_in_time" class="validation-msg" />
              <div
                class="floating-form-group with-icon"
                v-if="this.has_sourceLocation == 'Y'"
              >
                >
                <vue-google-autocomplete
                  ref="startingPointAddress"
                  id="map1"
                  :classname="
                    detail.starting_point ? 'form-control filled' : 'form-control'
                  "
                  placeholder=""
                  v-model="detail.starting_point"
                  v-on:placechanged="getAddressData2"
                  country="in"
                >
                </vue-google-autocomplete>
                <label for="map1">Starting Point</label>
                <span class="text-danger">*</span>
                <img src="/images/location-icon.svg" width="20" class="icon" />
              </div>
              <ErrorMessage name="starting_point" class="validation-msg" />
              <div class="floating-form-group with-icon">
                <vue-google-autocomplete
                  ref="address"
                  id="map"
                  :classname="detail.destination ? 'form-control filled' : 'form-control'"
                  placeholder=""
                  v-on:placechanged="getAddressData"
                  country="in"
                  v-model="detail.destination"
                >
                </vue-google-autocomplete>
                <label for="map">Destination</label>

                <img src="/images/location-icon.svg" width="20" class="icon" />
              </div>
              <ErrorMessage name="destination" class="validation-msg" />
              <div class="floating-form-group">
                <Field
                  type="text"
                  id="ContactNumber"
                  name="phone"
                  autocomplete="off"
                  v-model="detail.contact_no"
                  :class="detail.contact_no ? 'filled' : ''"
                  rules="required:contact number|phone"
                  :validateOnInput="true"
                />
                <label for="ContactNumber">Contact Number</label>
                <span class="text-danger">*</span>
              </div>
              <ErrorMessage name="phone" class="validation-msg" />
              <div class="pb-3">
                <div class="floating-form-group" style="height: 130px">
                  <Field
                    as="textarea"
                    rows="4"
                    id="description"
                    :class="detail.description ? 'filled' : ''"
                    name="description"
                    v-model="detail.description"
                    rules="required:description"
                    :validateOnInput="true"
                  ></Field>
                  <label for="description">Description</label>
                  <span class="text-danger">*</span>
                </div>
              </div>
              <ErrorMessage name="description" class="validation-msg" />
              <button class="btn btn-brand-1 w-100 mb-4" id="save-button">Publish</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </TextModal>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import DeleteModal from "@/components/DeleteModal";
import TextModal from "@/components/TextModal.vue";
import Datepicker from "@vuepic/vue-datepicker";
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import { ref } from "vue";

// you need to import css yourself
import "vue-easy-lightbox/external-css/vue-easy-lightbox.css";
import ErrorComponent from "@/components/ErrorComponent";
import EventCard from "@/components/EventCard";
import HealthCard from "@/components/HealthCard";
import BusinessConclaveCard from "@/components/BusinessConclaveCard";
import OuterHeader from "@/components/OuterHeader";
import moment from "moment";
export default {
  name: "MyHome",
  components: {
    Datepicker,
    TextModal,
    DeleteModal,
    Form,
    Field,
    ErrorMessage,
    VueGoogleAutocomplete,
    EventCard,
    HealthCard,
    ErrorComponent,
    BusinessConclaveCard,
    OuterHeader,
  },
  data() {
    return {
      loader: true,
      offset: -1,
      list: [],
      id: this.$route.params.id,
      interestList: [],
      interestedList: [],
      latitude: "",
      longitude: "",
      postImage: "",
      detail: {
        list_type: "",

        activity_id: "",
        health_wellbeing_id: "",
        starting_point: "",
      },
      searchObj: {
        keyword: "",
        offset: 0,
        list_type: "",
      },
      activityList: [],
      healthList: [],
      contact: "",
      obj: {},
      visibleRef: ref(false),
      indexRef: ref(0),
      has_sourceLocation: "",
      isShowAddressErrorMessage: false,
    };
  },
  mounted() {
    this.has_sourceLocation = "N";
    this.$store.commit("showSidebar", false);
    this.getList(0);
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
  },
  methods: {
    getAddressData: function (addressData) {
      this.detail.destination = addressData.locality;
      this.searchObj.latitude = addressData.latitude;
      this.searchObj.longitude = addressData.longitude;
    },
    getAddressData2: function (addressData) {
      this.detail.starting_point = addressData.locality;
      this.searchObj.latitude = addressData.latitude;
      this.searchObj.longitude = addressData.longitude;
    },

    getList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.searchObj.offset = offset;
      this.searchObj.activity_id = "";

      for (var i in this.activityList) {
        if (this.activityList[i].isChecked == true) {
          if (this.searchObj.activity_id) {
            this.searchObj.activity_id += "," + this.activityList[i].id;
          } else {
            this.searchObj.activity_id = this.activityList[i].id;
          }
        }
      }
      this.$refs.errorComponent.updateLoader(true);

      this.$api
        .getAPI({
          _action: "upcoming-events",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          if (this.list.length > 0) {
            for (var i in this.list) {
              if (this.list[i].info) {
                this.list[i].info.isShow = false;
                if (
                  moment(this.list[i].info.from_datetime, "YYYY-MM-DD HH:mm:ss") >
                  moment(new Date())
                ) {
                  this.list[i].info.isShow = true;
                }
              }
            }
          }
          console.log("conclave", this.list);
          this.offset = res.next_offset;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "Not available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    showFilterPopup() {
      this.$refs.filterModal.showModal();
    },
    showDeletePopup(id) {
      console.log(id);
      this.$router.push("/login");
    },
    report(id) {
      console.log(id);
      this.$router.push("/login");
    },

    setInterest(data) {
      console.log(data);
      this.$router.push("/login");
    },
  },
};
</script>
