<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                <img
                  src="/images/back-arrow.svg"
                  width="17"
                  class="mr-2 align-top"
                  alt=""
              /></a>
              Profile
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end"></div>
        </div>
        <div class="card mb-4 rounded-l border-0 shadow-sm">
          <!-- <div class="card-header p-0 border-0">
            <img
              src="/images/cover-img.jpg"
              class="card-img-top rounded-l cover-img"
              alt=""
            />
          </div> -->
          <div class="card-body px-md-5 -x3">
            <div class="row mb-4 align-items-center">
              <div class="col-md-7 d-flex align-items-center mb-md-0 mb-3">
                <img
                  :src="
                    this.detail.profile_img ? this.detail.profile_img : '/img/profile.svg'
                  "
                  alt=""
                  class="rounded-circle object-cover"
                  width="100"
                  height="100"
                />
                <div class="pl-4">
                  <p class="font-medium mb-2 font-18 font-xs-16">
                    {{ detail.first_name }} {{ detail.last_name }}
                  </p>

                  <p class="font-weight-light font-14 text-secondary mb-1 font-xs-12">
                    <i class="fa fa-flag text-primary pr-2" aria-hidden="true"></i>
                    <span v-if="this.type == 'LEADER'"> President </span>
                    <span v-else> {{ $helperService.getTitleCase(this.type) }}</span>
                  </p>
                </div>
              </div>
              <!-- <div class="col-md-2 mb-md-0 mb-3 text-md-left text-center"> -->

              <!-- </div> -->
              <div class="col-md-5 text-md-right text-center">
                <a :href="'tel:' + detail.phone">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  {{ detail.phone }}
                </a>
                <br />
                <a :href="'mailto:' + detail.email">
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                  {{ detail.email }}
                </a>
              </div>
            </div>
            <h4 class="font-18">About {{ detail.name }}</h4>
            <p>
              {{ detail.about_me }}
            </p>
            <div v-show="detail?.companies?.length > 0">
              <h4 class="font-18">Companies</h4>
              <div
                v-for="(data, index) in detail.companies"
                :key="index"
                class="d-flex align-items-center mb-3 cursor-pointer"
                v-on:click="$router.push('/companies-detail/' + data.id)"
              >
                <img
                  :src="this.data?.logo ? this.data?.logo : '/img/placeholder-image.jpg'"
                  width="50"
                  alt=""
                />
                <p class="ml-2 mb-0">{{ data.company_name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExpertProfile",
  components: {},
  data() {
    return {
      uuid: this.$route.params.id,
      detail: {},
      type: "",
    };
  },
  mounted() {
    this.$store.commit("showSidebar", false);
    this.getExpertDetail();
  },
  methods: {
    capitalizeFirstLetter(str) {
      console.log(str.charAt(0).toUpperCase() + str.slice(1));
    },
    getExpertDetail() {
      this.$api
        .getAPI({
          _action: "user/profile/" + this.uuid,
          ///f5b8dea9-bf19-48da-8961-5f28cdfb5019
        })
        .then((res) => {
          this.detail = res.response;
          if (
            this.detail.work_group.type == "MEMBER" &&
            this.detail.work_group.category !== null
          ) {
            this.type = this.detail.work_group.category.name;
          } else if (this.detail.work_group.type == "MEMBER") {
            this.type = this.detail.work_group.type;
          } else {
            this.type = this.detail.work_group.type;
          }

          this.type.charAt(0).toUpperCase() + this.type.slice(1);
          // this.$sharedService.setMapValue(
          //   "top_five_review",
          //   this.detail.top_five_review
          // );
        })
        .catch(() => {});
    },
  },
};
</script>
