<template>
  <div>
    <div class="card card-body events-card mb-4">
      <div class="row mb-2">
        <div class="col-md-8 d-flex align-items-center mb-md-0 mb-3">
          <img
            :src="
              data.user && data.user.profile_img
                ? data.user.profile_img
                : '/img/placeholder-image.jpg'
            "
            v-on:click="checked(data)"
            alt=""
            class="img-fluid rounded-circle object-cover"
            style="width: 50px; height: 50px"
          />
          <div class="pl-3">
            <p class="font-medium mb-0 font-xs-14">
              {{ data.user ? data.user.first_name : "" }}
              {{ data.user ? data.user.last_name : "" }}
            </p>
            <p class="font-12 text-dark mb-1 font-xs-12">
              {{ data.category ? data.category.name : "" }}
            </p>
          </div>
        </div>
        <div class="col-md-4 text-right">
          <div class="mb-0 float-md-none float-right">
            <a
              class="text-secondary"
              id="dropdown_btn"
              data-toggle="dropdown"
              aria-expanded="false"
              ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
            ></a>

            <div
              class="dropdown-menu dropdown-menu-right show-dropdown"
              style="
                position: absolute;
                transform: translate3d(220px, 44px, 0px);
                top: 0px;
                left: 0px;
                will-change: transform;
              "
              x-placement="bottom-end"
            >
              <a
                class="dropdown-item"
                v-show="data.posted_user_id != userId"
                type="button"
                v-on:click="clickEvent('report', data.id)"
              >
                Report Activity</a
              >
              <a
                class="dropdown-item"
                v-show="data.posted_user_id == userId"
                type="button"
                v-on:click="clickEvent('edit', data.id)"
              >
                Edit</a
              >
              <a
                class="dropdown-item"
                v-show="data.posted_user_id == userId"
                type="button"
                v-on:click="clickEvent('delete', data.id)"
              >
                Delete</a
              >
            </div>
          </div>
          <p class="font-12 text-color-2 mb-0 font-xs-12 ml-2 float-md-none float-left">
            {{ data.ago_time }}
          </p>
        </div>
      </div>
      <div class="position-relative mb-3">
        <img
          :src="
            data?.banner?.banner ? data?.banner?.banner : '/images/placeholder-image.jpg'
          "
          class="img-fluid card-img"
          alt=""
        />
        <!-- <span
          class="badge bage-brand position-absolute"
          style="left: 10px; top: 10px"
          >Online</span
        > -->
      </div>

      <div class="row">
        <div class="col-md-7 mb-md-0 mb-3">
          <p class="text-secondary mb-md-2 mb-2 font-14">
            <img src="/images/calendar.svg" width="14" class="align-baseline mr-1" />
            {{ $helperService.getFormatedDateAndTime(data.start_date_time) }}
          </p>
          <div
            class="mb-2 d-flex align-items-start"
            v-if="data && data.category && data.category.has_source_location == 'Y'"
          >
            <img src="/images/location-icon.svg" width="14" class="align-baseline mr-1" />
            <div class="pl-1 cursor-pointer">
              <p class="font-12 text-secondary-2 mb-0">Starting Point</p>
              <p
                class="text-secondary font-14 mb-0"
                v-on:click="showLocation(data, 'start')"
              >
                {{ data.starting_point }}
              </p>
            </div>
          </div>
          <div class="mb-0 d-flex align-items-start">
            <img src="/images/location-icon.svg" width="14" class="align-baseline mr-1" />
            <div class="pl-1 cursor-pointer">
              <p class="font-12 text-secondary-2 mb-0">Destination</p>
              <p
                class="text-secondary font-14 mb-0"
                v-on:click="showLocation(data, 'end')"
              >
                {{ data.destination }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-5 text-md-right">
          <p
            v-on:click="clickEvent('interested-list', data.id)"
            class="text-secondary mb-md-2 mb-2 font-14 cursor-pointer"
          >
            <img src="/images/people.svg" width="14" class="align-baseline mr-1" />
            {{ data.interested_user_count }} People Interested
          </p>
          <button
            class="btn btn-brand-outline w-xs-100 px-md-5 mt-md-4"
            data-toggle="modal"
            data-target="#modal_thankyou"
            id="interested-button"
            v-show="
              data.posted_user_id != userId &&
              !data.has_interested &&
              data.start_date_time > currentDate
            "
            v-on:click="clickEvent('interested', data.id)"
          >
            Interested
          </button>
          <button
            class="btn btn-brand-outline w-xs-100 px-md-4 mt-md-4"
            data-toggle="modal"
            data-target="#modal_thankyou"
            id="not-interested-button"
            v-show="data.posted_user_id != userId && data.has_interested"
            v-on:click="clickEvent('notInterested', data.id)"
          >
            I am Interested
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HealthCard",
  props: {
    data: Object,
    isVisible: Boolean,
  },
  data() {
    return {
      currentDate: new Date(),
      userId: localStorage.getItem("userId"),
      des_lat: "",
      des_long: "",
      name: "",
    };
  },
  mounted() {
    console.log("data", this.data);
    console.log("userId", this.userId);
    for (var i in this.data) {
      if (this.data[i]?.posted_user_id == this.userId) {
        console.log("true");
      }
    }
  },
  methods: {
    clickEvent(event, id) {
      console.log(event, id);
      this.$emit(event, id);
    },
    showLocation(detail, type) {
      if (type == "start") {
        (this.des_lat = detail.start_lat), (this.des_long = detail.start_lng);
      } else if (type == "end") {
        (this.des_lat = detail.dest_lat), (this.des_long = detail.dest_lng);
      }
      var url =
        "https://www.google.com/maps?saddr=" +
        this.$api.getLatitude() +
        "," +
        this.$api.getLongitude() +
        "&daddr=" +
        this.des_lat +
        "," +
        this.des_long;

      window.open(url);
    },
    notInterested() {
      this.name = "Intrested";
    },
  },
};
</script>
