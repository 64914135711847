<template>
  <div>
    <header
      class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100"
      style="z-index: 1"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img
              src="/images/tenx-logo.svg"
              width="80"
              height="26"
              class="img-fluid"
            />
          </div>
          <!-- <div class="col-md-9 col-9 text-right">
            <a class="mr-3">Signup</a>
            <a class="header-btn" v-on:click="$router.push('/login')">Login</a>
          </div> -->
        </div>
      </div>
    </header>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center bg-white">
            <div class="col-md-6 bg-white">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <div class="px-lg-5 px-0">
                    <div class="card-body pb-0">
                      <form class="form-style floating-form">
                        <div
                          class="row min-h-auto mb-md-0 mb-4"
                          style="min-height: 86vh"
                        >
                          <div class="col-md-12 mt-2">
                            <div class="mb-4 mt-4">
                              <h3 class="signup-title mt-2">
                                Add product/ service
                              </h3> 
                            </div>
                            <ul
                              class="nav nav-pills mb-3 tab-style-1"
                              id="login-tab"
                              role="tablist"
                            >
                              <li
                                class="nav-item w-50 text-center"
                                role="presentation"
                              >
                                <a
                                  class="nav-link"
                                  id="prod_tab"
                                  :class="{ active: currentTab == 'PRODUCT' }"
                                  v-on:click="changeTab('PRODUCT')"
                                  style="cursor: pointer"
                                  >Products
                                </a>
                              </li>
                              <li
                                class="nav-item w-50 text-center"
                                role="presentation"
                              >
                                <a
                                  class="nav-link"
                                  id="serv_tab"
                                  :class="{ active: currentTab == 'SERVICE' }"
                                  v-on:click="changeTab('SERVICE')"
                                  style="cursor: pointer"
                                  >Services
                                </a>
                              </li>
                            </ul>
                            <div
                              v-show="currentTab == 'PRODUCT'"
                              class="form-style pt-md-4 mb-3"
                              id="login-tabContent"
                            >
                              <div
                                class="tab-pane"
                                id="tab_1"
                                role="tabpanel"
                                aria-labelledby=" "
                                style="max-height: 400px; overflow-y: auto"
                              >
                                <table class="table table-borderless">
                                  <tbody>
                                    <tr
                                      class="border-bottom"
                                      v-for="(data, index) in ProductList"
                                      :key="index"
                                    >
                                      <td width="45" class="p-2">
                                        <img
                                          :src="
                                            data.image
                                              ? data.image
                                              : 'images/placeholder-image.jpg'
                                          "
                                          width="45"
                                          alt=""
                                        />
                                      </td>
                                      <td class="font-medium align-middle p-2">
                                        {{ data.title }}
                                      </td>
                                      <td class="align-middle p-2">
                                        <router-link
                                          :to="'/edit/product/' + data.id"
                                        >
                                          <a class="mr-2"
                                            ><img
                                              src="/images/edit-icon.svg"
                                              width="18"
                                              alt=""
                                          /></a>
                                        </router-link>
                                        <a v-on:click="showDeletePopup(data)"
                                          ><img
                                            src="/images/delete.svg"
                                            width="18"
                                            alt=""
                                        /></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div
                              v-show="currentTab == 'SERVICE'"
                              class="form-style pt-md-4"
                              id="login-tabContent"
                            >
                              <div
                                class="tab-pane"
                                id="tab_2"
                                role="tabpanel"
                                aria-labelledby=" "
                                style="max-height: 400px; overflow-y: auto"
                              >
                                <table class="table table-borderless">
                                  <tbody>
                                    <tr
                                      class="border-bottom"
                                      v-for="(data, index) in ServiceList"
                                      :key="index"
                                    >
                                      <td width="45" class="p-2">
                                        <img
                                          :src="
                                            data.image
                                              ? data.image
                                              : 'images/placeholder-image.jpg'
                                          "
                                          width="45"
                                          alt=""
                                        />
                                      </td>
                                      <td class="font-medium align-middle p-2">
                                        {{ data.title }}
                                      </td>
                                      <td class="align-middle p-2">
                                        <router-link
                                          :to="'/edit/service/' + data.id"
                                        >
                                          <a class="mr-2"
                                            ><img
                                              src="/images/edit-icon.svg"
                                              width="18"
                                              alt=""
                                          /></a>
                                        </router-link>
                                        <a v-on:click="showDeletePopup(data)"
                                          ><img
                                            src="/images/delete.svg"
                                            width="18"
                                            alt=""
                                        /></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="col-12 align-self-end">
                              <div class="text-center mb-4" id="add_more_prod">
                                <a
                                  v-show="currentTab == 'PRODUCT'"
                                  class="text-dark"
                                  v-on:click="$router.push('/add/product')"
                                  style="cursor: pointer"
                                  ><img
                                    src="/images/plus-circle.svg"
                                    width="30"
                                    class="mr-2"
                                  />
                                  Add More Product
                                </a>
                              </div>
                              <div
                                class="text-center mb-4"
                                id="add_more_service"
                              >
                                <a
                                  v-show="currentTab == 'SERVICE'"
                                  class="text-dark"
                                  v-on:click="$router.push('/add/service')"
                                  style="cursor: pointer"
                                  ><img
                                    src="/images/plus-circle.svg"
                                    width="30"
                                    class="mr-2"
                                  />
                                  Add More Service
                                </a>
                              </div>
                              <div class="d-flex">
                                <button
                                  type="button"
                                  class="
                                    btn btn-outline-secondary btn-block
                                    mb-md-0
                                    mr-3
                                  "
                                  v-on:click="this.skipButton()"
                                >
                                  {{
                                    this.code == "update" ? "Cancel" : "Skip"
                                  }}
                                </button>
                                <!-- $router.push('/select-your-interest'); -->
                                <!-- $router.push('/select-your-interest') -->
                                <button
                                  type="button"
                                  class="btn btn-brand-1 btn-block mt-0"
                                  v-on:click="this.nextButton()"
                                >
                                  {{
                                    this.code == "update" ? "Update" : "Next"
                                  }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 login-img-col"></div>
          </div>
        </div>
      </div>
    </main>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  </div>
</template>
<script>
import DeleteModal from "@/components/DeleteModal.vue";
import api from "../../../services/WebService";
export default {
  name: "ProductServiceList",
  components: {
    DeleteModal,
  },
  data() {
    return {
      id: "",
      currentTab: "",
      type: this.$route.params.type,
      ProductList: [],
      ServiceList: [],
      code: this.$route.params.code,
    };
  },
  mounted() {
    if(this.code != null || this.code!= undefined)
    {
    localStorage.setItem('code',this.code)
    } else {
      this. code = localStorage.getItem('code')
    }
    console.log("code",this.code);
    if (this.code == "update") {
      this.changeTab("PRODUCT");
    }
    if (this.type != null && this.type == "service") {
      this.changeTab("SERVICE");
    } else if (this.type != null && this.type == "product") {
      this.changeTab("PRODUCT");
    }
    this.$store.commit("showSidebar", false);
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "PRODUCT") {
        this.getProductList();
      } else if (tab == "SERVICE") {
        this.getServiceList();
      }
    },
    getProductList() {
      api
        .getAPI({
          _action: "company/" + localStorage.getItem("companyId") + "/products",
        })
        .then((res) => {
          this.ProductList = res.list;
        })
        .catch(() => {});
    },
    getServiceList() {
      api
        .getAPI({
          _action: "company/" + localStorage.getItem("companyId") + "/services",
        })
        .then((res) => {
          this.ServiceList = res.list;
          console.log(this.ServiceList);
        })
        .catch(() => {});
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Challenge",
        "Are you sure you want to delete " + data.title,
        data
      );
    },
    skipButton() {
      if (this.code == "update") {
        this.$router.go(-1);
      } else {
        this.$router.push("/select-your-interest");
      }
    },
    nextButton() {
      if (this.code == "update") {
        this.$router.push("/companyProfile");
      } else {
        this.$router.push("/select-your-interest");
      }
    },
    deleteRecord(dataObj) {
      var details = new Proxy(dataObj, {});

      api
        .deleteAPI({
          _action:
            "company/" +
            localStorage.getItem("companyId") +
            "/" +
            this.currentTab.toLowerCase() +
            "/" +
            details.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.changeTab(this.currentTab);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
  },
};
</script>