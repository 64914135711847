<template>
  <div>
    <header
      class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100"
      style="z-index: 1"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img
              src="/images/tenx-logo.svg"
              width="80"
              height="26"
              class="img-fluid"
            />
          </div>
          <!-- <div class="col-md-9 col-9 text-right">
            <a class="mr-3">Signup</a>
            <a class="header-btn" v-on:click="$router.push('/login')">Login</a>
          </div> -->
        </div>
      </div>
    </header>
    <!-- <main role="main">
      <div id="main-wrapper" class="login-wrap"> -->
    <div class="container-fluid login-wrap">
      <div class="row justify-content-md-center bg-white">
        <div class="col-md-6 bg-white">
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <div class="px-lg-5 px-0">
                <div class="card-body pb-0">
                  <Form class="form-style floating-form" @submit="save()">
                    <div class="row" style="min-height: 86vh">
                      <div class="col-md-12 align-seft-start mt-3">
                        <div class="mb-2 mt-5">
                          <h3 class="">
                            {{ id ? "Edit" : "Add" }} business address
                          </h3>
                        </div>
                      </div>
                      <div class="col-12 mb-4" v-show="!this.type" v-if="!id">
                        <div class="steps-count">
                          <div class="step-no active">1</div>
                          <div class="h-line"></div>
                          <div class="step-no">2</div>
                          <div class="h-line"></div>
                          <div class="step-no">3</div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="floating-form-group">
                          <Field
                            type="text"
                            name="address_1"
                            autocomplete="off"
                            :class="id ? 'filled' : ''"
                            v-model="detail.address_1"
                            rules="required:address 1"
                            :validateOnInput="true"
                          />
                          <label for="address_1">Business Address 1</label>
                        </div>
                        <ErrorMessage name="address_1" class="validation-msg" />
                      </div>

                      <div class="col-12">
                        <div class="floating-form-group">
                          <Field
                            type="text"
                            name="address_2"
                            autocomplete="off"
                            :class="id ? 'filled' : ''"
                            v-model="detail.address_2"
                            rules="required:address 2"
                            :validateOnInput="true"
                          />
                          <label for="address_2">Business Address 2</label>
                        </div>
                        <ErrorMessage name="address_2" class="validation-msg" />
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="inputRole">State</label>
                          <Field
                            as="select"
                            class="custom-select"
                            id="inputRole"
                            name="name_state"
                            v-model="detail.state_id"
                          >
                            <option value="">Select State</option>
                            <option
                              v-for="(data, index) in stateList"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.name }}
                            </option>
                          </Field>
                        </div>
                        <ErrorMessage
                          name="name_state"
                          class="validation-msg"
                        />
                      </div>
                      <div class="col-lg-6 ">
                        <div class="floating-form-group with-icon mb-3 mt-4">
                          <vue-google-autocomplete
                            ref="address"
                            :classname="detail.city ? 'form-control filled' : 'form-control'"
                            id="map"
                            placeholder=""
                            v-on:placechanged="getAddressData"
                             types=""
                            country="in"
                          >
                          </vue-google-autocomplete>
                          <img
                            src="/images/location-icon.svg"
                            width="20"
                            class="icon"
                          />
                          <label for="city">City</label
                          ><span class="text-danger">*</span>
                        </div>
                        <span class="validation-msg" v-show="isShowCityErrorMessage">Please enter city</span>
                      </div>
                      <div class="col-12">
                        <div class="floating-form-group">
                          <Field
                            type="text"
                            name="pincode"
                            :class="id ? 'filled' : ''"
                            v-model="detail.pin_code"
                            rules="required:pincode|pincode"
                            :validateOnInput="true"
                          />

                          <label>Pincode</label>
                        </div>
                        <ErrorMessage name="pincode" class="validation-msg" />
                      </div>
                      <div class="col-12 mb-3" v-show="!this.type" v-if="!id">
                        <p class="font-medium mb-2">
                          Do you have office in multiple locations ?
                        </p>
                        <div class="interest-tags mt-4">
                          <button
                            type="button"
                            v-on:click="selectReason('YES')"
                            class="btn btn-outline-rounded-color mr-2 mb-2"
                            :class="this.multiple == 'YES' ? 'active' : ''"
                            style="cursor: pointer"
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            v-on:click="selectReason('NO')"
                            class="btn btn-outline-rounded-color mr-2 mb-2"
                            :class="this.multiple == 'NO' ? 'active' : ''"
                            style="cursor: pointer"
                          >
                            No
                          </button>
                        </div>
                      </div>
                      <div
                        class="
                          d-flex
                          col-12
                          align-selft-end align-items-end
                          mb-3
                        "
                      >
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-block mr-3"
                          v-on:click="callPath()"
                        >
                          {{ type == "new" || id ? "Cancel" : "Skip" }}
                        </button>
                        <button
                          id="save-button"
                          class="btn btn-brand-1 btn-block mt-0"
                        >
                          {{ type == "new" || id ? "Save" : "Next" }}
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 login-img-col"></div>
      </div>
    </div>
  </div>
  <!-- </main>
  </div> -->
</template>
<script>
    import VueGoogleAutocomplete from "vue-google-autocomplete";
    import {
        Form,
        Field,
        ErrorMessage
    } from "vee-validate";
    import $ from "jquery";
    export default {
        name: "AddBAddress",
        components: {
            Form,
            Field,
            ErrorMessage,
            VueGoogleAutocomplete
        },
        data() {
            return {
                id: this.$route.params.id,
                type: this.$route.params.type,
                stateList: [],
                detail: {
                    state_id: '',
                    response: {}
                },
                multiple: "",
                isShowCityErrorMessage: false
            };
        },
        mounted() {
            this.getStateList();
            if (this.id) {
                this.getDetail();
            }
            //floating form
            $(document).ready(function() {
                $(
                    ".floating-form-group input,.floating-form-group textarea,.floating-form select"
                ).change(function() {
                    if ($(this).val() != "") {
                        $(this).addClass("filled");
                    } else {
                        $(this).removeClass("filled");
                    }
                });
            });
            this.$store.commit("showSidebar", false);
        },
        methods: {
            getAddressData: function(addressData,placeResultData) {
                this.detail.city = placeResultData.name ? (placeResultData.name) : ' ' ;
                this.detail.latitude = addressData.latitude;
                this.detail.longitude = addressData.longitude;
            },
            getStateList() {
                this.$api
                    .getAPI({
                        _action: "states",
                    })
                    .then((res) => {
                        this.stateList = res.list;
                    })
                    .catch(() => {});
            },
            getDetail() {
                this.$api
                    .getAPI({
                        _action: "company/" +
                            localStorage.getItem("companyId") +
                            "/address/" +
                            this.id,
                    })
                    .then((res) => {
                        if (document.getElementById("map") && res.response.city_name) {
                            document.getElementById("map").value = res.response.city_name
                            res.response.city = res.response.city_name;
                        }
                        this.detail = res.response;
                    })
                    .catch(() => {});
            },
            selectReason(reason) {
                this.multiple = reason;
                // console.log(this.multiple);
            },
            callPath() {
                if (this.type == "new") {
                    this.$router.push("/add-new-business-address");
                } else {
                    this.$router.push("/add-product-service");
                }
            },
            save() {
                if (!document.getElementById("map").value) {
                    this.isShowCityErrorMessage = true
                    return;
                }
                if (this.id) {
                    this.$api
                        .putAPI({
                            _action: "company/" +
                                localStorage.getItem("companyId") +
                                "/address/" +
                                this.id,
                            _body: this.detail,
                            _buttonId: "save-button",
                        })
                        .then(() => {
                            this.$router.push("/add-new-business-address");
                        })
                        .catch(() => {});
                } else {
                    this.$api
                        .postAPI({
                            _action: "company/" + localStorage.getItem("companyId") + "/address",
                            _body: this.detail,
                            _buttonId: "save-button",
                        })
                        .then(() => {
                            if (this.type) {
                                this.$router.push("/add-new-business-address");
                            } else {
                                if (this.multiple == "YES") {
                                    this.$router.push("/add-new-business-address");
                                } else if (this.multiple == "NO") {
                                    this.$router.push("/add-product-service");
                                }
                            }
                        })
                        .catch(() => {});
                }
            },
        },
    };
</script>