<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">Find Experts</h2>
          </div>

          <div class="col-md-4 text-right d-flex justify-content-end">
            <input
              type="text"
              class="search-click"
              name=""
              v-on:keyup.enter="getCategoryList(0)"
              placeholder="search here..."
              v-model="searchObj.keyword"
            />
          </div>
          <div class="col-md-2 text-right d-flex">
            <button
              class="btn btn-brand-1 w-xs-100"
              data-toggle="modal"
              data-target="#modal_thankyou"
              id="register-button"
              v-on:click="showPopup()"
            >
              Become Expert
            </button>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-3 col-md-4 mb-3"
            v-for="(data, index) in categoryList"
            :key="index"
          >
            <div
              class="card mb-4 find-expert mt-3"
              v-on:click="categogydata(index)"
            >
              <img
                :src="data.image ? data.image : '/images/placeholder-image.jpg'"
                alt="Business Consultants"
                class="img-fluid card-img"
              />
              <div
                class="card-footer text-center text-white"
                style="height: 80px"
              >
                {{ data.name }}
              </div>
            </div>
          </div>
        </div>
        <ErrorComponent @retry="getCategoryList(0)" ref="errorComponent" />
      </div>
    </div>
  </div>
  <TextModal ref="expertModal">
    <div class="modal-content">
      <div class="modal-header pb-0">
        <button
          type="button"
          class="close"
          v-on:click="this.$refs.expertModal.closeModal()"
        >
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body px-4">
        <h5 class="modal-title font-22 mb-0 text-center mb-3">
          Become An Expert
        </h5>
        <Form ref="expertForm" @submit="becomeExpert()">
          <div class="floating-form mb-4">
            <div class="floating-form-group mt-4" style="height: 130px">
              <Field
                as="textarea"
                type="text"
                rows="4"
                name="message"
                rules="required:message"
                :validateOnInput="true"
                v-model="obj.message"
              />

              <label for="message">Please write your message</label>
              <span class="text-danger">*</span>
            </div>
            <ErrorMessage name="message" class="text-danger" />
          </div>
          <div class="text-center">
            <button class="btn btn-brand-1" id="submit-button">Submit</button>
          </div>
        </Form>
      </div>
    </div>
  </TextModal>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import TextModal from "@/components/TextModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "ExperCategory",
  components: {
    ErrorComponent,
    TextModal,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loader: true,
      offset: 0,
      searchObj: {
        keyword: "",
      },
      categoryList: [],
      obj: {
        message:""
      },
    };
  },
  mounted() {
    this.getCategoryList();
    this.$store.commit("showSidebar", false);
  },
  methods: {
    getCategoryList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      if (this.offset == 0) {
        this.categoryList = [];
      }
      this.searchObj.offset = this.offset;
      this.$api
        .getAPI({
          _action: "category/expert",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          for (var i in res.list) {
            this.categoryList.push(res.list[i]);
          }
          this.offset = res.next_offset;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No expert-category available"
            );
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    categogydata(index) {
      this.$sharedService.setMapValue(
        "category_name",
        this.categoryList[index].name
      );
      this.$router.push("/category/" + this.categoryList[index].id + "/expert");
    },
    showPopup() {
      this.obj.message = ""
      this.$refs.expertModal.showModal();
    },
    becomeExpert()
    {
      this.$api
        .postAPI({
          _action: "become-export",
          _body: this.obj,
        })
        .then((res) => {
           if (res) {
            this.$refs.expertModal.closeModal();
            this.$notify({
              type: "success",
              text: res.message,
            });
        }
        })
        .catch(() => {
          
        });
    }
  },
};
</script>