<template>
  <div>
    <OuterHeader />
    <div>
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-10" style="margin-top: 90px">
          <div class="row col-12" id="prod-div" v-if="!loader">
            <div
              class="card card-body events-card mb-4"
              style="
                min-height: 100% !important;
                min-width: 100% !important;
                width: 638px;
              "
            >
              <div class="row mb-2">
                <div class="col-md-8 d-flex align-items-center mb-md-0 mb-3">
                  <img
                    :src="
                      detail.published_by && detail.published_by.profile_img
                        ? detail.published_by.profile_img
                        : '/img/placeholder-image.jpg'
                    "
                    v-on:click="checked(detail)"
                    alt=""
                    class="img-fluid rounded-circle object-cover"
                    style="width: 50px; height: 50px"
                  />
                  <div class="pl-3">
                    <p class="font-medium mb-0 font-xs-14">
                      {{
                        detail.published_by
                          ? detail.published_by.first_name
                          : ""
                      }}
                      {{
                        detail.published_by ? detail.published_by.last_name : ""
                      }}
                    </p>
                    <p class="font-12 text-dark mb-1 font-xs-12">
                      {{ detail.category ? detail.category.name : "" }}
                    </p>
                  </div>
                </div>
                <!-- <div class="col-md-4 text-right">
                      <div class="mb-0 float-md-none float-right">
                        <a
                          class="text-secondary"
                          id="dropdown_btn"
                          data-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
                        ></a>

                        <div
                          class="
                            dropdown-menu dropdown-menu-right
                            show-dropdown
                          "
                          style="
                            position: absolute;
                            transform: translate3d(220px, 44px, 0px);
                            top: 0px;
                            left: 0px;
                            will-change: transform;
                          "
                          x-placement="bottom-end"
                        >
                          <a
                            class="dropdown-item"
                            v-show="data.posted_user_id == myDetail.id"
                            type="button"
                            v-on:click="showEditPopup(data)"
                            v-if="self.id == data.user_id"
                          >
                            Edit</a
                          >
                          <a
                            class="dropdown-item"
                            v-show="data.posted_user_id == myDetail.id"
                            type="button"
                            v-on:click="showDeletePopup(data.uuid)"
                            v-if="self.id == data.user_id"
                          >
                            Delete</a
                          >
                          <a
                            class="dropdown-item"
                            type="button"
                            v-on:click="showSharePopup(data)"
                          >
                            Share</a
                          >
                        </div>
                      </div>
                    </div> -->
              </div>
              <div class="position-relative mb-3" v-if="detail.body">
                <!-- <div v-html="data.body ? data.body : ' '" v-linkified></div> -->
                <div v-html="getData(detail.body)" />
              </div>
              <div class="position-relative mb-3" v-if="detail.attachment">
                <img
                  :src="
                    detail.attachment
                      ? detail.attachment
                      : '/images/placeholder-image.jpg'
                  "
                  class="img-fluid card-img"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="d-flex mb-4 mt-n3 justify-content-center">
            <!-- <div
              class="
                col-1
                btn-group-toggle
                like-button
                justify-content-left
                p-0
              "
              data-toggle="buttons"
            >
              <label
                class="btn btn-light"
                :class="detail.has_liked ? 'active' : ''"
              >
                <input type="checkbox" />
                <i class="fa fa-thumbs-up" aria-hidden="true"></i>
              </label>
            </div> -->
            <div
              class="
                col-6
                btn-group-toggle
                like-button
                justify-content-left
                p-0
              "
              data-toggle="buttons"
              v-on:click="showLikeModal(detail.uuid)"
            >
              <label class="btn btn-light">
                Like
                <span class="like-count">{{ detail.likes_count }}</span>
              </label>
            </div>
            <div
              class="col-6 btn-group-toggle like-button"
              data-toggle="buttons"
              style="text-align-last: end"
              v-on:click="showCommentPopup(detail.uuid)"
            >
              <button
                type="button"
                class="btn btn-light comment-btn"
                data-toggle="modal"
                data-target="#CommentModal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chat-right-text-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z"
                  ></path>
                </svg>
                Comment {{ detail.comments_count }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="likeModal">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Likes</h4>
        <button
          v-on:click="$refs.likeModal.closeModal()"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="images/close.svg" width="15" />
        </button>
      </div>
      <div
        class="modal-body form-style pb-0 text-center pt-2"
        style="max-height: 400px; overflow: auto"
      >
        <div class="d-flex mb-3" v-for="(data, index) in likeList" :key="index">
          <img
            :src="
              data?.user.profile_img
                ? data?.user.profile_img
                : '/images/profile.svg'
            "
            class="rounded-circle object-cover"
            width="50"
            height="50"
            alt="Profile Img"
          />
          <div class="text-left ml-2">
            <div class="text-left bg-light-2 p-2 rounded">
              <p class="mb-0">
                {{ data?.user.first_name ? data?.user.first_name : " " }}
                {{ data?.user.last_name ? data?.user.last_name : " " }}
              </p>
            </div>
            <span class="small text-secondary">{{ data.ago_time }}</span>
          </div>
        </div>
        <ErrorComponent @retry="getLikes(0)" ref="listComponent" />
      </div>
    </div>
  </TextModal>
  <TextModal ref="commentModal">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Comments</h4>
        <button
          v-on:click="$refs.commentModal.closeModal()"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="images/close.svg" width="15" />
        </button>
      </div>
      <div
        class="modal-body form-style pb-0 text-center pt-2"
        style="max-height: 400px; overflow: auto"
      >
        <div
          class="d-flex mb-3"
          v-for="(data, index) in commentList"
          :key="index"
        >
          <img
            :src="
              data?.user.profile_img
                ? data?.user.profile_img
                : '/images/profile.svg'
            "
            class="rounded-circle object-cover"
            width="50"
            height="50"
            alt="Profile Img"
          />
          <div class="text-left ml-2">
            <div class="text-left bg-light-2 p-2 rounded">
              <p class="mb-0">
                {{ data?.user.first_name ? data?.user.first_name : " " }}
                {{ data?.user.last_name ? data?.user.last_name : " " }}
              </p>

              <p class="small mb-0">
                {{ data.body }}
              </p>
            </div>
            <div class="row">
              <div class="col-9 text-left">
                <span class="small text-secondary" style="margin-left: auto">{{
                  data.ago_time
                }}</span>
              </div>
              <!-- <div
                class="col-md-3 text-right"
                v-if="data.user.id == self.id || data.feed.user_id == self.id"
              >
                <div class="mb-0 float-md-none float-right">
                  <a
                    class="text-secondary"
                    id="dropdown_btn"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
                  ></a> -->

                  <!-- <div
                    class="dropdown-menu dropdown-menu-right show-dropdown"
                    style="
                      position: absolute;
                      transform: translate3d(220px, 44px, 0px);
                      top: 0px;
                      left: 0px;
                      will-change: transform;
                    "
                    x-placement="bottom-end"
                  >
                    <a
                      class="dropdown-item"
                      v-show="data.user.uuid == self.uuid"
                      type="button"
                      v-on:click="EditComment(data)"
                    >
                      Edit</a
                    >
                    <a
                      class="dropdown-item"
                      type="button"
                      v-on:click="showDeleteComment(data)"
                    >
                      Delete</a
                    >
                  </div> -->
                <!-- </div>
              </div> -->
            </div>
          </div>
        </div>
        <ErrorComponent @retry="getComments(0)" ref="commentComponent" />
      </div>
    </div>
  </TextModal>
</template>
<script>
import TextModal from "@/components/TextModal.vue";
import $ from "jquery";
import OuterHeader from "@/components/OuterHeader";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "SharedFedd",
  components: {
    OuterHeader,
    TextModal,
    ErrorComponent,
  },
  data() {
    return {
      loader: false,
      detail: {},
      id: this.$route.query.id,
      commentObj: {},
      likeList: [],
      commentList: [],
      searchObj: {},
    };
  },
  mounted() {
    this.$store.commit("showSidebar", false);
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
    this.getDetail();
  },
  methods: {
    getDetail() {
      (this.loader = true),
        this.$api
          .getAPI({
            _action: "feed/" + this.id,
          })
          .then((res) => {
            this.loader = false;
            this.detail = res.info;
          })
          .catch(() => {});
    },
    getData(data) {
      var temp = "";
      if (data) {
        var array = data.split(" ");
        for (var i in array) {
          if (array[i].startsWith("http")) {
            temp +=
              " <a target='_blank' href='" +
              array[i] +
              "'>" +
              array[i] +
              "</a>";
          } else {
            temp += " " + array[i];
          }
        }
      }
      return temp;
    },
    showLikeModal(id) {
      this.getLikes(id, 0);
      this.$refs.likeModal.showModal();
    },
    getLikes(id, offset) {
      this.loader = true;
      if (this.$refs.commentComponent) {
        this.$refs.commentComponent.updateLoader(true);
      }
      this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action: "feed/" + id + "/like",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.likeList = res.list;
          this.offset = res.next_offset;
          if (this.$refs.listComponent) {
            this.$refs.listComponent.setData(res, "No likes available");
            this.$refs.listComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.listComponent) {
            this.$refs.listComponent.setData(0, undefined, "");
            this.$refs.listComponent.updateLoader(false);
          }
        });
    },
    showCommentPopup(id) {
      this.$refs.commentModal.showModal();
      this.getComments(id, 0);
    },
    getComments(id, offset) {
      this.loader = true;
      if (this.$refs.commentComponent) {
        this.$refs.commentComponent.updateLoader(true);
      }
      this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action: "feed/" + id + "/comment",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.commentList = res.list;
          this.offset = res.next_offset;
          if (this.$refs.commentComponent) {
            this.$refs.commentComponent.setData(res, "No comment available");
            this.$refs.commentComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
  },
};
</script>