<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                <img
                  src="/images/back-arrow.svg"
                  width="17"
                  class="mr-2 align-top"
                  alt=""
              /></a>
              {{ this.categotyName }}
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end">
            <input
              type="text"
              class="search-click"
              name=""
              v-on:keyup.enter="getExpertList(0)"
              placeholder="search here..."
              v-model="searchObj.keyword"
            />
          </div>
        </div>
        <div class="card rounded-l border-0 shadow">
          <div
            v-for="(data, index) in expertList"
            :key="index"
            class="card-body border-bottom expert-list cursor-pointer"
            v-on:click="showDetails(data.id)"
          >
            <div class="row">
              <div class="col-md-8 d-flex align-items-center mb-md-0 mb-3">
                <img
                  :src="data.image ? data.image : '/img/profile.svg'"
                  alt=""
                  class="img-fluid rounded-circle object-cover"
                  style="width: 80px; height: 80px"
                />
                <div class="pl-3">
                  <p class="font-medium mb-2 font-18 font-xs-16">
                    {{ data.name }}
                  </p>
                  <p
                    class="
                      font-weight-light font-14
                      text-secondary
                      mb-1
                      font-xs-12
                    "
                  >
                    Exp-{{ data.experience }} years
                  </p>
                  <p
                    class="
                      font-weight-light font-14
                      text-secondary
                      mb-0
                      font-xs-12
                    "
                  >
                    {{ data.company_name }}
                  </p>
                </div>
              </div>
              <div class="col-md-4 text-md-right text-center">
                <!-- <vue3starRatings
                  v-if="data.avg_rating || data.avg_rating == 0"
                  starSize="15"
                  :showControl="false"
                  :disableClick="false"
                  v-model="data.avg_rating"
                /> -->
                <div class="d-flex justify-content-end align-items-center mb-2">
                    <star-rating
                    id="expert-list"
                    class="mb-1"
                    :increment="0.5"
                    v-if="data.avg_rating || data.avg_rating == 0"
                    :rating="data.avg_rating"
                    :show-rating="false"
                    :star-size="15"
                    :read-only="true"
                    ></star-rating>
                    <span class="text-secondary-2 font-12 ml-1">({{ data.avg_rating }})</span>
                </div>
                <p class="font-14 mb-0">
                  <img src="/img/distance.svg" width="20" alt="" />
                  <span class="ml-2 text-secondary">{{data.distance}}km</span>
                </p>
              </div>
            </div>
          </div>
          <ErrorComponent @retry="getExpertList(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
  </div>
  <div
    style="text-align: center; border-radius: 16px"
    class="col-12 pb-3"
    v-show="offset != -1 "
    v-if ="!loader"
  >
    <a
      id="load-button"
      class="txt-success cursor-pointer mb-3"
      v-on:click="getExpertList(offset)"
      style="max-width: 110px"
    >
      Load More
    </a>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import StarRating from "vue-star-rating";
export default {
  name: "ExpertList",
  components: {
    ErrorComponent,
    StarRating,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      offset: 0,
      searchObj: {
        keyword: "",
        category_id: "",
      },
      expertList: [],
      categotyName: "",
    };
  },
  mounted() {
    // alert(this.$sharedService.getMapValue("category_name"));
    if (this.$sharedService.getMapValue("category_name") != null) {
      this.categotyName = this.$sharedService.getMapValue("category_name");
    } else {
      this.$router.go(-1);
    }
    this.getExpertList(0);
    this.$store.commit("showSidebar", false);
  },
  methods: {
    getExpertList(offset) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      if (offset == 0) {
        this.expertList = [];
      }
      this.searchObj.offset = offset;
      (this.searchObj.category_id = this.id),
        this.$api
          .getAPI({
            _action: "experts",
            _body: this.searchObj,
          })
          .then((res) => {
            this.loader = false;
            for (var i in res.list) {
              this.expertList.push(res.list[i]);
              // this.$sharedService.setMapValue("expertDetail", res.list[i]);
            }
            this.offset = res.next_offset;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res, "No experts available");
              this.$refs.errorComponent.updateLoader(false);
            }
          })
          .catch(() => {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateLoader(false);
            }
          });
    },
    showDetails(id){
      for (var j in this.expertList)
      {
        if(id == this.expertList[j].id)
        {
          this.$sharedService.setMapValue("expertDetail", this.expertList[j]);
        }
      }
      this.$router.push('/expert/' + id)
    }
  },
};
</script>