<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
                      <h2 class="page-title mb-md-4">
                        <a class="cursor-pointer" v-on:click="$router.go(-1)">
                          <img
                            src="/images/back-arrow.svg"
                            width="17"
                            class="mr-2 align-top"
                            alt=""
                        /></a>
                        Report
                      </h2>
                    </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card mb-4 rounded-l border-0 shadow-sm">
              <div class="row justify-content-center">
                <div class="col-md-9">
                  <Form @submit="submit()">
                  <div class="floating-form mb-4">
                    <div class="floating-form-group mt-4" style="height: 130px">
                      <Field
                        as="textarea"
                        type="text"
                        rows="4"
                        name="message"
                         rules="required:message" 
                        :validateOnInput="true"
                       v-model="detail.message" 
                      />
                      
                      <label for="message" 
                        >Please write your problem/suggestion</label
                      >
                      <span class="text-danger">*</span>
                    </div>
                    <ErrorMessage name="message" class="text-danger" />
                  </div>
                  <div class="text-center mb-md-5 mb-4">
                    <button class="btn btn-brand-1 mt-5 px-5" id="submit-button">
                      <!-- v-on:click="save()" -->
                      Submit
                    </button>
                  </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  <script>
  import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "ReportPage",
   components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id:this.$route.params.id,
      detail:{},
    };
  },
  mounted() {},
  methods: {
    submit(){
      this.detail.promo_id=this.id
      this.$api
        .postAPI({
          _action: "loyalty-promo/report",
          _body: this.detail,
        })
        .then((res) => {
          this.$notify({
              type: "success",
              text: res.message,
            });
        })
        .catch(() => {});
    },

  },
};
</script>