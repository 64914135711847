<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-10">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">Business Conclave (offline)</h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end">
              <input
                type="text"
                class="search-click"
                name=""
                v-on:keyup.enter="getList(0)"
                placeholder="search here..."
                v-model="searchObj.keyword"
              />
            </div>
          </div>
          <ul
            class="nav nav-pills filter-tabs mb-3"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                :class="currentTab == 'UPCOMING' ? 'active' : ''"
                v-on:click="changeTab('UPCOMING')"
                style="cursor: pointer"
                >Upcoming</a
              >
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-contact-tab"
                data-toggle="pill"
                href="#pills-contact"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
                :class="currentTab == 'PAST' ? 'active' : ''"
                v-on:click="changeTab('PAST')"
                style="cursor: pointer"
                >Past</a
              >
            </li>
          </ul>
          <div
            v-show="!loader"
            class="mb-4"
            v-for="(data, index) in businessConclaveList"
            :key="index"
          >
            <BusinessConclaveCard :data="data" />
          </div>
          <div style="text-align: center" v-if="offset != -1">
            <button
              type="button"
              id="load-button"
              class="btn btn-brand-1 w-xs-100"
              v-on:click="getList(offset)"
              style="max-width: 110px"
            >
              Load More
            </button>
          </div>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import moment from "moment";
import BusinessConclaveCard from "@/components/BusinessConclaveCard";
import ErrorComponent from "@/components/ErrorComponent";
import moment from "moment";
export default {
  name: "BusinessConclave",
  components: {
    ErrorComponent,
    BusinessConclaveCard,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      offset: -1,
      businessConclaveList: [],
      searchObj: {
        event_list_type: "",
        latitude: "",
        longitude: "",
      },
      currentTab: "UPCOMING",
    };
  },
  mounted() {
    this.$store.commit("showSidebar", true);
    this.getList(0);
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "UPCOMING") {
        this.searchObj.event_list_type = "UPCOMING";
        this.getList(0);
      } else if (tab == "PAST") {
        this.searchObj.event_list_type = "PAST";
        this.getList(0);
      }
    },
    getList(offset) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      if (offset == 0) {
        this.businessConclaveList = [];
      }

      this.searchObj.offset = offset;

      this.$api
        .getAPI({
          _action: "business-conclaves",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.businessConclaveList = res.list;
          for (var j in this.businessConclaveList) {
            this.businessConclaveList[j].info = this.businessConclaveList[j];
          }
          console.log(" this.businessConclaveList", this.businessConclaveList);
          if (this.businessConclaveList.length > 0) {
            for (var i in this.businessConclaveList) {
              if (this.businessConclaveList[i].info) {
                this.businessConclaveList[i].info.isShow = false;
                if (
                  moment(
                    this.businessConclaveList[i].info.from_datetime,
                    "YYYY-MM-DD HH:mm:ss"
                  ) > moment(new Date())
                ) {
                  this.businessConclaveList[i].info.isShow = true;
                }
              }
            }
          }

          this.offset = res.next_offset;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No business conclave available"
            );
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
  },
};
</script>