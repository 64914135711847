<template>
  <header class="header">
    <div class="row main-row">
      <div class="col-lg-6 col-md-10 col-6 align-self-center d-flex align-items-center pr-0">
        <a href="javascript:void(0)" class="mr-md-4 mr-2 pr-lg-2 text-dark font-22" id="hear_menu_toggle"><img
            src="/images/hamburger-menu.svg" class="hamburger-menu" width="30" /></a>
        <img src="/images/tenx-logo.svg" alt="Logo" class="img-fluid d-lg-none d-inline-block header-logo mr-3" width="80"
          height="26" />
        <nav class="navbar navbar-expand-lg navbar-light d-none d-md-block py-0 px-1">
          <ul class="navbar-nav header-nav ml-2">
            <li class="nav-item">
              <a data-toggle="tooltip" data-placement="top" :title="homeTip" class="cursor-pointer nav-link"
                :class="currentTab == 'home' ? 'active' : ''" v-on:click="$router.push('/home')">
                <img src="/images/home-icon-color.svg" alt="" width="25" />
              </a>
            </li>
            <li class="nav-item" v-show="this.user_type != 'USER'">
              <a data-toggle="tooltip" data-placement="top" :title="webinarTip" class="cursor-pointer nav-link"
                :class="currentTab == 'webinar' ? 'active' : ''" v-on:click="$router.push('/webinar')">
                <img src="/images/calendar-tick-color.svg" alt="" width="25" />
              </a>
            </li>
            <li class="nav-item" v-show="this.user_type != 'USER'">
              <a data-toggle="tooltip" data-placement="top" :title="businessTip" class="cursor-pointer nav-link"
                :class="currentTab == 'business-growth-group' ? 'active' : ''" v-on:click="businessGroup()">
                <img src="/images/group-color.svg" alt="" width="25" />
              </a>
            </li>
            <li class="nav-item" v-show="this.user_type != 'USER'">
              <a data-toggle="tooltip" data-placement="top" :title="expertTip" class="cursor-pointer nav-link"
                :class="currentTab == 'expert-category' ? 'active' : ''" v-on:click="$router.push('/expert-category')">
                <img src="/images/find-experts-color.svg" alt="" width="25" />
              </a>
            </li>
            <li class="nav-item">
              <a data-toggle="tooltip" data-placement="top" :title="profileTip" class="cursor-pointer nav-link"
                :class="currentTab == 'my-profile' ? 'active' : ''" v-on:click="this.$router.push('/my-profile')">
                <img src="/images/profile-icon2.svg" alt="" width="25" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-lg-6 col-md-2 col-6 d-flex justify-content-end align-items-center">
        <ul class="navbar-nav">
          <li class="nav-item dropdown notification-dd">
            <a class="nav-link dropdown-toggle waves-effect waves-dark mr-md-3" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" id="notification_dd">
              <span class="badge badge-danger count" v-show="this.count != 0">{{
                this.count
              }}</span>
              <img src="/images/notification.svg" width="20" />
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="notification_dd">
              <a class="dropdown-item cursor-pointer" v-for="(data, index) in notificationList" :key="index"
                v-on:click="redirectTo(data)">
                <div class="">
                  <p class="font-16 mb-2">{{ data.title }}</p>
                  <p class="font-14 mb-2">{{ data.message }}</p>
                  <p class="font-12 text-secondary mb-0">
                    <img src="/images/clock.svg" width="12" class="align-text-top" />
                    <em>{{ data.created_at }}</em>
                  </p>
                  <div class="row mt-1 ml-2" v-if="data.action == 'JOIN_GROUP_REQUEST'">
                    <button type="button" style="height: 40px" id="submit-button" class="btn btn-success"
                      v-on:click="accept(data)">
                      Accept
                    </button>
                    <button type="button" style="height: 40px" id="submit-button" class="btn btn-danger ml-3"
                      v-on:click="reject(data)">
                      Reject
                    </button>
                  </div>
                </div>
              </a>

              <p class="text-center mb-0">
                <router-link class="dropdown-item text-warning" to="/notifications" style="text-decoration: underline"
                  v-show="this.length > 3">Show More
                </router-link>
              </p>
            </div>
          </li>
        </ul>

        <ul class="navbar-nav">
          <li class="nav-item dropdown notification-dd" id="dropdown-id">
            <a class="nav-link dropdown-toggle waves-effect waves-dark mr-md-3" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <img :src="detail.profile_img ? detail.profile_img : '/images/profile.svg'" width="32" height="32"
                class="rounded-circle object-cover" style="cursor: pointer" />
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" style="
                width: 245px;
                position: absolute;
                transform: translate3d(-205px, 48px, 0px);
                top: 0px;
                left: 0px;
                will-change: transform;
              " id="dropdown-menu-id">
              <a class="dropdown-item cursor-pointer" v-on:click="this.$router.push('/my-profile')"><img
                  src="/images/profile-icon.svg" width="16" class="mr-2" alt="" />My
                Profile</a>
              <a class="dropdown-item cursor-pointer" v-on:click="this.$router.push('/companyProfile')"><img
                  src="/images/company-profile.svg" width="18" class="mr-1" alt="" />
                My Company Profile</a>
              <a class="dropdown-item cursor-pointer" v-on:click="this.$router.push('/membership-info')"><img
                  src="/images/info.svg" width="18" class="mr-1" alt="" /> Membership
                Info</a>
              <!-- <a
                class="dropdown-item cursor-pointer"
                v-on:click="this.$router.push('/about-tenx')"
                ><img
                  src="/images/about-x.svg"
                  width="18"
                  class="mr-1"
                  alt=""
                />
                About TenX</a
              > -->
              <a class="dropdown-item cursor-pointer" v-on:click="this.$router.push('/contact')"><img
                  src="/images/contact-support.svg" width="18" class="mr-1" alt="" />
                Contact Support</a>
              <a class="dropdown-item cursor-pointer" v-on:click="logout"><img src="/images/logout.svg" width="18"
                  class="mr-1" alt="" /> Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <TextModal ref="reviewModal">
    <div class="modal-content">
      <div class="modal-header">
        <div class="row col-12">
          <h5 class="col-11" style="padding-left: 72px">Write Review</h5>
          <a type="button" class="close col-1" v-on:click="$refs.reviewModal.closeModal()">
            <img src="/images/cross.png" class="mb-lg-5 mb-4" />
          </a>
        </div>
      </div>
      <div class="modal-body">
        <p class="mb-3 ratings font-22">
          <star-rating :star-size="50" style="justify-content: center; width: 100%" :increment="0.5" :show-rating="false"
            v-model:rating="reviewObj.rating"></star-rating>
        </p>
        <div class="mb-3">
          <label style="width: 100%" for="title"> Write Review: </label>
          <textarea type="text" style="width: 100%" rows="4" id="description" name="review" v-model="reviewObj.review"
            rules="required:review" :validateOnInput="true" />
        </div>
        <ErrorMessage name="title" class="validation-msg" />
        <div class="text-center" style="margin-top: 30px">
          <button type="button" id="submit-button" class="btn btn-brand-1 px-5" v-on:click="submit()">
            Submit
          </button>
        </div>
      </div>
    </div>
  </TextModal>
</template>
<script>
import TextModal from "@/components/TextModal.vue";
import StarRating from "vue-star-rating";
import moment from "moment";
import $ from "jquery";
export default {
  name: "InnerHeader",
  components: {
    TextModal,
    StarRating,
  },
  data() {
    return {
      currentTab: location.pathname.replace("/", ""),
      searchObj: {
        offset: 0,
      },
      notificationList: [],
      length: "",
      loader: "",
      homeTip: "Home",
      webinarTip: "Webinar",
      expertTip: "TenX Verified Expert",
      businessTip: "TenX Growth Groups (TGG) ",
      profileTip: "My Profile",
      reviewObj: {},
      Id: "",
      otherId: "",
      subscription: {},
      user_type: localStorage.getItem("user_type"),
    };
  },
  watch: {
    "$route.fullPath"(newValue) {
      this.currentTab = newValue.replace("/", "");
    },
  },
  mounted() {
    this.getNotification(0);
    this.getUnreadCount();
    $("#menu-toggle,#hear_menu_toggle").click(function (e) {
      e.stopPropagation();
      $("#main-wraper").toggleClass("toggled");
    });
    $("#leftNav").click(function () {
      $("#main-wraper").removeClass("toggled");
    });
  },
  computed: {
    detail() {
      return this.$store.getters.getUser ? this.$store.getters.getUser : {};
    },
    count() {
      return this.$store.getters.getNotificationCount
        ? this.$store.getters.getNotificationCount
        : 0;
    },
  },
  methods: {
    mobileMenu() { },

    logout() {
      this.$sharedService.clearMap();
      localStorage.clear();
      this.$router.push("/login");
    },
    showDropdown() {
      var dropdownId = document.getElementById("dropdown-id");
      if (dropdownId) {
        if (dropdownId.className.includes("show")) {
          dropdownId.classList.remove("show");
        } else {
          dropdownId.classList.add("show");
        }
      }
      var dropdownMenuId = document.getElementById("dropdown-menu-id");
      if (dropdownMenuId) {
        if (dropdownMenuId.className.includes("show")) {
          dropdownMenuId.classList.remove("show");
        } else {
          dropdownMenuId.classList.add("show");
        }
      }
    },
    getNotification(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.searchObj.offset = offset;
      if (offset == 0) {
        this.notificationList = [];
      }
      this.$api
        .getAPI({
          _action: "notifications",
          _body: this.searchObj,
        })
        .then((res) => {
          this.length = res.count;
          if (res.list.length <= 2) {
            for (var j in res.list) {
              res.list[j].created_at = moment(res.list[j].created_at).format(
                "DD MMM YYYY | HH:mm A"
              );

              this.notificationList.push(res.list[j]);
            }
          } else {
            for (var i = 0; i < 3; i++) {
              res.list[i].created_at = moment(res.list[i].created_at).format(
                "DD MMM YYYY | HH:mm A"
              );
              this.notificationList.push(res.list[i]);
            }
          }
        })
        .catch(() => { });
    },
    getUnreadCount() {
      this.$api
        .getAPI({
          _action: "notification/count",
        })
        .then((res) => {
          this.$store.commit("setNotificationCount", res.unread_count);
        })
        .catch(() => { });
    },
    redirectTo(detail) {
      console.log(detail.action);
      if (detail.action == "OFFER_RECEIVED") {
        this.$api
          .getAPI({
            _action: "post/requirement/" + detail.action_id,
          })
          .then((res) => {
            this.$sharedService.setMapValue("data", res);
            this.$router.push("/offer-view/" + detail.action_id);
            this.unreadNotification(detail.id);
          })
          .catch(() => { });
      } else if (detail.action == "WEBINAR") {
        this.$router.push("/webinar-details/" + detail.action_id);
        this.unreadNotification(detail.id);
      } else if (detail.action == "HEALTH_AND_WELL_BEING_ACTIVITY") {
        this.$router.push("/health-well-being-details/" + detail.action_id);
        this.unreadNotification(detail.id);
      } else if (detail.action == "GROUP_MEMBER_REQUESTED") {
        this.$router.push("/business-member/" + detail.action_id);
        var tab = "PENDING";
        this.$sharedService.setMapValue("tab", tab);
        this.unreadNotification(detail.id);
      } else if (detail.action == "BUSINESS_CONCLAVE") {
        this.$router.push("/business-conclave-details/" + detail.action_id);
        this.unreadNotification(detail.id);
      } else if (detail.action == "REVIEW_REQUESTED") {
        this.Id = detail.action_id;
        this.otherId = detail.other_id;
        this.$refs.reviewModal.showModal();
        this.unreadNotification(detail.id);
      } else if (detail.action == "POST_REQUIREMENT") {
        this.$router.push("/my-leads");
        this.getMyProfile(false);
        this.unreadNotification(detail.id);
      } else {
        this.unreadNotification(detail.id);
      }
    },

    businessGroup() {
      this.getMyProfile(true);
    },

    getMyProfile(flag) {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.meCall = res.response;
          if (flag) {
            if (this.meCall && this.meCall.group_info && this.meCall.group_info.id) {
              this.$router.push("/business-growth-detail/" + this.meCall.group_info.id);
            } else {
              this.$router.push("/business-growth-group");
            }
          }
          if (!flag) {
            this.subscription = res.response?.user_subscription;
            if (this.subscription.my_prospects_lead_support == "Y") {
              this.$router.push("my-leads");
            } else {
              this.$router.push(
                "/select-plan/upgrade?attribute=my_prospects_lead_support&&redirect=my-leads"
              );
            }
          }
          // this.$store.commit("setUser", res.response);
          // localStorage.setItem("refer_code", res.response.referral_code);
          // this.subscription = res.response?.user_subscription;
          // this.companies = res.response?.companies;
          // this.user_type = res.response.user_type;
          // this.getActivityList();
          // this.getInterestList();
        })
        .catch(() => { });
    },
    getInterestList() {
      this.$api
        .getAPI({
          _action: "category/event",
        })
        .then((res) => {
          for (var i in res.list) {
            var isChecked = false;
            for (var j in this.detail.interests) {
              if (this.detail.interests[j].interest_id == res.list[i].id) {
                isChecked = true;
              }
            }

            res.list[i].isChecked = isChecked;
          }
          this.interestList = res.list;
        })
        .catch(() => { });
    },
    getActivityList() {
      this.$api
        .getAPI({
          _action: "category/activity",
        })
        .then((res) => {
          for (var i in res.list) {
            var isChecked = false;
            for (var j in this.detail.interested_activity) {
              if (this.detail.interested_activity[j].interest_id == res.list[i].id) {
                isChecked = true;
              }
            }

            res.list[i].isChecked = isChecked;
          }
          this.activityList = res.list;
        })
        .catch(() => { });
    },
    unreadNotification(id) {
      this.$api
        .putAPI({
          _action: "notification/" + id,
        })
        .then(() => {
          this.getUnreadCount();
        })
        .catch(() => { });
    },
    submit() {
      this.$api
        .putAPI({
          _action: "review/" + this.Id,
          _body: this.reviewObj,
          _buttonId: "submit-button",
        })
        .then((res) => {
          this.$refs.reviewModal.closeModal();
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => { });
    },
    accept(data) {
      var obj = {};
      obj.status = "ACCEPTED";
      this.Id = data.action_id;
      this.otherId = data.other_id;
      this.$api
        .postAPI({
          _action: "business-work-group/" + this.Id + "/" + this.otherId,
          _body: obj,
          _buttonId: "submit-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getNotification(0);
        })
        .catch(() => { });
    },
    reject(data) {
      var obj = {};
      obj.status = "REJECTED";
      this.Id = data.action_id;
      this.otherId = data.other_id;
      this.$api
        .postAPI({
          _action: "business-work-group/" + this.Id + "/" + this.otherId,
          _body: obj,
          _buttonId: "submit-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getNotification(0);
        })
        .catch(() => { });
    },
  },
};
</script>
