<template>
  <div>
    <meta property="og:url" :content="location" />
    <meta property="og:description" :content="data.description" />
    <meta property="og:image" :content="data.info.image" />
    <meta property="og:title" :content="data.title" />

    <div class="card card-body events-card mb-4">
      <div class="row mb-md-0 mb-3">
        <div class="col-md-8">
          <p class="font-18 font-medium mb-2">{{ data.info.name }}</p>
          <p class="font-14 mb-md-3 mb-1">
            <!-- {{ data.address }} -->
          </p>
        </div>
        <div class="col-md-4 text-md-right">
          <p class="font-14 mb-2 font-xs-12">{{ data.info.ago_time }}</p>

          <p v-show="data.info.isShow" v-on:click="showSharePopup(data)">
            <a><img src="/img/share-icon.png" /></a>
          </p>
          <span class="badge badge-light-yellow mb-2">{{ data.info.type }}</span>
        </div>
      </div>
      <div class="position-relative mb-3" v-on:click="this.$router.push('/business-conclave-details/' + data.info.id)">
        <img v-on:click="openImage(data.info.image, index)"
          :src="data.info.image ? data.info.image : '/img/placeholder-image.jpg'" class="img-fluid card-img" alt="" />
        <span class="badge bage-brand position-absolute" style="left: 10px; top: 10px">{{
          data.info.mode == "PHYSICAL"
          ? "Offline"
          : $helperService.getTitleCase(data.info.mode)
        }}</span>
      </div>
      <!-- <p class="font-14 text-dark mb-2">
        Together with my design team, we designed thi Cyberyacht concept
        artwork.
      </p> -->
      <div class="row ml-2">
        <p class="font-24 font-bold mb-md-3 mb-2" v-show="data.info.type != 'FREE'">
          ₹
          {{ data.info.price }}
        </p>
        <h5 class="text-secondary mb-md-2 mb-1" v-show="data.info.type != 'FREE'">
          +GST
        </h5>
      </div>
      <div class="row" v-on:click="this.$router.push('/business-conclave-details/' + data.info.id)">
        <div class="col-md-7 mb-md-0 mb-3">
          <p class="text-secondary mb-md-2 mb-1">
            <img src="/images/calendar.svg" width="14" class="align-baseline mr-1" />
            {{ $helperService.getFormatedDateAndTime(data.info.from_datetime) }}
          </p>
          <p class="text-secondary mb-0">
            <img src="/images/location-icon.svg" width="14" class="align-baseline mr-1" />{{ data.info.address }}
          </p>
        </div>
      </div>
      <div class="row pt-3"> 
        <div v-if="!data.info.has_register" class="col-md-12 text-center">
          <button class="btn btn-brand-1 w-xs-100" id="register-button" v-on:click="register(data)"
            v-if="data.info.isShow">
            Register
          </button>
        </div>
        <div class="col-md-12 py-1 pt-3 font-14 d-inline-block px-2 text-center cursor-pointer" v-else>
          <span class="alert alert-success"><span>You are already registered. View your digital pass.</span></span>
        </div>
      </div>
    </div>
  </div>
  <ShareModal ref="shareModal" />
  <vue-easy-lightbox :visible="visibleRef" :imgs="postImage" :index="indexRef" @hide="onHide"></vue-easy-lightbox>
</template>
<script>
import ShareModal from "./SocialShare/ShareModal.vue";
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js";
import { ref } from "vue";
import "vue-easy-lightbox/external-css/vue-easy-lightbox.css";
export default {
  name: "BusinessConclaveCard",
  components: { ShareModal, VueEasyLightbox },
  props: ["data"],
  data() {
    return {
      accessToken: localStorage.getItem("access_token"),
      isShow: false,
      location: "",
      visibleRef: ref(false),
      indexRef: ref(0),
    };
  },
  mounted() {
    this.location = window.location.href;
  },
  methods: {
    openImage(image, index) {
      this.postImage = image;
      this.indexRef = index;
      this.visibleRef = true;
    },
    onHide() {
      this.visibleRef = false;
    },
    register(data) {
      if (!this.accessToken) {
        this.$sharedService.setMapValue("event_detail", data);
        this.$router.push("/guest-signup?type=" + "BUSINESS_CONCLAVE" + "&id=" + data.id);
      } else {
        this.$router.push("/business-conclave-details/" + data.id);
      }
    },
    showSharePopup(data) {
      var url =
        "https://app.tenxindia.com/shared-event?id=" +
        data.id +
        "&type=" +
        "BUSINESS_CONCLAVE";
      var title =
        "TenX is organizing a business conclave we would be glad if you could join and participate. To save your seat, please register and feel free to share it with your friends.";
      this.$refs.shareModal.showModal(url, title);
    },
  },
};
</script>
