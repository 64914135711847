<template>
  <div class="page-content" style="margine-top: 150px !important">
    <h5>Refer A Friend</h5>
    <div class="row mb-4 justify-content-center pt-1">
      <div class="col-lg-8 mx-auto">
        <div class="rounded mb-4 text-center">
          <img
            src="/img/web_refer_friend.png"
            alt=""
            class="img-fluid mb-3"
            style="object-fit: fill !important"
          />
          <h3
            class="text-white font-medium font-xs-20 px-md-5 text-center position-absolute w-100"
            style="bottom: 25px"
          ></h3>

          <br />
          <h5 style="color: bolder">
            Refer a friend and get a one-month TenX subscription free.
          </h5>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <div class="d-flex justify-content-center">
          <div class="col-lg-7">
            <button
              type="button"
              class="btn btn-lg btn-block"
              style="border: 2px dotted; width: 50 %"
            >
              {{ refer_code }}
            </button>
          </div>
        </div>
        <br />
        <div class="d-flex justify-content-center">
          <div class="col-lg-7">
            <button
              type="button"
              class="btn btn-lg btn-block"
              style="background-color: #9cc338"
              v-on:click="showSharePopup(refer_code)"
            >
              Share Referral Code
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ShareModal ref="shareModal" />
</template>
<script>
import ShareModal from "../../components/SocialShare/ShareModal.vue";
export default {
  name: "ReferFriend",
  components: { ShareModal },
  data() {
    return {
      isEquity: false,
      isBuyout: false,
      industryList: [],
      reasonList: [],
      detail: {
        industry_id: "",
        reason: "",
        remark: "Testing_remark",
      },
      refer_code: localStorage.getItem("refer_code"),
      myDetail: {},
    };
  },
  mounted() {
    this.getDetails();
    this.getIndustryList();
    this.getReasonList();
    this.$store.commit("showSidebar", false);
  },
  methods: {
    getDetails() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.myDetail = res.response;
          // console.log("my data", this.myDetail);
        })
        .catch(() => {});
    },
    showSharePopup(data) {
      console.log("code " + data);
      console.log("url", window.location.href);

      var url = "https://app.tenxindia.com/signup?refer_code=" + data;
      var title = "Be with TenX India";

      var str = `Join the Missionary Entrepreneurs of TenX India

          Dear Friend,
          Since you already know me and my business from very close, I want to share with you an interesting growth support group which I have found in my journey of growth. 

          This is TenX India, a holistic Business Growth & Networking platform. Its world of Opportunities /  Investments / Knowledge / Experience.
          It’s been very meticulously designed where they have quarterly physical business conclaves with seasoned entrepreneurs & monthly webinar’s for learning and growth. 
          Apart from that small growth groups are there of entrepreneurs who meet once a month and also act as advisors for each other. 

          I am having a great learning experience and momentum in my business. 
          I strongly recommend that you too become a platinum member of TenX and speed up your growth journey.
          Please subscribe through this link ${url}

          Thanks,
          ${this.myDetail.first_name + " " + this.myDetail.last_name} `;

      this.$refs.shareModal.showModal(url, title, str);
    },
    showThankyouPopup() {
      this.$refs.ThankyouModal.showModal(
        "Thank You",
        "Admin team will verify this request and contact you "
      );
    },
    selectedReason(type) {
      if (type == "EQUITY") {
        this.isEquity = !this.isEquity;
      } else {
        this.isBuyout = !this.isBuyout;
      }
    },

    selectReason(index) {
      for (var i in this.reasonList) {
        if (i == index) {
          this.reasonList[i].is_checked = true;
        }
      }
    },
    getIndustryList() {
      this.$api
        .getAPI({
          _action: "category/industry",
        })
        .then((res) => {
          this.industryList = res.list;
        })
        .catch(() => {});
    },
    getReasonList() {
      this.$api
        .getAPI({
          _action: "category/collaboration-reason",
        })
        .then((res) => {
          for (var i in res.list) {
            res.list[i].is_checked = false;
          }
          this.reasonList = res.list;
        })
        .catch(() => {});
    },
  },
};
</script>
