import { createStore } from "vuex";
const store = createStore({
  state() {
    return {
      userInfo: {},
      isShowSidebar: false,
      summary: { company: [], businessGroup: [], webinars: [], activity: [] },
      keyword:"",
      notificationCount : 0
    };
  },
  mutations: {
    setUser(state, payload) {
      state.userInfo = payload;
    },
    setSummary(state, payload) {
      state.summary = payload;
    },
    showSidebar(state, payload) {
      state.isShowSidebar = payload;
    },
    keyword(state,payload)
    {
  state.keyword = payload;
    },
    setNotificationCount(state,payload)
    {
     state.notificationCount = payload;
    }
  },
  getters: {
    getUser(state) {
      return state.userInfo;
    },
    getShowSidebar(state) {
      return state.isShowSidebar;
    },
    getSummary(state) {
      return state.summary;
    },
    getNotificationCount(state)
    {
      return state.notificationCount;
    }
  },
  actions: {},
});

export default store;
