<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a v-on:click="this.$router.go(-1)">
                <img
                  src="images/back-arrow.svg"
                  width="17"
                  class="mr-2 align-top"
                  alt=""
              /></a>
              Chat
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end"></div>
        </div>
        <div class="row chatbox">
          <div class="col-md-10">
            <div class="card chating-box bg-white">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-md-9 mb-md-0 mb-2">
                    <div class="d-flex align-items-center">
                      <div class="position-relative">
                        <img
                          :src="
                            companyLogo
                              ? companyLogo
                              : '/img/placeholder-image.jpg'
                          "
                          width="70"
                          height="60"
                          class="mr-2 object-contain"
                        />
                      </div>
                      <div>
                        <p class="mb-0 font-medium">
                          {{ companyName }}
                        </p>
                        <p class="text-primary font-medium mb-0"></p>
                        <a
                      :href="'tel:' + company.sales_contact_no"
                      v-if="company.sales_contact_no"
                    >
                      <i class="fa fa-phone" aria-hidden="true"></i>
                      {{ company.sales_dial_code ? company.sales_dial_code : " " }}{{company.sales_contact_no ? company.sales_contact_no : " "}}
                    </a>
                    <br />
                    <a
                      :href="'mailto:' + company.sales_email"
                      v-if="company.sales_email"
                    >
                      <i class="fa fa-envelope-o" aria-hidden="true"></i>
                      {{ company.sales_email ? company.sales_email : " " }}
                    </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 text-right">
                    <button
                      class="btn btn-brand-1"
                      v-on:click="showOfferModal(details)"
                    >
                      Send offer
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body msg-body border-success">
                <div
                  v-for="(data, index) in list"
                  :key="index"
                  class="mb-3"
                  :class="userId == data.sender.id ? 'sent-msg' : 'receive-msg'"
                >
                  <div class="d-flex align-items-start">
                    <img
                      :src="
                        (data.sender.logo
                          ? data.sender.logo
                          : '/images/profile.svg') ||
                        (data.receiver.logo
                          ? data.receiver.logo
                          : '/images/profile.svg')
                      "
                      width="30"
                      height="30"
                      class="mr-2 rounded-circle object-cover"
                      v-if="userId != data.sender.id"
                    />
                    <div class="msg" v-if="data.message">
                      {{ data.message }}
                    </div>
                    <div v-if="data.document_url">
                      <a :href="data.document_url" target="blank">
                        <img
                          :src="getDocumentType(data.document_url)"
                          width="80"
                          height="80"
                          class="mr-2 object-cover cursor-pointer"
                        />
                      </a>
                    </div>
                    <img
                      :src="
                        (data.sender.logo
                          ? data.sender.logo
                          : '/images/profile.svg') ||
                        (data.receiver.logo
                          ? data.receiver.logo
                          : '/images/profile.svg')
                      "
                      width="30"
                      height="30"
                      class="mr-2 rounded-circle object-cover"
                      v-if="userId == data.sender.id"
                    />
                  </div>
                  <p class="text-secondary font-12 mb-0 mt-1">
                    {{ data.created_at }}
                  </p>
                </div>
              </div>
              <div class="card-footer type-msg">
                <div
                  class="w-100 d-flex py-2 rounded-common"
                  style="background: #f1f1f1"
                >
                  <input
                    type="text"
                    v-model="message"
                    class="form-control rounded-pill input-box mr-2"
                    placeholder="Start Typing.."
                  />

                  <div class="attach-file-wrapper">
                    <div class="btn">
                      <i class="fa fa-paperclip" aria-hidden="true"></i>
                    </div>
                    <input
                      type="file"
                      name="img[]"
                      id="pickFile"
                      v-on:change="selectedFile($event)"
                      accept="image/*"
                    />
                  </div>
                  <button
                    v-on:click="sendMessage"
                    type="button"
                    class="btn-send-msg"
                  >
                    <img src="images/send.svg" width="20" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="uploadModal">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="this.$refs.uploadModal.closeModal()"
        >
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body px-4">
        <h5 class="modal-title font-22 mb-0 text-center mb-3">Upload File</h5>
        <form class="form-style floating-form">
          <Form @submit="sendFile()">
            <div class="row mb-md-0 mb-4">
              <div class="col-md-12 mt-2">
                <div class="floating-form-group">
                  <Field
                    type="text"
                    class="filled"
                    id="title"
                    rules="required:title"
                    name="title"
                    :validateOnInput="true"
                    v-model="file.name"
                  />
                  <!-- v-model="detail.tmppath" -->
                  <label for="title">File Name</label>
                  <span class="text-danger">*</span>
                </div>
                <ErrorMessage name="title" class="validation-msg" />

                <button class="btn btn-brand-1 w-100 mb-4" id="post-button">
                  Send
                </button>
                <button
                  type="button"
                  class="btn btn-brand-1 w-100 mb-4"
                  id="post-button"
                  v-on:click="this.$refs.uploadModal.closeModal()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </form>
      </div>
    </div>
  </TextModal>
  <!-- Send Offer -->
  <TextModal ref="offerModal">
    <Form @submit="send()">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            v-on:click="this.$refs.offerModal.closeModal()"
          >
            <img src="images/close.svg" width="15" />
          </button>
        </div>
        <div class="modal-body floating-form px-md-5 px-4">
          <h4 class="font-22 modal-tital text-center mb-4">Lead Description</h4>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="product-service">Products / Services</label>
                <p class="mb-0">{{ title }}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="quantity">Quantity</label>
                <p class="mb-0">{{ offerDetail.quantity }}</p>
              </div>
            </div>
            <div class="col-md-12 mb-4">
              <div class="form-group">
                <label for="otherInfo">Other Info</label>
                <p class="mb-0">
                  {{ offerDetail.description }}
                </p>
              </div>
            </div>
            <hr class="w-100" />
            <div class="col-12 mb-4">
              <h4 class="text-center h5 font-bold">Send Offer</h4>
            </div>
            <div class="col-md-6">
              <div class="floating-form-group">
                <Field
                  type="text"
                  id="price"
                  v-model="offer.price"
                  name="price"
                  rules="required:price"
                />
                <label for="price">Price</label>
                <span class="text-danger">*</span>
              </div>
              <ErrorMessage name="price" class="validation-msg" />
            </div>
            <div class="col-md-6">
              <div class="floating-form-group">
                <Field
                  type="text"
                  id="quantity"
                  v-model="offer.quantity"
                  name="quantity"
                  rules="required:quantity"
                />
                <label for="quantity">Quantity</label>
                <span class="text-danger">*</span>
              </div>
              <ErrorMessage name="quantity" class="validation-msg" />
            </div>
            <div class="col-md-12">
              <div class="floating-form-group">
                <Field
                  type="text"
                  id="message"
                  v-model="offer.message"
                  name="message"
                />
                <label for="quantity">Message (Optional)</label>
              </div>
            </div>
            <div class="col-12">
              <label class="font-medium">I can deliver</label>
              <div class="d-md-flex mb-3">
                <div class="custom-control custom-radio mb-2 mr-3">
                  <input
                    type="radio"
                    id="Immediate"
                    name="deliveryBy"
                    class="custom-control-input"
                    checked=""
                    v-on:click="selectReason('IMMIDIATE')"
                  />
                  <label class="custom-control-label" for="Immediate"
                    >Immediate</label
                  >
                </div>
                <div class="custom-control custom-radio mb-2 mr-3">
                  <input
                    type="radio"
                    id="Within7day"
                    name="deliveryBy"
                    class="custom-control-input"
                    v-on:click="selectReason('WITHIN_7_DAYS')"
                  />
                  <label class="custom-control-label" for="Within7day"
                    >Within 7 Days</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="Within15day"
                    name="deliveryBy"
                    class="custom-control-input"
                    v-on:click="selectReason('WITHIN_15_DAYS')"
                  />
                  <label class="custom-control-label" for="Within15day"
                    >Within 15 Days</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button class="btn btn-brand-1 px-4" id="send-button">Send</button>
        </div>
      </div>
    </Form>
  </TextModal>
</template>
<script>
// import helperService from "../../services/HelperService";
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal.vue";
import moment from "moment";
import $ from "jquery";
export default {
  name: "MyChat",
  components: {
    TextModal,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      userId: localStorage.getItem("companyId"),
      image: "",
      details: {},
      offer: {
        company_id: localStorage.getItem("companyId"),
        request_id: "",
        delivery_by: "IMMIDIATE",
      },
      message: "",
      title: "",
      offerDetail: {},
      list: [],
      file: "",
      offset: -1,
      companyName: "",
      companyLogo: "",
      company:{}
    };
  },
  mounted() {
    if (this.$sharedService.getMapValue("data")) {
      this.details = this.$sharedService.getMapValue("data");
    } else {
      this.$router.go(-1);
    }
    this.getMessage(0);
    this.getCompanyName();
    this.$store.commit("showSidebar", false);

    //floating label
    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    showOfferModal(data) {
      this.$refs.offerModal.showModal();
      this.offerDetail = data;
      this.offer.request_id = this.offerDetail.id;
      if (this.offerDetail.product) {
        this.title = this.offerDetail.product.title;
        this.image = this.offerDetail.product.image;
      } else if (this.offerDetail.service) {
        this.title = this.offerDetail.service.title;
        this.image = this.offerDetail.service.image;
      }
    },
    selectReason(reason) {
      this.offer.delivery_by = reason;
    },
    send() {
      this.$api
        .postAPI({
          _action: "offer",
          _body: this.offer,
          _buttonId: "send-button",
        })
        .then((res) => {
          if (res) {
            this.$refs.offerModal.closeModal();
          }
        })
        .catch(() => {});
    },
    sendMessage() {
      var obj = {
        sender_id: localStorage.getItem("companyId"),
        receiver_id: this.details.company_id,
        request_id: this.details.id,
        message: this.message,
      };
      this.$api
        .postAPI({
          _action: "message",
          _body: obj,
        })
        .then(() => {
          this.message = "";
          this.getMessage(0);
        })
        .catch(() => {});
    },
    sendFile() {
      console.log("first", this.file);
      var obj = {
        sender_id: this.userId,
        receiver_id: this.details.company_id,
        request_id: this.details.id,
        document: this.file,
      };
      this.$api
        .uploadImageAPI({
          _action: "upload/document",
          _body: obj,
        })
        .then(() => {
          this.file = "";
          this.getMessage(0);
          this.$refs.uploadModal.closeModal();
        })
        .catch(() => {});
    },
    getMessage(offset) {
      if (offset == 0) {
        this.list = [];
      }
      var obj = {};
      obj.offset = offset;
      obj.company_id = this.details.company_id;
      this.$api
        .getAPI({
          _action: "messages/" + this.details.id,
          _body: obj,
        })
        .then((res) => {
          for (var i in res.list) {
            this.list.push(res.list[res.list.length - 1 - i]);
            res.list[i].created_at = moment(res.list[i].created_at).format(
              "hh:mm A"
            );
          }
        })
        .catch(() => {});
    },
    selectedFile(e) {
      let files = e.target.files;
      // if (
      //   files[0].type.toLowerCase() != "image/jpeg" &&
      //   files[0].type.toLowerCase() != "image/png"
      // ) {
      //   alert("Invalid file formate, please use jpeg or png file.");
      //   return;
      // }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      } else {
        this.$refs.uploadModal.showModal();
      }
      this.file = files[0];
      // document.getElementById("preview").src = URL.createObjectURL(files[0]);;
    },
    getDocumentType(url) {
      var ext = url.split(".").pop();
      if (ext == "pdf") {
        return "/images/pdf.png";
      } else if (ext == "ppt") {
        return "/images/ppt.png";
      } else if (ext == "xls") {
        return "/images/xls.png";
      } else if (ext == "doc") {
        return "/images/doc.png";
      } else if (ext == "zip") {
        return "/images/zip.png";
      } else if (ext == "docx") {
        return "/images/doc.png";
      } else if (ext == "txt") {
        return "/images/txticon.png";
      } else if (ext == "jpeg") {
        return "/images/jpeg.png";
      } else if (ext == "csv") {
        return "/images/csv.png";
      } else if (ext == "mp4") {
        return "/images/mp4.png";
      } else if (ext == "gif") {
        return "/images/gif.png";
      }
      return url;
    },
    getCompanyName() {
      this.$api
        .getAPI({
          _action: "company/" + this.details.company_id,
        })
        .then((res) => {
          this.companyName = res.response?.company_name;
          this.companyLogo = res.response?.logo;
          this.company = res.response;
          console.log("company", res.response);
        })
        .catch(() => {});
    },
  },
};
</script>