<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>
                Booked Ticket Details
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card card-body events-card mb-4">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <!-- <div class="d-flex justify-content-end pb-3">
                  <button
                    type="button"
                    class="btn btn-brand-1"
                    v-on:click="
                    ()"
                  >
                    Add User
                  </button>
                </div> -->
                <div class="card">
                  <div class="card-body">
                    <table class="table table-style-1 table-responsive">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <!-- <th>Amount</th> -->
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in userList" :key="index" v-show="data.has_joined == 1">
                          <td>{{ data.index }}</td>
                          <td>{{ data?.first_name }} {{ data?.last_name }}</td>
                          <td>
                            <div class="form-check" v-if="data?.has_joined == 0">
                              <input class="pl-2 text-center" type="checkbox" name="member_attendance"
                                v-on:click="pushDataToDetailList(data, index, $event)" />
                            </div>
                            <button v-else class="btn btn-action pl-2 text-danger"
                              v-on:click="viewParticipantDetails(data?.id)" title="Edit">
                              <i class="fa fa-eye"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ErrorComponent @retry="getList(0)" ref="errorComponent" />
                  </div>
                  <div class="col-lg-12 mt-2 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <TextModal ref="thankYouModal" class="modal-small">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" v-on:click="$refs.thankYouModal.closeModal">
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <a href="" v-on:click="this.$router.go(-1)" class="">
        <div class="modal-body text-center px-md-4">
          <h5 class="modal-title font-22 mb-4">Thank you</h5>
          <img src="/images/claping.png" alt="" width="75" class="mb-3" />
          <p class="font-18 mb-0">Thanks for registering<br /></p>
        </div>
      </a>
    </div>
  </TextModal>
</template>
<script>
import moment from "moment";
import ErrorComponent from "@/components/ErrorComponent";
import $ from "jquery";
import Pagination from "@/components/Pagination";
import TextModal from "../../components/TextModal.vue";
import { indexOf } from "lodash";
export default {
  name: "BusinessConclaveDetails",
  components: { TextModal, ErrorComponent, Pagination },
  data() {
    return {
      disabled: 1,

      id: this.$route.params.id,
      loader: true,
      detailList: [],
      userList: [],
      OldUser: [],
      detail: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",

        dial_code: "+91",
        country_code: "IND",
      },
      current_user_id: localStorage.getItem("current_user_id"),
      searchObj: {
        keyword: "",
        offset: 0,
      },
      showForm: "1",
      user_price: "",
      type: "",
    };
  },
  mounted() {
    this.getDetail();
    this.getInfo();

    this.$store.commit("showSidebar", true);
  },
  methods: {
    AddTicketInfo() {
      var first_name = $("#first_name").val();
      var last_name = $("#last_name").val();
      var email = $("#email").val();
      var phone = $("#phone").val();
      if (first_name == "") {
        this.$notify({
          type: "error",
          text: "Please Enter first name.",
        });
        return;
      } else if (last_name == "") {
        this.$notify({
          type: "error",
          text: "Please Enter last name.",
        });
        return;
      } else if (email == "") {
        this.$notify({
          type: "error",
          text: "Please Enter email address.",
        });
        return;
      } else if (phone == "") {
        this.$notify({
          type: "error",
          text: "Please Enter Phone number.",
        });
        return;
      }
      this.detailList.push({
        first_name: this.detail.first_name,
        last_name: this.detail.last_name,
        email: this.detail.email,
        phone: this.detail.phone,
        non_member_price: this.detail.non_member_price,
        dial_code: "+91",
        country_code: "IND",
        type: "NEW",
      });

      this.showForm = "0";

      this.detail.first_name = "";
      this.detail.last_name = "";
      this.detail.email = "";
      this.detail.phone = "";
      console.log("list Of service list data", this.detailList);
    },
    deleteOptions(index) {
      this.detailList.splice(index, 1);
    },
    toShowFrom() {
      this.showForm = "1";
    },
    updateUserInfo() {
      this.$refs.textModal.closeModal();
      this.detailList[this.index] = this.detail;
      this.$set(this.detailList, this.index, this.detailList[this.index]);
    },
    viewParticipantDetails(id) {
      this.$router.push("/view-participant/" + id);
    },
    editOptions(data, index) {
      // this.options = data;
      this.index = index;
      var temp = JSON.stringify(data);
      var parse_data = JSON.parse(temp);
      this.detail = {};
      this.detail.first_name = parse_data.first_name;
      this.detail.last_name = parse_data.last_name;
      this.detail.email = parse_data.email;
      this.detail.phone = parse_data.phone;
      this.detail.non_member_price = parse_data.non_member_price;
      this.detail.dial_code = "+91";
      this.detail.country_code = "IND";
      this.detail.type = "NEW";

      this.$refs.textModal.showModal();
    },
    showThankyouPopup() {
      this.$refs.thankYouModal.showModal();
    },

    pushDataToDetailList(data, index, e) {
      console.log("checking current event from the list", index, data, e.target.checked);
      if (e.target.checked == true) {
        this.detailList.push({
          first_name: data.first_name ? data.first_name : data?.user?.first_name,
          last_name: data.last_name ? data.last_name : data?.user?.last_name,
          email: data?.email ? data?.email : data?.user?.email,
          phone: data?.phone ? data?.phone : data?.user?.phone,
          dial_code: data?.dial_code ? data?.dial_code : data?.user?.dial_code,
          country_code: data?.country_code
            ? data?.country_code
            : data?.user?.country_code,
          type: data.type ? data.type : "EXISTING",
          non_member_price: data?.owner_charge
            ? data?.owner_charge
            : this.detail.non_member_price,
          user_id: data?.id ? data?.id : data?.user?.id,
          index: index,
        });
      } else {
        this.detailList.splice(indexOf(index), 1);
      }
    },
    getDetail() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "business-conclave/" + this.id,
          _body: {"has_joined" : 1}
        })
        .then((res) => {
          this.loader = false;

          this.detail = res;
          if (this.detail.has_register == 0) {
            this.$router.push("/business-conclave-details/" + this.id);
          }
          this.user_price = res.non_member_price;
          this.type = res.type;
          console.log("this type is", this.type);
          this.detail.from_datetime = moment(this.detail.from_datetime).format(
            "DD/MM/YYYY HH:mm A"
          );
          this.speakerList = res.business_conclave_speakers;
          localStorage.setItem("business_conclave_id", this.id);
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action: "business-conclave/" + this.id + "/employees",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.userList = res.list;
          var index = 1;
          for (var i = 0; i < this.userList.length; i++) {
            if (this.userList[i].has_joined) {
              this.userList[i].index = index;
              index++;
            }
          }



          this.searchObj.offset = res.next_offset;
          if (offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No User available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },

    getInfo() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.userInfo = res.response;
          console.log(this.userInfo.id, "ME call");
          localStorage.setItem("user_id", this.userInfo.id);
          this.getList(0);
        })
        .catch(() => { });
    },
    checkout(detail) {
      var obj = {};
      obj.event_id = detail.id;
      obj.type = "BUSINESS_CONCLAVE";
      this.$api
        .postAPI({
          _action: "checkout",
          _body: obj,
        })
        .then((res) => {
          if (res) {
            console.log("response", res);
          }
        });
    },
    checkoutSuccess(response, transaction_token) {
      var obj = {};
      // console.log(response)
      (obj.razorpay_payment_id = response.razorpay_payment_id),
        (obj.transaction_token = transaction_token);
      (obj.razorpay_signature = response.razorpay_signature),
        (obj.razorpay_order_id = response.razorpay_order_id),
        //   (obj.amount = amount);
        this.$api
          .postAPI({
            _action: "checkout/success",
            _body: obj,
          })
          .then(() => {
            this.detail.has_register = 1;
            this.$helperService.getSummary();
            this.showThankyouPopup();
          });
    },
    register(detail) {
      // console.log("detail",this.accessToken );
      if (detail.price > 0.0) {
        this.checkout(detail);
      } else {
        var obj = {};
        obj.business_conclave_id = detail.id;
        this.$api
          .postAPI({
            _action: "business-conclave/participant",
            _body: obj,
            _buttonId: "register-button",
          })
          .then(() => {
            this.detail.has_register = 1;
            this.$helperService.getSummary();
            this.showThankyouPopup();
          })
          .catch(() => { });
      }
    },
    submitAdditionTicket() {

      this.$api
        .postAPI({
          _action: "business-conclave/" + this.id + "/checkout",
          _body: { list: this.detailList },
          _buttonId: "register-button",
        })
        .then((res) => {
          this.subscribe(res);
        })
        .catch(() => { });
    },
    subscribe(data) {
      var $this = this;
      var options = {
        key: data.razorpay_sdk_key, // Enter the Key ID generated from the Dashboard
        amount: data.event_amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Tenx",
        description: "Tenx Description",
        order_id: data.subscription,
        handler: function (response) {
          if (response.razorpay_payment_id) {
            $this.checkoutSuccess(response, data.transaction_token);
          }
        },
        prefill: {
          name: $this.userInfo.first_name,
          email: $this.userInfo.email,
          contact: $this.userInfo.phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function () {
        this.$noty.error("Payment Failed, Please try again");
      });

      rzp1.open();
    },
    showLocation(detail) {
      var url =
        "https://www.google.com/maps?saddr=" +
        this.$api.getLatitude() +
        "," +
        this.$api.getLongitude() +
        "&daddr=" +
        detail.address_lat +
        "," +
        detail.address_lng;

      window.open(url);
    },
  },
};
</script>
