<template >
  <div>
    <header
      class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100"
      style="z-index: 1"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img
              src="/images/tenx-logo.svg"
              width="80"
              height="26"
              class="img-fluid"
            />
          </div>
          <div class="col-md-9 col-9 text-right">
            <a
              class="mr-3"
              style="cursor: pointer"
              v-on:click="$router.push('/signup')"
              >Signup</a
            >
            <a
              class="header-btn"
              v-on:click="$router.push('/login')"
              style="cursor: pointer"
              >Login</a
            >
          </div>
        </div>
      </div>
    </header>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid h-100">
          <div
            class="
              row
              justify-content-md-center
              align-items-center
              h-100
              bg-white
            "
          >
            <div class="col-md-6 bg-white">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <div class="px-lg-5 px-0 py-3">
                    <div
                      class="
                        card-header
                        bg-white
                        rounded-10
                        text-center
                        border-0
                      "
                    >
                      <img
                        src="/images/tenx-logo.svg"
                        width="110"
                        height="35"
                        class="img-fluid mb-lg-5 mb-4"
                      />
                    </div>
                    <div class="card-body pt-1">
                      <!-- <div class="bg-white text-center border-0">
                        <img
                          src="/images/tenx-logo.svg"
                          width="110"
                          height="35"
                          class="img-fluid mb-lg-5 mb-4"
                        />
                      </div> -->
                      <div style="margin-bottom: 18px" class="font-weight-bold">
                        Forgot your password?
                      </div>
                      <div class="mb-3">
                        Enter your registered email address / phone number to
                        get a link to reset your password
                      </div>
                      <Form
                        @submit="forgotPassword"
                        class="form-style floating-form"
                      >
                        <div class="row">
                          <div class="custom-control custom-radio mb-2 mr-3">
                            <input
                              type="radio"
                              id="Email"
                              name="forget_pass"
                              class="custom-control-input"
                              checked=""
                              v-on:click="selectType('EMAIL')"
                            />
                            <label class="custom-control-label" for="Email"
                              >Email</label
                            >
                          </div>
                          <div class="custom-control custom-radio mb-2 mr-3">
                            <input
                              type="radio"
                              id="Phone"
                              name="forget_pass"
                              class="custom-control-input"
                              v-on:click="selectType('PHONE')"
                            />
                            <label class="custom-control-label" for="Phone"
                              >Phone</label
                            >
                          </div>
                        </div>
                       
                        <div class="floating-form-error mb-3">
                        <div
                          class="floating-form-group mb-4"
                          v-if="this.type == 'EMAIL'"
                        >
                          <Field
                            type="email"
                            id="email"
                            name="email"
                            rules="required:email"
                            v-model="details.email"
                            :validateOnInput="true"
                          />
                          <label for="email">Email</label>
                        </div>
                        <ErrorMessage name="email" class="validation-msg" />
                        </div>
                        <div class="floating-form-error mb-3">
                        <div
                          class="floating-form-group mb-4"
                          v-if="this.type == 'PHONE'"
                        >
                          <Field
                            type="text"
                            id="phone"
                            name="phone"
                            rules="required:phone number|phone"
                            v-model="details.phone"
                            :validateOnInput="true"
                          />
                          <label for="phone">Phone Number</label>
                        </div>
                        <ErrorMessage name="phone" class="validation-msg" />
                        </div>
                        <button
                          class="btn btn-brand-1 btn-block mb-4"
                          id="submit-button"
                          style="cursor: pointer"
                        >
                          Submit
                        </button>
                      </Form>
                      <TextModal ref="textModal">
                        <div class="modal-header">
                          <h5 class="modal-title font-weight-bold text-center">
                            <button
                              type="button"
                              class="close"
                              v-on:click="$refs.textModal.closeModal"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                            Successful!
                          </h5>

                          <div class="bg-white rounded-10 text-center border-0">
                            <img
                              src="/images/claping.png"
                              width="85"
                              height="20"
                              class="img-fluid mb-lg-5 mb-4"
                            />
                            <div class="">
                              We have sent a reset password link <br />to your
                             {{type == 'EMAIL'? 'email address' : 'phone number'}}
                            </div>
                            <button
                              type="button"
                              class="btn btn-outline-success text-center"
                              style="width: 200px; height: 40px"
                              v-on:click="$router.push('/login')"
                            >
                              OK</button
                            ><br />
                            Didn't received yet?
                            <a
                              style="cursor: pointer"
                              v-on:click="forgotPassword()"
                              >Resend.</a
                            >
                          </div>
                        </div>
                      </TextModal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 login-img-col" style="min-height: 92vh"></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal";
import api from "../../services/WebService";
import router from "../../router";
import $ from "jquery";
export default {
  name: "ForgotPassword",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
  },
  data() {
    return {
      type: "EMAIL",
      details: {
        email: "",
        phone: "",
        dial_code: "+91",
      },
    };
  },
  mounted() {
    //floating form
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
  },
  methods: {
    showPopup() {
      this.$refs.textModal.showModal();
    },
    forgotPassword() {
      var url = "";
      var obj = {};
      if (this.type == "EMAIL") {
        url = "forgot-password/email";
        obj.email = this.details.email;
      } else if (this.type == "PHONE") {
        url = "forgot-password/phone";
        obj.phone = this.details.phone;
        obj.dial_code = this.details.dial_code;
      }
      api
        .postAPI({
          _action: url,
          _body: obj,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            if (this.type == "EMAIL") {
              localStorage.setItem("email", this.details.email);
            }

            this.showPopup();
            router.push("");
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
    selectType(type) {
      this.type = type;
    },
  },
};
</script>