<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-10" style="max-width: 92.333% !important">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">Home</h2>
            </div>
            <!-- <div class="col-md-6 text-right">
              <input
                type="text"
                class="search-click"
                name=""
                placeholder="search here..."
                v-model="searchObj.keyword"
                v-on:keyup.enter="getList(0)"
              />
            </div> -->
          </div>
          <div
            class=""
            id="prod-div"
            v-on:scroll="handleScroll($event)"
            style="height: 90vh; overflow-y: scroll; overflow-x: hidden"
            v-if="!loader"
          >
            <div
              class="card card-body hover-card border-0 mb-3"
              v-if="myDetail.user_type == 'OWNER'"
            >
              <div
                v-on:click="showPostPopup()"
                class="d-flex align-items-center my-3"
                data-toggle="modal"
                data-target="#PostModal"
              >
                <img
                  :src="
                    myDetail.profile_img ? myDetail.profile_img : '/images/profile.svg'
                  "
                  class="rounded-circle object-cover"
                  width="50"
                  height="50"
                  alt="Profile Img"
                />
                <div class="w-100 ml-2">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="
                      'What\'s you want to share ' + myDetail.first_name + ' ?'
                    "
                  />
                </div>
              </div>
            </div>
            <div v-for="(data, index) in list" :key="index">
              <div>
                <div
                  class="card card-body events-card mb-4"
                  style="
                    min-height: 100% !important;
                    min-width: 100% !important;
                    width: 600px;
                  "
                >
                  <div class="row mb-2">
                    <div class="col-md-8 d-flex align-items-center mb-md-0 mb-3">
                      <img
                        :src="
                          data.published_by && data.published_by.profile_img
                            ? data.published_by.profile_img
                            : '/img/placeholder-image.jpg'
                        "
                        v-on:click="checked(data)"
                        alt=""
                        class="img-fluid rounded-circle object-cover"
                        style="width: 50px; height: 50px"
                      />
                      <div class="pl-3">
                        <p class="font-medium mb-0 font-xs-14">
                          {{ data.published_by ? data.published_by.first_name : "" }}
                          {{ data.published_by ? data.published_by.last_name : "" }}
                        </p>
                        <p class="font-12 text-dark mb-1 font-xs-12">
                          {{ data.category ? data.category.name : "" }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 text-right">
                      <div class="mb-0 float-md-none float-right">
                        <a
                          class="text-secondary"
                          id="dropdown_btn"
                          data-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
                        ></a>

                        <div
                          class="dropdown-menu dropdown-menu-right show-dropdown"
                          style="
                            position: absolute;
                            transform: translate3d(220px, 44px, 0px);
                            top: 0px;
                            left: 0px;
                            will-change: transform;
                          "
                          x-placement="bottom-end"
                        >
                          <a
                            class="dropdown-item"
                            v-show="data.published_by.id == myDetail.id"
                            type="button"
                            v-on:click="showEditPopup(data)"
                            v-if="self.id == data.user_id"
                          >
                            Edit</a
                          >
                          <a
                            class="dropdown-item"
                            v-show="data.published_by.id == myDetail.id"
                            type="button"
                            v-on:click="showDeletePopup(data.uuid)"
                            v-if="self.id == data.user_id"
                          >
                            Delete</a
                          >
                          <a
                            class="dropdown-item"
                            type="button"
                            v-on:click="showSharePopup(data)"
                          >
                            Share</a
                          >
                        </div>
                      </div>
                      <p class="font-14 mb-2 font-xs-12">
                        {{ data.created_at }}
                      </p>
                    </div>
                  </div>
                  <div class="position-relative mb-3" v-if="data.body">
                    <div v-html="getData(data.body)" />
                    <a
                      class="link cursor-pointer"
                      v-on:click="hideShowPackageDescription(data)"
                      v-if="data.body.length >= 199"
                    >
                      <!-- {{ data.body.length }} -->
                      ( Show {{ data.isShowDescription ? "Less" : "More" }})
                    </a>
                  </div>
                  <div class="position-relative mb-3" v-if="data.attachment">
                    <img
                      :src="
                        data.attachment
                          ? data.attachment
                          : '/images/placeholder-image.jpg'
                      "
                      class="img-fluid card-img cursor-pointer"
                      v-on:click="openImage(data.attachment, index)"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex mb-4 mt-n3 justify-content-center">
                <div
                  class="col-1 btn-group-toggle like-button justify-content-left p-0"
                  data-toggle="buttons"
                  v-if="myDetail.user_type == 'OWNER'"
                >
                  <label class="btn btn-light" :class="data.has_liked ? 'active' : ''">
                    <input type="checkbox" v-on:click="likePost(data.uuid)" />
                    <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                  </label>
                </div>

                <div
                  :class="myDetail.user_type == 'OWNER' ? 'col-5' : 'col-6'"
                  class="btn-group-toggle like-button justify-content-left p-0"
                  data-toggle="buttons"
                  v-on:click="showLikeModal(data.uuid)"
                >
                  <label class="btn btn-light">
                    Like
                    <span class="like-count">{{ data.likes_count }}</span>
                  </label>
                </div>
                <div
                  class="col-6 btn-group-toggle like-button"
                  data-toggle="buttons"
                  style="text-align-last: end"
                >
                  <button
                    v-on:click="showCommentPopup(data.uuid)"
                    type="button"
                    class="btn btn-light comment-btn"
                    data-toggle="modal"
                    data-target="#CommentModal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chat-right-text-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z"
                      ></path>
                    </svg>
                    Comment {{ data.comments_count }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          <!-- <div style="text-align: center" v-show="offset != -1 && !loader">
            <a
              id="load-button"
              class="txt-success cursor-pointer mb-3"
              v-on:click="getList(offset)"
              style="max-width: 110px"
            >
              Load More
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <vue-easy-lightbox
    :visible="visibleRef"
    :imgs="postImage"
    :index="indexRef"
    @hide="onHide"
  ></vue-easy-lightbox>
  <!--	Post Modal -->
  <TextModal ref="postModal">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Create Post</h4>
        <button
          v-on:click="$refs.postModal.closeModal()"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body form-style pb-0 text-center pt-2">
        <div class="d-flex align-items-center border-bottom pb-3 mb-3">
          <img
            :src="
              myDetail.profile_img ? myDetail.profile_img : '/img/placeholder-image.jpg'
            "
            class="rounded-circle object-cover"
            width="50"
            height="50"
            alt="Profile Img"
          />
          <div class="ml-2 text-left">
            <p class="mb-0">
              {{ myDetail.first_name ? myDetail.first_name : "-" }}
              {{ myDetail.last_name ? myDetail.last_name : "-" }}
            </p>
            <select
              id="disabledSelect"
              class="form-control custom-select rounded-common select-p-dd font-medium"
              v-model="obj.category_id"
              style="font-size: 12px; font-weight: 500"
            >
              <option value="">Select</option>
              <option v-for="(data, index) in options" :key="index" :value="data.id">
                {{ data.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <textarea
            name=""
            id=""
            class="form-control"
            rows="5"
            v-model="obj.body"
            :placeholder="'What\'s you want to share ' + myDetail.first_name + ' ?'"
          ></textarea>
        </div>
        <button
          type="button"
          class="btn px-3 upload-img-merge-btn btn-light bg-light-2 text-dark py-2 h-auto"
          style="maxwidth"
        >
          <i class="fa fa-picture-o mr-2 text-primary font-18" aria-hidden="true"></i>
          Upload Photo
          <input
            type="file"
            name="img[]"
            id="preview"
            class="file-upload-input"
            v-on:change="selectedFile($event)"
            accept="image/*"
          />
        </button>
      </div>
      <div class="modal-footer border-0 justify-content-center mb-3">
        <button type="button" class="btn btn-brand-1 w-100" v-on:click="savePost()">
          Post
        </button>
      </div>
    </div>
  </TextModal>
  <!--	Comment Modal -->
  <TextModal ref="commentModal">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Comments</h4>
        <button
          v-on:click="$refs.commentModal.closeModal()"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="images/close.svg" width="15" />
        </button>
      </div>
      <div
        class="modal-footer border-0 d-block pt-0"
        v-if="myDetail.user_type == 'OWNER'"
      >
        <div class="form-group mx-0 mb-2">
          <textarea
            name="comment"
            id="comment"
            class="form-control w-100"
            rows="1"
            placeholder="Write a Comment.."
            v-model="commentObj.body"
          ></textarea>
        </div>
        <button
          v-if="update == false"
          v-on:click="commentPost()"
          class="btn btn-brand-1 w-100 m-0"
          style="height: 42px; font-size: 16px"
        >
          Post Comment
        </button>
        <button
          v-if="update == true"
          v-on:click="updateComment()"
          class="btn btn-brand-1 w-50 m-0"
          style="height: 42px; font-size: 16px"
        >
          Update
        </button>
        <button
          v-if="update == true"
          v-on:click="clearComment()"
          class="btn btn-outline-secondary w-50 m-0"
          style="height: 42px; font-size: 16px"
        >
          Cancel
        </button>
      </div>
      <div
        class="modal-body form-style pb-0 text-center pt-2"
        style="max-height: 400px; overflow: auto"
      >
        <template v-if="commentList.length">
          <div class="d-flex mb-3" v-for="(data, index) in commentList" :key="index">
            <img
              :src="
                data?.user.profile_img ? data?.user.profile_img : '/images/profile.svg'
              "
              class="rounded-circle object-cover"
              width="50"
              height="50"
              alt="Profile Img"
            />
            <div class="text-left ml-2">
              <div class="text-left bg-light-2 p-2 rounded">
                <p class="mb-0">
                  {{ data?.user.first_name ? data?.user.first_name : " " }}
                  {{ data?.user.last_name ? data?.user.last_name : " " }}
                </p>

                <p class="small mb-0">
                  {{ data.body }}
                </p>
              </div>
              <div class="row">
                <div class="col-9 text-left">
                  <span class="small text-secondary" style="margin-left: auto">{{
                    data.ago_time
                  }}</span>
                </div>
                <div
                  class="col-md-3 text-right"
                  v-if="data.user.id == self.id || data.feed.user_id == self.id"
                >
                  <div class="mb-0 float-md-none float-right">
                    <a
                      class="text-secondary"
                      id="dropdown_btn"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
                    ></a>

                    <div
                      class="dropdown-menu dropdown-menu-right show-dropdown"
                      style="
                        position: absolute;
                        transform: translate3d(220px, 44px, 0px);
                        top: 0px;
                        left: 0px;
                        will-change: transform;
                      "
                      x-placement="bottom-end"
                    >
                      <a
                        class="dropdown-item"
                        v-show="data.user.uuid == self.uuid"
                        type="button"
                        v-on:click="EditComment(data)"
                      >
                        Edit</a
                      >
                      <a
                        class="dropdown-item"
                        type="button"
                        v-on:click="showDeleteComment(data)"
                      >
                        Delete</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="m-3">
            <div class="text-center m-5"><h5>No Comments Found</h5></div>
          </div>
        </template>
      </div>
    </div>
  </TextModal>
  <TextModal ref="likeModal">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Likes</h4>
        <button
          v-on:click="$refs.likeModal.closeModal()"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="images/close.svg" width="15" />
        </button>
      </div>
      <div
        class="modal-body form-style pb-0 text-center pt-2"
        style="max-height: 400px; overflow: auto"
      >
        <div class="d-flex mb-3" v-for="(data, index) in likeList" :key="index">
          <img
            :src="data?.user.profile_img ? data?.user.profile_img : '/images/profile.svg'"
            class="rounded-circle object-cover"
            width="50"
            height="50"
            alt="Profile Img"
          />
          <div class="text-left ml-2">
            <div class="text-left bg-light-2 p-2 rounded">
              <p class="mb-0">
                {{ data?.user.first_name ? data?.user.first_name : " " }}
                {{ data?.user.last_name ? data?.user.last_name : " " }}
              </p>
            </div>
            <span class="small text-secondary">{{ data.ago_time }}</span>
          </div>
        </div>
      </div>
    </div>
  </TextModal>
  <!-- <VueSocialSharing /> -->
  <ShareModal ref="shareModal" />
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
  <DeleteModal @remove="deleteComment" ref="deleteCommentModal" />
</template>

<script>
// import VueSocialSharing from "vue-social-sharing";
import ShareModal from "../../components/SocialShare/ShareModal.vue";
import DeleteModal from "@/components/DeleteModal";
import $ from "jquery";
import moment from "moment";
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js";
import { ref } from "vue";

// you need to import css yourself
import "vue-easy-lightbox/external-css/vue-easy-lightbox.css";
import ErrorComponent from "@/components/ErrorComponent";
import TextModal from "@/components/TextModal.vue";
// import moment from "moment";
export default {
  name: "MyHome",
  components: {
    ErrorComponent,
    TextModal,
    DeleteModal,
    // VueSocialSharing,
    VueEasyLightbox,
    ShareModal,
  },
  data() {
    return {
      isRunning: false,
      loader: false,
      offset: -1,
      list: [],
      id: this.$route.params.id,
      interestList: [],
      interestedList: [],
      latitude: "",
      longitude: "",
      detail: {
        list_type: "",
        // dial_code: "+91",
        // country_code: "IN",
        activity_id: "",
        health_wellbeing_id: "",
        starting_point: "",
      },
      searchObj: {
        keyword: "",
        offset: 0,
        list_type: "",
      },
      activityList: [],
      healthList: [],
      contact: "",
      obj: {
        category_id: "",
        body: "",
      },
      has_sourceLocation: "",
      isShowAddressErrorMessage: false,
      myDetail: {},
      options: [],
      file: null,
      commentList: [],
      likeList: [],
      commentObj: {
        body: "",
      },
      feedId: "",
      deleteObj: {},
      update: false,
      commentId: "",
      postImage: "",
      visibleRef: ref(false),
      indexRef: ref(0),
      user_type: localStorage.getItem("user_type"),
    };
  },
  watch: {
    "$route.fullPath"() {
      this.list = [];
      this.getList();
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    this.has_sourceLocation = "N";
    if (this.user_type == "USER") {
      this.$store.commit("showSidebar", false);
    } else {
      this.$store.commit("showSidebar", true);
    }
    this.getList(0);
    this.getDetails();
    this.getOption();
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
    console.log("self", this.self);
  },
  computed: {
    self() {
      return this.$store.getters.getUser ? this.$store.getters.getUser : {};
    },
  },
  methods: {
    handleScroll(e) {
      if (!this.isRunning) {
        if (e.target.scrollTop >= 500) {
          if (this.offset != -1) {
            this.isRunning = true;
            this.getList(this.offset);
          } else {
            return;
          }
        }
      }
    },
    getAddressData: function (addressData, placeResultData) {
      this.detail.destination = placeResultData.name ? placeResultData.name : " ";
      this.searchObj.latitude = addressData.latitude;
      this.searchObj.longitude = addressData.longitude;
    },
    getAddressData2: function (addressData, placeResultData) {
      this.detail.starting_point = placeResultData.name ? placeResultData.name : " ";
      this.searchObj.latitude = addressData.latitude;
      this.searchObj.longitude = addressData.longitude;
    },
    getOption() {
      this.$api
        .getAPI({
          _action: "category/home-feed",
        })
        .then((res) => {
          this.options = res.list;
          console.log("my data", res.list);
        })
        .catch(() => {});
    },
    getDetails() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.myDetail = res.response;
        })
        .catch(() => {});
    },
    getList(offset) {
      if (offset == 0) {
        this.loader = true;
      }
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      if (offset == -1 || offset < 0) {
        this.loader = false;
        this.isRunning = false;
        return;
      }
      this.searchObj.offset = offset;
      this.searchObj.activity_id = "";

      for (var i in this.activityList) {
        if (this.activityList[i].isChecked == true) {
          if (this.searchObj.activity_id) {
            this.searchObj.activity_id += "," + this.activityList[i].id;
          } else {
            this.searchObj.activity_id = this.activityList[i].id;
          }
        }
      }

      this.$refs.errorComponent.updateLoader(true);
      if (offset == 0) {
        this.list = [];

        this.setInterest(this.searchObj.activity_id);
      }

      this.$api
        .getAPI({
          _action: "feeds",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          // console.log("data", res);
          for (var i in res.list) {
            res.list[i].created_at = moment(res.list[i].created_at).format(
              "DD MMM YYYY | HH:mm A"
            );
            res.list[i].isShowDescription = false;
            res.list[i].full_body = res.list[i].body;
            if (res.list[i].body.length > 200) {
              res.list[i].body = res.list[i].body.slice(0, 200).trim();
            }

            this.list.push(res.list[i]);
          }

          this.offset = res.next_offset;
          if (this.$refs.errorComponent) {
            if (offset == 0) {
              this.$refs.errorComponent.setData(res, "No feed available");
            }
            this.$refs.errorComponent.updateLoader(false);
          }
          this.loader = false;
          this.isRunning = false;
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    savePost() {
      if (this.obj.uuid) {
        this.$api
          .putAPI({
            _action: "feed/" + this.obj.uuid,
            _body: this.obj,
          })
          .then((res) => {
            if (res) {
              this.$notify({
                type: "success",
                text: res.message,
              });
              this.uploadImage(this.obj.uuid);
            }
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "feed",
            _body: this.obj,
          })
          .then((res) => {
            this.uploadImage(res.id);
          })
          .catch(() => {});
      }
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    showPostPopup() {
      document.getElementById("preview").value = "";
      this.obj.body = "";
      this.$refs.postModal.showModal();
    },
    uploadImage(id) {
      if (!this.file) {
        this.$refs.postModal.closeModal();
        this.getList(0);
        return;
      } else {
        this.$api
          .uploadImageAPI({
            _action: "feed/" + id + "/upload",
            _key: "file",
            _file: this.file,
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.getList(0);
            this.$refs.postModal.closeModal();
          });
      }
    },
    showCommentPopup(id) {
      this.commentObj.body = "";
      this.feedId = id;
      this.$refs.commentModal.showModal();
      this.getComments(id, 0);
      this.update = false;
    },
    getComments(id, offset) {
      this.loader = true;
      if (this.$refs.commentComponent) {
        this.$refs.commentComponent.updateLoader(true);
      }
      this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action: "feed/" + id + "/comment",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.commentList = res.list;
          this.offset = res.next_offset;
          if (this.$refs.commentComponent) {
            this.$refs.commentComponent.setData(res, "No comment available");
            this.$refs.commentComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    commentPost() {
      this.$api
        .postAPI({
          _action: "feed/" + this.feedId + "/comment",
          _body: this.commentObj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getList(0);
          this.$refs.commentModal.closeModal();
        })
        .catch(() => {});
    },
    likePost(id) {
      console.log("test");
      this.$api
        .postAPI({
          _action: "feed/" + id + "/like",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getList(0);
        })
        .catch(() => {});
    },
    interested(id) {
      var obj = {};
      obj.health_wellbeing_id = id;
      obj.has_interested = true;
      this.$api
        .postAPI({
          _action: "health-well-being/interesed",
          _body: obj,
          _buttonId: "interested-button",
        })
        .then(() => {
          var data = undefined;
          for (var i in this.healthList) {
            if ((this.healthList[i].id = id)) {
              data = this.healthList[i];
            }
          }

          data.has_interested = true;
          if (data) {
            this.showThankyouPopup(data);
          }
          this.$helperService.getSummary();
          this.getList(0);
        })
        .catch(() => {});
    },

    save() {
      this.detail.dial_code = "+91";
      this.detail.country_code = "IN";
      this.detail.start_date_time = moment(this.detail.start_date_time).format(
        "yyyy-MM-DD HH:mm:ss"
      );

      if (this.detail.id) {
        this.$api
          .putAPI({
            _action: "/health-well-being/" + this.detail.id,
            _body: this.detail,
          })
          .then((res) => {
            this.$refs.activityModal.closeModal();
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.getList(0);
          })
          .catch(() => {});
      }
    },
    showActivityPopup(id) {
      this.detail = { activity_id: "" };
      // console.log(">>>>>>>>>>>>>>>>>>");
      // console.log(this.detail);
      this.getActivityList();
      if (id) {
        this.$api
          .getAPI({
            _action: "health-well-being/" + id,
          })
          .then((res) => {
            this.detail = res.info;
            console.log(this.detail.destination);
            this.$refs.activityModal.showModal();
            this.$refs.activityForm.resetForm();
            var $this = this;
            setTimeout(() => {
              document.getElementById("map").value = $this.detail.destination;
              document.getElementById("map1").value = $this.detail.starting_point;
            }, 500);
          })
          .catch(() => {});
      } else {
        this.detail = { activity_id: "" };
        this.$refs.activityModal.showModal();
        this.$refs.activityForm.resetForm();
      }
    },
    getActivityList(detail) {
      this.$api
        .getAPI({
          _action: "category/activity",
        })
        .then((res) => {
          // console.log("inside", res.list);
          for (var i in res.list) {
            var isChecked = false;
            // console.log("intrested", detail);
            for (var j in detail.interested_activity) {
              if (detail.interested_activity[j].interest_id == res.list[i].id) {
                isChecked = true;
              }
            }

            res.list[i].isChecked = isChecked;
          }

          this.activityList = res.list;
          // console.log("activity list", this.activityList);
          // this.changeTab("ALL");
          this.getList(0);
        })
        .catch(() => {});
    },
    notIntrested(id) {
      this.$api
        .deleteAPI({
          _action: "health-well-being/" + id + "/interested",
          _buttonId: "not-interested-button",
        })
        .then(() => {
          this.$helperService.getSummary();
          this.getList(0);
          location.reload();
        })
        .catch(() => {});
    },

    callapi() {
      this.$refs.filterModal.closeModal();
      this.getList(0);
    },
    checkLocation(id) {
      console.log("inside");
      this.$api
        .getAPI({
          _action: "category/activity",
        })
        .then((res) => {
          for (var i in res.list) {
            if (id == res.list[i].id) {
              this.has_sourceLocation = res.list[i].has_source_location;
            }
          }
        })
        .catch(() => {});
    },
    setInterest(data) {
      var obj = {};
      obj.interest_ids = data;
      this.$sharedService.setMapValue("activity_ids", data);
      this.$api
        .postAPI({
          _action: "user/activity",
          _body: obj,
        })
        .then(() => {
          // this.getList(0);
        })
        .catch(() => {});
    },
    getData(data) {
      var temp = "";
      if (data) {
        var array = data.split(" ");
        for (var i in array) {
          if (array[i].startsWith("http")) {
            temp += " <a target='_blank' href='" + array[i] + "'>" + array[i] + "</a>";
          } else {
            temp += " " + array[i];
          }
        }
      }
      return temp;
    },
    showEditPopup(data) {
      this.obj = data;
      // console.log("data......",data);
      this.$refs.postModal.showModal();
    },
    editPost(id) {
      this.$api
        .putAPI({
          _action: "feed/" + id,
          _body: this.obj,
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.uploadImage(id);
          }
        })
        .catch(() => {});
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        "Delete Feed",
        "Are you sure you want to delete this feed",
        id
      );
    },
    showDeleteComment(data) {
      this.deleteObj = data;
      this.$refs.deleteCommentModal.showModal(
        "Delete Comment",
        "Are you sure you want to delete this comment",
        data.uuid
      );
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "feed/" + id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    deleteComment(id) {
      this.$api
        .deleteAPI({
          _action: "feed/" + this.deleteObj.feed.uuid + "/comment/" + id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteCommentModal.closeModal();
            this.getComments(this.deleteObj.feed.uuid, 0);
            this.getList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteCommentModal.removeLoader();
        });
    },
    showLikeModal(id) {
      this.getLikes(id, 0);
      this.$refs.likeModal.showModal();
    },
    getLikes(id, offset) {
      this.loader = true;
      if (this.$refs.commentComponent) {
        this.$refs.commentComponent.updateLoader(true);
      }
      this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action: "feed/" + id + "/like",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.likeList = res.list;
          this.offset = res.next_offset;
          if (this.$refs.commentComponent) {
            this.$refs.commentComponent.setData(res, "No likes available");
            this.$refs.commentComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    EditComment(data) {
      this.commentObj.body = data.body;
      this.update = true;
      this.commentId = data.uuid;
    },
    clearComment() {
      this.commentObj.body = "";
      this.update = false;
    },
    updateComment() {
      this.$api
        .putAPI({
          _action: "feed/" + this.feedId + "/comment/" + this.commentId,
          _body: this.commentObj,
        })
        .then((res) => {
          if (res) {
            this.commentObj.body = "";
            this.update = false;
            this.getComments(this.feedId, 0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
    showSharePopup(data) {
      console.log(data);
      console.log("url", window.location.href);
      data;
      var url = "https://app.tenxindia.com/feed?id=" + data.uuid;
      // var url = "http://localhost:8080/feed?id="+data.uuid;
      var title = data.full_body;

      this.$refs.shareModal.showModal(url, title);
      // this.shareCall();
    },
    openImage(image, index) {
      this.postImage = image;
      this.indexRef = index;
      this.visibleRef = true;
    },
    onHide() {
      this.visibleRef = false;
    },
    hideShowPackageDescription(data) {
      for (var i in this.list) {
        if (this.list[i].id == data.id) {
          this.list[i].isShowDescription = !this.list[i].isShowDescription;
          if (this.list[i].isShowDescription) {
            this.list[i].body = data.full_body;
          } else {
            this.list[i].body = data.body.slice(0, 200).trim();
          }
        }
      }
    },
  },
};
</script>
