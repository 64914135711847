<template>
  <div>
    <header class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100" style="z-index: 1">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img src="/images/tenx-logo.svg" width="80" height="26" class="img-fluid" />
          </div>
          <!-- <div class="col-md-9 col-9 text-right">
            <a class="mr-3">Signup</a>
            <a class="header-btn" v-on:click="$router.push('/login')">Login</a>
          </div> -->
        </div>
      </div>
    </header>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center bg-white">
            <div class="col-md-6 bg-white">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <div class="px-lg-5 px-0">
                    <div class="card-body pb-0">
                      <form class="form-style floating-form">
                        <Form @submit="next">
                          <div class="row mb-md-0 mb-4" style="min-height: 86vh">
                            <div class="col-md-12 mt-2">
                              <div class="mb-4 mt-4">
                                <h3 class="signup-title mt-2">
                                  <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                                    <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>
                                  What I regularly buy
                                </h3>
                                <div class="steps-count mb-4">
                                  <div class="step-no active">
                                    <img src="/images/check-white.svg" width="20" height="20" />
                                  </div>
                                  <div class="h-line"></div>
                                  <div class="step-no active">
                                    <img src="/images/check-white.svg" width="20" height="20" />
                                  </div>
                                  <div class="h-line"></div>
                                  <div class="step-no active">3</div>
                                  <!-- <div class="h-line"></div>
                                  <div class="step-no">4</div> -->
                                </div>
                                <p class="font-14 font-medium">
                                  what i regularly buy for my business
                                </p>
                              </div>
                              <div class="mb-3">
                                <label class="font-medium">Products</label><span class="text-danger">*</span>
                                <!-- <Field
                                  id="products"
                                  type="text"
                                  name="product"
                                  v-model="detail.product"
                                  placeholder="Enter product"
                                  rules="required:product"
                                  :validateOnInput="true"
                                /> -->
                                <Multiselect v-model="detail.product" :options="productList" :searchable="true"
                                  :createOption="true" mode="tags" />
                                <!-- <label class="typo__label"
                                  >Simple select / dropdown</label
                                > -->
                                <!-- <ErrorMessage
                                  name="product"
                                  class="text-danger"
                                /> -->
                              </div>
                              <div class="mb-3">
                                <label class="font-medium">Services</label><span class="text-danger">*</span>
                                <Multiselect v-model="detail.service" :options="serviceList" :searchable="true"
                                  :createOption="true" mode="tags" />
                                <!-- <Field
                                  id="services"
                                  type="text"
                                  name="service"
                                  v-model="detail.service"
                                  placeholder="Enter service"
                                  rules="required:service"
                                  :validateOnInput="true"
                                />
                                <label class="typo__label"
                                  >Simple select / dropdown</label
                                >
                                <ErrorMessage
                                  name="service"
                                  class="text-danger"
                                /> -->
                              </div>
                            </div>
                            <div class="col-12 d-flex align-self-end">
                              <button type="button" class="
                                  btn btn-outline-secondary btn-block
                                  mb-md-0
                                  mr-3
                                " v-on:click="$router.push('/home')">
                                Skip
                              </button>
                              <button type="button" class="btn btn-brand-1 btn-block mt-0"
                                v-on:click="$router.push('/home')">
                                Next
                              </button>
                            </div>
                          </div>
                        </Form>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 login-img-col"></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>

import Multiselect from "@vueform/multiselect";
import { Form } from "vee-validate";
import api from "../../../services/WebService";
export default {
  name: "SelectYourInterest",
  components: {
    Form,
    // Field,
    // ErrorMessage,
    Multiselect,
  },
  data() {
    return {
      value: null,
      productList: [],
      serviceList: [],
      detail: {},
    };
  },
  mounted() {
    this.getProductList();
    this.getServiceList();
    this.$store.commit("showSidebar", false);
  },
  methods: {
    next() {
      api.post;
    },
    getProductList() {
      api
        .getAPI({
          _action: "products",
        })
        .then((res) => {
          this.productList = [];
          for (var i in res.list) {
            this.productList.push({
              value: res.list[i].id,
              label: res.list[i].title,
            });
          }
        })
        .catch(() => { });
    },
    getServiceList() {
      api
        .getAPI({
          _action: "services",
        })
        .then((res) => {
          this.serviceList = [];
          for (var i in res.list) {
            this.serviceList.push({
              value: res.list[i].id,
              label: res.list[i].title,
            });
          }
        })
        .catch(() => { });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: #f0f0f0;
  height: 55px;
  border-radius: 8px;
  border: 0;
}

.multiselect-tag {
  background: #9cc338 !important;
 
}
.multiselect-tags{
  overflow: auto !important;
}

.floating-form div.multiselect {
  min-height: 55px;
  border-radius: 8px;
  height: auto;
}
</style>
