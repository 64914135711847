<template>
  <div>
    <div class="page-content pt-5">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="col-lg-12">
            <div class="row mb-md-4 mb-2 align-items-center">
              <div class="col-md-6 text-md-left text-center">
                <h2 class="page-title mb-md-0">
                  <a class="cursor-pointer" v-on:click="$router.go(-1)">
                    <img
                      src="/images/back-arrow.svg"
                      class="mr-2 align-top"
                      alt=""
                      width="17"
                  /></a>
                  <span v-if="!this.id">Add User</span>
                  <span v-else>Edit User</span>
                </h2>
              </div>
            </div>
            <div class="card border-0 mb-4 rounded-l">
              <div class="card-body">
                <div class="row justify-content-center"></div>
                <div class="form-style floating-form" id="login-tabContent">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="floating-form floating-form-error">
                        <div class="floating-form-group">
                          <Field
                            type="text"
                            id="fname"
                            class="filled"
                            name="first_name"
                            v-model="detail.first_name"
                            rules="required:first name|userName"
                            :validateOnInput="true"
                          />
                          <label for="fname">First Name</label
                          ><span class="text-danger">*</span>
                        </div>
                      </div>
                      <ErrorMessage name="first_name" class="validation-msg" />
                    </div>
                    <div class="col-md-6">
                      <div class="floating-form-group">
                        <Field
                          type="text"
                          class="filled"
                          id="lname"
                          name="last_name"
                          v-model="detail.last_name"
                          rules="required:last name|userName"
                          :validateOnInput="true"
                        />
                        <label for="lname">Last Name</label
                        ><span class="text-danger">*</span>
                      </div>
                      <ErrorMessage name="last_name" class="validation-msg" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="floating-form-group">
                        <Field
                          type="email"
                          id="email"
                          class="filled"
                          name="email"
                          v-model="detail.email"
                          rules="required:email"
                          :validateOnInput="true"
                        />
                        <label for="email">Email</label
                        ><span class="text-danger">*</span>
                      </div>
                      <ErrorMessage name="email" class="validation-msg" />
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                      <div class="text-right cursor-pointer">
                        <a
                          style="cursor-pointer"
                          v-on:click="changePasswordPopup()"
                          >Change Password</a
                        >
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-md-12">
                      <div class="floating-form-group">
                        <Field
                          type="text"
                          id="phone"
                          name="phone"
                          class="filled"
                          v-model="detail.phone"
                          rules="required:contact number|phone"
                          :validateOnInput="true"
                        />
                        <label for="phone">Phone</label
                        ><span class="text-danger">*</span>
                      </div>
                      <ErrorMessage name="phone" class="validation-msg" />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 mb-4">
                      <!-- <Multiselect
                        v-model="company_ids"
                        :options="companyList"
                        label="company_name"
                        trackBy="id"
                        track-by="id"
                        :createOption="true"
                        mode="tags"
                        multiple="true"
                        :placeholder="'Select Company'"
                      /> -->
                      <!-- <multiselect
                        class="mr-2"
                        v-model="company_ids"
                        :options="companyList"
                        :placeholder="'Select company'"
                        label="company_name"
                        track-by="id"
                        mode="tags"
                        name="company_name"
                        id="company_name"
                      > -->
                      <Multiselect
                        v-model="company_ids"
                        :options="companyList"
                        :searchable="true"
                        :createOption="true"
                        :placeholder="'Select Company'"
                        mode="tags"
                      />
                      <ErrorMessage
                        name="company_name"
                        class="validation-msg"
                      />
                    </div>
                  </div>
                  <div class="row" v-show="!this.id">
                    <div class="col-md-12">
                      <div class="floating-form-group mb-4">
                        <Field
                          :type="newPasswordFieldType"
                          id="password"
                          class="filled"
                          name="password"
                          v-model="detail.password"
                          v-on:keydown.space="$event.preventDefault()"
                          rules="required:password|minLength:6|password"
                          :validateOnInput="true"
                        />

                        <label for="password">Password</label
                        ><span class="text-danger">*</span>
                        <a class="psw-icon" v-on:click="switchVisibilityNew"
                          ><img :src="imageNew" width="20"
                        /></a>
                      </div>
                      <ErrorMessage name="password" class="validation-msg" />
                    </div>
                  </div>
                  <div class="row" v-show="!this.id">
                    <div class="col-md-12">
                      <div class="floating-form-group mb-4">
                        <Field
                          :type="passwordFieldType"
                          id="conf_password"
                          name="confirm_password"
                          class="filled"
                          v-on:keydown.space="$event.preventDefault()"
                          rules="required:confirm password|confirmed:@password"
                          :validateOnInput="true"
                        />
                        <label for="conf_password">Confirm Password</label>
                        <span class="text-danger">*</span>
                        <a class="psw-icon" v-on:click="switchVisibility"
                          ><img :src="image" width="20"
                        /></a>
                      </div>
                      <ErrorMessage
                        name="confirm_password"
                        class="validation-msg"
                      />
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <button
                      class="btn btn-brand-1"
                      id="submitBtn"
                      v-on:click="doSubmit()"
                      style="background-color: #9cc338"
                    >
                      Save
                    </button>
                  </div>
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div
            v-on:scroll="handleScroll($event)"
            id="chat_div"
            style="height: 60vh; overflow: scroll"
          > -->

          <div class="row mx-0 mb-4"></div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="passwordModal">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="$refs.passwordModal.closeModal()"
        >
          <img src="/images/close.svg" width="15" />
        </button>
        <h5 class="modal-title">Change Password</h5>
      </div>
      <div class="modal-body px-md-4">
        <Form
          @submit="changePassword()"
          ref="passwordForm"
          class="form-style floating-form"
        >
          <div class="col-md-12">
            <div class="floating-form-group mb-4">
              <label>New Password</label>
              <Field
                :type="change_newPasswordFieldType"
                id="new_password"
                placeholder="New Password"
                name="new_password"
                v-model="passObj.new_password"
                v-on:keydown.space="$event.preventDefault()"
                rules="required:new password|minLength:6|password"
                :validateOnInput="true"
              />

              <a class="psw-icon" v-on:click="changeSwitchVisibilityNew"
                ><img :src="change_imageNew" width="20"
              /></a>
            </div>
            <ErrorMessage name="new_password" class="validation-msg" />
          </div>
          <div class="col-md-12">
            <div class="floating-form-group mb-4">
              <Field
                :type="change_confirmPasswordFieldType"
                id="new_conf_password"
                name="new_confirm_password"
                v-on:keydown.space="$event.preventDefault()"
                rules="required:confirm password|confirmed:@new_password"
                :validateOnInput="true"
              />
              <label for="new_conf_password">Confirm Password</label>
              <span class="text-danger">*</span>
              <a class="psw-icon" v-on:click="confirmSwitchVisibilityConfirm"
                ><img :src="change_imageConfirm" width="20"
              /></a>
            </div>
            <ErrorMessage name="new_confirm_password" class="validation-msg" />
          </div>
          <button
            class="btn btn-brand-1 btn-block mb-4"
            id="submit-button"
            style="cursor: pointer"
          >
            Submit
          </button>
        </Form>
      </div>
    </div>
  </TextModal>
</template>
<script>
import Pagination from "@/components/Pagination";
import Multiselect from "@vueform/multiselect";
import TextModal from "@/components/TextModal";
import $ from "jquery";
import { Field, ErrorMessage, Form } from "vee-validate";
export default {
  name: "ManageUser",
  components: {
    Pagination,
    ErrorMessage,
    Multiselect,
    Field,
    TextModal,
    Form,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      detail: {
        country_code: "IN",
        dial_code: "+91",
        company_ids: [],
      },
      userList: [],
      companyList: [],
      company_ids: [],
      searchObj: {
        keyword: "",
        offset: 0,
      },
      currentPasswordFieldType: "password",
      newpasswordFieldType: "password",
      image: "/images/show.png",
      imageNew: "/images/show.png",

      change_confirmPasswordFieldType: "password",
      change_newPasswordFieldType: "password",
      change_imageConfirm: "/images/show.png",
      change_imageNew: "/images/show.png",

      companyId: "",
      showLoader: false,
      passObj: {
        new_password: null,
        confirm_password: null,
      },
    };
  },
  mounted() {
    this.getList(0);
    this.getCategory();

    this.$store.commit("showSidebar", true);
    this.companyId = localStorage.getItem("compId");
    if (this.id) {
      this.getUserInfo();
    }
    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    changePassword() {
      this.passObj.password = this.passObj.new_password;
      this.$api
        .postAPI({
          _action: "employee/" + this.detail.uuid + "/reset-password",
          _body: this.passObj,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            this.$refs.passwordModal.closeModal();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
    changePasswordPopup() {
      this.passObj.new_password = null;
      this.passObj.confirm_password = null;
      this.$refs.passwordModal.showModal();
      // this.$refs.passwordForm.resetForm();
    },

    doSubmit() {
      if (this.company_ids.length == 0) {
        this.$notify({
          type: "error",
          text: "Please Select at-least one company.",
        });
      }
      this.detail.company_ids = this.company_ids;
      if (this.id) {
        this.$api
          .putAPI({
            _action: "employee/" + this.id,
            _body: this.detail,
          })
          .then((res) => {
            this.$router.push("/manage-user");
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      } else {
        this.detail.company_ids = this.company_ids;
        this.$api
          .postAPI({
            _action: "employee",
            _body: this.detail,
          })
          .then((res) => {
            this.$router.push("/manage-user");
            this.$notify({
              type: "success",
              text: res.message,
            });
          });
      }
    },
    getUserInfo() {
      this.$api
        .getAPI({
          _action: "employee/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
          if (res.info.companies) {
            for (var i = 0; i < res.info.companies.length; i++) {
              console.log("product_id " + res.info.companies[i].id);
              this.company_ids.push(res.info.companies[i].id);
            }
          }
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action: "/employees",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.userList = res.list;
          this.searchObj.offset = res.next_offset;
          if (offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No User available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sendRequest(data) {
      this.showLoader == true;
      var obj = {
        user_ids: [],
      };
      obj.user_ids.push(data.id);
      this.$api
        .postAPI({
          _action: "company/" + this.companyId + "/reviews",
          _body: obj,
          _buttonId: "send-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.loader = false;
        })
        .catch(() => {});
    },
    getCategory() {
      this.$api
        .getAPI({
          _action: "user/companies",
        })
        .then((res) => {
          console.log(res);
          this.companyList = [];
          for (var i in res.list) {
            this.companyList.push({
              value: res.list[i].id,
              label: res.list[i].company_name,
            });
          }
        })
        .catch(() => {});
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },

    changeSwitchVisibilityNew() {
      this.change_newPasswordFieldType =
        this.change_newPasswordFieldType === "password" ? "text" : "password";
      this.change_imageNew =
        this.change_imageNew === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    confirmSwitchVisibilityConfirm() {
      this.change_confirmPasswordFieldType =
        this.change_confirmPasswordFieldType === "password"
          ? "text"
          : "password";
      this.change_imageConfirm =
        this.change_imageConfirm === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
  },
};
</script>
