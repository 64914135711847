<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">Contact Support</h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card mb-4 rounded-l border-0 shadow-sm">
            <div class="card-header p-0 border-0 mb-md-n4">
              <img
                src="/images/contact-banner.png"
                class="card-img-top rounded-common"
                alt=""
              />
            </div>
            <div class="card-body p-md-0 mt-md-n5 mt-3">
              <div class="row justify-content-center">
                <div class="col-md-9">
                  <div class="d-md-flex mb-4">
                    <div
                      class="
                        card card-body
                        border-0
                        shadow-sm
                        py-md-4
                        rounded-l
                        text-center
                        mr-md-5
                        mb-md-0 mb-4
                      "
                    >
                      <p class="mb-0">
                        <img src="/images/call.svg" width="50" alt="Call" />
                      </p>
                      <p class="text-secondary font-medium font-18 my-4">
                        Call
                      </p>
                      <p class="mb-0">
                        <a :href="'tel:' + detail.phone">{{ detail.phone }}</a>
                      </p>
                    </div>
                    <div
                      class="
                        card card-body
                        border-0
                        shadow-sm
                        py-md-4
                        rounded-l
                        text-center
                      "
                    >
                      <p class="mb-0 h1 text-primary">
                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                      </p>
                      <p class="text-secondary font-medium font-18 my-4">
                        Email
                      </p>
                      <a :href="'mailto:' + detail.email" class="font-18"
                        ><i class="fa fa-envelope-o" aria-hidden="true"></i>
                        {{ detail.email }}</a
                      >
                    </div>
                  </div>
                  <div class="floating-form mb-4">
                    <div class="floating-form-group" style="height: 130px">
                      <Field
                        as="textarea"
                        type="text"
                        rows="4"
                        name="description"
                        v-model="details.description"
                        rules="required:description"
                        :validateOnInput="true"
                      />
                      <label for="description"
                        >Please write your problem/suggestion</label
                      >
                      <ErrorMessage name="description" class="text-danger" />
                    </div>
                  </div>
                  <div class="text-center mb-md-5 mb-4">
                    <button
                      type="button"
                      class="btn btn-brand-1 mb-3 px-5"
                      v-on:click="save()"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, ErrorMessage } from "vee-validate";

export default {
  name: "ContactSupport",
  components: {
    Field,
    ErrorMessage,
  },
  data() {
    return {
      detail: {},
      details: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "support",
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
    save() {
      this.$api
        .postAPI({
          _action: "query",
          _body: this.details,
        })
        .then((res) => {
           this.$notify({
              type: "success",
              text: res.message,
            });
          this.$router.push('/home');
        })
        .catch(() => {});
    },
  },
};
</script>