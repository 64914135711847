<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                <img
                  src="/images/back-arrow.svg"
                  width="17"
                  class="mr-2 align-top"
                  alt=""
              /></a>
              Notifications
            </h2>
          </div>
          <!-- <div class="col-md-6 text-right d-flex justify-content-end">
            <input
              type="text"
              class="search-click"
              name=""
              v-on:keyup.enter="getExpertList(0)"
              placeholder="search here..."
              v-model="searchObj.keyword"
            />
          </div> -->
        </div>
        <div class="card rounded-l border-0 shadow">
          <div
            v-for="(data, index) in notificationList"
            :key="index"
            class="card-body border-bottom expert-list"
            v-on:click="redirectTo(data)"
          >
            <div class="row" :class="data.is_read == 'Y' ? 'notification-row' : ' '">
              <div class="col-md-8 d-flex align-items-center mb-md-0 mb-3">
                <div class="pl-3">
                  <p class="font-medium mb-2 font-18 font-xs-16">
                    {{ data.title }}
                  </p>
                  <p class="font-weight-light font-14 text-secondary mb-1 font-xs-12">
                    {{ data.message }}
                  </p>
                  <p class="font-weight-light font-14 text-secondary mb-0 font-xs-12">
                    {{ data.created_at }}
                  </p>
                </div>
              </div>
              <div class="row mt-1 ml-2" v-if="data.action == 'JOIN_GROUP_REQUEST'">
                <button
                  type="button"
                  style="height: 40px"
                  id="submit-button"
                  class="btn btn-success"
                  v-on:click="accept('ACCEPTED')"
                >
                  Accept
                </button>
                <button
                  type="button"
                  style="height: 40px"
                  id="submit-button"
                  class="btn btn-danger ml-3"
                  v-on:click="reject('REJECTED')"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
          <ErrorComponent @retry="getNotification(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
  </div>
  <div
    style="text-align: center; border-radius: 16px"
    class="col-12 pb-3"
    v-show="searchObj.offset != -1 && loader == false"
  >
    <a
      id="load-button"
      class="txt-success cursor-pointer mb-3"
      v-on:click="getNotification(searchObj.offset)"
      style="max-width: 110px"
    >
      Load More
    </a>
  </div>
  <TextModal ref="reviewModal">
    <div class="modal-content">
      <div class="modal-header">
        <div class="row col-12">
          <h5 class="col-11" style="padding-left: 72px">Write Review</h5>
          <a
            type="button"
            class="close col-1"
            v-on:click="$refs.reviewModal.closeModal()"
          >
            <img src="/images/cross.png" class="mb-lg-5 mb-4" />
          </a>
        </div>
      </div>
      <div class="modal-body">
        <p class="mb-3 ratings font-22">
          <star-rating
            :star-size="50"
            style="justify-content: center; width: 100%"
            :increment="0.5"
            :show-rating="false"
            v-model:rating="reviewObj.rating"
          ></star-rating>
        </p>
        <div class="mb-3">
          <label style="width: 100%" for="title"> Write Review: </label>
          <textarea
            type="text"
            style="width: 100%"
            rows="4"
            id="description"
            name="review"
            v-model="reviewObj.review"
            rules="required:review"
            :validateOnInput="true"
          />
        </div>
        <ErrorMessage name="title" class="validation-msg" />
        <div class="text-center" style="margin-top: 30px">
          <button
            type="button"
            id="submit-button"
            class="btn btn-brand-1 px-5"
            v-on:click="submit()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </TextModal>
</template>
<script>
import TextModal from "@/components/TextModal.vue";
import StarRating from "vue-star-rating";
import ErrorComponent from "@/components/ErrorComponent";
import moment from "moment";
export default {
  name: "NotificationList",
  components: {
    ErrorComponent,
    TextModal,
    StarRating,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      searchObj: {
        offset: 0,
      },
      notificationList: [],
      reviewObj: {},
      Id: "",
      subscription: {},
      otherId: "",
    };
  },
  mounted() {
    this.$store.commit("showSidebar", false);
    this.getNotification(0);
  },
  methods: {
    getNotification(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.searchObj.offset = offset;
      if (offset == 0) {
        this.notificationList = [];
      }
      this.$api
        .getAPI({
          _action: "notifications",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          for (var i in res.list) {
            res.list[i].created_at = moment(res.list[i].created_at).format(
              "DD MMM YYYY | HH:mm A"
            );
            this.notificationList.push(res.list[i]);
          }
          this.searchObj.offset = res.next_offset;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No notification available");
            this.$refs.errorComponent.updateLoader(false);
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    getUnreadCount() {
      this.$api
        .getAPI({
          _action: "notification/count",
        })
        .then((res) => {
          this.$store.commit("setNotificationCount", res.unread_count);
        })
        .catch(() => {});
    },
    redirectTo(detail) {
      if (detail.action == "OFFER_RECEIVED") {
        this.$api
          .getAPI({
            _action: "post/requirement/" + detail.action_id,
          })
          .then((res) => {
            this.$sharedService.setMapValue("data", res);
            this.$router.push("/chat");
            this.unreadNotification(detail.id);
          })
          .catch(() => {});
      } else if (detail.action == "WEBINAR") {
        this.$router.push("/webinar-details/" + detail.action_id);
        this.unreadNotification(detail.id);
      } else if (detail.action == "HEALTH_AND_WELL_BEING_ACTIVITY") {
        this.$router.push("/health-well-being-details/" + detail.action_id);
        this.unreadNotification(detail.id);
      } else if (detail.action == "GROUP_MEMBER_REQUESTED") {
        this.$router.push("/business-member/" + detail.action_id);
        var tab = "PENDING";
        this.$sharedService.setMapValue("tab", tab);
        this.unreadNotification(detail.id);
      } else if (detail.action == "BUSINESS_CONCLAVE") {
        this.$router.push("/business-conclave-details/" + detail.action_id);
        this.unreadNotification(detail.id);
      } else if (detail.action == "REVIEW_REQUESTED") {
        this.Id = detail.action_id;
        this.otherId = detail.other_id;
        this.$refs.reviewModal.showModal();
        this.unreadNotification(detail.id);
      } else if (detail.action == "POST_REQUIREMENT") {
        this.getMyProfile();
        this.unreadNotification(detail.id);
      } else {
        this.unreadNotification(detail.id);
      }
    },

    getMyProfile() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.subscription = res.response?.user_subscription;
          if (this.subscription.my_prospects_lead_support == "N") {
            this.$router.push("my-leads");
          } else {
            this.$router.push(
              "select-plan/upgrade?attribute=my_prospects_lead_support&&redirect=my-leads"
            );
          }
        })
        .catch(() => {});
    },
    unreadNotification(id) {
      this.$api
        .putAPI({
          _action: "notification/" + id,
        })
        .then(() => {
          this.getUnreadCount();
        })
        .catch(() => {});
    },
    submit() {
      this.$api
        .putAPI({
          _action: "review/" + this.Id,
          _body: this.reviewObj,
          _buttonId: "submit-button",
        })
        .then((res) => {
          this.$refs.reviewModal.closeModal();
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
    accept(status) {
      var obj = {};
      obj.status = status;
      this.$api
        .postAPI({
          _action: "business-work-group/" + this.Id + "/" + this.otherId,
          _body: obj,
          _buttonId: "submit-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
    reject(status) {
      var obj = {};
      obj.status = status;
      this.$api
        .postAPI({
          _action: "business-work-group/" + this.Id + "/" + this.otherId,
          _body: obj,
          _buttonId: "submit-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
  },
};
</script>
