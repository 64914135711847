<template>
  <div class="page-content" style="margine-top: 150px !important">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-8">
        <div
          class="card border-0 collaboration-card mb-4"
          v-on:click="showOpportunitiesPopup"
        >
          <img
            src="/img\collaboration-bg-1.png"
            alt=""
            class="bg-img img-fluid"
          />
          <h3
            class="
              text-white
              font-medium font-xs-20
              px-md-5
              text-center
              position-absolute
              w-100
            "
            style="bottom: 25px"
          >
            I am looking for Investment Opportunities
          </h3>
        </div>
        <div
          class="card border-0 collaboration-card"
          v-on:click="showCollaborationPopup"
        >
          <img
            src="/img/collaboration-bg-2.png"
            alt=""
            class="bg-img img-fluid"
          />
          <h3
            class="
              text-white
              font-medium font-xs-20
              px-md-5
              text-center
              position-absolute
              w-100
            "
            style="bottom: 25px"
          >
            I am looking for Investment in my business
          </h3>
        </div>
      </div>
    </div>
  </div>
  <!--	Investment Opportunities-->
  <TextModal ref="opportunities">
    <div>
      <Form @submit="postOpportunities">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            v-on:click="$refs.opportunities.closeModal()"
          >
            <img src="/images/close.svg" width="15" />
          </button>
        </div>
        <div class="modal-body form-style floating-form pb-0 pt-2 px-4">
          <h4 class="font-22 modal-title text-center mb-4">
            Want to do collaboration?
          </h4>
          <div class="form-group">
            <label for=" ">Interested Industry</label>
            <Field
              as="select"
              class="custom-select"
              v-model="detail.industry_id"
              name="industry"
              rules="required:industry"
            >
              <option value="">Select</option>
              <option
                v-for="(data, index) in industryList"
                :key="index"
                :value="data.id"
                style="cursor: pointer"
              >
                {{ data.name }}
              </option>
              >
            </Field>
            <ErrorMessage name="industry" class="text-danger" />
          </div>

          <p class="font-medium mb-2">Interested In</p>
          <div class="text-center interest-tags mt-4">
            <button
              type="button"
              v-on:click="selectedReason('EQUITY')"
              class="btn btn-outline-rounded-color mr-2 mb-2"
              :class="isEquity == true ? 'active' : ''"
              style="cursor: pointer"
            >
              Equity Purchase
            </button>
            <button
              type="button"
              v-on:click="selectedReason('BUYOUT')"
              class="btn btn-outline-rounded-color mr-2 mb-2"
              :class="isBuyout == true ? 'active' : ''"
              style="cursor: pointer"
            >
              Buyout
            </button>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <a>
            <button
              class="btn btn-brand-1 px-4 w-100"
              v-on:click="postOpportunities"
              style="cursor: pointer"
            >
              Post
            </button>
          </a>
        </div>
      </Form>
    </div>
  </TextModal>
  <!-- Need Collaboration?-->
  <TextModal ref="collaboration">
    <div>
      <Form @submit="postCollabration">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            v-on:click="$refs.collaboration.closeModal()"
            style="cursor: pointer"
          >
            <img src="/img/close.svg" width="15" />
          </button>
        </div>
        <div class="modal-body form-style pb-0 pt-2 px-4">
          <h4 class="font-22 modal-title text-center mb-4">
            Need Collaboration?
          </h4>
          <label class="mb-4 font-bold">Reason For Collaboration</label>
          <div class="form-group">
            <div
              v-for="(data, index) in reasonList"
              :key="index"
              class="custom-control custom-radio mb-2"
            >
              <input
                type="checkbox"
                :id="'id_' + index"
                name="reason"
                class="custom-control-input"
                v-on:click="selectReason(index)"
              />

              <label class="custom-control-label" :for="'id_' + index">
                {{ data.name }}</label
              >
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            type="button"
            class="btn btn-brand-1 px-4 w-100"
            v-on:click="postCollabration"
            style="cursor: pointer"
          >
            Post
          </button>
        </div>
      </Form>
    </div>
  </TextModal>
  <ThankyouModal ref="ThankyouModal" />
</template>
<script>
import ThankyouModal from "@/components/ThankyouModal.vue";
import TextModal from "@/components/TextModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "MyCollaboration",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    ThankyouModal,
  },
  data() {
    return {
      isEquity: false,
      isBuyout: false,
      industryList: [],
      reasonList: [],
      detail: {
        industry_id: "",
        reason: "",
        remark: "Testing_remark",
      },
    };
  },
  mounted() {
    this.getIndustryList();
    this.getReasonList();
    this.$store.commit("showSidebar", false);
  },
  methods: {
    showThankyouPopup() {
      this.$refs.ThankyouModal.showModal(
        "Thank You",
        "Admin team will verify this request and contact you "
      );
    },
    selectedReason(type) {
      if (type == "EQUITY") {
        this.isEquity = !this.isEquity;
      } else {
        this.isBuyout = !this.isBuyout;
      }
    },
    showOpportunitiesPopup() {
      this.isEquity = false;
      this.isBuyout = false;
      this.detail.industry_id = "";
      this.$refs.opportunities.showModal();
    },
    showCollaborationPopup() {
      this.$refs.collaboration.showModal();
    },
    postOpportunities() {
      if (this.isEquity && this.isBuyout) {
        this.detail.reason = "EQUITY_BUYOUT";
      } else if (this.isEquity) {
        this.detail.reason = "EQUITY";
      } else if (this.isBuyout) {
        this.detail.reason = "BUYOUT";
      }
      this.$api
        .postAPI({
          _action: "collobaration/seeker",
          _body: this.detail,
          // _buttonId: "post-button",
        })
        .then((res) => {
          if (res) {
            this.$refs.opportunities.closeModal();
            this.showThankyouPopup();
            // this.$router.push("/add-new-business-address");
          }
        })
        .catch(() => {});
    },
    // alert(this.detail.reason);
    selectReason(index) {
      for (var i in this.reasonList) {
        if (i == index) {
          this.reasonList[i].is_checked = true;
          // this.detail.reason = ;
        }
      }
    },
    getIndustryList() {
      this.$api
        .getAPI({
          _action: "category/industry",
        })
        .then((res) => {
          this.industryList = res.list;
        })
        .catch(() => {});
    },
    getReasonList() {
      this.$api
        .getAPI({
          _action: "category/collaboration-reason",
        })
        .then((res) => {
          for (var i in res.list) {
            res.list[i].is_checked = false;
          }
          this.reasonList = res.list;
        })
        .catch(() => {});
    },
    postCollabration() {
      var new_reason = "";
      for (var j in this.reasonList) {
        if (this.reasonList[j].is_checked == true) {
          console.log("here");
          new_reason = new_reason + this.reasonList[j].name + ",";
        }
      }
      this.detail.reason = new_reason;
      this.$api
        .postAPI({
          _action: "business/collobaration",
          _body: this.detail,
          // _buttonId: "post-button",
        })
        .then((res) => {
          if (res) {
            this.$refs.collaboration.closeModal();
            this.showThankyouPopup();
            // this.$router.push("/add-new-business-address");
          }
        })
        .catch(() => {});
    },
  },
};
</script>