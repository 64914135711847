<template>
  <!-- <div v-show="isShowModal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" v-on:click="closeModal">
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-5">
        <h5 class="modal-title font-22 mb-4">{{ title }}</h5>
        <img src="/images/claping.png" alt="" width="75" class="mb-3" />
        <p class="font-18">
          {{ body }}
        </p>
      </div>
      <div class="modal-footer justify-content-center border-0 pb-4 pt-0">
        <button
          type="button"
          class="btn btn-brand-outline px-4 w-100"
          :disabled="showLoader"
          style="width: 18%; border-radius: 5px"
          v-on:click="onClick"
        >
          <i v-if="showLoader" class="fa fa-spinner fa-spin"></i>
          OK
        </button>
      </div>
    </div>
  </div> -->
  <div v-show="isShowModal">
    <div class="spx-modal h-100 justify-content-center">
      <div class="spx-modal-body mx-auto">
        <div class="modal-header pt-0">
          <h5 class="modal-title">{{ title }}</h5>

          <button type="button" class="close" v-on:click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center px-md-5">
          <img src="/images/claping.png" alt="" width="90" class="mb-3" />
          <p>{{ body }}</p>
        </div>
        <div class="modal-footer justify-content-center border-0 pb-4 pt-0">
          <button
            type="button"
            class="btn btn-brand-outline px-4 w-100"
            id="delete-button"
            :disabled="showLoader"
            style="width: 18%; border-radius: 5px"
            v-on:click="closeModal"
          >
            OK
          </button>
        </div>
      </div>
    </div>
    <div class="spx-modal-background"></div>
  </div>
</template>
<script>
export default {
  name: "ThankyouModal",
  data() {
    return {
      isShowModal: false,
      showLoader: false,
      title: "Thank You",
      body: "Admin team will verify this request and contact you",
      obj: {},
    };
  },
  mounted() {},
  methods: {
    showModal(title, body, obj) {
      this.title = title;
      this.body = body;
      this.obj = obj;
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
      this.removeLoader();
      this.$emit("redirect");
    },
    onClick() {
      this.$emit("remove", this.obj);
      this.showLoader = true;
    },
    removeLoader() {
      this.showLoader = false;
    },
  },
};
</script>