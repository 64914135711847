<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-10">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt="" /></a
                >Invite Members
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end">
              <input
                type="text"
                class="search-click"
                name="name-keyword"
                v-on:keyup.enter="getList(0)"
                placeholder="search here..."
                v-model="searchObj.keyword"
              />
            </div>
          </div>
          <!-- <div
            v-on:scroll="handleScroll($event)"
            id="chat_div"
            style="height: 60vh; overflow: scroll"
          > -->
          <div
            class="form-style pt-md-4 card card-body bg-white"
            id="login-tabContent"
          >
            <div id="tab_1" aria-labelledby=" ">
              <table class="table table-style-1">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loader">
                  <tr
                    class="border-bottom"
                    v-for="(data, index) in userList"
                    :key="index"
                  >
                    <td width="45" class="p-2">
                      <img
                        :src="
                          data.profile_img
                            ? data.profile_img
                            : 'images/placeholder-image.jpg'
                        "
                        width="45"
                        alt=""
                      />
                    </td>
                    <td class="font-medium align-middle p-2">
                      {{ data.name ? data.name : " " }}
                    </td>

                    <td class="p-2 text-right">
                      <button
                        id="invite-button"
                        type="button"
                        class="btn btn-brand-1 px-4"
                        style="cursor: pointer"
                        v-on:click="invite(data)"
                        v-if="!data.has_requested"
                      >
                        Invite
                      </button>
                      <button
                        type="button"
                        class="btn btn-brand-1 px-4"
                        style="cursor: pointer"
                        v-if="data.has_requested"
                      >
                        Invited <i class="fa fa-check" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            </div>

            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
          <div class="row mx-0 mb-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
export default {
  name: "UserList",
  components: {
    ErrorComponent,
    Pagination,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      detail: {},
      userList: [],
      searchObj: {
        keyword: "",
        offset: 0,
      },
      groupId: this.$route.query.id,
    };
  },
  mounted() {
    this.getList(0);
    this.$store.commit("showSidebar", true);
    // this.groupId = this.$route.query.id;
  },
  methods: {
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action: "business-work-group/" + this.groupId + "/users",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.userList = res.list;
          this.searchObj.offset = res.next_offset;
          if (offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No User available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    invite(data) {
      var obj = {
        member_ids: [],
      };
      obj.member_ids.push(data.id);
      this.$api
        .postAPI({
          _action: "business-work-group/" + this.groupId + "/invite",
          _body: obj,
          _buttonId: "invite-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getList(0);
        })
        .catch(() => {});
    },
  },
};
</script>