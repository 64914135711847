<template>
  <div>
    <header
      class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100"
      style="z-index: 1"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img
              src="/images/tenx-logo.svg"
              width="80"
              height="26"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </header>
    <main role="main">
      <div class="" id="main-wraper">
        <div class="">
          <div class="container pt-md-5 pt-4">
            <div class="row justify-content-center pt-4">
              <div
                class="
                  col-lg-12 col-md-12
                  px-lg-5 px-3
                  py-md-5 py-4
                  rounded-theme
                "
              >
                <div class="row justify-content-center mb-4">
                  <div
                    class="col-lg-4 col-md-4 col-12 mb-3"
                    v-for="(data, index) in planList"
                    :key="index"
                  >
                    <div
                      class="
                        card
                        shadow-sm
                        mb-3
                        plan-card
                        rounded-l
                        border-0
                        h-100
                      "
                    >
                      <div
                        class="
                          card-header
                          text-white
                          bg-primary
                          text-center
                          card-bg
                        "
                        style="min-height: 150px"
                      >
                        <img
                          src="/images/recommended.svg"
                          width="150"
                          alt=""
                          class="position-absolute"
                          style="left: -11px; top: -6px"
                          v-if="data.has_recommended"
                        />
                        <p class="font-18 font-medium mb-1 font-xs-16">
                          {{ data.title }}
                        </p>
                        <h5
                          v-show="
                            data.feature.price != data.feature.discounted_price
                          "
                          class="mb-0 font-medium font-xs-25"
                          style="color: #white; text-decoration: line-through"
                        >
                          Rs. {{ data.feature.price }}
                        </h5>
                        <h1 class="mb-0 mt-0 font-28 font-medium font-xs-25">
                          Rs. {{ data.feature.discounted_price }}
                        </h1>
                        <select
                          id="disabledSelect"
                          v-on:change="selectPlan(index)"
                          v-model="data.selected"
                          class="
                            form-control
                            custom-select
                            rounded-common
                            select-p-dd
                            font-medium
                          "
                          style=""
                        >
                          <option
                            v-for="(feature, j) in data.features"
                            :key="j"
                            :value="j"
                          >
                            {{ feature.duration_text }}
                          </option>
                        </select>
                      </div>
                      <div class="card-body">
                        <p
                          class="d-flex align-items-start features"
                        >
                          <img
                            src="/images/check-outline.svg"
                            width="20"
                            class="mr-2"
                          />{{ data.intro_text }}
                          <a
                            href="javascript:void(0)"
                            data-toggle="tooltip"
                            data-placement="top"
                            :title="data.intro_text"
                          >
                            <img
                              src="/images/info-icon.svg"
                              width="14"
                              height="14"
                              class="ml-1"
                            />
                          </a>
                        </p>
                        <div class="text-center mb-3" v-if="data.is_collapse">
                          <a
                            class="font-medium"
                            data-toggle="collapse"
                            :href="'#collapseExample_' + data.id"
                            role="button"
                            aria-expanded="false"
                            :aria-controls="'collapseExample_' + data.id"
                            v-on:click="setCollapse(index)"
                            >View More Features
                            <i
                              class="fa fa-chevron-down font-12"
                              aria-hidden="true"
                            ></i
                          ></a>
                        </div>
                        <div
                          class="mb-5 collapse"
                          :id="'collapseExample_' + data.id"
                          v-if="data.is_collapse"
                        >
                          <p
                            v-for="(planDetail, k) in data.unfea_list"
                            :key="k"
                            class="d-flex align-items-start features"
                          >
                            <img
                              :src="
                                planDetail.is_included == 'Y'
                                  ? '/images/check-outline.svg'
                                  : '/images/close-outline.svg'
                              "
                              width="20"
                              class="mr-2"
                            />{{ planDetail.title }}
                            <a
                              href="javascript:void(0)"
                              data-toggle="tooltip"
                              data-placement="top"
                              :title="planDetail.info_text"
                            >
                              <img
                                src="/images/info-icon.svg"
                                width="14"
                                height="14"
                                class="ml-1"
                              />
                            </a>
                          </p>
                        </div>
                        <template v-if="!data.is_collapse">
                          <p
                            v-for="(planDetail, k) in data.fea_list"
                            :key="k"
                            class="d-flex align-items-start features"
                          >
                            <img
                              :src="
                                planDetail.is_included == 'Y'
                                  ? '/images/check-outline.svg'
                                  : '/images/close-outline.svg'
                              "
                              width="20"
                              class="mr-2"
                            />{{ planDetail.title }}
                            <a
                              href="javascript:void(0)"
                              data-toggle="tooltip"
                              data-placement="top"
                              :title="planDetail.info_text"
                            >
                              <img
                                src="/images/info-icon.svg"
                                width="14"
                                height="14"
                                class="ml-1"
                              />
                            </a>
                          </p>
                          <p
                            v-for="(planDetail, k) in data.unfea_list"
                            :key="k"
                            class="d-flex align-items-start features"
                          >
                            <img
                              :src="
                                planDetail.is_included == 'Y'
                                  ? '/images/check-outline.svg'
                                  : '/images/close-outline.svg'
                              "
                              width="20"
                              class="mr-2"
                            />{{ planDetail.title }}
                            <a
                              href="javascript:void(0)"
                              data-toggle="tooltip"
                              data-placement="top"
                              :title="planDetail.info_text"
                            >
                              <img
                                src="/images/info-icon.svg"
                                width="14"
                                height="14"
                                class="ml-1"
                              />
                            </a>
                          </p>
                        </template>
                        <div
                          class="text-center w-100"
                          style="left: 0; bottom: 20px"
                        >
                          <button
                            type="button"
                            class="btn btn-brand-1 mx-auto btn-sm"
                            data-toggle="modal"
                            data-target="#modal_success"
                            v-on:click="
                              subscribe(
                                data.id,
                                data.feature.id,
                                data.feature.discounted_price,
                                data.title
                              )
                            "
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <TextModal ref="textModal">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="close"
                          v-on:click="$refs.textModal.closeModal"
                        >
                          <img src="/images/close.svg" width="15" />
                        </button>
                        <h5 class="modal-title font-22">Congratulations</h5>
                      </div>
                      <div class="modal-body text-center px-md-5">
                        <img
                          src="images/claping.png"
                          alt=""
                          width="90"
                          class="mb-3"
                        />
                        <p class="font-18">
                          You are now member of the {{ popupTitle }} plan
                          community.
                        </p>
                        <h4 class="font-medium">
                          Are you interested in taking leadership position?
                        </h4>
                      </div>
                      <div
                        class="
                          modal-footer
                          justify-content-center
                          border-0
                          pb-4
                          pt-0
                        "
                      >
                        <button
                          type="button"
                          class="btn btn-brand-1 mr-3"
                          style="min-width: 115px"
                          v-on:click="setLeadership('Y')"
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          class="btn btn-brand-outline"
                          v-on:click="setLeadership('N')"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </TextModal>
                  <ThankyouModal ref="ThankyouModal" @redirect="redirectTo()" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import api from "../../services/WebService";
import TextModal from "@/components/TextModal";
import ThankyouModal from "@/components/ThankyouModal.vue";

export default {
  name: "SelectPlan",
  components: {
    TextModal,
    ThankyouModal,
  },
  data() {
    return {
      type: this.$route.params.type,
      id: this.$route.params.id,
      attribute: this.$route.query.attribute,
      redirect: this.$route.query.redirect,
      popupTitle: "",
      planList: [],
      userInfo: {},
      detail: {
        features: [],
      },
    };
  },
  watch: {
    "$route.query.attribute"(newValue) {
      this.attribute = newValue;
      this.getPlanList();
    },
  },
  mounted() {
    this.getPlanList(0);
    this.getMe("INITIAL");
    if (!this.type) {
      this.getLoginUserDetails();
    }
  },
  methods: {
    setCollapse(index) {
      this.planList[index].is_collapse = 0;
    },
    getPlanList() {
      var obj = {};
      if (this.attribute) {
        obj.check_level = "Y";
        obj.attribute = this.attribute;
      }
      api
        .getAPI({
          _action: "subscriptions",
          _body: obj,
        })
        .then((res) => {
          for (var i in res.list) {
            res.list[i].feature = res.list[i].features[0];
            res.list[i].selected = 0;
            var fea_list = [];
            var unfea_list = [];
            for (var j in res.list[i].feature.list) {
              if (res.list[i].feature.list[j].has_featured == "1") {
                fea_list.push(res.list[i].feature.list[j]);
              }
              if (res.list[i].feature.list[j].has_featured == "0") {
                unfea_list.push(res.list[i].feature.list[j]);
              }
            }
            res.list[i].fea_list = fea_list;
            res.list[i].unfea_list = unfea_list;
            res.list[i].is_collapse = fea_list.length > 0 ? 1 : 0;
          }
          this.planList = res.list;
        })
        .catch(() => {});
    },
    subscribe(subScriptionId, featureId, amount, title) {
      var obj = {};
      if (this.type && this.type == "upgrade") {
        obj.has_upgrade = "Y";
      } else {
        obj.has_upgrade = "N";
      }
      (obj.plan_id = subScriptionId), (obj.plan_feature_id = featureId);
      (obj.company_id = localStorage.getItem("companyId")),
        (obj.amount = amount);
      this.$api
        .postAPI({
          _action: "payment/checkout",
          _body: obj,
        })
        .then((res) => {
          this.checkOut(amount, res, title);
        });
    },
    checkOut(amount, data, title) {
      var $this = this;
      var options = {
        key: data.razorpay_sdk_key, // Enter the Key ID generated from the Dashboard
        amount: amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Tenx",
        description: "Tenx Description",
        order_id: data.subscription,
        handler: function (response) {
          console.log(response);
          $this.showSubscribePopup(response, data.transaction_token, title);
        },
        prefill: {
          name: $this.userInfo.first_name,
          email: $this.userInfo.email,
          contact: $this.userInfo.phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function () {
        this.$noty.error("Payment Failed, Please try again");
      });

      rzp1.open();
    },
    showSubscribePopup(response, transaction_token, title) {
      var obj = {};
      // console.log(response)
      (obj.razorpay_payment_id = response.razorpay_payment_id),
        (obj.transaction_token = transaction_token);
      (obj.razorpay_signature = response.razorpay_signature),
        (obj.razorpay_order_id = response.razorpay_order_id),
        //   (obj.amount = amount);
        this.$api
          .postAPI({
            _action: "payment/success",
            _body: obj,
          })
          .then(() => {
            this.getMe(title);
          });
    },
    showThankyouPopUp(title) {
      this.$refs.ThankyouModal.showModal(
        "Congratulations !",
        " You are now  member of the " + title + " plan community."
      );
    },
    getMe(title) {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          if (res && res.response) {
            this.userInfo = res.response;
            if (res.response.companies && res.response.companies.length > 0) {
              this.$store.commit("setUser", res.response);
              localStorage.setItem("companyId", res.response.companies[0].id);
              if (res.response.user_subscription) {
                localStorage.setItem(
                  "subscriptionId",
                  res.response.user_subscription.id
                );
              }

              localStorage.setItem("subscriptionId", 1);
            } else {
              localStorage.removeItem("access_token");
              this.$router.push("/login");
              return;
            }

            localStorage.setItem("userId", res.response.id);

            if (title != "INITIAL") {
              if (this.type) {
                this.$router.go(-1);
                this.showThankyouPopUp(title);
              } else if (
                res.response.user_subscription.my_prospects_lead_support == "Y"
              ) {
                this.popupTitle = title;
                this.$refs.textModal.showModal();
              } else if (
                res.response.user_subscription.my_prospects_lead_support == "N"
              ) {
                this.showThankyouPopUp(title);
              }
            }
          }
        })
        .catch(() => {
          localStorage.removeItem("access_token");
          this.$router.push("/login");
        });
    },
    selectPlan(i) {
      this.planList[i].feature =
        this.planList[i].features[this.planList[i].selected];
    },
    redirectTo() {
      if (this.type && this.redirect) {
        window.location = "/" + this.redirect;
      } else if (this.type) {
        this.$router.push("/addCompanyProfile");
      } else {
        this.$router.push("/add-business-address");
      }
    },
    setLeadership(data) {
      var obj = {};
      obj.has_leadership_position = data;
      this.$api
        .postAPI({
          _action: "user/leadership/interested",
          _body: obj,
        })
        .then(() => {
          if (this.type && this.type == "upgrade") {
            this.$router.go(-1);
          } else {
            this.$router.push("/add-business-address");
          }
        });
    },
    getLoginUserDetails() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          if (res && res.response && res.response.user_subscription) {
            this.$router.push("/home");
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.plan-card .select-p-dd {
  background-color: #fff;
  font-size: 13px;
  text-align: center;
  text-align-last: center;
  width: 127px;
  border: 0;
  background-size: 11px;
  line-height: 22px;
}
</style>