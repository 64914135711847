<template>
  <div>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center">
            <div class="card-body pb-0">
              <div class="row min-h-auto mb-md-0 mb-4" style="min-height: 86vh">
                <div class="col-12" style="max-height: 100%">
                  <div class="row col-12 mb-2">
                    <div class="col-md-6 text-md-left text-center">
                      <h2 class="page-title mb-md-4">
                        <a class="cursor-pointer" v-on:click="$router.go(-1)">
                          <img
                            src="/images/back-arrow.svg"
                            width="17"
                            class="mr-2 align-top"
                            alt=""
                        /></a>
                        Members
                      </h2>
                    </div>
                    <div class="col-md-6 p-0 text-md-right text-center"></div>
                  </div>
                  <div
                    class="form-style pt-md-4 mb-3 card card-body bg-white"
                    id="login-tabContent "
                  >
                    <div
                      id="tab_1"
                      aria-labelledby=" "
                      style="max-height: 400px"
                    >
                      <table class="table table-style-1">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="border-bottom"
                            v-for="(data, index) in list"
                            :key="index"
                          >
                            <td width="45" class="p-2">
                              <img
                                :src="
                                  data?.member?.profile_img
                                    ? data?.member?.profile_img
                                    : 'images/placeholder-image.jpg'
                                "
                                width="45"
                                alt=""
                              />
                            </td>
                            <td class="font-medium align-middle p-2">
                              {{ data?.member?.name
                              }} <span v-if="data.category"
                                >- {{ data?.category?.name }}</span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
  <script>
export default {
  name: "CityMember",
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$api
        .getAPI({
          _action: "city-committee/" + this.id + "/members",
        })
        .then((res) => {
          this.list = res.list;
        })
        .catch(() => {});
    },
  },
};
</script>
  