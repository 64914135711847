<template>
    <div
      class="card rounded-l cursor-pointer border-0 exibition-card"
      v-on:click="$router.push('/exibition-detail/' + data.id)"
    >
      <div class="card-header bg-white">
        <img
          :src="
            data.event_banner
              ? data.event_banner
              : 'images/placeholder-image.jpg'
          "
          class="img-fluid rounded-l card-img"
        />
      </div>
      <div class="card-body">
        <p class="font-medium font-18 mb-2">
          {{ data.event_title }}
        </p>
        <p class="font-14 text-secondary mb-2">
          {{ $helperService.getFormatedDate(data.start_at) }} -
          {{ $helperService.getFormatedDate(data.end_at) }}
        </p>
        <p class="text-secondary mb-0 font-14">
          <img
            src="/images/location-icon.svg"
            width="14"
            class="align-baseline mr-1"
          />{{ data.venue }}
        </p>
      </div>
    </div>
</template>
<script>
export default {
  name: "ExibitionCard",
  props: ["data"]
};
</script>
