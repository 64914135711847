<template >
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">Exhibition</h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end">
            <input
              type="text"
              class="search-click"
              name=""
              v-on:keyup.enter="getExibitionList(0)"
              placeholder="search here..."
              v-model="searchObj.keyword"
            />
          </div>
        </div>
        <div
          id="carouselExampleIndicators"
          class="carousel slide mb-5 exibition-slider"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              v-for="(data, index) in bannerList"
              :key="index"
              :class="index == 0 ? 'active' : ''"
              data-target="#carouselExampleIndicators"
              :data-slide-to="index"
            ></li>
          </ol>
          <div class="carousel-inner">
            <div
              v-for="(data, index) in bannerList"
              :key="index"
              class="carousel-item"
              :class="index == 0 ? 'active' : ''"
            >
              <img
                :src="data.banner ? data.banner : '/img/placeholder-image.jpg'"
                class="d-block w-100"
                alt="..."
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div
            class="col-lg-4 col-md-6 mb-3"
            v-for="(data, index) in exibitionList"
            :key="index"
          >
            <ExibitionCard :data="data" />
          </div>
          <div class="row col-12 justify-content-center" >
          <ErrorComponent @retry="getExibitionList(0)" ref="errorComponent" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import ExibitionCard from "@/components/ExibitionCard";
export default {
  name: "MyExibition",
  components: {
    ErrorComponent,
    ExibitionCard,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      exibitionList: [],
      detail: {},
      searchObj: {
        keyword: "",
      },
      bannerList: [],
    };
  },
  mounted() {
    this.getExibitionList();
    this.getBannerList();
    this.$store.commit("showSidebar", false);
  },
  methods: {
    getExibitionList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "exhibitions",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.exibitionList = res.list;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No exhibition  available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    getBannerList() {
      this.$api
        .getAPI({
          _action: "banners",
        })
        .then((res) => {
          this.bannerList = res.list;
        })
        .catch(() => {});
    },
  },
};
</script>