<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="$router.go(-1)">
                <img
                  src="/images/back-arrow.svg"
                  width="17"
                  class="mr-2 align-top"
                  alt=""
              /></a>
              TenX Growth Group
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end"></div>
        </div>
        <div class="card mb-4 rounded-l border-0 shadow-sm">
          <!-- <div class="card-header p-0 border-0">
            <img
              src="/images/cover-img.jpg"
              class="card-img-top rounded-l cover-img"
              alt=""
            />
          </div> -->
          <div class="card-body px-md-5 -x3">
            <div class="row mb-4 align-items-center border-bottom pb-md-4">
              <div class="col-md-6 d-flex align-items-center mb-md-0 mb-3">
                <img
                  :src="
                    this.detail.logo ? this.detail.logo : '/images/placeholder-image.jpg'
                  "
                  alt=""
                  class="rounded-circle object-cover"
                  width="100"
                  height="100"
                />
                <div class="pl-4">
                  <p class="font-medium mb-2 font-18 font-xs-16">
                    {{ detail.name }}
                  </p>
                  <p
                    class="text-secondary mb-md-2 mb-2 font-14 cursor-pointer font-medium"
                  >
                    <img src="/images/location-icon.svg" width="20" class="mr-1" />{{
                      detail.area
                    }}
                  </p>
                  <p
                    class="font-14 text-secondary mb-0 font-xs-12"
                    v-if="detail.no_of_member != null"
                  >
                    Members: ({{ detail.no_of_member }}/{{ detail.group_size }})
                  </p>
                </div>
              </div>

              <div class="col-md-6" v-if="detail.leader">
                <div
                  class="d-flex align-items-center mb-md-0 mb-3 px-0 ml-auto justify-content-md-end cursor-pointer"
                  v-on:click="$router.push('/member-detail/' + detail?.leader?.uuid)"
                >
                  <img
                    :src="
                      detail?.leader?.profile_img
                        ? detail?.leader?.profile_img
                        : '/images/profile.svg'
                    "
                    alt=""
                    class="img-fluid rounded-circle object-cover"
                    style="width: 55px; height: 55px"
                  />
                  <div class="pl-3">
                    <p class="font-medium mb-0 font-xs-16">
                      {{ detail?.leader?.first_name }}
                      {{ detail?.leader?.last_name }}
                    </p>
                    <p class="font-14 text-secondary mb-1 font-xs-12">
                      <i class="fa fa-flag text-primary" aria-hidden="true"></i>
                      President
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h4 class="font-18">About {{ detail.name }}</h4>
            <p>
              {{ detail.about }}
            </p>
            <p
              class="badge-light-yellow mr-ml-50"
              style="
                margin-left: 60px;
                margin-right: 60px;
                text-align: center;
                font-weight: bold;
              "
              v-show="
                detail.is_member == 'PENDING' ||
                this.meCall.has_leadership_requested == 'Y'
              "
            >
              <img src="/images/index.png" width="10" height="10" />
              Your joining request is under review.
            </p>
            <div class="text-center">
              <button
                type="button"
                class="btn btn-brand-1 px-4 mr-md-4"
                data-toggle="modal"
                data-target="#modal_thankyou"
                v-on:click="requestMembership()"
                v-show="detail.is_member == 'NA' || detail.is_member == 'REJECTED'"
                v-if="detail.no_of_member < detail.group_size"
              >
                Join Group
              </button>

              <button
                type="button"
                class="btn btn-brand-1 px-4 mr-md-4"
                data-toggle="modal"
                data-target="#modal_thankyou"
                v-on:click="viewMeetings(detail.id)"
                v-show="detail.is_member == 'ACCEPTED' || detail.is_leader == 'ACCEPTED'"
              >
                View meetings
              </button>
              <button
                type="button"
                class="btn btn-brand-outline mr-md-4 px-4"
                v-on:click="memberList(detail.id)"
                v-show="detail.is_member != 'PENDING'"
              >
                Members
              </button>

              <button
                type="button"
                class="btn btn-brand-outline mr-md-4 px-4"
                v-on:click="designation()"
                v-show="detail.is_member != 'PENDING' && meCall.city_committee_id"
              >
                {{ meCall?.city_committee_id?.title }}
              </button>
            </div>
            <!-- <div class="mt-3" style="text-align: center;">
            <p
                v-if="
                  detail.no_of_member >= detail.group_size
                "
                v-show="detail.is_leader == 'NA'"
              >
                Group is already full
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <ThankyouModal ref="ThankyouModal" />
</template>
<script>
import api from "../../services/WebService";
import ThankyouModal from "@/components/ThankyouModal.vue";
// import helperService from "../../services/HelperService";
export default {
  name: "BusinessGroupDetail",
  components: {
    ThankyouModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      meCall: { city_committee_id: {} },
      obj: {
        company_id: localStorage.getItem("companyId"),
        member_type: "MEMBER",
      },
    };
  },
  mounted() {
    this.getBusinessDetail();
    this.$store.commit("showSidebar", false);
    this.getUserDetail();
    // console.log("$parent", this.$parent.$parent.callMethod());
  },
  methods: {
    showThankyouPopup() {
      this.$refs.ThankyouModal.showModal(
        "Congratulations !",
        "Your membership is under approval, we will notify you when it’s approved."
      );
    },
    getBusinessDetail() {
      api
        .getAPI({
          _action: "business-work-group/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
          this.$sharedService.setMapValue("business_detail", this.detail);
          // console.log("detail",this.detail);
        })
        .catch(() => {});
    },
    getUserDetail() {
      api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          this.meCall = res.response;
          console.log(this.meCall);
        })
        .catch(() => {});
    },
    memberList(id) {
      this.$sharedService.clearMap("tab");
      this.$router.push("/business-member/" + id);
    },
    designation() {
      this.$router.push("/city-member/" + this.meCall.city_committee_id.id);
    },
    requestMembership() {
      api
        .postAPI({
          _action: "business-work-group/" + this.id + "/member",
          _body: this.obj,
        })
        .then(() => {
          this.detail.is_member = "ACCEPTED";
          this.showThankyouPopup();
          this.getBusinessDetail();
        })
        .catch(() => {});
    },
    viewMeetings(id) {
      this.$router.push("/meetings/" + id);
    },
  },
};
</script>
