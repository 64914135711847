<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">My Leads</h2>
          </div>
          <div class="col-md-6 text-right d-md-flex justify-content-end mb-3">
            <input
              type="text"
              class="search-click"
              name=""
              v-on:keyup.enter="getMyLeads(0)"
              placeholder="search here..."
              v-model="searchObj.keyword"
            />
          </div>
        </div>
        <!-- <ul
          class="nav nav-pills filter-tabs mb-3"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-home-tab"
              :class="{ active: currentTab == 'MATCHED' }"
              v-on:click="changeTab('MATCHED')"
              style="cursor: pointer"
              >Matched Leads
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-profile-tab"
              :class="{ active: currentTab == 'ALL' }"
              v-on:click="changeTab('ALL')"
              style="cursor: pointer"
              >All Leads</a
            >
          </li>
        </ul> -->
        <table class="table table-style-1 table-responsive" id="sorting_tbl">
          <thead>
            <tr>
              <th
                class="sorting cursor-pointer"
                id="id-title"
                v-on:click="sorting('title', 'id-title')"
              >
                Product / Service
              </th>
              <th class="sorting cursor-pointer" id="id-title">Type</th>
              <th
                class="sorting cursor-pointer"
                id="id-quantity"
                v-on:click="sorting('quantity', 'id-quantity')"
              >
                Quantity
              </th>
              <th>Other Info</th>
              <th
                class="sorting cursor-pointer"
                id="id-date"
                v-on:click="sorting('created_date', 'id-date')"
              >
                Created Date
              </th>
              <th><div style ="width:180px;">Action</div></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in leadList" :key="index">
              <td>
                {{
                  data.item_type == "PRODUCT"
                    ? data?.product?.title
                    : data?.service?.title
                }}
              </td>
              <td>
                {{ data.item_type }}
              </td>
              <td>{{ data.quantity }}</td>
              <td>{{ data.description }}</td>
              <td>{{ data.created_date }}</td>
              <td>
                <button
                  v-if="data.requirement_type == 'SELF'"
                  class="btn btn-action mr-2 mb-md-0 mb-2"
                  v-on:click="chat(data)"
                >
                  <img src="images/chat-icon.svg" width="12" alt="" />
                </button>
                <button
                  v-if="data.requirement_type == 'SELF'"
                  class="btn btn-brand-1"
                  v-on:click="showOfferModal(data)"
                  v-show="!data.has_offered"
                >
                  Send offer
                </button>
                <button
                  class="btn btn-brand-1"
                  v-show="data.has_offered"
                  v-if="data.requirement_type == 'SELF'"
                >
                  Offered
                </button>
                <div v-if =" data.requirement_type == 'GUEST'">
                Guest : {{
                  data.requirement_type == "GUEST"
                    ? data.guest_name
                      ? data.guest_name
                      : ""
                    : " "
                }}
                <br />
               
               Guest Mobile :{{
                  data.requirement_type == "GUEST"
                    ? data.guest_phone_number
                      ? data.guest_phone_number
                      : ""
                    : " "
                }}
                <br />
                Ref By : {{
                  data.requirement_type == "GUEST"
                    ? data.company.company_name
                      ?  data.company.company_name
                      : ""
                    : " "
                }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div
    style="text-align: center; border-radius: 16px"
    class="col-12 pb-3"
    v-show="offset != -1 && !loader"
  >
    <a
      id="load-button"
      class="txt-success cursor-pointer mb-3"
      v-on:click="getMyLeads(offset)"
      style="max-width: 110px"
    >
      Load More
    </a>
  </div>
  <ErrorComponent @retry="getMyLeads(0)" ref="errorComponent" />
  <!-- Send Offer -->
  <TextModal ref="offerModal">
    <Form ref="offerForm" @submit="send()">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            v-on:click="this.$refs.offerModal.closeModal()"
          >
            <img src="images/close.svg" width="15" />
          </button>
        </div>
        <div class="modal-body floating-form px-md-5 px-4">
          <h4 class="font-22 modal-tital text-center mb-4">Lead Description</h4>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="product-service">Products / Services</label>
                <p class="mb-0">
                  {{
                    offerDetail.item_type == "PRODUCT"
                      ? offerDetail?.product?.title
                      : offerDetail?.service?.title
                  }}
                </p>
              </div>
            </div>
            <div class="col-md-6" v-if="offerDetail.item_type == 'PRODUCT'">
              <div class="form-group">
                <label for="quantity">Quantity</label>
                <p class="mb-0">{{ offerDetail.quantity }}</p>
              </div>
            </div>
            <div class="col-md-12 mb-4">
              <div class="form-group">
                <label for="otherInfo">Other Info</label>
                <p class="mb-0">
                  {{ offerDetail.description }}
                </p>
              </div>
            </div>
            <hr class="w-100" />
            <div class="col-12 mb-4">
              <h4 class="text-center h5 font-bold">Send Offer</h4>
            </div>
            <div class="col-md-6">
              <div class="floating-form-group">
                <Field
                  type="text"
                  id="price"
                  v-model="detail.price"
                  name="price"
                  rules="required:price"
                />
                <label for="price">Price</label>
                <span class="text-danger">*</span>
              </div>
              <ErrorMessage name="price" class="validation-msg" />
            </div>
            <div class="col-md-6" v-if="offerDetail.item_type == 'PRODUCT'">
              <div class="floating-form-group">
                <Field
                  type="text"
                  id="quantity"
                  v-model="detail.quantity"
                  name="quantity"
                  rules="required:quantity"
                />
                <label for="quantity">Quantity</label>
                <span class="text-danger">*</span>
              </div>
              <ErrorMessage name="quantity" class="validation-msg" />
            </div>
            <div class="col-md-12">
              <div class="floating-form-group">
                <Field
                  type="text"
                  id="message"
                  v-model="detail.message"
                  name="message"
                />
                <label for="quantity">Message (Optional)</label>
              </div>
            </div>
            <div class="col-12">
              <label class="font-medium">I can deliver</label>
              <div class="d-md-flex mb-3">
                <div class="custom-control custom-radio mb-2 mr-3">
                  <input
                    type="radio"
                    id="Immediate"
                    name="deliveryBy"
                    class="custom-control-input"
                    checked=""
                    v-on:click="selectReason('IMMIDIATE')"
                  />
                  <label class="custom-control-label" for="Immediate"
                    >Immediate</label
                  >
                </div>
                <div class="custom-control custom-radio mb-2 mr-3">
                  <input
                    type="radio"
                    id="Within7day"
                    name="deliveryBy"
                    class="custom-control-input"
                    v-on:click="selectReason('WITHIN_7_DAYS')"
                  />
                  <label class="custom-control-label" for="Within7day"
                    >Within 7 Days</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="Within15day"
                    name="deliveryBy"
                    class="custom-control-input"
                    v-on:click="selectReason('WITHIN_15_DAYS')"
                  />
                  <label class="custom-control-label" for="Within15day"
                    >Within 15 Days</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button class="btn btn-brand-1 px-4" id="send-button">Send</button>
        </div>
      </div>
    </Form>
  </TextModal>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import helperService from "../../services/HelperService";
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal.vue";
import moment from "moment";
import $ from "jquery";
export default {
  name: "MyLeads",
  components: {
    TextModal,
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
  },
  data() {
    return {
      id: this.$route.params.id,
      leadList: [],
      offset: 0,
      loader: true,
      currentTab: "",
      offerDetail: "",
      title: "",
      detail: {
        company_id: localStorage.getItem("companyId"),
        request_id: "",
        delivery_by: "IMMIDIATE",
      },
      searchObj: {
        keyword: "",
        type: "MATCHED",
      },
    };
  },
  mounted() {
    this.getMyLeads(0);
    this.$store.commit("showSidebar", false);
    //floating label
    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    // changeTab(tab) {
    //   this.currentTab = tab;
    //   this.searchObj.type = tab;
    //
    // },
    getMyLeads(offset, order_by, sort_by) {
      this.loader = true;
      // this.$refs.errorComponent.updateLoader(true);
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      var obj = {};
      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      }
      if (order_by) {
        obj.order_by = order_by;
      }
      if (sort_by) {
        obj.sort_by = sort_by;
      }
      if (this.searchObj.type) {
        obj.type = this.searchObj.type;
      }
      if (this.detail.company_id) {
        obj.company_id = this.detail.company_id;
      }
      if (offset == 0) {
        this.leadList = [];
      }
      obj.offset = offset;
      this.$api
        .getAPI({
          _action: "leads",
          _body: obj,
        })
        .then((res) => {
          this.loader = false;
          for (var k in res.list) {
            this.leadList.push(res.list[k]);
          }
          this.offset = res.next_offset;
          // for (var j in this.leadList) {
          //   if (this.leadList[j].item_type == "PRODUCT") {
          //     this.title = this.leadList[j].product.title;
          //   } else if (this.leadList[j].item_type == "SERVICE") {
          //     this.title = this.leadList[j].service.title;
          //   }
          // }
          for (var i in res.list) {
            res.list[i].created_date = moment(res.list[i].created_date).format(
              "DD-MM-YYYY"
            );
          }
          this.offset = res.next_offset;
          if (this.offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No leads available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.updateLoader(false);
        });
    },
    selectReason(reason) {
      this.detail.delivery_by = reason;
    },
    send() {
      if (this.offerDetail.item_type == "SERVICE") {
        this.detail.quantity = 1;
      }

      this.detail.request_id = this.offerDetail.id;
      this.$api
        .postAPI({
          _action: "offer",
          _body: this.detail,
          _buttonId: "send-button",
        })
        .then((res) => {
          if (res) {
            this.$refs.offerModal.closeModal();
            this.getMyLeads(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
            // this.showThankyouPopup();
            // this.$router.push("/add-new-business-address");
          }
        })
        .catch(() => {});
    },
    chat(data) {
      // console.log("data", data);
      this.$sharedService.setMapValue("data", data);
      this.$router.push("/chat");
    },
    showOfferModal(data) {
      this.$refs.offerForm.resetForm();
      this.$refs.offerModal.showModal();
      this.offerDetail = data;
      if (this.offerDetail.product) {
        this.title = this.offerDetail.product.title;
      } else if (this.offerDetail.service) {
        this.title = this.offerDetail.service.title;
      }
    },
    sorting(sortBy, id) {
      var obj = helperService.sorting(sortBy, id);
      this.getMyLeads(0, obj.order_by, obj.sort_by);
    },
  },
};
</script>