<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-10">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="this.$router.push('/home')">
                <img
                  src="/images/back-arrow.svg"
                  class="mr-2 align-top"
                  alt=""
                  width="17" /></a
              >My Company Profile
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end">
            <input
              type="text"
              class="search-click"
              name=""
              v-on:keyup.enter="getCompanies(0)"
              placeholder="search here..."
              v-model="searchObj.keyword"
            />
          </div>
        </div>

        <div class="card border-0 mb-4">
          <div class="card-body" v-for="(data, index) in detail" :key="index">
            <div class="row">
              <div
                class="
                  col-md-11
                  d-md-flex
                  align-items-center
                  text-md-left text-center
                "
              >
                <img
                  :src="data.logo ? data.logo : '/images/profile.svg'"
                  alt=""
                  class="object-contain border border-light mb-md-0 mb-2"
                  width="160"
                  height="80"
                />
                <div class="pl-3">
                  <p class="font-medium mb-2 font-18 font-xs-16">
                    {{ data.company_name }}
                  </p>
                  <p class="text-dark mb-md-2 mb-2 font-14">
                    {{ data.sales_email }}
                  </p>
                  <p class="text-dark mb-md-2 mb-2 font-14">
                    {{ data.sales_dial_code }}{{ data.sales_contact_no }}
                  </p>
                </div>
              </div>
              <div class="position-absolute" style="right: 15px">
                <a
                  style="cursor-pointer"
                  v-on:click="
                    this.$router.push('/editCompanyProfile/' + data.id)
                  "
                  ><img src="images/edit-icon.svg" width="20" alt=""
                /></a>
                <a
                  style="cursor-pointer"
                  v-show="this.company_id != data.id"
                  v-on:click="showDeletePopup(data)"
                  ><img src="/images/delete.svg" width="20" alt=""
                /></a>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-3 col-7">
                <p class="font-14 font-xs-12 text-secondary-2 mb-0">Industry</p>
                <p class="font-medium mb-0 font-xs-14">
                  {{ data.industry?.name }}
                  <!-- industry -->
                </p>
              </div>
              <div class="col-md-3 col-5">
                <p class="font-14 font-xs-12 text-secondary-2 mb-0">
                  No of Employees
                </p>
                <p class="font-medium font-xs-14 mb-0">
                  {{ data.no_of_employees }}
                  <!-- no of employees -->
                </p>
              </div>
              <div class="col-md-6 col-6 " style="text-align: end;" >
                <button
                  type="button"
                  class="btn btn-brand-1 px-3"
                  v-on:click="request(data.id)"
                >
                  Request Recommendation
                </button>
              </div>
            </div>
            <hr />
          </div>

          <div class="text-center pb-4">
            <button
              type="button"
              class="btn btn-brand-1 px-4"
              v-on:click="addCompany()"
            >
              + Add Company Profile
            </button>
          </div>
          <ErrorComponent @retry="getCompanies(0)" ref="errorComponent" />
          <DeleteModal @remove="deleteRecord" ref="deleteModal" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DeleteModal from "@/components/DeleteModal";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "MyCompanyProfile",
  components: {
    ErrorComponent,
    DeleteModal,
  },
  data() {
    return {
      type: "upgrade",
      loader: true,
      detail: {
        sales_email: "",
      },
      myDetail: {},
      industryList: [],
      industryName: "",
      industryID: "",
      companyName: "",
      noOfEmployees: "",
      searchObj: {
        keyword: "",
      },
      company_id: localStorage.getItem("companyId"),
    };
  },
  mounted() {
    this.getCompanies();
    this.meCall();
    // this.getIndustryList();
  },
  methods: {
    meCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.myDetail = res.response;
          console.log("my details", this.myDetail);
        })
        .catch(() => {});
    },
    getCompanies() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "user/companies",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.detail = res.list;
          // console.log(this.detail);
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No company profile available"
            );
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    addCompany() {
      // console.log(
      //   "multiple display count",
      //   this.myDetail.user_subscription?.multiple_company_display_count
      // );
      // console.log("companies length", this.myDetail.companies.length);
      // console.log("companies", this.myDetail.companies);
      // alert(this.myDetail.user_subscription?.multiple_company_display_count);
      if (
        this.myDetail.user_subscription?.multiple_company_display_count >
        this.detail.length
      ) {
        this.$router.push("/addCompanyProfile");
      } else if (
        this.myDetail.user_subscription?.multiple_company_display_count <=
        this.detail.length
      ) {
        this.$router.push("/select-plan/" + this.type);
      }
    },
    deleteRecord(data) {
      this.$api
        .deleteAPI({
          _action: "company/" + data.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getCompanies(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    showDeletePopup(data) {
      // console.log(data);
      // if(this.company_id != this.myDetail.company_id){}
      this.$refs.deleteModal.showModal(
        "Delete Challenge",
        "Are you sure you want to delete Company " + data.company_name,
        data
      );
    },
    request(id)
    {
      localStorage.setItem("compId", id)
      this.$router.push('/users')
    }
  },
};
</script>