<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="$router.go(-1)">
                  <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>Meetings
              </h2>
            </div>
          </div>
          <ul class="nav nav-pills filter-tabs mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                aria-controls="pills-profile" aria-selected="false" :class="currentTab == 'UPCOMING' ? 'active' : ''"
                v-on:click="changeTab('UPCOMING')" style="cursor: pointer">Upcoming</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab"
                aria-controls="pills-contact" aria-selected="false" :class="currentTab == 'PAST' ? 'active' : ''"
                v-on:click="changeTab('PAST')" style="cursor: pointer">Past</a>
            </li>
          </ul>
          <div class="d-flex cursor-pointer" v-for="(data, index) in detail" :key="index">
            <div class="text-warning pr-2 text-center mt-2" style="min-width: 70px">
              <div class="font-medium font-16 mb-n1">
                {{ data.short_start_date }}
              </div>
            </div>
            <div class="bd-callout bd-callout-warning mt-0 w-100 bg-white">
              <div class="row">
                <div class="col-6">
                  <h4 class="font-16">
                    {{ data.start_time_12 }} - {{ data.end_time_12 }}
                  </h4>
                </div>
                <div class="col-6 text-right"></div>
              </div>
              <p class="font-medium mb-2">
                {{ data.title }}
              </p>
              <div class="text-secondary-2 font-14 cursor-pointer mb-2">
                <img src="/images/location-icon.svg" width="14" class="align-baseline" />
                {{ data.location }}
              </div>
              <div class="col">
                <div class="row">
                  <div class="cursor-pointer mr-3" v-show="this.type == 'UPCOMING'" @click="showWhatsappModal(data.uuid)">
                    <a href="javascript:void(0)" id="webinar-whtp">Send WhatsApp Message</a>
                  </div>
                  <div class="cursor-pointer" v-on:click="showLocation(data)">
                    <a href="javascript:void(0)">View on Map</a>
                  </div>
                  <div class="cursor-pointer ml-3" v-on:click="getMom(data)" v-show="this.type == 'PAST' &&
                    data.mom_description">
                    <a href="javascript:void(0)">View MOM</a>
                  </div>

                  <div class="cursor-pointer ml-3" v-if="this.type == 'PAST' &&
                    !data.mom_description &&
                    business_Detail?.leader?.id == meCall?.id
                    " v-on:click="showMom(data, 'ADD')">
                    <a href="javascript:void(0)">Add MOM</a>
                  </div>

                  <div class="cursor-pointer ml-3" v-else-if="this.type == 'PAST' &&
                    data.mom_description &&
                    business_Detail?.leader?.id == meCall?.id
                    " v-on:click="showMom(data, 'EDIT')">
                    <a href="javascript:void(0)">Edit MOM</a>
                  </div>
                  <div class="cursor-pointer ml-3" v-on:click="$router.push('/attendance/' + data.id)"
                    v-show="this.type == 'PAST' && this.owner === 'Y'">
                    <a href="javascript:void(0)">Attendance ({{ data.attendance_percent }}%)</a>
                  </div>

                  <div class="cursor-pointer ml-3" v-if="business_Detail?.leader?.id == meCall?.id &&
                    this.type == 'UPCOMING'
                    " v-on:click="getMeetingData(data)">
                    <a href="javascript:void(0)">Edit</a>
                  </div>
                  <div class="cursor-pointer ml-3" v-if="business_Detail?.leader?.id == meCall?.id &&
                    this.type == 'UPCOMING'
                    " v-on:click="showDeletePopup(data.id)">
                    <a href="javascript:void(0)">Delete</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="button" class="
              btn
              bg-brand
              position-sticky
              shadow
              btn-lg
              float-right
              mr-0
              add-sticky-btn
            " v-show="business_Detail?.leader?.id == meCall?.id" style="margin-right: 0 !important; right: 10px"
            v-on:click="addMeeting()">
            <img src="/images/plus.svg" width="12" alt="" class="mr-1" /> Add
            Meeting
          </button>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getMeetings(0)" ref="errorComponent" />
    <TextModal ref="addMeeting">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" v-on:click="$refs.addMeeting.closeModal()">
            <img src="/images/close.svg" width="15" />
          </button>
        </div>
        <div class="modal-body px-4" style="max-height: 500px; overflow: auto">
          <h5 class="modal-title font-22 mb-0 text-center mb-3">
            {{ meetingData.id ? "Update" : "Schedule" }} Meeting
          </h5>
          <Form ref="addForm" class="form-style floating-form" @submit="add(meetingData.id)">
            <div class="row mb-md-0 mb-4">
              <div class="col-md-12 mt-2">
                <div class="floating-form-group">
                  <Field type="text" id="title" name="title" :class="meetingData.title ? 'filled' : ''"
                    v-model="meetingData.title" rules="required:title" :validateOnInput="true" />
                  <label for="title">Meeting Title</label>
                  <span class="text-danger">*</span>
                </div>
                <ErrorMessage name="title" class="validation-msg" />
                <div class="floating-form-group with-icon">
                  <VueDatePicker v-model="meetingData.start_date" hideInputIcon :clearable="false"
                    :enableTimePicker="false" format="dd/MM/yyyy" placeholder="Select Date" name="date"></VueDatePicker>

                  <span class="text-danger">*</span>
                  <img src="/images/calendar.svg" width="20" class="icon" />
                </div>
                <ErrorMessage name="date" class="validation-msg" />
                <div class="floating-form-group with-icon">
                  <vue-google-autocomplete ref="address" id="map" :classname="meetingData.location
                    ? 'form-control filled'
                    : 'form-control'
                    " placeholder="" v-on:placechanged="getAddressData" country="in" types="">
                  </vue-google-autocomplete>
                  <label for="StartingPoint">Location</label>
                  <img src="/images/location-icon.svg" width="20" class="icon" />
                </div>
                <span class="validation-msg" v-show="isShowAddressErrorMessage">Please enter location</span>
                <ErrorMessage name="location" class="validation-msg" />
                <div class="floating-form-group with-icon">
                  <VueDatePicker v-model="meetingData.start_time" hideInputIcon :clearable="false" formate="hh:mm A"
                    timePicker placeholder="Select Start Time" class=" time-picker" />
                  <span class="text-danger">*</span>
                  <img src="/images/icons8-clock.svg" width="20" class="icon" />
                </div>
                <div class="floating-form-group with-icon time-picker">
                  <VueDatePicker v-model="meetingData.end_time" hideInputIcon :clearable="false" formate="hh:mm A"
                    timePicker placeholder="Select End Time" style="height: 30px important" />
                  <span class="text-danger">*</span>
                  <img src="/images/icons8-clock.svg" width="20" class="icon" />
                </div>
                <button id="save-button" class="btn btn-brand-1 w-100 mb-4 mt-2">
                  {{ meetingData.id ? "Update" : "Save" }}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </TextModal>
    <TextModal ref="momModal">
      <div class="modal-content">
        <form class="form-style floating-form">
          <Form @submit="submitMom">
            <div class="modal-header">
              <div class="row col-12">
                <h5 class="col-11" style="padding-left: 72px">
                  {{ meetingData.type }} MOM
                </h5>
                <a type="button" class="close col-1" v-on:click="$refs.momModal.closeModal()">
                  <img src="/images/cross.png" class="mb-lg-5 mb-4" />
                </a>
              </div>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <div class="floating-form-group">
                  <Field type="textarea" :disabled="meetingData.type == 'VIEW' ||
                    business_Detail?.leader?.id != meCall?.id
                    " :class="meetingData.mom_description ? 'filled' : ''" id="description" name="description"
                    v-model="meetingData.mom_description" rules="required:MOM" :validateOnInput="true"></Field>
                </div>
                <ErrorMessage name="description" class="validation-msg" />
              </div>

              <div class="custom-select-img" v-if="meetingData.type != 'VIEW'">
                <div class="file-upload">
                  <div class="image-upload-wrap p-3 mb-1">
                    <input v-if="business_Detail?.leader?.id == meCall?.id" type="file" name="img" id="pickFile"
                      class="file-upload-input" v-on:change="selectedFile($event)" accept="image/*" />

                    <div class="drag-text text-center">
                      <img :src="this.meetingData.mom_img &&
                        this.meetingData.mom_img != null
                        ? this.meetingData.mom_img
                        : '/images/Imageplace-holder.svg'
                        " id="preview" class="mb-2" width="60" height="50" />
                      <h3 v-if="business_Detail?.leader?.id == meCall?.id" for="pickFile" class="heading font-20">
                        Select Image
                      </h3>
                      <p v-if="business_Detail?.leader?.id == meCall?.id" class="font-12 text-secondary mb-1">
                        PNG, GIF, JPEG. (Max 10 mb)
                      </p>
                      <p v-if="business_Detail?.leader?.id == meCall?.id" class="font-12 text-secondary mb-0">
                        (Optional)
                      </p>
                    </div>
                  </div>
                </div>
                <div class="file-upload">
                  <div class="p-3 mb-1">
                    <input v-if="business_Detail?.leader?.id == meCall?.id" type="file" name="document"
                      class="file-upload-input" v-on:change="selectedPdfFile($event)"
                      style="width: 450px; height: 100px" />

                    <div class="drag-text text-center">
                      <img :src="this.meetingData.document &&
                        this.meetingData.document != null
                        ? this.meetingData.document
                        : '/images/upload-pdf.png'
                        " title="testPdf" height="130" width="120" id="pdfPreview" style="object-fit: cover;"
                        class="mb-3 rounded" />
                      <h3 v-if="business_Detail?.leader?.id == meCall?.id" for="pickFile" class="heading font-20">
                        Select PDF
                      </h3>
                      <p v-if="business_Detail?.leader?.id == meCall?.id" class="font-12 text-secondary mb-1">
                        PDF(Max 10 mb)
                      </p>
                      <p v-if="business_Detail?.leader?.id == meCall?.id" class="font-12 text-secondary mb-0">
                        (Optional)
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 text-center" v-else>
                <img :src="this.meetingData.mom_img
                  ? this.meetingData.mom_img
                  : '/images/Imageplace-holder.svg'
                  " style="object-fit: contain; width: 100%; height: 250px" />
              </div>

              <div class="text-center" v-if="meetingData.type != 'VIEW'">
                <button type="submit" id="submit-button" class="btn btn-brand-1 px-5">
                  Submit
                </button>
              </div>
            </div>
            <div class="row" v-if="meetingData.type == 'VIEW' && document">
              <div class="col-lg-4 mx-auto">
                <a :href="this.document" target="_blank" class="">
                  <button class="btn btn-lg" style="background-color: #9cc338; width: 100% !important">
                    View Pdf
                  </button>
                </a>
              </div>
            </div>
          </Form>
        </form>
      </div>
    </TextModal>
    <DeleteModal @remove="deleteRecord" ref="deleteModal" />
    <DeleteModal @remove="sendWhatsappMessage" ref="sendWhatsappMessageModal" />
  </div>
</template>
<style>
.time-picker .dp__instance_calendar {
  height: 105px !important;
}

.dp__overlay_container {
  min-height: 265px !important;
}

.dp__action {
  margin-left: 15px;
  cursor: pointer;
}

.dp__action_row {
  position: relative;
  z-index: 1;
}

.dp__time_input {
  height: 255px;
}
</style>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import TextModal from "@/components/TextModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
export default {
  components: {
    TextModal,
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
    VueDatePicker,
    VueGoogleAutocomplete,
    DeleteModal,
  },
  name: "MeetingList",
  data() {
    return {
      id: this.$route.params.id,
      offset: 0,
      loader: true,
      detail: {},
      business_Detail: {},
      meetingData: {
        latitude: "",
        longitude: "",
        start_date: null,
        start_time: null,
        end_time: null,
        type: "VIEW",
      },
      pdfData: {
        document: "",
      },
      document: "",
      meCall: {},
      obj: {
        group_id: "",
      },
      value5: null,
      isShowAddressErrorMessage: false,
      currentTab: "UPCOMING",
      type: "UPCOMING",
      meeting_id: "",
      file: "",
      pdf: "",
      member: {},
      owner: localStorage.getItem("has_leadership_position"),
    };
  },
  mounted() {
    this.getUserDetail();
    this.getMeetings(0);
    this.business_Detail = this.$sharedService.getMapValue("business_detail");
    if (!this.business_Detail) {
      this.getBusinessDetail();
    }

    localStorage.setItem("group_id", this.id);
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "UPCOMING") {
        this.type = "UPCOMING";
        this.getMeetings(0);
      } else if (tab == "PAST") {
        this.type = "PAST";
        this.getMeetings(0);
      }
    },
    addMeeting() {
      this.$refs.addForm.resetForm();
      this.meetingData = {};
      this.$refs.addMeeting.showModal();
    },
    getBusinessDetail() {
      this.$api
        .getAPI({
          _action: "business-work-group/" + this.id,
        })
        .then((res) => {
          this.business_Detail = res.info;
          // console.log("leader id", this.business_Detail);
        })
        .catch(() => { });
    },
    getAddressData: function (addressData, placeResultData) {
      this.meetingData.location = placeResultData.formatted_address;
      this.meetingData.latitude = addressData.latitude;
      this.meetingData.longitude = addressData.longitude;
    },
    add(id) {
      if (!document.getElementById("map").value) {
        this.isShowAddressErrorMessage = true;
        return;
      }
      this.meetingData.start_time = moment(this.meetingData.start_time).format(
        "HH:mm:ss"
      );
      this.meetingData.end_time = moment(this.meetingData.end_time).format(
        "HH:mm:ss"
      );
      if (id) {
        this.$api
          .putAPI({
            _action: "business-work-group/" + this.id + "/meeting/" + id,
            _body: this.meetingData,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (res) {
              this.$refs.addMeeting.closeModal();
            }
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.getMeetings(0);
          })
          .catch(() => { });
      } else {
        this.$api
          .postAPI({
            _action: "business-work-group/" + this.id + "/meeting",
            _body: this.meetingData,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (res) {
              this.$refs.addMeeting.closeModal();

              (this.meetingData = {}), this.getMeetings(0);
            }
          })
          .catch(() => { });
      }
    },
    getUserDetail() {
      this.$api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          this.meCall = res.response;
          // console.log("my group id ", this.meCall.id);
        })
        .catch(() => { });
    },
    getMeetings(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      var obj = {};
      if (offset == 0) {
        this.detail = [];
      }
      obj.offset = offset;
      obj.type = this.type;
      this.$api
        .getAPI({
          _action: "business-work-group/" + this.id + "/meetings",
          _body: obj,
        })
        .then((res) => {
          this.loader = false;

          this.offset = res.next_offset;
          for (var i in res.list) {
            res.list[i].short_start_date = moment(
              res.list[i].start_date
            ).format("DD MMM Y");
            res.list[i].start_time_12 = moment(
              res.list[i].start_time,
              "HH:mm:ss"
            ).format("hh:mm A");
            res.list[i].end_time_12 = moment(
              res.list[i].end_time,
              "HH:mm:ss"
            ).format("hh:mm A");

            res.list[i].start_time = {
              hours: moment(res.list[i].start_time, "HH:mm:ss").format("HH"),
              minutes: moment(res.list[i].start_time, "HH:mm:ss").format("mm"),
            };

            res.list[i].end_time = {
              hours: moment(res.list[i].end_time, "HH:mm:ss").format("HH"),
              minutes: moment(res.list[i].end_time, "HH:mm:ss").format("mm"),
            };

            this.detail.push(res.list[i]);
          }
          this.offset = res.next_offset;
          if (this.offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No meeting available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    getMeetingData(data) {
      this.$refs.addMeeting.showModal();

      var temp = data;
      if (this.business_Detail?.leader?.id == this.meCall?.id) {
        for (var i in this.detail) {
          if (temp.id == this.detail[i].id) {
            temp.start_date = this.detail[i].start_date;
          }
        }

        this.meetingData = temp;

        var $this = this;
        setTimeout(() => {
          document.getElementById("map").value = $this.meetingData.location;
        }, 500);
      }
    },
    showLocation(detail) {

      var url =
        "https://maps.google.com/?q=" +
        detail.latitude +
        "," +
        detail.longitude;
      window.open(url);
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        "Delete Meeting",
        "Are you sure you want to delete",
        id
      );
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "business-work-group/" + this.id + "/meeting/" + id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getMeetings(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    getMom(data) {
      this.document = data.document;
      if (data.mom_img) {
        this.meetingData.mom_img = data.mom_img;
      } else {
        this.meetingData.mom_img == "";
      }
      this.meetingData.mom_description = data.mom_description;
      this.meeting_id = data.id;
      this.meetingData.type = "VIEW";
      this.$refs.momModal.showModal();
    },
    showMom(data, type) {
      this.meetingData = data;
      this.meetingData.type = type;
      this.meeting_id = data.id;
      if (data.mom_img) {
        this.meetingData.mom_img = data.mom_img;
      } else {
        this.meetingData.mom_img == "";
      }
      if (data.document) {
        this.meetingData.document = data.document;
      } else {
        this.meetingData.document == "";
      }
      this.file = null;
      this.pdfData.document = null;
      this.$refs.momModal.showModal();
    },
    submitMom() {
      var obj = {};
      obj.mom_description = this.meetingData.mom_description;
      this.$api
        .postAPI({
          _action: "business-work-group/" + this.meeting_id + "/mom",
          _body: obj,
        })
        .then((res) => {
          this.$refs.momModal.closeModal();
          this.uploadImage(this.meeting_id);
          this.saveDocument(this.meeting_id);
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => { });
    },

    saveDocument(id) {
      if (!this.pdfData.document) {
        this.$refs.momModal.closeModal();
        this.getMeetings(0);
        return;
      } else {
        this.$api
          .postAPI({
            _action: "business-work-group/" + id + "/document",
            _body: this.pdfData.document,
          })
          .then((res) => {
            this.$refs.momModal.closeModal();
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => { });
      }
    },
    selectedPdfFile(e) {
      let files = e.target.files;
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      // var tmppath = URL.createObjectURL(files[0]);
      this.pdfData.document = files[0];
      this.meetingData.document = URL.createObjectURL(files[0]);

      document.getElementById("pdfPreview").src;
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }

      console.log("this is file", files);
      // var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      this.meetingData.mom_img = URL.createObjectURL(files[0]);
      document.getElementById("preview").src;
    },
    uploadImage(id) {
      if (!this.file) {
        this.$refs.momModal.closeModal();
        this.getMeetings(0);
        return;
      } else {
        this.$api
          .uploadImageAPI({
            _action: "business-work-group/" + id + "/upload",
            _key: "image",
            _file: this.file,
          })
          .then(() => {
            this.$refs.momModal.closeModal();
            this.getMeetings(0);
          })
          .catch(() => {
            return;
          });
      }
    },

    showWhatsappModal(id) {
      this.$refs.sendWhatsappMessageModal.showModal(
        "Are you sure?",
        "You want to send WhatsApp message to users ?",
        id
      );
    },


    sendWhatsappMessage(uuid) {
      this.$api
        .postAPI({
          _action: "business-work-group/" + this.id + "/meeting/" + uuid + '/send-message',
          _buttonId: "webinar-whtp",
          _body: {
          },
        })
        .then((res) => {
          this.$noty.success(res.message);
          this.getMeetings(0);
        })
        .catch(() => { });
    }
  },
};
</script>
