<template>
  <div>
    <OuterHeader />
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid h-100">
          <div class="row justify-content-md-center align-items-center h-100 bg-white">
            <div class="col-md-6 bg-white">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <div class="px-lg-5 px-0 py-3">
                    <div class="card-header bg-white rounded-10 text-center border-0">
                      <img
                        src="/images/tenx-logo.svg"
                        width="110"
                        height="35"
                        class="img-fluid mb-3"
                      />
                      <p class="mb-lg-5 mb-4 font-14">
                        <em>Igniting The Spirit Of Entrepreneurship Across Bharat</em>
                      </p>
                    </div>
                    <div class="card-body pt-1">
                      <Form @submit="login" class="form-style floating-form">
                        <div class="floating-form-error mb-3">
                          <div class="floating-form-group">
                            <Field
                              type="email"
                              id="email"
                              name="Username"
                              v-model="details.email"
                              rules="required|email"
                              :validateOnInput="true"
                            />
                            <label for="email">Email</label>
                          </div>
                          <ErrorMessage name="Username" class="validation-msg" />
                        </div>
                        <div class="floating-form-error mb-3">
                          <div class="floating-form-group">
                            <Field
                              :type="passwordFieldType"
                              id="password"
                              name="Password"
                              v-model="details.password"
                              rules="required"
                              :validateOnInput="true"
                            />
                            <label for="password">Password</label>
                            <span>
                              <a class="psw-icon" v-on:click="switchVisibility"
                                ><img :src="image" width="20"
                              /></a>
                            </span>
                          </div>
                          <ErrorMessage name="Password" class="validation-msg" />
                        </div>
                        <div class="form-check pl-0 mb-4 overflow-auto pr-3">
                          <a
                            class="text-dark float-right"
                            style="cursor: pointer"
                            v-on:click="$router.push('/forgot-password')"
                            >Forgot Password?</a
                          >
                        </div>
                        <button
                          class="btn btn-brand-1 btn-block mb-3"
                          id="login-button"
                          style="cursor: pointer"
                        >
                          Log in
                        </button>
                      </Form>
                    </div>
                    <div class="card-footer text-center bg-white border-0 pt-0">
                      <div class="links mb-5">
                        Don’t have an account?
                        <a
                          class="ml-2 text-primary font-medium"
                          style="cursor: pointer"
                          v-on:click="$router.push('/signup')"
                        >
                          Sign Up</a
                        >
                      </div>
                      <div class="d-flex align-items-center mb-3">
                        <hr class="my-0 border-secondary w-25" />
                        <p class="font-medium mb-2">Vision Statement</p>
                        <hr class="my-0 border-secondary w-25" />
                      </div>

                      <p class="font-14">
                        <em
                          >Contributing to the growth of Indian GDP by igniting ‘The
                          Spirit Of Entrepreneurship’ across the nation.</em
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 login-img-col" style="min-height: 92vh"></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<style scoped>
.floating-form .floating-form-group input:-webkit-autofill ~ label {
  font-size: 14px;
  position: absolute;
  padding: 5px 15px;
  line-height: 20px;
}
</style>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import OuterHeader from "@/components/OuterHeader";
import $ from "jquery";
export default {
  name: "WebLogin",
  components: {
    Form,
    Field,
    ErrorMessage,
    OuterHeader,
  },
  data() {
    return {
      passwordFieldType: "password",
      image: "/images/show.png",
      details: {
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    //floating form
    localStorage.clear();
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
  },
  methods: {
    login() {
      this.$api
        .postAPI({
          _action: "login",
          _body: this.details,
          _buttonId: "login-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("access_token", res.access_token);
            this.getMe();
          }
        })
        .catch(() => {});
    },
    getMe() {
      this.$api
        .getAPI({
          _action: "me",
          _buttonId: "login-button",
        })
        .then((res) => {
          localStorage.setItem(
            "has_leadership_position",
            res.response.has_leadership_position
          );
          if (
            res &&
            res.response &&
            res.response.companies &&
            res.response.companies.length > 0
          ) {
            this.$store.commit("setUser", res.response);
            localStorage.setItem("companyId", res.response.companies[0].id);
            localStorage.setItem("companyUuid", res.response.companies[0].uuid);
            if (res.response.user_subscription) {
              localStorage.setItem("subscriptionId", res.response.user_subscription.id);
            }

            localStorage.setItem("user_type", res.response.user_type);
          } else {
            localStorage.removeItem("access_token");
            if (res.response.user_type == "USER") {
              this.$router.push("/home");
            }
            return;
          }

          console.log("from login--up" + res.response.user_type);
          localStorage.setItem("userId", res.response.id);
          this.$router.push("/home");
        })
        .catch(() => {
          localStorage.removeItem("access_token");
        });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
  },
};
</script>
