<template>
  <aside class="left-sidebar" id="mob_sidebar">
    <div class="scroll-sidebar">
      <div class="logo py-lg-4 py-3 d-lg-flex d-none justify-content-center">
        <img src="/images/tenx-main.svg" width="90" alt="Logo" class="img-fluid mb-1 left-menu-logo" />
        <a href="javascript:void(0)" class="d-md-none d-block" id="menu-toggle"><img src="/images/hamburger-menu.svg"
            width="30" class="hamburger-menu" /></a>
      </div>
      <nav class="sidebar-nav">
        <div class="row align-items-center pb-2 px-2 mb-2 mx-0 cursor-pointer" v-on:click="$router.push('/my-profile')">
          <img :src="this.detail.profile_img ? this.detail.profile_img : '/images/profile.svg'
            " width="45" height="45" class="rounded-circle object-cover mr-2" />
          {{ detail.first_name }} {{ detail.last_name }}
        </div>
        <div class="row align-items-center pb-2 px-2 mb-2 mx-0">
          <ul class="navbar-nav">
            <li class="nav-item dropdown notification-dd" id="dropdown-id">
              <a class="nav-link dropdown-toggle waves-effect waves-dark mr-md-3 cursor-pointer" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" style="color: black">
                <img src="/images/down-arrow.svg" width="20" height="20" class="rounded-circle object-cover mr-2" />
                {{ this.companyName }}
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" style="
                  width: 245px;
                  position: absolute;
                  transform: translate3d(-205px, 48px, 0px);
                  top: 0px;
                  left: 0px;
                  will-change: transform;
                " id="dropdown-menu-id">
                <a class="dropdown-item cursor-pointer" v-on:click="storeCompany(data)" v-for="(data, index) in companies"
                  :key="index">
                  <div class="">
                    <p class="font-14 mb-2">
                      {{ data.company_name }}
                    </p>
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </div>
        <ul v-if="this.user_type == 'USER'" class="nav-bar pl-1" id="leftNav">
          <li class="nav-item">
            <a class="nav-link" id="home" :class="{ active: pageName.includes('home') }" style="cursor: pointer"
              v-on:click="$router.push('/home')"><img src="/images/home-icon.svg" width="20" class="side-nav-icon" /><span
                class="hide-menu">Home</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="myRequirements" :class="{ active: pageName.includes('Update Product Service List') }"
              style="cursor: pointer" v-on:click="$router.push('/product-service-list/PRODUCT/update')"><img
                src="/images/requirements.svg" width="20" class="side-nav-icon" /><span class="menu-title">Product /
                Service</span>
              <!--<span
                class="ml-auto lock-icon"
                v-show="this.subscription.my_prospects_lead_support == 'N'"
                ><img
                  src="/images/lock.svg"
                  width="15"
                  class="float-right lock-icon"
              /></span>-->
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="myRequirements" :class="{ active: pageName.includes('my-requirements') }"
              style="cursor: pointer" v-on:click="$router.push('/my-requirements')"><img src="/images/requirements.svg"
                width="20" class="side-nav-icon" /><span class="menu-title">My Requirements</span>
              <!--<span
                class="ml-auto lock-icon"
                v-show="this.subscription.my_prospects_lead_support == 'N'"
                ><img
                  src="/images/lock.svg"
                  width="15"
                  class="float-right lock-icon"
              /></span>-->
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="myLeads" :class="{ active: pageName.includes('my-leads') }" style="cursor: pointer"
              v-on:click="$router.push('/my-leads')"><img src="/images/leads.svg" width="20" class="side-nav-icon" /><span
                class="menu-title">
                My Leads
              </span>
              <!--<span
                class="ml-auto lock-icon"
                v-show="this.subscription.my_prospects_lead_support == 'N'"
                ><img
                  src="/images/lock.svg"
                  width="15"
                  class="float-right lock-icon"
              /></span>-->
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="exibition" :class="{ active: pageName.includes('offers') }" style="cursor: pointer"
              v-on:click="$router.push('/offers')">
              <img src="/images/loylity.svg" class="side-nav-icon" />
              <span>Offers</span>
            </a>
          </li>
        </ul>
        <ul v-if="this.user_type == 'OWNER'" class="nav-bar pl-1" id="leftNav">
          <li class="nav-item">
            <a class="nav-link" id="home" :class="{ active: pageName.includes('home') }" style="cursor: pointer"
              v-on:click="$router.push('/home')"><img src="/images/home-icon.svg" width="20" class="side-nav-icon" /><span
                class="hide-menu">Home</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="businessConclave" :class="{ active: pageName.includes('business-conclave') }"
              style="cursor: pointer" v-on:click="$router.push('/business-conclave')"><img src="/images/collaboration.svg"
                width="20" class="side-nav-icon" /><span class="hide-menu">TenX Business Conclave (TBC) Offline</span></a>
          </li>
          <li v-if="this.subscription.online_seminar == 'Y'" class="nav-item">
            <a class="nav-link" id="event" :class="{ active: pageName.includes('webinar') }" style="cursor: pointer"
              v-on:click="$router.push('/webinar')"><img src="/images/calendar-tick.svg" width="20"
                class="side-nav-icon" /><span class="menu-title">Webinar</span>
            </a>
          </li>
          <li v-if="this.subscription.online_seminar == 'N'" class="nav-item"
            :class="this.subscription.online_seminar == 'N' ? 'lock-nav-item' : ''">
            <a class="nav-link" id="event" :class="{ active: pageName.includes('webinar') }" v-on:click="
              $router.push('/select-plan/upgrade?attribute=webinar&&redirect=webinar')
              " style="cursor: pointer">
              <img src="/images/calendar-tick.svg" width="20" class="side-nav-icon" />
              <span :class="this.subscription.online_seminar == 'N' ? 'menu-title' : 'hide-menu'
                ">Webinar<span class="float-right lock-icon" v-show="this.subscription.online_seminar == 'N'"><svg
                    xmlns="http://www.w3.org/2000/svg" width="21" height="22" style="fill: #545454; transform: msfilter">
                    <path
                      d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z">
                    </path>
                  </svg></span></span>
            </a>
          </li>
          <li v-if="this.subscription.business_growth_group == 'Y'" class="nav-item">
            <a class="nav-link" id="businessGrowthGroup" :class="{ active: pageName.includes('business-growth') }"
              style="cursor: pointer" v-on:click="businessGroup()"><img src="/images/group.svg" width="20"
                class="side-nav-icon" /><span class="menu-title">TenX Growth Groups (TGG)</span>
            </a>
          </li>
          <li v-if="this.subscription.business_growth_group == 'N'" class="nav-item"
            :class="this.subscription.business_growth_group == 'N' ? 'lock-nav-item' : ''">
            <a class="nav-link" id="businessGrowthGroup" :class="{ active: pageName.includes('business-growth') }"
              v-on:click="
                $router.push(
                  '/select-plan/upgrade?attribute=business_growth_group&&redirect=business-growth-group'
                )
                " style="cursor: pointer">
              <img src="/images/group.svg" width="20" class="side-nav-icon" />
              <span :class="this.subscription.business_growth_group == 'N'
                ? 'menu-title'
                : 'hide-menu'
                ">TenX Growth Groups (TGG)<span class="float-right lock-icon"
                  v-show="this.subscription.business_growth_group == 'N'"><svg xmlns="http://www.w3.org/2000/svg"
                    width="21" height="22" style="fill: #545454; transform: msfilter">
                    <path
                      d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z">
                    </path>
                  </svg></span></span>
              <!-- <img
                src="/images/lock.svg"
                width="15"
                class="float-right lock-icon"
              /> -->
            </a>
          </li>
          <li v-if="this.subscription.my_prospects_lead_support == 'Y'" class="nav-item">
            <a class="nav-link" id="myLeads" :class="{ active: pageName.includes('my-leads') }" style="cursor: pointer"
              v-on:click="$router.push('/my-leads')"><img src="/images/leads.svg" width="20" class="side-nav-icon" /><span
                class="menu-title">
                My Leads
              </span>
              <!--<span
                class="ml-auto lock-icon"
                v-show="this.subscription.my_prospects_lead_support == 'N'"
                ><img
                  src="/images/lock.svg"
                  width="15"
                  class="float-right lock-icon"
              /></span>-->
            </a>
          </li>
          <li v-if="this.subscription.my_prospects_lead_support == 'N'" class="nav-item" :class="this.subscription.my_prospects_lead_support == 'N' ? 'lock-nav-item' : ''
            ">
            <a class="nav-link" id="myLeads" :class="{ active: pageName.includes('my-leads') }" style="cursor: pointer"
              v-on:click="
                $router.push(
                  '/select-plan/upgrade?attribute=my_prospects_lead_support&&redirect=my-leads'
                )
                "><img src="/images/leads.svg" width="20" class="side-nav-icon" /><span :class="this.subscription.my_prospects_lead_support == 'N'
    ? 'menu-title'
    : 'hide-menu'
    ">
                My Leads
                <span class="float-right lock-icon" v-show="this.subscription.my_prospects_lead_support == 'N'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22"
                    style="fill: #545454; transform: msfilter">
                    <path
                      d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z">
                    </path>
                  </svg> </span></span>
              <!--<span
                class="ml-auto lock-icon"
                v-show="this.subscription.my_prospects_lead_support == 'N'"
                ><img
                  src="/images/lock.svg"
                  width="15"
                  class="float-right lock-icon"
              /></span>-->
            </a>
          </li>
          <li v-if="this.subscription.my_prospects_lead_support == 'Y'" class="nav-item">
            <a class="nav-link" id="myRequirements" :class="{ active: pageName.includes('my-requirements') }"
              style="cursor: pointer" v-on:click="$router.push('/my-requirements')"><img src="/images/requirements.svg"
                width="20" class="side-nav-icon" /><span class="menu-title">My Requirements</span>
              <!--<span
                class="ml-auto lock-icon"
                v-show="this.subscription.my_prospects_lead_support == 'N'"
                ><img
                  src="/images/lock.svg"
                  width="15"
                  class="float-right lock-icon"
              /></span>-->
            </a>
          </li>
          <li v-if="this.subscription.my_prospects_lead_support == 'N'" class="nav-item" :class="this.subscription.my_prospects_lead_support == 'N' ? 'lock-nav-item' : ''
            ">
            <a class="nav-link" id="myRequirements" :class="{ active: pageName.includes('my-requirements') }"
              style="cursor: pointer" v-on:click="
                $router.push(
                  '/select-plan/upgrade?attribute=my_prospects_lead_support&&redirect=my-requirements'
                )
                "><img src="/images/requirements.svg" width="20" class="side-nav-icon" /><span :class="this.subscription.my_prospects_lead_support == 'N'
    ? 'menu-title'
    : 'hide-menu'
    ">My Requirements<span class="float-right lock-icon"
                  v-show="this.subscription.my_prospects_lead_support == 'N'"><svg xmlns="http://www.w3.org/2000/svg"
                    width="21" height="22" style="fill: #545454; transform: msfilter">
                    <path
                      d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z">
                    </path>
                  </svg></span></span>
              <!--<span
                class="ml-auto lock-icon"
                v-show="this.subscription.my_prospects_lead_support == 'N'"
                ><img
                  src="/images/lock.svg"
                  width="15"
                  class="float-right lock-icon"
              /></span>-->
            </a>
          </li>
          <li class="nav-item" :class="this.subscription.membership_loyalty == 'N' ? 'lock-nav-item' : ''">
            <a class="nav-link" id="exibition" :class="{ active: pageName.includes('offers') }" style="cursor: pointer"
              v-on:click="$router.push('/offers')">
              <img src="/images/loylity.svg" class="side-nav-icon" />
              <span :class="this.subscription.membership_loyalty == 'N' ? 'menu-title' : 'hide-menu'
                ">Offers<span class="float-right lock-icon" v-show="this.subscription.membership_loyalty == 'N'"><svg
                    xmlns="http://www.w3.org/2000/svg" width="21" height="22"
                    style="fill: #545454; transform: ; msfilter: ">
                    <path
                      d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z">
                    </path>
                  </svg></span></span>
            </a>
          </li>
          <li v-if="this.subscription.access_experts == 'Y'" class="nav-item">
            <a class="nav-link" id="experts" :class="{ active: pageName.includes('expert') }" style="cursor: pointer"
              v-on:click="$router.push('/expert-category')"><img src="/images/find-experts.svg" width="20"
                class="side-nav-icon" /><span class="menu-title">TenX Verified Experts</span>
            </a>
          </li>
          <li v-if="this.subscription.access_experts == 'N'" class="nav-item"
            :class="this.subscription.access_experts == 'N' ? 'lock-nav-item' : ''">
            <a class="nav-link" id="experts" :class="{ active: pageName.includes('expert') }" v-on:click="
              $router.push(
                '/select-plan/upgrade?attribute=expert-category&&redirect=expert-category'
              )
              " style="cursor: pointer">
              <img src="/images/group.svg" width="20" class="side-nav-icon" />
              <span :class="this.subscription.access_experts == 'N' ? 'menu-title' : 'hide-menu'
                ">TenX Verified Experts<span class="float-right lock-icon"
                  v-show="this.subscription.access_experts == 'N'"><svg xmlns="http://www.w3.org/2000/svg" width="21"
                    height="22" style="fill: #545454; transform: msfilter">
                    <path
                      d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z">
                    </path>
                  </svg></span></span>
              <!-- <img
                src="/images/lock.svg"
                width="15"
                class="float-right lock-icon"
              /> -->
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="health&WellBeing" :class="{ active: pageName.includes('health-well-being') }"
              v-on:click="$router.push('/health-well-being')" style="cursor: pointer"><img src="/images/health.svg"
                width="20" class="side-nav-icon" /><span class="hide-menu">Health & Well Being</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="exibition" :class="{ active: pageName.includes('exibition') }"
              v-on:click="$router.push('/exibition')" style="cursor: pointer">
              <img src="/images/group.svg" class="side-nav-icon" />
              <span class="menu-title">Exhibition</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="companies" :class="{ active: pageName.includes('companies') }"
              v-on:click="$router.push('/find-companies')" style="cursor: pointer"><img src="/images/companies.svg"
                width="20" class="side-nav-icon" /><span class="hide-menu">Search Companies</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="manage-user" :class="{ active: pageName.includes('manage-user') }"
              v-on:click="$router.push('/manage-user')" style="cursor: pointer"><img src="/images/Vector.svg" width="20"
                class="side-nav-icon" /><span class="hide-menu">Manage Users</span></a>
          </li>

          <!-- <li
            class="nav-item"
            :class="this.online_seminar == 'N' ? 'lock-nav-item' : ''"
          >
            <a
              class="nav-link"
              id="event"
              :class="{ active: pageName.includes('webinar') }"
              style="cursor: pointer"
              v-on:click="showCategory('webinar')"
              ><img
                src="/images/calendar-tick.svg"
                width="20"
                class="side-nav-icon" /><span
                :class="
                  this.subscription.online_seminar == 'N'
                    ? 'menu-title'
                    : 'hide-menu'
                "
                >Webinar
                <span
                  class="float-right lock-icon"
                  v-show="this.subscription.online_seminar == 'N'"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="22"
                    style="fill: #545454; transform: ; msfilter: "
                  >
                    <path
                      d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z"
                    ></path></svg></span></span
            ></a>
          </li> -->

          <!-- <li
            class="nav-item"
            :class="
              this.subscription.access_experts == 'N' ? 'lock-nav-item' : ''
            "
          >
            <a
              class="nav-link"
              id="experts"
              :class="{ active: pageName.includes('expert') }"
              v-on:click="$router.push('/expert-category')"
              style="cursor: pointer"
              ><img
                src="/images/find-experts.svg"
                width="20"
                class="side-nav-icon"
              /><span
                :class="
                  this.subscription.access_experts == 'N'
                    ? 'menu-title'
                    : 'hide-menu'
                "
              >
                TenX Verified Experts</span
              >
              <span
                class="float-right lock-icon"
                v-show="this.subscription.access_experts == 'N'"
              >
                <img
                  src="/images/lock.svg"
                  width="15"
                  class="float-right lock-icon"
                />
              </span>
            </a>
          </li> -->

          <li v-if="this.subscription.collaboration_opportunities == 'Y'" class="nav-item">
            <a class="nav-link" id="collaboration" :class="{ active: pageName.includes('collaboration') }"
              style="cursor: pointer" v-on:click="$router.push('/collaboration')"><img src="/images/collab.svg" width="20"
                class="side-nav-icon" /><span class="menu-title">Collaboration</span>
            </a>
          </li>
          <li v-if="this.subscription.collaboration_opportunities == 'N'" class="nav-item" :class="this.subscription.collaboration_opportunities == 'N' ? 'lock-nav-item' : ''
            ">
            <a class="nav-link" id="collaboration" :class="{ active: pageName.includes('collaboration') }" v-on:click="
              $router.push(
                '/select-plan/upgrade?attribute=collaboration&&redirect=collaboration'
              )
              " style="cursor: pointer">
              <img src="/images/collab.svg" width="20" class="side-nav-icon" />
              <span :class="this.subscription.collaboration_opportunities == 'N'
                ? 'menu-title'
                : 'hide-menu'
                ">Collaboration<span class="float-right lock-icon"
                  v-show="this.subscription.collaboration_opportunities == 'N'"><svg xmlns="http://www.w3.org/2000/svg"
                    width="21" height="22" style="fill: #545454; transform: msfilter">
                    <path
                      d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z">
                    </path>
                  </svg></span></span>
              <!-- <img
                src="/images/lock.svg"
                width="15"
                class="float-right lock-icon"
              /> -->
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="home" :class="{ active: pageName.includes('refer-friend') }" style="cursor: pointer"
              v-on:click="$router.push('/refer-friend')"><img src="/images/refer_a_friend.svg" width="20"
                class="side-nav-icon" /><span class="hide-menu">Refer A Friend</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="home" :class="{ active: pageName.includes('e-magazine') }" style="cursor: pointer"
              v-on:click="$router.push('/e-magazine')"><img src="/img/e_magazine.svg" width="20"
                class="side-nav-icon" /><span class="hide-menu">e-Magazines</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="home" :class="{ active: pageName.includes('about-tenx') }" style="cursor: pointer"
              v-on:click="$router.push('/about-tenx')"><img src="/images/about-x.svg" width="20"
                class="side-nav-icon" /><span class="hide-menu">About TenX</span></a>
          </li>
          <!-- <li
            class="nav-item"
            :class="
              this.subscription.collaboration_opportunities == 'N'
                ? 'lock-nav-item'
                : ''
            "
          >
            <a
              class="nav-link"
              id="collaboration"
              :class="{ active: pageName.includes('collaboration') }"
              v-on:click="$router.push('/collaboration')"
              style="cursor: pointer"
              ><img
                src="/images/group.svg"
                width="20"
                class="side-nav-icon" /><span
                :class="
                  this.subscription.collaboration_opportunities == 'N'
                    ? 'menu-title'
                    : 'hide-menu'
                "
                >Collaboration<span
                  class="float-right lock-icon"
                  v-show="this.subscription.collaboration_opportunities == 'N'"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="22"
                    style="fill: #545454; transform: ; msfilter: "
                  >
                    <path
                      d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z"
                    ></path></svg></span></span
            ></a>
          </li> -->

          <!-- <li class="nav-item lock-nav-item">
            <a class="nav-link" id="">
              <img src="/images/loylity.svg" class="side-nav-icon" />
              <span class="menu-title"
                >Prime Feature
                <span class="float-right lock-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="22"
                    style="fill: #545454; transform: ; msfilter: "
                  >
                    <path
                      d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z"
                    ></path>
                  </svg>
                </span>
              </span>
            </a>
          </li> -->
        </ul>
      </nav>
      <TextModal ref="intrestModal">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" v-on:click="$refs.intrestModal.closeModal">
              <img src="/images/close.svg" width="15" />
            </button>
          </div>
          <div class="modal-body form-style pb-0 text-center pt-2">
            <h4 class="font-22">Select your interest</h4>
            <p class="font-14 text-secondary">Please select below</p>
            <div class="text-center interest-tags mt-4">
              <button type="button" class="btn btn-outline-rounded-color mr-2 mb-2" v-for="(data, index) in interestList"
                :key="index" :value="data.id" v-on:click="checked(data)" :class="data.isChecked == true ? 'active' : ''">
                {{ data.name }}
              </button>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button type="button" class="btn btn-outline-brand-1 px-4" v-on:click="showActivity">
              Show Activities
            </button>
          </div>
        </div>
      </TextModal>

      <TextModal ref="activityModal">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" v-on:click="$refs.activityModal.closeModal">
              <img src="/images/close.svg" width="15" />
            </button>
          </div>
          <div class="modal-body form-style pb-0 text-center pt-2">
            <h4 class="font-22">Select your activity</h4>
            <p class="font-14 text-secondary">Please select below</p>
            <div class="text-center interest-tags mt-4">
              <button type="button" class="btn btn-outline-rounded-color mr-2 mb-2" v-for="(data, index) in activityList"
                :key="index" :value="data.id" v-on:click="checked(data)" :class="data.isChecked == true ? 'active' : ''">
                {{ data.name }}
              </button>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button type="button" class="btn btn-outline-brand-1 px-4" v-on:click="showActivityPopup">
              Show Activities
            </button>
          </div>
        </div>
      </TextModal>
    </div>
  </aside>
</template>
<script>
import TextModal from "@/components/TextModal.vue";

export default {
  name: "MyMenu",
  components: {
    TextModal,
  },
  data() {
    return {
      activityList: [],
      interestList: [],
      pageName: "",
      details: {},
      meCall: {},
      clickEvent: "",
      subscription: {},
      companyName: "",
      company_id: localStorage.getItem("companyId"),
      companies: [],
      user_type: "",
    };
  },
  watch: {
    "$route.fullPath"(newValue) {
      this.pageName = newValue.replace("/", "");
    },
  },
  computed: {
    detail() {
      return this.$store.getters.getUser ? this.$store.getters.getUser : {};
    },
    // detail() {
    //   return this.$store.getters.getSummary
    //     ? this.$store.getters.getSummary
    //     : { company: []};
    // },
  },
  mounted() {
    this.pageName = this.$route.fullPath.replace("/", "");
    this.getMyProfile(false);
    this.getCompanyName();
  },
  methods: {
    checked(data) {
      data.isChecked = !data.isChecked;
    },
    getInterestList() {
      this.$api
        .getAPI({
          _action: "category/event",
        })
        .then((res) => {
          for (var i in res.list) {
            var isChecked = false;
            for (var j in this.detail.interests) {
              if (this.detail.interests[j].interest_id == res.list[i].id) {
                isChecked = true;
              }
            }

            res.list[i].isChecked = isChecked;
          }
          this.interestList = res.list;
        })
        .catch(() => { });
    },
    getActivityList() {
      this.$api
        .getAPI({
          _action: "category/activity",
        })
        .then((res) => {
          for (var i in res.list) {
            var isChecked = false;
            for (var j in this.detail.interested_activity) {
              if (this.detail.interested_activity[j].interest_id == res.list[i].id) {
                isChecked = true;
              }
            }

            res.list[i].isChecked = isChecked;
          }
          this.activityList = res.list;
        })
        .catch(() => { });
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    businessGroup() {
      this.getMyProfile(true);
    },

    getMyProfile(flag) {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.meCall = res.response;
          if (flag) {
            if (this.meCall && this.meCall.group_info && this.meCall.group_info.id) {
              this.$router.push("/business-growth-detail/" + this.meCall.group_info.id);
            } else {
              this.$router.push("/business-growth-group");
            }
          }
          this.$store.commit("setUser", res.response);
          localStorage.setItem("refer_code", res.response.referral_code);
          this.subscription = res.response?.user_subscription;
          this.companies = res.response?.companies;
          this.user_type = res.response.user_type;
          this.getActivityList();
          this.getInterestList();
        })
        .catch(() => { });
    },
    getCompanyName() {
      this.$api
        .getAPI({
          _action: "company/" + this.company_id,
        })
        .then((res) => {
          this.companyName = res.response?.company_name;
        })
        .catch(() => { });
    },
    showCategory(page) {
      this.clickEvent = page;

      var tempData = this.$sharedService.getMapValue("categories");
      if (tempData != null) {
        for (var i in this.interestList) {
          this.interestList[i].isChecked = false;
          for (var j in tempData) {
            if (tempData[j].id == this.interestList[i].id) {
              this.interestList[i].isChecked = true;
            }
          }
        }
      }

      var isChecked = false;
      for (var k in this.interestList) {
        if (this.interestList[k].isChecked == true) {
          isChecked = true;
        }
      }

      if (isChecked == false) {
        this.$refs.intrestModal.showModal();
      } else {
        this.showActivity();
      }
    },
    showCategoryPopup(page) {
      this.clickEvent = page;

      var tempData = this.$sharedService.getMapValue("activities");
      if (tempData != null) {
        for (var i in this.activityList) {
          this.activityList[i].isChecked = false;
          for (var j in tempData) {
            if (tempData[j].id == this.activityList[i].id) {
              this.activityList[i].isChecked = true;
            }
          }
        }
      }
      var isChecked = false;
      for (var k in this.activityList) {
        if (this.activityList[k].isChecked == true) {
          isChecked = true;
        }
      }

      if (isChecked == false) {
        this.$refs.activityModal.showModal();
      } else {
        this.showActivity();
      }
    },
    storeCompany(obj) {
      localStorage.setItem("companyId", obj.id);
      this.companyName = obj.company_name;
      location.reload();
    },
    showActivity() {
      this.$sharedService.setMapValue("categories", this.interestList);
      this.$refs.intrestModal.closeModal();
      this.$router.push("/" + this.clickEvent);
    },
    showActivityPopup() {
      this.$sharedService.setMapValue("activities", this.activityList);
      this.$refs.activityModal.closeModal();
      this.$router.push("/" + this.clickEvent);
    },
  },
};
</script>
