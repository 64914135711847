<template>
  <div>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center">
            <div class="card-body pb-0">
              <div class="row min-h-auto mb-md-0 mb-4" style="min-height: 86vh">
                <div class="col-12" style="max-height: 100%">
                  <div class="row col-12 mb-2">
                    <div class="col-md-6 text-md-left text-center">
                      <h2 class="page-title mb-md-4">
                        <a class="cursor-pointer" v-on:click="$router.go(-1)">
                          <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>
                        Members
                      </h2>
                    </div>
                    <div class="col-md-6 p-0 text-md-right text-center">
                      <button type="button" class="btn btn-brand-1 w-auto" style="cursor: pointer" v-on:click="invite()"
                        v-show="this.leaderId == this.myId">
                        Invite
                      </button>
                    </div>
                  </div>
                  <ul v-show="this.leaderId == this.myId" class="nav nav-pills mb-3 tab-style-1 w-50 mx-auto"
                    id="login-tab" role="tablist">
                    <li class="nav-item text-center w-50" role="presentation">
                      <a class="nav-link" id="serv_tab" :class="{ active: currentTab == 'ACCEPTED' }"
                        v-on:click="changeTab('ACCEPTED')" style="cursor: pointer">Accepted
                      </a>
                    </li>
                    <li class="nav-item text-center w-50" role="presentation">
                      <a class="nav-link" id="prod_tab" :class="{ active: currentTab == 'PENDING' }"
                        v-on:click="changeTab('PENDING')" style="cursor: pointer">Pending
                      </a>
                    </li>
                  </ul>
                  <div v-show="currentTab == 'PENDING'" class="form-style pt-md-4 mb-3 card card-body bg-white"
                    id="login-tabContent ">
                    <div id="tab_1" aria-labelledby=" " style="max-height: 400px">
                      <table class="table table-style-1">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name</th>
                            <th class="text-right">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="border-bottom" v-for="(data, index) in pendingList" :key="index">
                            <td width="45" class="p-2">
                              <img :src="data?.member?.profile_img
                                ? data?.member?.profile_img
                                : 'images/placeholder-image.jpg'
                                " width="45" alt="" />
                            </td>
                            <td class="font-medium align-middle p-2">
                              {{ data?.member?.name }}
                            </td>

                            <td class="p-2 text-right">
                              <button type="button" v-on:click="acceptAction(data)" class="btn btn-action w-auto mr-3"
                                style="cursor: pointer">
                                <i class="fa fa-check-square-o mr-2 text-success" aria-hidden="true"></i>
                                Accept
                              </button>

                              <button type="button" v-on:click="rejectAction(data)" class="btn btn-action w-auto"
                                style="cursor: pointer">
                                <i class="fa fa-times mr-2 text-danger" aria-hidden="true"></i>
                                Reject
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div v-show="currentTab == 'ACCEPTED'" class="form-style pt-md-4 card card-body bg-white"
                    id="login-tabContent">
                    <div id="tab_2" aria-labelledby=" ">
                      <table class="table table-borderless">
                        <tbody>
                          <tr class="border-bottom cursor-pointer" v-for="(data, index) in acceptedList" :key="index">
                            <td class="p-2" style="width: 60px" v-on:click="
                              this.$router.push(
                                '/member-info/' + data?.member.uuid
                              )
                              ">
                              <img :src="data?.member?.profile_img
                                ? data?.member?.profile_img
                                : '/images/placeholder-image.jpg'
                                " class="img-fluid rounded-circle object-cover" style="width: 40px; height: 40px"
                                alt="" />
                            </td>
                            <td class="font-medium p-2 align-middle" v-on:click="
                              this.$router.push(
                                '/member-info/' + data?.member.uuid
                              )
                              ">
                              {{ data?.member?.name }}
                              <br />
                              <p class="
                                  font-14
                                  text-secondary
                                  mb-1
                                  mt-2
                                  font-xs-12
                                " v-show="data?.type == 'LEADER'">
                                <i class="fa fa-flag text-primary" aria-hidden="true"></i>
                                {{
                                  $helperService.getTitleCase(data?.designation)
                                }} <span v-if="data.city_designation">- {{ data?.city_designation?.name }}</span>
                              </p>
                              <p class="
                                  font-14
                                  text-secondary
                                  mb-1
                                  mt-2
                                  font-xs-12
                                " v-if="data.type != 'LEADER'">
                                {{
                                  $helperService.getTitleCase(data?.designation)
                                }}
                              </p>
                            </td>
                            <td class="p-2 text-right">
                              <button class="" title="Edit" style="border: none !important"
                                v-if="isPresident && data.designation != 'President'">
                                <i class="fa fa-edit" style="color: #9cc338" v-on:click="showDesignationPopup(data)"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-5 mb-5">
            <button type="button" class="btn btn-brand-1 px-4 md-4 text-center"
              v-show="this.leaderId != this.myId && user.has_leadership_position == 'Y' && user.group_info && user.group_info.id"
              v-on:click="leadershipRequest()">
              Request for Leadership
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
  <TextModal ref="collaboration">
    <div>
      <div class="modal-header">
        <button type="button" class="close" v-on:click="$refs.collaboration.closeModal()" style="cursor: pointer">
          <img src="/img/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body form-style pb-0 pt-2 px-4">
        <label for="">Designation</label>
        <span class="text-danger">*</span>
        <div class="input-group">
          <select name="designation" class="form-control" id="designation" v-model="designation.category_id">
            <option value="">Select Designation</option>
            <option v-for="(category, index) in this.categoryList" :key="index" :value="category.id">
              {{ category.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="modal-footer border-0 justify-content-center mb-3">
        <button type="button" class="btn btn-brand-1 px-4 w-100" v-on:click="saveDesignation()" style="cursor: pointer">
          Save
        </button>
      </div>
    </div>
  </TextModal>
</template>
<script>
import TextModal from "@/components/TextModal.vue";
export default {
  name: "MemberAction",
  components: { TextModal },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      detail: {},
      pendingList: [],
      designationList: [],
      acceptedList: [],
      categoryList: [],
      leaderId: "",
      name: "",
      currentTab: "",
      data_id: "",
      obj: {
        company_id: "",
        member_id: "",
        action: "",
      },
      designation: {
        category_id: "",
      },
      myId: "0",
      tab: this.$sharedService.getMapValue("tab"),
      business_work_group_member_id: "",
      business_work_group_ids: "",
      user: {},
      isPresident: false
    };
  },
  mounted() {
    this.getBusinessDetail();
    if (this.tab) {
      this.changeTab(this.tab);
    } else {
      this.changeTab("ACCEPTED");
    }
    this.getList();
    this.meCall();
    this.getCategory();
    this.$store.commit("showSidebar", true);
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
    },
    saveDesignation() {
      this.$api
        .postAPI({
          _action:
            "business-work-group/" +
            this.business_work_group_ids +
            "/member/" +
            this.business_work_group_member_id +
            "/designation",
          _body: this.designation,
        })
        .then((res) => {
          this.getList(0);
          this.$refs.collaboration.closeModal();
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.designation = {
            category_id: "",
          };
        })
        .catch(() => { });
    },
    getList() {
      this.$api
        .getAPI({
          _action: "business-work-group/" + this.id + "/members",
        })
        .then((res) => {
          this.acceptedList = res.members.list;
          this.pendingList = res.pending_members.list;
          var president = false;
          var member = false;
          for (var i in this.acceptedList) {
            if (this.acceptedList[i].member.id == this.user.id) {
              member = true;
            }

            if (this.acceptedList[i].designation == 'President' && this.acceptedList[i].member.id == this.user.id) {
              president = true;
            }

            if (member && president) {
              this.isPresident = true;
            }
          }
        })
        .catch(() => { });
    },
    getBusinessDetail() {
      this.$api
        .getAPI({
          _action: "business-work-group/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
          this.leaderId = res.info.leader?.id;
        })
        .catch(() => { });
    },
    showDesignationPopup(data) {
      this.business_work_group_member_id = data.id;
      this.business_work_group_ids = data.business_work_group_id;
      if (data.category_id) {
        this.designation.category_id = data.category_id;
      } else {
        this.designation.category_id = "";
      }
      this.$refs.collaboration.showModal();
    },

    meCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          console.log(res);
          this.user = res.response;
          this.myId = res.response.id;
          this.obj.company_id = res.response.companies[0].id;
        })
        .catch(() => { });
    },
    leadershipRequest() {
      this.obj.is_leader = "PENDING";
      this.$api
        .postAPI({
          _action: "business-work-group/" + this.id + "/leadership/request",
          _body: this.obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => { });
    },
    acceptAction(data) {
      this.obj.action = "ACCEPTED";
      this.obj.member_id = data.member_id;
      this.obj.company_id = data.company_id;
      this.requestAction();
    },
    rejectAction(data) {
      this.obj.action = "REJECTED";
      this.obj.member_id = data.member_id;
      this.obj.company_id = data.company_id;
      this.requestAction();
    },
    requestAction() {
      this.$api
        .postAPI({
          _action: "business-work-group/" + this.id + "/member/action",
          _body: this.obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getList();
        })
        .catch(() => { });
    },
    invite() {
      this.$router.push("/invite?id=" + this.id);
    },

    getCategory() {
      this.$api
        .getAPI({
          _action: "category/work_group_designation",
        })
        .then((res) => {
          console.log(res);
          this.categoryList = res.list;
        })
        .catch(() => { });
    },
  },
};
</script>
