<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                <img
                  src="/images/back-arrow.svg"
                  width="17"
                  class="mr-2 align-top"
                  alt=""
              /></a>
              Profile
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end"></div>
        </div>
        <div class="card mb-4 rounded-l border-0 shadow-sm">
          <!-- <div class="card-header p-0 border-0">
            <img
              src="/images/cover-img.jpg"
              class="card-img-top rounded-l"
              alt=""
            />
          </div> -->
          <div class="card-body px-md-5 -x3">
            <div class="row mb-4 align-items-center">
              <div class="col-md-7 d-flex align-items-center mb-md-0 mb-3">
                <img
                  :src="
                    this.expertDetail.image
                      ? this.expertDetail.image
                      : '/img/profile.svg'
                  "
                  alt=""
                  class="img-fluid rounded-circle object-cover"
                  style="width: 100px; height: 100px"
                />
                <div class="pl-4">
                  <p class="font-medium mb-2 font-18 font-xs-16">
                    {{ expertDetail.name }}
                  </p>
                  <p class="font-14 text-secondary mb-1 font-xs-12">
                    Exp-{{ expertDetail.experience }} years
                  </p>
                  <p class="font-14 text-secondary mb-1 font-xs-12">
                    {{ expertDetail.company_name }}
                  </p>
                  <p
                    class="font-14 cursor-pointer mb-1"
                    v-on:click="$router.push('/expert-review/' + this.id)"
                  >
                    <star-rating
                      id="exper-detail"
                      :increment="0.5"
                      v-if="
                        expertDetail.avg_rating || expertDetail.avg_rating == 0
                      "
                      :rating="expertDetail.avg_rating"
                      :show-rating="false"
                      :star-size="15"
                      :read-only="true"
                    ></star-rating>
                  </p>
                  <p class="font-14 mb-0">
                    <img src="/img/distance.svg" width="20" alt="" />
                    <span class="ml-2 text-secondary">{{
                      detail.distance
                    }}km</span>
                  </p>
                </div>
              </div>
              <!-- <div class="col-md-2 mb-md-0 mb-3 text-md-left text-center"> -->

              <!-- </div> -->
              <div class="col-md-5 text-md-right text-center">
                <a :href="'tel:' + expertDetail.phone_number" v-if="expertDetail.phone_number">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  {{ expertDetail.phone_number }}
                </a>
                <br />
                <a :href="'mailto:' + expertDetail.email" v-if="expertDetail.email">
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                  {{ expertDetail.email }}
                </a>
              </div>
            </div>
            <h4 class="font-18">About {{ detail.name }}</h4>
            <p>
              {{ expertDetail.about_me }}
            </p>
            <div class="text-md-left text-center">
              <a
                target="_blank"
                style="color: #2f80ed"
                :href="'https://www.facebook.com/' + expertDetail.facebook_id"
                v-if="expertDetail.facebook_id "
              >
                <i class="fa fa-facebook-official mr-2" aria-hidden="true"></i>
                www.facebook.com/{{ expertDetail.facebook_id }}
              </a>
              <br />
              <a
                target="_blank"
                :href="'https://twitter.com/' + expertDetail.twitter_id"
                style="color: #117eb8"
                v-if="expertDetail.twitter_id "
              >
                <i class="fa fa-linkedin-square mr-2" aria-hidden="true"></i>
                twitter.com/{{ expertDetail.twitter_id }}
              </a>
              <br />
              <a
                target="_blank"
                style="color: #c13584"
                :href="
                  'https://www.instagram.com/accounts/' +
                  expertDetail.instagram_id
                "
                v-if="expertDetail.instagram_id "
                ><i class="fa fa-instagram mr-2" aria-hidden="true"></i
                >www.instagram.com/accounts/{{ expertDetail.instagram_id }}</a
              >
            </div>
            <!-- <div>
              <h4 class="font-18">Companies</h4>
              <div class="d-flex align-items-center mb-3">
                <img src="/img/logo-2-2.png" width="50" alt="" />
                <p class="ml-2 mb-0">Expar Solution</p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StarRating from "vue-star-rating";
export default {
  name: "ExpertProfile",
  components: { StarRating },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      expertDetail: {},
    };
  },
  mounted() {
    this.expertDetail = this.$sharedService.getMapValue("expertDetail");
    console.log("expertDetail", this.expertDetail);

    this.$store.commit("showSidebar", false);
    this.getExpertDetail();
  },
  methods: {
    getExpertDetail() {
      this.$api
        .getAPI({
          _action: "expert/" + this.id,
        })
        .then((res) => {
          this.detail = res.response;
          this.$sharedService.setMapValue(
            "top_five_review",
            this.detail.top_five_review
          );
        })
        .catch(() => {});
    },
  },
};
</script>