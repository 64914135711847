import { defineRule } from "vee-validate";

defineRule("required", (value, [name, isSelect]) => {
  if (!value || value.length <= 0) {
    if (name) {
      if (!isSelect || isSelect == false) {
        return `Please enter ${name}`;
      } else {
        return `Please select ${name}`;
      }
    }
    return "This field is required";
  }
  return true;
});

defineRule("message", (value, [name]) => {
  if (value && value == "select") {
    return `Please select ${name}`;
  }
  return true;
});

defineRule("email", (value) => {
  if (
    value &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return "Please enter valid email";
  }
  return true;
});

defineRule("phone", (value) => {
  if (value && !/^[0-9]{10}$/.test(value)) {
    return "Please enter valid contact number";
  }
  return true;
});

defineRule("gst", (value) => {
  console.log(value);
  if (value && !/^[A-Z0-9]{15}$/.test(value)) {
    console.log("....", value);
    return "Please enter valid GST number";
  }
  return true;
});

defineRule("pincode", (value) => {
  if (value && !/^[0-9]{6}$/.test(value)) {
    return "Please enter valid pin code";
  }
  return true;
});

defineRule("userName", (value, [name]) => {
  if (value && !/^[A-Za-z][A-Za-z_]{1,}$/.test(value)) {
    if (name) {
      return `Please enter valid ${name}`;
    }
    return "Please enter valid name";
  }
  return true;
});

defineRule("minLength", (value, [limit]) => {
  if (value.length < limit) {
    return `This field must be at least ${limit} characters`;
  }
  return true;
});

defineRule("password", (value) => {
  if (value && !/^\S*$/.test(value)) {
    return "Space is not allowed in password";
  }
  return true;
});

defineRule("confirmed", (value, [target]) => {
  if (value === target) {
    return true;
  }
  return "Passwords must match";
});

defineRule("price", (value) => {
  if (value && !/^[1-9]\d*(\.\d{1,2}?)?$/.test(value)) {
    return "Please enter valid price";
  }
  return true;
});

defineRule("numeric", (value) => {
  if (value && !/^[1-9]\d*(\.\d{1,2}?)?$/.test(value)) {
    return "Please enter numeric values only";
  }
  return true;
});
