<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-10">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">Health & Well Being</h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end">
              <input
                type="text"
                class="search-click"
                name=""
                v-on:keyup.enter="getList(0)"
                placeholder="search here..."
                v-model="searchObj.keyword"
              />
              <button
                type="button"
                class="btn btn-filter ml-2"
                v-on:click="showFilterPopup()"
              >
                <img src="/images/filter.svg" width="30" alt="" />
              </button>
            </div>
          </div>
          <ul
            class="nav nav-pills filter-tabs mb-3"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-home-tab"
                data-toggle="pill"
                href="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                :class="currentTab == 'ALL' ? 'active' : ''"
                v-on:click="changeTab('ALL')"
                style="cursor: pointer"
                >All</a
              >
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                :class="currentTab == 'POSTED_BY_ME' ? 'active' : ''"
                v-on:click="changeTab('POSTED_BY_ME')"
                style="cursor: pointer"
                >Posted By Me</a
              >
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-contact-tab"
                data-toggle="pill"
                href="#pills-contact"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
                :class="currentTab == 'INTERESTED' ? 'active' : ''"
                v-on:click="changeTab('INTERESTED')"
                style="cursor: pointer"
                >Interested</a
              >
            </li>
          </ul>
          <div class="filter-optns mb-3 ml-1 row">
            <div
              class="mr-1"
              v-for="(data, index) in activityList"
              :key="index"
            >
              <span class="badge" v-if="data.isChecked"
                >{{ data.name }} &nbsp;
                <img
                  src="images/close.svg"
                  class="ml-1"
                  width="10"
                  v-on:click="remove(data)"
              /></span>
            </div>
          </div>
          <div v-for="(data, index) in healthList" :key="index">
            <HealthCard
              :isVisible="true"
              :data="data"
              @interested-list="getInterestedList"
              @interested="interested"
              @notInterested="notIntrested"
              @delete="showDeletePopup"
              @edit="showActivityPopup"
              @report="report"
            />
          </div>
          <div
            style="text-align: center; border-radius: 16px"
            class="col-12 pb-3"
            v-show="searchObj.offset > 0 && !loader"
          >
            <a
              id="load-button"
              class="txt-success cursor-pointer mb-3"
              v-on:click="getList(searchObj.offset)"
              style="max-width: 110px"
            >
              Load More
            </a>
          </div>
          <button
            type="button"
            class="
              btn
              bg-brand
              position-sticky
              shadow
              btn-lg
              float-right
              mr-md-n5
              add-sticky-btn
            "
            data-toggle="modal"
            data-target="#add_plan"
            v-on:click="showActivityPopup('')"
          >
            <img src="/images/plus.svg" width="12" alt="" class="mr-1" /> Add
            Activity
          </button>
        </div>
      </div>
      <ErrorComponent @retry="getList(0)" ref="errorComponent" />
      <TextModal ref="activityModal">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              v-on:click="$refs.activityModal.closeModal()"
            >
              <img src="/images/close.svg" width="15" />
            </button>
          </div>
          <div class="modal-body px-4">
            <h5 class="modal-title font-22 mb-0 text-center mb-3">
              Plan An Activity
            </h5>
            <Form
              ref="activityForm"
              class="form-style floating-form"
              @submit="save()"
            >
              <div class="row mb-md-0 mb-4">
                <div class="col-md-12 mt-2">
                  <div class="form-group">
                    <label for="state">Select Activity</label>
                    <span class="text-danger">*</span>
                    <Field
                      as="select"
                      v-model="detail.activity_id"
                      class="custom-select"
                      rules="required:activity,true"
                      name="activity"
                      @change="checkLocation(detail.activity_id)"
                    >
                      <option value="">Select</option>
                      <option
                        v-for="(data, index) in activityList"
                        :key="index"
                        :value="data.id"
                      >
                        {{ data.name }}
                      </option>
                    </Field>
                  </div>
                  <ErrorMessage name="activity" class="validation-msg" />
                  <div class="floating-form-group with-icon">
                    <Datepicker
                      v-model="detail.start_date_time"
                      hideInputIcon
                      :clearable="false"
                      format="yyyy-MM-dd HH:mm:ss"
                      placeholder="Select Date"
                    ></Datepicker>
                    <img src="/images/calendar.svg" width="20" class="icon" />
                  </div>
                  <ErrorMessage name="name_in_time" class="validation-msg" />
                  <div
                    class="floating-form-group with-icon"
                    v-if="this.has_sourceLocation == 'Y'"
                  >
                    <vue-google-autocomplete
                      ref="startingPointAddress"
                      id="map"
                      :classname="
                        detail.starting_point
                          ? 'form-control filled'
                          : 'form-control'
                      "
                      v-model="detail.starting_point"
                      v-on:placechanged="getAddressData2"
                      country="in"
                      placeholder=""
                      types=""
                    >
                    </vue-google-autocomplete>
                    <label for="map">Starting Point</label>
                    <img
                      src="/images/location-icon.svg"
                      width="20"
                      class="icon"
                    />
                  </div>
                  <ErrorMessage name="startingpoint" class="validation-msg" />
                  <div class="floating-form-group with-icon">
                    <vue-google-autocomplete
                      ref="address"
                      id="map1"
                      :classname="
                        detail.destination
                          ? 'form-control filled'
                          : 'form-control'
                      "
                      v-model="detail.destination"
                      v-on:placechanged="getAddressData"
                      country="in"
                      types=""
                      placeholder=""
                    >
                    </vue-google-autocomplete>
                    <label for="map1">Destination</label>
                    <img
                      src="/images/location-icon.svg"
                      width="20"
                      class="icon"
                    />
                  </div>
                  
                  <span
                    class="validation-msg"
                    v-show="isShowAddressErrorMessage"
                    >Please enter destination</span
                  >
                  <div class="floating-form-group">
                    <Field
                      type="text"
                      id="ContactNumber"
                      name="phone"
                      autocomplete="off"
                      v-model="detail.contact_no"
                      :class="detail.contact_no ? 'filled' : ''"
                      rules="required:contact number|phone"
                      :validateOnInput="true"
                    />
                    <label for="ContactNumber">Contact Number</label>
                    <span class="text-danger">*</span>
                  </div>
                  <ErrorMessage name="phone" class="validation-msg" />
                  <div class="pb-3">
                    <div class="floating-form-group" style="height: 130px">
                      <Field
                        as="textarea"
                        rows="4"
                        id="description"
                        :class="detail.description ? 'filled' : ''"
                        name="description"
                        v-model="detail.description"
                        rules="required:description"
                        :validateOnInput="true"
                      ></Field>
                      <label for="description">Description</label>
                      <span class="text-danger">*</span>
                    </div>
                  </div>
                  <ErrorMessage name="description" class="validation-msg" />
                  <button class="btn btn-brand-1 w-100 mb-4" id="save-button">
                    {{ detail.id ? "Save" : "Publish" }}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </TextModal>
      <TextModal ref="filterModal">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              v-on:click="$refs.filterModal.closeModal()"
            >
              <img src="/images/close.svg" width="15" />
            </button>
          </div>
          <div class="modal-body form-style floating-form pb-2 pt-2">
            <h4 class="font-22 modal-tital text-center">Filter</h4>

            <p class="font-medium mb-3">Activity</p>
            <div class="text-center interest-tags">
              <button
                type="button"
                class="btn btn-outline-rounded-color mr-2 mb-2"
                v-on:click="checked(data)"
                v-for="(data, index) in activityList"
                :key="index"
                :class="data.isChecked == true ? 'active' : ''"
              >
                {{ data.name }}
              </button>
            </div>
             <div><hr /></div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              type="button"
              class="btn btn-outline-brand-1 px-4"
              v-on:click="callapi()"
            >
              Apply
            </button>
          </div>
        </div>
      </TextModal>
      <TextModal ref="textModal" class="modal-small">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              v-on:click="$refs.textModal.closeModal"
            >
              <img src="/images/close.svg" width="15" />
            </button>
          </div>
          <div class="modal-body text-center px-md-5">
            <h5 class="modal-title font-22 mb-4">Thank you</h5>
            <img src="/images/claping.png" alt="" width="75" class="mb-3" />
            <p class="font-18">
              Thanks for showing <br />
              interest in this activity.
            </p>
          </div>
          <div class="modal-footer justify-content-center border-0 pb-4 pt-0">
            <button
              type="button"
              class="btn btn-brand-outline px-4"
              style="min-width: 115px"
              v-on:click="showContactModal()"
            >
              Contact Organizer
            </button>
          </div>
        </div>
      </TextModal>
      <TextModal ref="interestedModal">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              v-on:click="$refs.interestedModal.closeModal"
            >
              <img src="images/close.svg" width="15" />
            </button>
          </div>
          <div class="modal-body form-style floating-form pb-2 pt-2 px-md-4">
            <h4 class="font-22 modal-tital text-center mb-4">
              Interested People
            </h4>
            <div style="height: 500px; overflow-y: auto">
              <div
                class="row mb-2 align-items-center border-bottom pb-2"
                v-for="(data, index) in interestedList"
                :key="index"
                v-on:click="
                  this.$router.push('/member-info/' + data?.user.uuid)
                "
              >
                <div class="col-md-9 d-flex align-items-center cursor-pointer">
                  <img
                    :src="
                      data.user.profile_img
                        ? data.user.profile_img
                        : '/img/placeholder-image.jpg'
                    "
                    alt=""
                    class="img-fluid rounded-circle object-cover"
                    style="width: 40px; height: 40px"
                  />
                  <div class="pl-2">
                    <p class="font-medium mb-0 font-xs-14">
                      {{ data.user.first_name }} {{ data.user.last_name }}
                    </p>
                    <p class="font-12 text-color-2 mb-0 font-xs-12">
                      {{ data.ago_time }}
                    </p>
                  </div>
                </div>
                <div class="col-md-3 text-right font-14">Interested</div>
              </div>
            </div>
          </div>
        </div>
      </TextModal>
      <TextModal ref="contactModal">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              v-on:click="$refs.contactModal.closeModal()"
              aria-label="Close"
            >
              <img src="images/close.svg" width="15" />
            </button>
          </div>
          <div class="modal-body text-center px-md-5">
            <h5 class="modal-title font-22 mb-0">Contact Number</h5>
            <img src="images/contact	.png" alt="" width="180" class="mb-3" />
            <p class="">
              You can contact organizer using below contact number.
            </p>
            <p class="font-medium font-18">
              <a :href="'tel:+91' + this.contact">+91{{ this.contact }}</a>
            </p>
          </div>
        </div>
      </TextModal>
      <TextModal ref="reportModal">
        <div class="modal-content">
          <div class="modal-header pb-0">
            <button
              type="button"
              class="close"
              v-on:click="this.$refs.reportModal.closeModal()"
            >
              <img src="/images/close.svg" width="15" />
            </button>
          </div>
          <div class="modal-body px-4">
            <h5 class="modal-title font-22 mb-0 text-center mb-3">Report</h5>
            <Form ref="reportForm" @submit="reportAPI()">
              <div class="floating-form mb-4">
                <div class="floating-form-group mt-4" style="height: 130px">
                  <Field
                    as="textarea"
                    type="text"
                    rows="4"
                    name="message"
                    rules="required:message"
                    :validateOnInput="true"
                    v-model="obj.message"
                  />

                  <label for="message"
                    >Please write your problem/suggestion</label
                  >
                  <span class="text-danger">*</span>
                </div>
                <ErrorMessage name="message" class="text-danger" />
              </div>
              <div class="text-center">
                <button class="btn btn-brand-1" id="submit-button">
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </TextModal>
      <DeleteModal @remove="deleteRecord" ref="deleteModal" />
    </div>
  </div>
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import TextModal from "@/components/TextModal.vue";
import Datepicker from "@vuepic/vue-datepicker";
import { Form, Field, ErrorMessage } from "vee-validate";
import HealthCard from "@/components/HealthCard";
import $ from "jquery";
import moment from "moment";

export default {
  name: "HealthAndWellbeing",
  components: {
    TextModal,
    DeleteModal,
    ErrorComponent,
    Datepicker,
    Form,
    Field,
    ErrorMessage,
    HealthCard,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      id: this.$route.params.id,
      interestList: [],
      interestedList: [],
      latitude: "",
      city: "",
      longitude: "",
      detail: {
        list_type: "",
        // dial_code: "+91",
        // country_code: "IN",
        starting_point:"",
        destination:"",
        activity_id: "",
        health_wellbeing_id: "",
      },
      searchObj: {
        offset: 0,
        list_type: "",
      },
      activityList: [],
      healthList: [],
      contact: "",
      obj: {},
      has_sourceLocation: "",
      isShowAddressErrorMessage: false,
      isShowAddressErrorMessage1: false,
      currentTab: 'ALL'
    };
  },
  mounted() {
    this.has_sourceLocation = "N";
    // this.$refs.address.focus();
    if (this.$sharedService.getMapValue("activities") != null) {
      console.log("object",this.$sharedService.getMapValue("activities"));
      this.activityList = this.$sharedService.getMapValue("activities");
      this.changeTab("ALL");
    } else {
      this.getDetails();
    }
    this.$store.commit("showSidebar", true);

    //floating form
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
  },
  methods: {
    getAddressData: function (addressData,placeResultData) {
      this.detail.destination =  placeResultData.name ? (placeResultData.name) : ' ' ;
      // console.log('addressData',addressData)
      // console.log('this.detail.destination',placeResultData)
      this.searchObj.latitude = addressData.latitude;
      this.searchObj.longitude = addressData.longitude;
    },
    getAddressData2: function (addressData,placeResultData) {
      this.detail.starting_point =  placeResultData.name ? (placeResultData.name) : ' ' ;
      console.log('addressData.name',addressData)
      this.searchObj.latitude = addressData.latitude;
      this.searchObj.longitude = addressData.longitude;
    },
    getDetails() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.getActivityList(res.response);
        })
        .catch(() => {});
    },
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "ALL") {
        this.searchObj.list_type = "ALL";
      } else if (tab == "POSTED_BY_ME") {
        this.searchObj.list_type = "POSTED_BY_ME";
      } else if (tab == "INTERESTED") {
        this.searchObj.list_type = "INTERESTED";
      }
      this.getList(0);
    },
    remove(data) {
      data.isChecked = false;
      this.getList(0);
    },

    checked(data) {
      data.isChecked = !data.isChecked;
    },
     pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.searchObj.offset = offset;
      this.searchObj.activity_id = "";

      for (var i in this.activityList) {
        if (this.activityList[i].isChecked == true) {
          if (this.searchObj.activity_id) {
            this.searchObj.activity_id += "," + this.activityList[i].id;
          } else {
            this.searchObj.activity_id = this.activityList[i].id;
          }
        }
      }
      this.$refs.errorComponent.updateLoader(true);
     
      this.$api
        .getAPI({
          _action: "health-well-beings",
          _body: this.searchObj,
        })
        .then((res) => {
           if (offset == 0) {
        this.healthList = [];
        this.setInterest(this.searchObj.activity_id);
      }
          this.loader = false;
          for (var i in res.list) {
            res.list[i].start_date_time = moment(
              res.list[i].start_date_time,
              "YYYY-MM-DD HH:mm:ss A"
            );

            this.healthList.push(res.list[i]);
          }
          this.searchObj.offset = res.next_offset;
          if (offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No activity available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    getActivityList(detail) {
      this.$api
        .getAPI({
          _action: "category/activity",
        })
        .then((res) => {
          for (var i in res.list) {
            var isChecked = false;
            for (var j in detail.interested_activity) {
              if (detail.interested_activity[j].interest_id == res.list[i].id) {
                isChecked = true;
              }
            }

            res.list[i].isChecked = isChecked;
          }

          this.activityList = res.list;
          this.changeTab("ALL");
          this.getList(0);
        })
        .catch(() => {});
    },
    getInterestedList(id) {
      this.$api
        .getAPI({
          _action: "health-well-being/" + id + "/interested",
        })
        .then((res) => {
          this.interestedList = res.list;
          this.$refs.interestedModal.showModal();
        })
        .catch(() => {});
    },
    showFilterPopup() {
      this.$refs.filterModal.showModal();
    },
    showContactModal() {
      this.$refs.textModal.closeModal();
      this.$refs.contactModal.showModal();
    },
    showActivityPopup(id) {
      if (id) {
        this.$api
          .getAPI({
            _action: "health-well-being/" + id,
          })
          .then((res) => {
            this.detail = res.info;
            this.$refs.activityModal.showModal();
            this.$refs.activityForm.resetForm();
            var $this = this;
            setTimeout(() => {
              document.getElementById("map1").value = $this.detail.destination;
              if(this.detail.has_source_location == 'Y')
              {
              document.getElementById("map").value =
                $this.detail.starting_point;
            }
            }, 500);
          })
          .catch(() => {});
      } else {

        this.detail = {
          activity_id: "",
        };
        this.getActivityList(this.detail);
        this.$refs.activityModal.showModal();
        this.$refs.activityForm.resetForm();
      }
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        "Delete Event",
        "Are you sure you want to delete",
        id
      );
    },
    showThankyouPopup(data) {
      this.contact = data.contact_no;
      this.$refs.textModal.showModal();
    },
    save() {
      // if (!document.getElementById("map").value) {
      //   this.isShowAddressErrorMessage1 = true;
      //   return;
      // }
      if (!document.getElementById("map1").value) {
        this.isShowAddressErrorMessage = true;
        return;
      }
      this.detail.dial_code = "+91";
      this.detail.country_code = "IN";
      this.detail.start_date_time = moment(this.detail.start_date_time).format(
        "yyyy-MM-DD HH:mm:ss"
      );
      if (this.detail.id) {
        this.$api
          .putAPI({
            _action: "/health-well-being/" + this.detail.id,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$refs.activityModal.closeModal();
            // this.$router.go(-1);
            this.getList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
            (this.detail = {}), this.$refs.activityForm.resetForm();
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "health-well-being",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$refs.activityModal.closeModal();
            this.getList(0);
            (this.detail = {}), this.$refs.activityForm.resetForm();
            // this.$router.go(-1);
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateLoader(false);
            }
          });
      }
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "health-well-being/" + id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    interested(id) {
      var obj = {};
      obj.health_wellbeing_id = id;
      obj.has_interested = true;
      this.$api
        .postAPI({
          _action: "health-well-being/interesed",
          _body: obj,
          _buttonId: "interested-button",
        })
        .then(() => {
          var data = undefined;
          for (var i in this.healthList) {
            if ((this.healthList[i].id = id)) {
              data = this.healthList[i];
            }
          }

          data.has_interested = true;
          if (data) {
            this.showThankyouPopup(data);
          }
          this.$helperService.getSummary();
          this.getList(0);
        })
        .catch(() => {});
    },
    notIntrested(id) {
      this.$api
        .deleteAPI({
          _action: "health-well-being/" + id + "/interested",
          _buttonId: "not-interested-button",
        })
        .then(() => {
          // var data = undefined;
          // for (var i in this.healthList) {
          //   if ((this.healthList[i].id = id)) {
          //     data = this.healthList[i];
          //   }
          // }

          // data.has_interested = true;
          // if (data) {
          //   this.showThankyouPopup(data);
          // }
          this.$helperService.getSummary();
          this.getList(0);
        })
        .catch(() => {});
    },
    report(id) {
      this.obj.health_wellbeing_id = id;
      this.$refs.reportModal.showModal();
      // console.log(id);
    },
    reportAPI() {
      this.$api
        .postAPI({
          _action: "health-well-being/report",
          _body: this.obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.reportModal.closeModal();
          (this.obj = {}), this.$refs.reportForm.resetForm();
          // this.$notify({
          //   type: "success",
          //   text: res.list.message,
          // });
        })
        .catch(() => {});
    },
    callapi() {
      this.$refs.filterModal.closeModal();
      this.getList(0);
    },
    checkLocation(id) {
      console.log("inside",id);
      this.$api
        .getAPI({
          _action: "category/activity",
        })
        .then((res) => {
          for (var i in res.list) {
            if (id == res.list[i].id) {
              this.has_sourceLocation = res.list[i].has_source_location;
            }
          }
        })
        .catch(() => {});
    },
    setInterest(data) {
      var obj = {};
      obj.interest_ids = data;
      var temp = [];
      for (var i in this.interestList) {
        this.interestList[i].isChecked = false;
        for (var j in data) {
          if (data[j] == this.interestList[i].id) {
            temp.push(this.interestList[i]);
          }
        }
      }
      this.$sharedService.setMapValue("activities", temp);
      this.$api
        .postAPI({
          _action: "user/activity",
          _body: obj,
        })
        .then(() => {
          // this.getList(0);
        })
        .catch(() => {});
    },
  },
};
</script>