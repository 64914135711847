<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">Magazines</h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end">
            <input
              type="text"
              class="search-click"
              name=""
              v-on:keyup.enter="getOfferList(0)"
              placeholder="search here..."
              v-model="searchObj.keyword"
            />
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-4 col-md-6 mb-3"
            v-for="(data, index) in list"
            :key="index"
          >
            <div class="card rounded-l border-0 loyalty-card">
              <div class="card-header bg-white text-center">
                <img
                  :src="
                    data.cover_image ? data.cover_image : '/images/placeholder-image.jpg'
                  "
                  alt=""
                  class="logo-img"
                />
              </div>
              <div
                class="
                  card-body
                  py-2
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <p class="font-medium my-2">{{ data.title }}</p>
                <span
                  class="btn btn-brand-outline rounded-pill font-14 text-dark"
                  style="height: 36px; line-height: 25px"
                  v-if="data.url"
                  v-on:click="openLink(data.url)"
                  >View</span
                >
              </div>
            </div>
          </div>
          <div
            style="text-align: center; border-radius: 16px"
            class="col-12 pb-3"
          >
            <div v-show="offset != -1 && this.loader != true">
              <a
                id="load-button"
                class="txt-success cursor-pointer mb-3"
                v-on:click="getList(offset)"
                style="max-width: 110px"
              >
                Load More
              </a>
            </div>
          </div>
          <div class="col-12 text-center mt-4">
            <div>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { Form, Field, ErrorMessage } from "vee-validate";
// import DeleteModal from "@/components/DeleteModal";
// import TextModal from "@/components/TextModal.vue";
import ErrorComponent from "@/components/ErrorComponent";
import $ from "jquery";
export default {
  name: "MyOffers",
  components: {
    // Form,
    // Field,
    // ErrorMessage,
    // TextModal,
    // DeleteModal,
    ErrorComponent,
  },
  data() {
    return {
      id: "",
      loader: false,
      list: [],
      offset: 0,
      userId: localStorage.getItem("userId"),
      searchObj: {
        keyword: "",
      },
      file: "",
    };
  },
  mounted() {
    this.$store.commit("showSidebar", false);
    this.getList(0);

    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    getList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      if (offset == 0) {
        this.list = [];
      }
      this.searchObj.offset = this.offset;
      this.$api
        .getAPI({
          _action: "emagzines",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          for (var i in res.list) {
            this.list.push(res.list[i]);
          }
          this.offset = res.next_offset;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No magazine available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>