<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a v-on:click="$router.go(-1)">
                <img
                  src="/images/back-arrow.svg"
                  width="17"
                  class="mr-2 align-top"
                  alt=""
              /></a>
              Exhibition Details
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end"></div>
        </div>
        <div class="card mb-4 rounded-l border-0 shadow-sm">
          <div class="card-header p-0 border-0">
            <img
              :src="
                this.detail.event_banner
                  ? this.detail.event_banner
                  : '/img/placeholder-image.jpg'
              "
              class="card-img-top rounded-l cover-img"
              alt=""
            />
          </div>
          <div class="card-body px-md-5 -x3">
            <div class="row mb-4 align-items-center border-bottom pb-md-4">
              <div class="col-md-8 mb-md-0 mb-3">
                <p class="font-medium mb-2 font-18 font-xs-16">
                  {{ this.detail.event_title }}
                  <!-- title -->
                </p>
                <p
                  class="
                    text-secondary
                    mb-md-2 mb-2
                    font-14 font-medium
                    cursor-pointer
                  "
                  v-on:click="showLocation(detail)"
                >
                  <img src="\img\location-icon.svg" width="20" class="mr-1" />
                  <!-- venue -->
                  {{ this.detail.venue }}
                </p>
                <p class="font-14 text-secondary mb-0 font-xs-12">
                  {{ $helperService.getFormatedDate(this.detail.start_at) }}
                  -
                  {{ $helperService.getFormatedDate(this.detail.end_at) }}
                  <!-- date -->
                </p>
                <p class="font-14 text-secondary mb-0 font-xs-12">
                  {{ $helperService.getFormatedTime(this.detail.start_at) }}
                  to
                  {{ $helperService.getFormatedTime(this.detail.end_at) }}
                </p>
              </div>

              <div class="col-md-4 d-md-flex justify-content-end mb-md-0 mb-3">
                <a :href="'tel:' + detail.contact_no">
                  <button type="button" class="btn btn-brand-1 mr-3">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                    Contact
                  </button>
                </a>
                <a :href="'mailto:' + detail.email">
                  <button type="button" class="btn btn-brand-1">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    Email
                  </button>
                </a>
              </div>
            </div>

            <p>
              {{ this.detail.description }}
            </p>

            <!-- <p>
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and moreLorem Ipsum is simply dummy text
                      of the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the
                      1500s, when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book. It has survived
                      not only five centuries, but also the leap into electronic
                      typesetting, remaining essentially unchanged. It was
                      popularised in the Lorem Ipsum is simply dummy text of the
                      printing and typesetting industry. Lorem Ipsum has been
                      the industry's standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book. It has survived
                      not only five centuries, but also the leap into electronic
                      typesetting, remaining essentially unchanged. It was
                      popularised in the dummy text ever since the 1500s, when
                      an unknown printer took a galley of type and scrambled it
                      to make a type specimen book. It has survived not only
                      five centuries, but also the leap into electronic
                      typesetting,
                    </p> -->
          </div>
        </div>
      </div>
    </div>

    <!--Thank you modal-->
    <div
      class="modal fade"
      id="modal_thankyou"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-small">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src="/images/close.svg" width="15" />
            </button>
          </div>
          <div class="modal-body text-center px-md-5">
            <h5 class="modal-title font-22 mb-4">Congratulations !</h5>
            <img src="/images/claping.png" alt="" width="75" class="mb-3" />
            <p class="font-18">
              Your mebership is under approval, we will notify you when it’s
              approved.
            </p>
          </div>
          <div
            class="
              modal-footer
              justify-content-center
              border-0
              pb-4
              pt-0
              px-md-5
            "
          >
            <button
              type="button"
              class="btn btn-brand-outline px-4 w-100"
              data-dismiss="modal"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../services/WebService";
// import helperService from "../../services/HelperService";
// import moment from "moment"
export default {
  name: "MyExibition",
  components: {
    // helperService
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
    };
  },
  mounted() {
    this.getExibitionDetail();
    this.$store.commit("showSidebar", false);
  },
  methods: {
    getExibitionDetail() {
      api
        .getAPI({
          _action: "exhibition/" + this.id,
        })
        .then((res) => {
          
          this.detail = res.response;
        })
        .catch(() => {});
    },
    // showSubscribePopup() {
    //   this.$refs.textModal.showModal();
    // },
    showLocation(detail) {
      var url =
        "https://www.google.com/maps?saddr=" +
        this.$api.getLatitude() +
        "," +
        this.$api.getLongitude() +
        "&daddr=" +
        detail.latitude +
        "," +
        detail.longitude;

      window.open(url);
    },
  },
};
</script>
