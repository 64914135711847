<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-10">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>
                Webinar
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card card-body events-card mb-4">
            <div class="row mb-md-0 mb-3">
              <div class="col-md-8">
                <p class="font-18 font-medium mb-2">{{ detail.name }}</p>
              </div>
              <div class="col-md-4 text-md-right">
                <p class="font-14 mb-2 font-xs-12">{{ detail.ago_time }}</p>

                <span class="badge badge-light-yellow ml-2 mb-2">{{
                  detail?.category?.name
                }}</span>
              </div>
            </div>
            <div class="position-relative mb-3">
              <img :src="detail.image ? detail.image : '/img/placeholder-image.jpg'
                  " class="img-fluid card-img" alt="" />
            </div>

            <div class="row">
              <div class="col-md-6 mb-md-0 mb-3">
                <p class="text-secondary mb-md-2 mb-1">
                  <img src="/images/calendar.svg" width="14" class="align-baseline mr-1" />
                  {{
                    $helperService.getFormatedDateAndTime(
                      this.detail.from_datetime
                    )
                  }}
                </p>
              </div>

              <div class="col-md-6 text-right">
                <p class="font-24 font-bold mb-0" v-show="detail.type != 'FREE'">
                  ₹{{ detail.price }}
                </p>
                <h5 class="text-secondary mb-md-2 mb-1" v-show="detail.type != 'FREE'">
                  +GST
                </h5>
                <p class="font-24 font-bold mb-2" v-show="detail.type == 'FREE'">
                  {{ detail.type }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-md-right">
                <hr />
              </div>
              <div class="col-md-12">
                <p>
                  {{ detail.description }}
                </p>
              </div>
              <div>
                <div class="col-md-12 mb-md-0 mb-3">
                  <div class="mb-2 mt-2" v-show="speakerList.length">
                    <h5 class="">Speakers</h5>
                  </div>

                  <div v-for="(data, index) in speakerList" :key="index" v-on:click="
                    $router.push({
                      path: '/author-profile/' + data.id,
                      query: { event_id: detail.id },
                    })
                    " class="
                      row
                      align-items-center
                      pb-2
                      px-2
                      mb-2
                      mx-0
                      cursor-pointer
                    " v-show="speakerList.length">
                    <img :src="data.image ? data.image : '/images/profile.svg'" width="45" height="45"
                      class="rounded-circle object-cover mr-2" />
                    <div class="text-left">
                      <p class="mb-0 font-medium">
                        {{ data.name }}
                      </p>
                      <p class="font-14 mb-0 text-secondary">
                        {{ data.profession }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 text-center">
                <button v-show="!detail.has_register &&
                  isShow &&
                  detail.event_registration_closed == 'N'
                  " class="btn btn-brand-1 w-xs-100" data-toggle="modal" data-target="#modal_thankyou"
                  id="register-button" v-on:click="register(detail.id)">
                  Register
                </button>
                <button v-if="detail.has_register &&
                  isShow &&
                  detail.webinar_link
                  " class="btn btn-brand-1 w-xs-100" data-toggle="modal" data-target="#modal_thankyou"
                  id="register-button" v-on:click="joinNow(detail.webinar_link)">
                  Join Now
                </button>
                <div class="alert alert-success py-1 font-14 d-inline-block px-2" style="margine-left:97px;" v-else-if="(detail.has_register || detail.has_register == 1) &&
                  isShow &&
                  !detail.webinar_link
                  ">
                  You are already registered for this event
                </div>

                <p v-show="(!detail.has_register || detail.has_register == 0) &&
                  detail.event_registration_closed == 'Y'
                  ">
                  Event Registration Closed.
                </p>
              </div>
              <TextModal ref="textModal" class="modal-small">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
                      <img src="/images/close.svg" width="15" />
                    </button>
                  </div>
                  <div class="modal-body text-center px-md-4">
                    <h5 class="modal-title font-22 mb-4">Thank you</h5>
                    <img src="/images/claping.png" alt="" width="75" class="mb-3" />
                    <p class="font-18 mb-0">
                      Thanks for registering<br />
                    </p>
                  </div>
                </div>
              </TextModal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import TextModal from "@/components/TextModal.vue";
import moment from "moment";

export default {
  name: "EventDetails",
  components: {
    TextModal,
  },
  watch: {
    "$route.params.id"(newId) {
      this.id = newId;
      this.getDetail();
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      userInfo: {},
      detail: {},
      speakerList: [],
      currentDate: new Date(),
      event_type: "",
      isShow: false,
    };
  },
  mounted() {
    this.getDetail();
    this.getInfo();
    this.$store.commit("showSidebar", true);
    // this.$refs.textModal.showModal();
  },
  methods: {
    showThankyouPopup() {
      this.$refs.textModal.showModal();
    },
    getDetail() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      if (this.id == "undefined" || this.id == null) {
        return;
      }
      this.$api
        .getAPI({
          _action: "event/" + this.id,
        })
        .then((res) => {
          this.loader = false;
          this.detail = res;
          this.event_type = res.event_type;

          if (this.event_type == 'INTERNAL') {
            if (
              moment(this.detail.from_datetime, "YYYY-MM-DD HH:mm:ss") >=
              moment(new Date())
            ) {
              this.isShow = true;
            }
          } else if (this.event_type == 'EXTERNAL') {
            var date1 = new Date(this.detail.from_datetime);
            var date2 = new Date();
            if (moment(String(date1)).format("Y-MM-DD") == moment(String(date2)).format("Y-MM-DD")) {
              this.isShow = true;
            }
          }
          this.detail.from_datetime = moment(
            this.detail.from_datetime,
            "YYYY-MM-DD HH:mm:ss"
          );

          this.speakerList = res.event_speakers;

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No business-conclave available"
            );
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    joinNow(link) {
      window.open(link, "_blank");
    },
    subscribe(detail, data) {
      var $this = this;
      var options = {
        key: data.razorpay_sdk_key, // Enter the Key ID generated from the Dashboard
        amount: detail.event_amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Tenx",
        description: "Tenx Description",
        order_id: data.subscription,
        handler: function (response) {
          if (response.razorpay_payment_id) {
            $this.checkoutSuccess(response, data.transaction_token);
          }
        },
        prefill: {
          name: $this.userInfo.first_name,
          email: $this.userInfo.email,
          contact: $this.userInfo.phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function () {
        this.$noty.error("Payment Failed, Please try again");
      });

      rzp1.open();

    },

    checkout(detail) {
      var obj = {};
      obj.event_id = detail.id;
      obj.type = "WEBINAR";
      this.$api
        .postAPI({
          _action: "checkout",
          _body: obj,
        })
        .then((res) => {
          if (res) {
            console.log("response", res);
            this.subscribe(detail, res);
          }
        });
    },
    checkoutSuccess(response, transaction_token) {
      var obj = {};
      // console.log(response)
      (obj.razorpay_payment_id = response.razorpay_payment_id),
        (obj.transaction_token = transaction_token);
      (obj.razorpay_signature = response.razorpay_signature),
        (obj.razorpay_order_id = response.razorpay_order_id),
        //   (obj.amount = amount);
        this.$api
          .postAPI({
            _action: "checkout/success",
            _body: obj,
          })
          .then(() => {
            this.detail.has_register = 1;
            this.$helperService.getSummary();
            this.showThankyouPopup();
          });
    },
    getInfo() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.userInfo = res.response;
        })
        .catch(() => {
          localStorage.removeItem("access_token");
        });
    },
    register(id) {
      if (this.event_type == "INTERNAL") {
        if (this.detail.type == "FREE") {
          var obj = {};
          obj.event_id = id;
          this.$api
            .postAPI({
              _action: "event/participant",
              _body: obj,
              _buttonId: "register-button",
            })
            .then(() => {
              this.detail.has_register = 1;
              this.$helperService.getSummary();
              if (this.event_type == "INTERNAL") {
                this.showThankyouPopup();
              } else {
                window.open(this.detail.webinar_link, "_blank");
              }
            })
            .catch(() => { });
        } else {
          this.checkout(this.detail);
        }
      } else {
        window.open(this.detail.webinar_link, "_blank");
      }


    },
  },
};
</script>
