<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-10">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="$router.go(-1)">
                <img
                  src="/images/back-arrow.svg"
                  width="17"
                  class="mr-2 align-top"
                  alt=""
              /></a>
              Event Details
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end"></div>
        </div>
        <div class="card card-body events-card mb-4">
          <div class="row mb-2">
            <div class="col-md-10 d-flex align-items-center mb-md-0 mb-3">
              <img
                :src="
                  this.detail.category && this.detail.category.image
                    ? this.detail.category.image
                    : '/img/placeholder-image.jpg'
                "
                v-on:click="checked(data)"
                alt=""
                class="img-fluid rounded-circle object-cover"
                style="width: 50px; height: 50px"
              />
              <div class="pl-3">
                <p class="font-medium mb-0 font-xs-14">
                  {{ this.detail.category ? this.detail.category.name : "" }}
                </p>
                <p class="font-12 text-dark mb-1 font-xs-12">
                  {{ detail.description }}
                </p>
              </div>
            </div>
            <div class="col-md-2 text-right">
              <div class="mb-0 float-md-none float-right">
                <a
                  class="text-secondary"
                  id="dropdown_btn"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
                ></a>

                <div
                  class="dropdown-menu dropdown-menu-right show-dropdown"
                  style="
                    position: absolute;
                    transform: translate3d(220px, 44px, 0px);
                    top: 0px;
                    left: 0px;
                    will-change: transform;
                  "
                  x-placement="bottom-end"
                >
                  <a
                    class="dropdown-item"
                    v-show="this.detail.posted_user_id != userId"
                    type="button"
                    v-on:click="report(detail.id)"
                  >
                    Report Activity</a
                  >
                  <a
                    class="dropdown-item"
                    v-show="detail.posted_user_id == userId"
                    type="button"
                    v-on:click="edit(detail.id)"
                  >
                    Edit</a
                  >
                  <a
                    class="dropdown-item"
                    v-show="this.detail.posted_user_id == userId"
                    type="button"
                    v-on:click="clickEvent('delete', detail.id)"
                  >
                    Delete</a
                  >
                </div>
              </div>
              <p
                class="
                  font-12
                  text-color-2
                  mb-0
                  font-xs-12
                  ml-2
                  float-md-none float-left
                "
              >
                {{ this.detail.ago_time }}
              </p>
            </div>
          </div>
          <div class="position-relative mb-3">
            <img
              :src="
                detail?.banner?.banner
                  ? detail?.banner?.banner
                  : '/images/placeholder-image.png'
              "
              class="img-fluid card-img"
              alt=""
              v-on:click="this.$router.push('/health-well-being-details/' + id)"
            />
            <!-- <span
              class="badge bage-brand position-absolute"
              style="left: 10px; top: 10px"
              >{{detail.mode}}</span > -->
          </div>

          <div class="row">
            <div class="col-md-7 mb-md-0 mb-3">
              <p class="text-secondary mb-md-2 mb-2 font-14">
                <img
                  src="/images/calendar.svg"
                  width="14"
                  class="align-baseline mr-1"
                />
                {{
                  $helperService.getFormatedDateAndTime(
                    this.detail.start_date_time
                  )
                }}
              </p>
              <div
                class="mb-2 d-flex align-items-start"
                v-if="this.detail?.category?.has_source_location == 'Y'"
              >
                <img
                  src="/images/location-icon.svg"
                  width="14"
                  class="align-baseline mr-1"
                />
                <div class="pl-1">
                  <p class="font-12 text-secondary-2 mb-0">Starting Point</p>
                  <p class="text-secondary font-14 mb-0">
                    {{ this.detail.starting_point }}
                  </p>
                </div>
              </div>
              <div class="mb-0 d-flex align-items-start">
                <img
                  src="/images/location-icon.svg"
                  width="14"
                  class="align-baseline mr-1"
                />
                <div class="pl-1">
                  <p class="font-12 text-secondary-2 mb-0">Destination</p>
                  <p class="text-secondary font-14 mb-0">
                    {{ this.detail.destination }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-5 text-md-right">
              <p
                v-on:click="clickEvent('interested-list', detail.id)"
                class="text-secondary mb-md-2 mb-2 font-14 cursor-pointer"
              >
                <img
                  src="/images/people.svg"
                  width="14"
                  class="align-baseline mr-1"
                />
                {{ this.detail.interested_user_count }} People Intrested
              </p>
              <button
                class="btn btn-brand-outline w-xs-100 px-md-5 mt-md-4"
                data-toggle="modal"
                data-target="#modal_thankyou"
                id="interested-button"
                v-show="
                  this.detail.posted_user_id != userId &&
                  !this.detail.has_interested &&
                  detail.start_date_time > currentDate
                "
                v-on:click="interested(detail.id)"
              >
                Interested
              </button>

              <button
                class="btn btn-brand-outline w-xs-100 px-md-4 mt-md-4"
                data-toggle="modal"
                data-target="#modal_thankyou"
                id="not-interested-button"
                v-show="
                  detail.posted_user_id != userId && detail.has_interested
                "
                v-on:click="notInterested(detail.id)"
              >
                I am Interested
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="reportModal">
    <div class="modal-content">
      <div class="modal-header pb-0">
        <button
          type="button"
          class="close"
          v-on:click="this.$refs.reportModal.closeModal()"
        >
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body px-4">
        <h5 class="modal-title font-22 mb-0 text-center mb-3">Report</h5>
        <Form ref="reportForm" @submit="reportAPI()">
          <div class="floating-form mb-4">
            <div class="floating-form-group mt-4" style="height: 130px">
              <Field
                as="textarea"
                type="text"
                rows="4"
                name="message"
                rules="required:message"
                :validateOnInput="true"
                v-model="obj.message"
              />

              <label for="message">Please write your problem/suggestion</label>
              <span class="text-danger">*</span>
            </div>
            <ErrorMessage name="message" class="text-danger" />
          </div>
          <div class="text-center">
            <button class="btn btn-brand-1" id="submit-button">Submit</button>
          </div>
        </Form>
      </div>
    </div>
  </TextModal>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
  <TextModal ref="textModal" class="modal-small">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="$refs.textModal.closeModal"
        >
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-5">
        <h5 class="modal-title font-22 mb-4">Thank you</h5>
        <img src="/images/claping.png" alt="" width="75" class="mb-3" />
        <p class="font-18">
          Thanks for showing <br />
          interest in this activity.
        </p>
      </div>
      <div class="modal-footer justify-content-center border-0 pb-4 pt-0">
        <button
          type="button"
          class="btn btn-brand-outline px-4"
          style="min-width: 115px"
          v-on:click="showContactModal()"
        >
          Contact Organizer
        </button>
      </div>
    </div>
  </TextModal>
  <TextModal ref="contactModal">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="$refs.contactModal.closeModal()"
          aria-label="Close"
        >
          <img src="images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-5">
        <h5 class="modal-title font-22 mb-0">Contact Number</h5>
        <img src="images/contact	.png" alt="" width="180" class="mb-3" />
        <p class="">You can contact organizer using below contact number.</p>
        <p class="font-medium font-18">
          <a :href="'tel:+91' + this.contact">+91{{ this.contact }}</a>
        </p>
      </div>
    </div>
  </TextModal>
</template>
<script>
import DeleteModal from "@/components/DeleteModal";
import TextModal from "@/components/TextModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from "moment";
export default {
  name: "HealthDetail",
  components: {
    TextModal,
    DeleteModal,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      edit_id: "",
      loader: true,
      detail: {},
      currentDate: new Date(),
      userId: localStorage.getItem("userId"),
      obj: {},
    };
  },
  watch: {
    "$route.params.id"(newId) {
      this.id = newId;
      this.getDetail();
    },
  },
  mounted() {
    this.getDetail();
    this.$store.commit("showSidebar", true);
  },
  methods: {
    getAddressData: function (addressData) {
      this.detail.starting_point = addressData.locality;
      this.detail.latitude = addressData.latitude;
      this.detail.longitude = addressData.longitude;
      console.log(this.detail.starting_point);
    },
    getDetails() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.getActivityList(res.response);
        })
        .catch(() => {});
    },
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "ALL") {
        this.searchObj.list_type = "ALL";
      } else if (tab == "POSTED_BY_ME") {
        this.searchObj.list_type = "POSTED_BY_ME";
      } else if (tab == "INTERESTED") {
        this.searchObj.list_type = "INTERESTED";
      }
      this.getList(0);
    },
    remove(data) {
      data.isChecked = false;
      this.getList(0);
    },
    edit(id) {
      this.edit_id = id;
      this.getDetail();
      this.$refs.activityModal.showModal();
    },
    checked(data) {
      data.isChecked = !data.isChecked;
    },

    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.searchObj.offset = offset;
      this.searchObj.activity_id = "";

      for (var i in this.activityList) {
        if (this.activityList[i].isChecked == true) {
          if (this.searchObj.activity_id) {
            this.searchObj.activity_id += "," + this.activityList[i].id;
          } else {
            this.searchObj.activity_id = this.activityList[i].id;
          }
        }
      }
      this.$refs.errorComponent.updateLoader(true);
      if (offset == 0) {
        this.healthList = [];
        this.setInterest(this.searchObj.activity_id);
      }
      this.$api
        .getAPI({
          _action: "health-well-beings",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          for (var i in res.list) {
            res.list[i].start_date_time = moment(
              res.list[i].start_date_time,
              "YYYY-MM-DD HH:mm:ss"
            );

            this.healthList.push(res.list[i]);
          }
          this.searchObj.offset = res.next_offset;
          if (offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No activity available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    getActivityList(detail) {
      this.$api
        .getAPI({
          _action: "category/activity",
        })
        .then((res) => {
          for (var i in res.list) {
            var isChecked = false;
            for (var j in detail.interested_activity) {
              if (detail.interested_activity[j].interest_id == res.list[i].id) {
                isChecked = true;
              }
            }

            res.list[i].isChecked = isChecked;
          }

          this.activityList = res.list;
          this.changeTab("ALL");
          // this.getList(0);
        })
        .catch(() => {});
    },
    getInterestedList(id) {
      this.$api
        .getAPI({
          _action: "health-well-being/" + id + "/interested",
        })
        .then((res) => {
          this.interestedList = res.list;
          this.$refs.interestedModal.showModal();
        })
        .catch(() => {});
    },
    showFilterPopup() {
      this.$refs.filterModal.showModal();
    },
    showContactModal() {
      this.$refs.textModal.closeModal();
      this.$refs.contactModal.showModal();
    },
    showActivityPopup(id) {
      if (id) {
        this.$api
          .getAPI({
            _action: "health-well-being/" + id,
          })
          .then((res) => {
            this.detail = res.info;
            this.$refs.activityModal.showModal();
          })
          .catch(() => {});
      } else {
        this.detail = { activity_id: "" };
        this.$refs.activityModal.showModal();
      }
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        "Delete Event",
        "Are you sure you want to delete",
        id
      );
    },
    showThankyouPopup(data) {
      this.contact = data.contact_no;
      this.$refs.textModal.showModal();
    },
    save() {
      this.detail.dial_code = "+91";
      this.detail.country_code = "IN";
      this.detail.start_date_time = moment(this.detail.start_date_time).format(
        "yyyy-MM-DD HH:mm:ss"
      );

      if (this.detail.id) {
        this.$api
          .putAPI({
            _action: "/health-well-being/" + this.detail.id,
            _body: this.detail,
          })
          .then((res) => {
            this.$refs.activityModal.closeModal();
            // this.$router.go(-1);
            this.getList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "health-well-being",
            _body: this.detail,
          })
          .then((res) => {
            this.$refs.activityModal.closeModal();
            this.getList(0);
            (this.detail = {}), this.$refs.activityForm.resetForm();
            // this.$router.go(-1);
            this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateLoader(false);
            }
          });
      }
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "health-well-being/" + id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },

    report(id) {
      this.obj.health_wellbeing_id = id;
      this.$refs.reportModal.showModal();
      // console.log(id);
    },
    reportAPI() {
      this.$api
        .postAPI({
          _action: "health-well-being/report",
          _body: this.obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.list.message,
          });
          this.$refs.reportModal.closeModal();
          (this.obj = {}), this.$refs.reportForm.resetForm();
        })
        .catch(() => {});
    },
    callapi() {
      this.$refs.filterModal.closeModal();
      this.getList(0);
    },
    getDetail() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "health-well-being/" + this.id,
        })
        .then((res) => {
          this.loader = false;
          res.info.start_date_time = moment(
            res.info.start_date_time,
            "YYYY-MM-DD HH:mm:ss"
          );

          this.detail = res.info;
        })
        .catch(() => {});
    },
    notInterested(id) {
      console.log(id);
      this.$api
        .deleteAPI({
          _action: "health-well-being/" + id + "/interested",
          _buttonId: "not-interested-button",
        })
        .then(() => {
          this.$helperService.getSummary();
          this.getDetail();
        })
        .catch(() => {});
    },
    interested(id) {
      var obj = {};
      obj.health_wellbeing_id = id;
      obj.has_interested = true;
      this.$api
        .postAPI({
          _action: "health-well-being/interesed",
          _body: obj,
          _buttonId: "interested-button",
        })
        .then(() => {
          var data = undefined;
          // for (var i in this.healthList) {
          if ((this.detail.id = id)) {
            data = this.detail;
          }

          data.has_interested = true;
          if (data) {
            this.showThankyouPopup(data);
          }
          this.$helperService.getSummary();
          this.getDetail();
        })
        .catch(() => {});
    },
  },
};
</script>