<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-12 text-md-left text-center">
              <h2 class="page-title mb-md-0">Membership Info</h2>
            </div>
          </div>

          <div class="card border-0 mb-4 rounded-l">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8 mt-4">
                  <!-- <div class="text-center mb-4">
                    <div>
                      <img
                        :src="
                          myDetail?.profile_img
                            ? myDetail.profile_img
                            : '/images/placeholder-image.png'
                        "
                        width="110"
                        height="110"
                        class="rounded-circle object-cover mb-2"
                        alt=""
                      />
                    </div>
                    <div class="font-medium">
                      {{ myDetail.first_name }} {{ myDetail.last_name }}
                    </div>
                  </div> -->
                  <!-- <VueSocialSharing /> -->
                  <div class="card card-body rounded-l text-white membership-card mb-4">
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <img
                          :src="
                            myDetail?.profile_img
                              ? myDetail.profile_img
                              : '/images/placeholder-image.png'
                          "
                          width="80"
                          height="80"
                          class="rounded-circle object-cover mb-2"
                          alt=""
                        />
                        <div class="font-medium mb-4" style="color: white">
                          {{ myDetail.first_name }} {{ myDetail.last_name }}
                        </div>
                        <div class="font-bold mb-3 font-20">
                          {{ detail.title }}
                        </div>
                        <div class="font-bold mb-3 font-20">
                          {{ detail.duration_text }}
                        </div>
                        <div class="font-14 mb-3">
                          Valid from {{ detail.start_date }} to
                          {{ detail.end_date }}
                        </div>
                        <div class="font-medium mb-3">
                          {{ detail.subscription_number }}
                        </div>
                        <img src="/images/tenx-logo-white.svg" width="80" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="row col-12 justify-content-center mb-2">
                    <button
                      v-if="detail.invoice && detail.trans_amount > 0"
                      class="btn btn-brand-1 w-xs-50 col-5 mr-1"
                      style="min-width: 78px !important ; height: 60px !important"
                      id="register-button"
                      v-on:click="downloadInvoice(detail.invoice)"
                    >
                      Download Invoice
                    </button>
                    <button
                      v-if="detail.certificate"
                      class="btn btn-brand-1 w-xs-50 col-5 ml-1"
                      style="min-width: 78px !important ; height: 60px !important"
                      id="register-button"
                      v-on:click="downloadCertificate(detail.certificate)"
                    >
                      Download Certificate
                    </button>
                  </div>
                  <br />
                  <div
                    class="card card-body border-0 rouded-common d-flex shadow-sm flex-row mb-4"
                  >
                    <img src="/images/hours-glass.svg" width="30" alt="" />
                    <div class="pl-3 w-100">
                      <p class="mb-2">
                        <span class="font-medium text-primary"
                          >{{ detail.days }} days left</span
                        >
                        in membership
                      </p>
                      <div class="progress" style="height: 5px">
                        <div
                          class="progress-bar bg-brand"
                          role="progressbar"
                          style="width: 65%"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center my-4" v-show="showUpgradeNow">
                    <button
                      type="button"
                      class="btn btn-brand-1 px-md-5"
                      v-on:click="this.$router.push('/select-plan/' + this.type)"
                    >
                      Upgrade Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ShareModal ref="shareModal" />
</template>
<script>
// import VueSocialSharing from "vue-social-sharing";
import ShareModal from "../../components/SocialShare/ShareModal.vue";
import moment from "moment";
export default {
  name: "MembershipInfo",
  components: {
    // VueSocialSharing,
    ShareModal,
  },
  data() {
    return {
      showUpgradeNow: true,
      myDetail: {},
      detail: {},
      type: "upgrade",
    };
  },
  mounted() {
    this.getDetail();
    this.meCall();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "membership/info",
        })
        .then((res) => {
          const date1 = new Date();
          const date2 = new Date(res.info.end_date);
          const diffTime = date2 - date1;
          res.info.days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          this.detail = res.info;
          this.detail.end_date = moment(this.detail.end_date, "YYYY-MM-DD").format(
            "DD MMMM YYYY "
          );

          this.detail.start_date = moment(this.detail.start_date, "YYYY-MM-DD").format(
            "DD MMMM YYYY "
          );
          if (this.detail.days > 30 && this.detail.title == "Platinum") {
            this.showUpgradeNow = false;
          }
        })
        .catch(() => {});
    },
    meCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.myDetail = res.response;
        })
        .catch(() => {});
    },
    downloadInvoice(invoice) {
      if (invoice == null || invoice == undefined) {
        this.$notify({
          type: "success",
          text: "Invoice not available",
        });
      } else {
        window.open(invoice, "_blank");
      }
    },
    downloadCertificate(certificate) {
      if (certificate == null || certificate == undefined) {
        this.$notify({
          type: "success",
          text: "certificate not available",
        });
      } else {
        window.open(certificate, "_blank");
      }
    },
  },
};
</script>
