<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt=""
                /></a>
                {{ detail.name }}
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card mb-4 rounded-l border-0 shadow-sm">
            <!-- <div class="card-header p-0 border-0">
              <img
                src="/images/cover-img.jpg"
                class="card-img-top rounded-l cover-img"
                alt=""
              />
            </div> -->
            <div class="card-body px-md-5 -x3">
              <div class="text-center mt-n5">
                <img
                  :src="detail.image ? detail.image : '/images/profile.svg'"
                  width="120"
                  height="120"
                  class="
                    rounded-circle
                    object-cover
                    border border-light
                    shadow
                    mb-3
                    mt-n4
                  "
                  style="border-width: 3px !important"
                />
                <p class="font-bold font-22 mb-1">{{ detail.name }}</p>
                <p class="text-secondary mb-0">{{ detail.profession }}</p>
                <p class="text-secondary mb-0" v-show="detail.experience">
                  Exp-{{ detail.experience }}
                </p>
              </div>
              <div><hr /></div>
              <h4 class="font-20">About {{ detail.name }}</h4>
              <p>
                {{ detail.about_speaker }}
              </p>
              <p v-show="detail.facebook_link">
                <a
                  target="_blank"
                  :href="'https://www.facebook.com/' + detail.facebook_link"
                  style="color: #2f80ed"
                  ><span class="font-20 mr-2"
                    ><i
                      class="fa fa-facebook-official"
                      aria-hidden="true"
                    ></i></span
                  >www.facebook.com/{{ detail.facebook_link }}</a
                >
              </p>
              <p v-show="detail.linkedin_link">
                <a
                  target="_blank"
                  :href="'https://www.linkedin.com/' + detail.linkedin_link"
                  style="color: #117eb8"
                  ><span class="font-20 mr-2"
                    ><i
                      class="fa fa-linkedin-square"
                      aria-hidden="true"
                    ></i></span
                  >www.linkedin.com/{{ detail.linkedin_link }}</a
                >
              </p>
              <p v-show="detail.twitter_link">
                <a
                  target="_blank"
                  :href="'https://www.twittwr.com/' + detail.twitter_link"
                  style="color: #117eb8"
                  ><span class="font-20 mr-2"
                    ><i
                      class="fa fa-twitter-square"
                      aria-hidden="true"
                    ></i></span
                  >www.twitter.com/{{ detail.twitter_link }}</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AuthorProfile",
  components: {},
  data() {
    console.log("data cdfvsd", this.$route.query);
    return {
      id: this.$route.params.id,
      event_id: this.$route.query.event_id,
      detail: {},
    };
  },
  mounted() {
    this.getDetail();
    this.$store.commit("showSidebar", true);
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "event/" + this.event_id + "/speaker/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
  },
};
</script>