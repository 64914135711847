<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                <img
                  src="/images/back-arrow.svg"
                  width="17"
                  class="mr-2 align-top"
                  alt=""
              /></a>
              Company Profile
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end"></div>
        </div>
        <div class="card mb-4 rounded-l border-0 shadow-sm">
          <!-- <div class="card-header p-0 border-0">
            <img
              src="/images/cover-img.jpg"
              class="card-img-top rounded-l"
              alt=""
            />
          </div> -->
          <div class="card-body px-md-5 -x3">
            <div
              class="
                row
                mb-4
                align-items-center
                justify-content-between
                border-bottom
                pb-4
              "
            >
              <div class="col-md-6 d-flex align-items-center mb-md-0 mb-3">
                <img
                  :src="
                    detail.logo ? detail.logo : '/img/placeholder-image.jpg'
                  "
                  alt=""
                  class="img-fluid border"
                  width="120"
                  height="80"
                />
                <div class="pl-4">
                  <p class="font-medium mb-2 font-18 font-xs-16">
                    {{ detail.company_name }}
                  </p>
                  <p class="font-14 cursor-pointer">
                    {{ this.address ? this.address : " " }}
                  </p>
                  <p class="row pl-3" v-if="detail.avg_rating">
                    <star-rating
                      id="expert-list"
                      class="mb-1 text-left"
                      style="justify-content: left"
                      :increment="0.5"
                      :rating="detail.avg_rating"
                      :show-rating="false"
                      :star-size="15"
                      :read-only="true"
                    ></star-rating>
                    <span
                      class="font-14 text-secondary mt-2 ml-1 cursor-pointer"
                      v-on:click="scroll()"
                      >({{ detail.reviews_count }})</span
                    >
                  </p>
                </div>
                <a
                  v-if="detail.id == company_id"
                  class="mb-3 ml-2"
                  style="cursor-pointer"
                  v-on:click="
                    this.$router.push('/editCompanyProfile/' + detail.id)
                  "
                  ><img src="/images/edit-icon.svg" width="20" alt=""
                /></a>
              </div>

              <div class="col-md-5 text-md-right text-center">
                <a :href="'tel:' + detail.dial_code + detail.sales_contact_no">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  {{
                    detail.sales_dial_code
                      ? detail.sales_dial_code
                      : detail.dial_code
                  }}{{
                    detail.sales_contact_no
                      ? detail.sales_contact_no
                      : detail.phone
                  }}
                </a>
                <br />
                <a :href="'mailto:' + detail.sales_email">
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                  {{ detail.sales_email ? detail.sales_email : detail.email }}
                </a>
              </div>
            </div>
            <div
              class="
                col-md-3
                d-flex
                align-items-center
                mb-md-0 mb-3
                px-0
                ml-auto
                cursor-pointer
              "
              v-on:click="$router.push('/member-info/' + ownerDetail.uuid)"
            >
              <img
                :src="
                  ownerDetail.profile_img
                    ? ownerDetail.profile_img
                    : '/images/profile.svg'
                "
                alt=""
                class="img-fluid rounded-circle object-cover"
                style="width: 55px; height: 55px"
              />
              <div class="pl-3">
                <p class="font-medium mb-0 font-xs-16">
                  {{ ownerDetail.first_name }} {{ ownerDetail.last_name }}
                </p>
                <p class="font-14 text-secondary mb-1 font-xs-12">Founder</p>
              </div>
            </div>
            <h4 class="font-18">About {{ detail.company_name }}</h4>
            <p>
              {{ detail.description }}
            </p>

            <nav
              class="mb-3"
              v-show="
                (ServiceList && ServiceList.length > 0) ||
                (ProductList && ProductList.length > 0)
              "
            >
              <div
                class="nav nav-tabs tab-style-2 border-0"
                id="nav-tab"
                role="tablist"
              >
                <a
                  class="nav-link"
                  v-show="ProductList && ProductList.length > 0"
                  :class="{ active: currentTab == 'PRODUCT' }"
                  v-on:click="changeTab('PRODUCT')"
                  style="cursor: pointer"
                  >Products</a
                >
                <a
                  class="nav-link"
                  v-show="ServiceList && ServiceList.length > 0"
                  :class="{ active: currentTab == 'SERVICE' }"
                  v-on:click="changeTab('SERVICE')"
                  style="cursor: pointer"
                  >Services</a
                >
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                v-show="currentTab == 'PRODUCT'"
                class="tab-pane fade show"
                :class="{ active: currentTab == 'PRODUCT' }"
                id="nav-products"
                role="tabpanel"
                aria-labelledby="nav-products-tab"
              >
                <div
                  class="d-md-flex align-items-center mb-3 border-bottom pb-2"
                  v-for="(data, index) in ProductList"
                  :key="index"
                >
                  <div
                    class="
                      col-md-10
                      d-flex
                      align-items-center
                      mb-md-0 mb-3
                      px-0
                      ml-auto
                    "
                  >
                    <img
                      :src="
                        data.image
                          ? data.image
                          : '/images/placeholder-image.jpg'
                      "
                      alt=""
                      class="img-fluid"
                      width="100"
                      height="80"
                    />
                    <div class="pl-md-3 mb-md-0 mb-3">
                      <p class="font-medium mb-1 font-18 font-xs-16">
                        {{ data.title }}
                      </p>
                      <p class="font-14 mb-0">
                        {{ data.description }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="
                      col-md-2
                      d-flex
                      align-items-center
                      mb-md-0 mb-3
                      px-0
                      ml-auto
                    "
                  >
                    <div class="col-md-3 px-0 text-md-right">
                      <button
                        type="button"
                        class="btn btn-brand-outline text-nowrap"
                        v-on:click="showRequestPopup(data)"
                        v-show="ownerDetail.id != myInfo.id"
                      >
                        Request Quotation
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-show="currentTab == 'SERVICE'"
                class="tab-pane fade show"
                :class="{ active: currentTab == 'SERVICE' }"
                id="nav-services"
                role="tabpanel"
                aria-labelledby="nav-services-tab"
              >
                <div
                  class="d-md-flex align-items-center mb-3 border-bottom pb-2"
                  v-for="(data, index) in ServiceList"
                  :key="index"
                >
                  <div
                    class="
                      col-md-10
                      d-flex
                      align-items-center
                      mb-md-0 mb-3
                      px-0
                      ml-auto
                    "
                  >
                    <img
                      :src="
                        data.image
                          ? data.image
                          : '/images/placeholder-image.jpg'
                      "
                      alt=""
                      class="img-fluid"
                      width="100"
                      height="80"
                    />
                    <div class="pl-md-3 mb-md-0 mb-3">
                      <p class="font-medium mb-1 font-18 font-xs-16">
                        {{ data.title }}
                      </p>
                      <p class="font-14 mb-0">
                        {{ data.description }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="
                      col-md-2
                      d-flex
                      align-items-center
                      mb-md-0 mb-3
                      px-0
                      ml-auto
                    "
                  >
                    <div class="col-md-3 px-0 text-md-right">
                      <button
                        type="button"
                        class="btn btn-brand-outline text-nowrap"
                        v-on:click="showRequestPopup(data)"
                        v-show="ownerDetail.id != myInfo.id"
                      >
                        Request Quotation
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4 px-md-0 px-2 justify-content-left">
              <div class="col-lg-6 mb-3">
                <p class="font-xs-18 font-20 font-medium">Reviews</p>
              </div>
              <div
                class="col-6 col-lg-6 mb-3"
                style="text-align-last: end"
                v-if="this.count > 5"
              >
                <a class="text-dark cursor-pointer" v-on:click="reviewRoute()"
                  >View all</a
                >
              </div>

              <div
                class=" row col-12 mb-4 justify-content-center"
                v-show="reviewList.length == 0"
              >
                <h6>No Review Added Yet</h6>
              </div>
              <div
                class="row col-12 mb-3"
                v-for="(data, index) in reviewList"
                :key="index"
                v-on:click="
                  this.$router.push('/member-info/' + data?.user.uuid)
                "
              >
                <div class="col-md-12 col-12 mb-3">
                  <div
                    class="d-flex cursor-pointer"
                    v-on:click="userRoute(data.user.uuid)"
                  >
                    <img
                      :src="
                        data?.user?.profile_img
                          ? data.user.profile_img
                          : '/images/placeholder-image.jpg'
                      "
                      width="40"
                      height="40"
                      class="rounded-circle object-cover mr-2"
                      alt=""
                    />
                    <div class="ps-2">
                      <p class="mb-0 font-xs-14 text-dark">
                        {{ data.user.first_name }} {{ data.last_name }}
                      </p>
                      <p class="mb-0 ratings font-14">
                        <star-rating
                          id="expert-list"
                          class="mb-1 text-left"
                          style="justify-content: left"
                          :increment="0.5"
                          :rating="data.rating"
                          :show-rating="false"
                          :star-size="15"
                          :read-only="true"
                        ></star-rating>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-5 col-4 text-end">
                    <p class="text-secondary font-14 mb-1 font-xs-12">
                      {{ data.created_at }}
                    </p>
                    <p class="font-14 mb-0">
                      <a
                        class="like"
                        :class="data.is_like == '1' ? 'active' : ''"
                        v-on:click="likeComment(data)"
                      >
                        <i
                          class="bi bi-hand-thumbs-up-fill me-1 cursor-pointer"
                        ></i>
                      </a>
                      {{ data.like_count }}
                    </p>
                  </div> -->
                <div class="col-12 text-secondary">
                  {{ data.review }}
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Write Req-->
  <TextModal ref="quotation">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="$refs.quotation.closeModal()"
        >
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body form-style px-md-5">
        <h5 class="font-22 modal-title text-center mb-4" id="ReviewModallLabel">
          Write Requirement
        </h5>
        <Form @submit="getQuotation()" class="floating-form">
          <div class="floating-form-group" style="height: 130px">
            <Field
              as="textarea"
              rows="4"
              id="requirement"
              name="requirement"
              style="padding: 0 !important"
              v-model="this.quotationObj.message"
              rules="required:requirement"
              :validateOnInput="true"
            ></Field>
            <label for="requirement">Requirement</label>
          </div>
          <ErrorMessage name="requirement" class="validation-msg" />
          <div
            class="
              modal-footer
              text-center
              justify-content-center
              border-0
              pt-0
            "
          >
            <button class="btn btn-brand-1 px-4 mb-3">Submit</button>
          </div>
        </Form>
      </div>
    </div>
  </TextModal>
  <!-- Modal success-->
  <TextModal ref="success">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="$refs.success.closeModal()"
        >
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-5">
        <h5 class="font-22 modal-title text-center mb-4" id="ReviewModallLabel">
          Thank You !
        </h5>
        <img src="/img/success-message.svg" width="90" class="mb-4" />
        <p class="mb-0">
          Quotation request <br />
          sent successfully.
        </p>
      </div>
      <div
        class="modal-footer text-center justify-content-center border-0 pt-0"
      >
        <button
          type="button"
          class="btn btn-brand-1 px-4 mb-3"
          v-on:click="this.$refs.success.closeModal()"
        >
          OK
        </button>
      </div>
    </div>
  </TextModal>
</template>
<script>
import StarRating from "vue-star-rating";
import TextModal from "@/components/TextModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "CompanyDetail",
  components: {
    TextModal,
    Form,
    Field,
    ErrorMessage,
    StarRating,
  },
  data() {
    return {
      company_id: localStorage.getItem("companyId"),
      id: this.$route.params.id,
      currentTab: "",
      keyword: "",
      detail: {
        address: "",
      },
      ProductList: [],
      ServiceList: [],
      ownerDetail: [],
      quotationObj: { message: "" },
      myInfo: {},
      address: "",
      count: "",
      reviewList: [],
    };
  },
  mounted() {
    this.meCall();
    this.getCompanyDetail();
    this.getReviews(0);
    this.$store.commit("showSidebar", false);
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "PRODUCT") {
        this.getCompanyDetail();
      } else if (tab == "SERVICE") {
        this.getCompanyDetail();
      }
    },
    meCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.myInfo = res.response;
        })
        .catch(() => {});
    },
    getProductList(list) {
      this.ProductList = list;
    },
    getServiceList(list) {
      this.ServiceList = list;
    },
    getOwnerDetail(list) {
      this.ownerDetail = list;
    },
    getCompanyDetail() {
      var obj = {};
      if (this.keyword) {
        obj.keyword = this.keyword;
      }
      this.$api
        .getAPI({
          _action: "company/" + this.id,
          _body: obj,
        })
        .then((res) => {
          this.detail = res.response;
          this.address =
            this.detail.address.address_1 +
            " " +
            this.detail.address.address_2 +
            " , " +
            this.detail.address.city_name;
          if (res.response.products.length < res.response.services.length) {
            this.currentTab = "SERVICE";
          } else {
            this.currentTab = "PRODUCT";
          }
          this.count = this.detail.reviews_count;
          this.getProductList(res.response.products);
          this.getServiceList(res.response.services);
          this.getOwnerDetail(res.response.owner);
          this.detail.address = res.response.addresses[0];
        })
        .catch(() => {});
    },
    getQuotation() {
      this.$api
        .postAPI({
          _action: "company/" + this.quotationObj.url + "/quotation",
          _body: this.quotationObj,
        })
        .then((res) => {
          if (res) {
            this.$refs.success.showModal();
            this.$refs.quotation.closeModal();
            this.quotationObj = {};
          }
        });
    },
    showRequestPopup(data) {
      if (data.product_id) {
        this.quotationObj = {
          product_id: data.product_id,
          url: "product",
          message: "",
        };
      } else {
        this.quotationObj = { service_id: data.service_id, url: "service" };
      }

      this.$refs.quotation.showModal();
    },
    showSuccessPopup() {
      // this.$refs.quotation.closeModal();
      this.$refs.success.showModal();
    },
    getReviews(offset) {
      var obj = {};
      if (this.keyword) {
        obj.keyword = this.keyword;
        obj.offset = offset;
      }
      this.$api
        .getAPI({
          _action: "company/" + this.id + "/reviews",
          _body: obj,
        })
        .then((res) => {
          this.reviewList = res.list;
          this.count = res.count;
          // console.log("count", res.count);
        })
        .catch(() => {});
    },
    reviewRoute() {
      this.$router.push("/review/" + this.id);
    },
    scroll() {
      window.scrollTo(0, document.body.scrollHeight);
    },
  },
};
</script>