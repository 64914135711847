<template>
  <div class="right-sidebar scrollscreen">
    <div class="mb-4 direction-ltr">
      <div class="title">
        <span>My Company Profile</span>
      </div>
      <div
        class="d-flex align-itesms-center mb-3"
        v-for="(data, index) in detail.company"
        :key="index"
      >
        <img
          :src="
            data.logo && data.logo != null
              ? data.logo
              : '/img/placeholder-image.jpg'
          "
          width="50"
          height="30"
          style="object-fit: contain; cursor: pointer"
          alt=""
          v-on:click="this.$router.push('/companies-detail/' + data.id)"
        />
        <div
          class="ml-2 cursor-pointer"
          v-on:click="this.$router.push('/companies-detail/' + data.id)"
        >
          <p class="mb-0">{{ data.company_name }}</p>
          <p class="font-12 text-secondary">
            {{ data.sales_email ? data.sales_email : data.email }} <br />
            {{ data.sales_contact_no ? data.sales_dial_code : data.dial_code }}
            {{ data.sales_contact_no ? data.sales_contact_no : data.phone }}
          </p>
        </div>
        <a
          style="cursor-pointer"
          v-on:click="this.$router.push('/editCompanyProfile/' + data.id)"
          ><img src="images/edit-icon.svg" width="20" alt=""
        /></a>
      </div>
    </div>
    <div
      class="mb-4 direction-ltr"
      v-show="detail.businessGroup && detail.businessGroup.length > 0"
    >
      <div class="title">
        <span>My TenX Growth Group</span>
      </div>
      <div
        class="d-flex align-itesms-center cursor-pointer"
        v-for="(data, index) in detail.businessGroup"
        :key="index"
        v-on:click="this.$router.push('/business-growth-detail/' + data.id)"
      >
        <img
          :src="
            data.logo && data.logo != null
              ? data.logo
              : '/img/placeholder-image.jpg'
          "
          width="40"
          height="40"
          class="rounded-circle object-cover"
          alt=""
        />
        <div class="ml-2">
          <p class="mb-0">{{ data.name }}</p>
          <p class="font-12 text-secondary">
            <img src="/images/location-icon.svg" width="15" /> {{ data.city }}
            <br />
            {{ data.no_of_member }}/{{ data.group_size }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="mb-4 direction-ltr"
      v-show="detail.webinars && detail.webinars.length > 0"
    >
      <div class="title">
        <span>Upcoming Webinars</span>
      </div>
      <div
        class="d-flex align-itesms-center mb-3 cursor-pointer"
        v-for="(data, index) in detail.webinars"
        :key="index"
        v-on:click="this.$router.push('/webinar-details/' + data.id)"
      >
        <img
          :src="
            data.image && data.image != null
              ? data.image
              : '/img/placeholder-image.jpg'
          "
          width="40"
          height="40"
          class="rounded-circle object-cover"
          alt=""
        />
        <div class="ml-2">
          <p class="mb-1">{{ data.name }}</p>
          <p class="font-12 text-secondary mb-1">
            <img src="/images/calendar.svg" width="14" />
            {{ $helperService.getFormatedDate(data.from_datetime) }}
            <br />
          </p>
          <button
            v-show="data.webinar_link"
            class="btn btn-brand-1 w-xs-50"
            style="min-width: 78px !important ; height: 34px !important"
            id="register-button"
            v-on:click="joinNow(data.webinar_link)"
          >
            Join Now
          </button>
          <p v-show="!data.webinar_link">
            You are already registered for this event
          </p>
        </div>
      </div>
    </div>

    <div
      class="mb-4 direction-ltr"
      v-show="detail.business_conclaves && detail.business_conclaves.length > 0"
    >
      <div class="title">
        <span>Upcoming Business Conclave</span>
      </div>
      <div
        class="d-flex align-itesms-center mb-3 cursor-pointer"
        v-for="(data, index) in detail.business_conclaves"
        :key="index"
        v-on:click="this.$router.push('/business-conclave-details/' + data.id)"
      >
        <img
          :src="
            data.image && data.image != null
              ? data.image
              : '/img/placeholder-image.jpg'
          "
          width="40"
          height="40"
          class="rounded-circle object-cover"
          alt=""
        />
        <div class="ml-2">
          <p class="mb-1">{{ data.name }}</p>
          <p class="font-12 text-secondary mb-1">
            <img src="/images/calendar.svg" width="14" />
            {{ $helperService.getFormatedDate(data.from_datetime) }}
            <br />
          </p>
          <p>You are already registered. View your digital pass.</p>
        </div>
      </div>
    </div>

    <div
      class="mb-4 direction-ltr"
      v-show="detail.activity && detail.activity.length > 0"
    >
      <div class="title">
        <span>Upcoming Activity</span>
      </div>
      <div
        class="d-flex align-itesms-center mb-4 cursor-pointer"
        v-for="(data, index) in detail.activity"
        :key="index"
        v-on:click="this.$router.push('/health-well-being-details/' + data.id)"
      >
        <img
          :src="
            data.category.image
              ? data.category.image
              : '/img/placeholder-image.jpg'
          "
          width="40"
          height="40"
          class="rounded-circle object-cover"
          alt=""
        />
        <div class="ml-2">
          <p class="mb-1">{{ data.category.name }}</p>
          <p class="font-12 text-secondary mb-1">
            <img src="/images/calendar.svg" width="14" />
            {{ $helperService.getFormatedDate(data.start_date_time) }}
            <br />
          </p>
          <p class="font-12 text-secondary mb-0">
            <img src="/images/location-icon.svg" width="15" />{{
              data.destination
            }}<br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RightSidebar",
  data() {
    return {};
  },
  computed: {
    detail() {
      return this.$store.getters.getSummary
        ? this.$store.getters.getSummary
        : { company: [], businessGroup: [], webinars: [], activity: [] };
    },
  },
  mounted() {
    this.$helperService.getSummary();
  },
  methods: {
    joinNow(link) {
      window.open(link, "_blank");
    },
  },
};
</script>