<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0" v-if="id">
                <a v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    class="mr-2 align-top"
                    alt=""
                    width="17"
                /></a>
                Edit Company Profile
              </h2>
              <h2 class="page-title mb-md-0" v-else>
                <a v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    class="mr-2 align-top"
                    alt=""
                    width="17"
                /></a>
                {{ id ? "Edit" : "Add" }} Company Profile
              </h2>
            </div>
          </div>
          <div class="card border-0 mb-4 rounded-l">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-10 mt-4">
                  <form class="form-style floating-form">
                    <Form @submit="save()">
                      <div class="row">
                        <div class="col-12">
                          <div class="custom-select-img">
                            <div class="file-upload">
                              <div class="image-upload-wrap p-3 mb-4">
                                <input
                                  type="file"
                                  name="img[]"
                                  id="pickFile"
                                  class="file-upload-input"
                                  v-on:change="selectedFile($event)"
                                  accept="image/*"
                                />

                                <div class="drag-text text-center">
                                  <img
                                    :src="
                                      detail.logo && detail.logo != null
                                        ? detail.logo
                                        : '/images/Imageplace-holder.svg'
                                    "
                                    id="preview"
                                    class="mb-2"
                                    width="60"
                                    height="50"
                                  />
                                  <h3 for="pickFile" class="heading font-20">
                                    Upload a Company Logo
                                  </h3>
                                  <p class="font-12 text-secondary mb-1">
                                    PNG, GIF, JPEG. (Max 10 mb)
                                  </p>
                                  <p class="font-12 text-secondary mb-0">
                                    (Optional)
                                  </p>
                                </div>
                              </div>
                              <div class="row col-12 justify-content-center">
                                <h6>Preferred Image Size : 500px X 500 px</h6>
                              </div>
                              <div class="file-upload-content">
                                <img
                                  class="file-upload-image mb-3"
                                  src="#"
                                  alt="your image"
                                />
                                <div class="image-title-wrap">
                                  <button
                                    type="button"
                                    onclick="removeUpload()"
                                    class="remove-image"
                                  >
                                    <i
                                      class="fa fa-times text-danger mr-2"
                                      aria-hidden="true"
                                    ></i>
                                    <span class="image-title"
                                      >Uploaded Image</span
                                    >
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              id="gst_number"
                              :class="id ? 'filled' : ''"
                              v-model="detail.gst_number"
                              v-on:keyup="getCompany(detail.gst_number)"
                              name="gst_number"
                            ></Field>
                            <label for="gst_number">GST Number</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              id="compny_name"
                              :class="detail.company_name ? 'filled' : ''"
                              v-model="detail.company_name"
                              rules="required:company"
                              name="company"
                              :validateOnInput="true"
                            />
                            <label for="compny_name">Company Name</label
                            ><span class="text-danger">*</span>
                          </div>
                          <ErrorMessage name="company" class="validation-msg" />
                        </div>
                        <div class="col-md-6">
                          <div class="floating-form-group with icon">
                            <vue-google-autocomplete
                              ref="address"
                              id="map"
                              :classname="
                                detail.city_name
                                  ? 'form-control filled'
                                  : 'form-control'
                              "
                              types="(cities)"
                              v-on:placechanged="getAddressData"
                              country="in"
                            >
                            </vue-google-autocomplete>
                            <label for="map">City</label>
                            <img
                              src="/images/location-icon.svg"
                              width="20"
                              class="icon"
                            />
                          </div>
                          <ErrorMessage
                            name="city_name"
                            class="validation-msg"
                          />
                        </div>
                        <div class="col-md-6">
                          <div class="floating-form-group">
                            <Field
                              type="email"
                              id="email"
                              name="email"
                              :class="id ? 'filled' : ''"
                              v-model="detail.email"
                              rules="required:email"
                              :validateOnInput="true"
                            />
                            <label for="email">Email</label
                            ><span class="text-danger">*</span>
                          </div>
                          <ErrorMessage name="email" class="validation-msg" />
                        </div>
                        <div class="col-md-6">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              id="phone"
                              :class="id ? 'filled' : ''"
                              name="phone"
                              v-model="detail.phone"
                              rules="required:phone number|phone"
                              :validateOnInput="true"
                            />
                            <label for="phone">Phone</label
                            ><span class="text-danger">*</span>
                          </div>
                          <ErrorMessage name="phone" class="validation-msg" />
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for=" ">Industry</label
                            ><span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="detail.industry_id"
                              name="industry"
                              rules="required:industry,true"
                            >
                              <option value="">Select</option>
                              <option
                                v-for="(data, index) in industryList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.name }}
                              </option>
                            </Field>
                          </div>
                          <ErrorMessage
                            name="industry"
                            class="validation-msg"
                          />
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for=" ">No of Employees</label
                            ><span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="detail.no_of_employees"
                              name="employee"
                              rules="required:employees,true"
                            >
                              <option value="">Select</option>
                              <option value="0-10">0-10</option>
                              <option value="10-20">10-20</option>
                              <option value="20-50">20-50</option>
                              <option value="50-100">50-100</option>
                              <option value="100+">100+</option>
                            </Field>
                          </div>
                          <ErrorMessage
                            name="employee"
                            class="validation-msg"
                          />
                        </div>
                        <div class="col-lg-12 mb-4">
                          <div class="floating-form-group">
                            <Field
                              as="textarea"
                              type="text"
                              id="description"
                              :class="id ? 'filled' : ''"
                              v-model="detail.description"
                              rules="required:description"
                              name="description"
                              :validateOnInput="true"
                            ></Field>
                            <label for="description">About</label>
                            <span class="text-danger">*</span>
                          </div>
                          <ErrorMessage
                            name="description"
                            class="validation-msg mt-4"
                          />
                        </div>

                        <div class="col-md-6 mt-2">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              id="salesContactNumber"
                              :class="id ? 'filled' : ''"
                              name="salesContactNumber"
                              v-model="detail.sales_contact_no"
                              rules="required: sales contact number|phone"
                              :validateOnInput="true"
                            />
                            <label for="salesContactNumber"
                              >Sales Contact Number</label
                            ><span class="text-danger">*</span>
                          </div>
                          <ErrorMessage
                            name="salesContactNumber"
                            class="validation-msg"
                          />
                        </div>
                        <div class="col-md-6 mt-2">
                          <div class="floating-form-group">
                            <Field
                              type="email"
                              id="sales_email"
                              name="salesemail"
                              :class="id ? 'filled' : ''"
                              v-model="detail.sales_email"
                              rules="required:salesemail"
                              :validateOnInput="true"
                            />
                            <label for="email">Email</label
                            ><span class="text-danger">*</span>
                          </div>
                          <ErrorMessage
                            name="salesemail"
                            class="validation-msg"
                          />
                        </div>
                        <div class="col-lg-12 mt-3">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              id="website"
                              :class="id ? 'filled' : ''"
                              v-model="detail.website"
                              name="website"
                            ></Field>
                            <label for="website">Website (Optional)</label>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mt-3">
                        <button
                          class="btn btn-brand-1 mb-3 px-5"
                          id="save-button"
                        >
                          {{ id ? "Continue to add product/service" : "Save" }}
                        </button>
                      </div>
                    </Form>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { Form, Field, ErrorMessage } from "vee-validate";
import api from "../../services/WebService";
import $ from "jquery";
var md5 = require("md5");
export default {
  name: "AddCompanyProfile",
  components: {
    Form,
    Field,
    ErrorMessage,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      id: this.$route.params.id,
      industryList: [],
      type: "PRODUCT",
      code: "update",
      last_name: "",
      detail: {
        city_name: "",
        dial_code: "+91",
        country_code: "IN",
        sales_dial_code: "+91",
        sales_country_code: "IN",
        industry: {},
      },
      obj: "edit",
    };
  },
  mounted() {
    if (this.id) {
      localStorage.setItem("companyId", this.id);
      this.getDetail();
    }
    this.getIndustryList();
    //floating form
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
  },
  methods: {
    getAddressData: function (addressData, placeResultData) {
      this.detail.city_name = placeResultData.formatted_address;
      this.detail.latitude = addressData.latitude;
      this.detail.longitude = addressData.longitude;
      var $this = this;
      setTimeout(() => {
        document.getElementById("map").value = $this.detail.city_name;
      }, 100);
    },
    save() {
      if (!this.detail.latitude || !this.detail.longitude) {
        this.$notify({
          type: "error",
          text: "Please select city from geo location",
        });
        return false;
      }

      if (this.id) {
        this.$api
          .putAPI({
            _action: "company/" + this.id,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.uploadImage(this.id, this.obj);
          })
          .catch(() => {});
      } else {
        api
          .postAPI({
            _action: "company",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (res) {
              localStorage.setItem("companyId", res.company_id);
              this.uploadImage(res.company_id);
            }
          })
          .catch(() => {});
      }
    },
    getIndustryList() {
      this.$api
        .getAPI({
          _action: "category/industry",
        })
        .then((res) => {
          this.industryList = res.list;
        })
        .catch(() => {});
    },
    getCompany(e) {
      if (e.length == 15) {
        var ENCRYPTION_KEY = "Sph!nx@T@nX";
        var keygstmd5 = md5(e + "-" + ENCRYPTION_KEY);
        this.$api
          .getAPI({
            _action: "search/gst/" + e,
            _header: { "x-api-key": keygstmd5 },
          })
          .then((res) => {
            console.log("company", res);
            this.detail.company_name = res.company_name;
          })
          .catch(() => {});
      }
    },
    getDetail() {
      api
        .getAPI({
          _action: "/company/" + this.id,
        })
        .then((res) => {
          if (!res.response.dial_code) {
            res.response.dial_code = "+91";
          }
          if (!res.response.country_code) {
            res.response.country_code = "IN";
          }
          if (!res.response.sales_dial_code) {
            res.response.sales_dial_code = "+91";
          }
          if (!res.response.sales_country_code) {
            res.response.sales_country_code = "IN";
          }
          this.detail = res.response;
          console.log("res", res);
          var $this = this;
          setTimeout(() => {
            document.getElementById("map").value = $this.detail.city_name;
          }, 500);
        })
        .catch(() => {});
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage(id, data) {
      console.log("first", data);
      if (!this.file) {
        if (data == "edit") {
          this.$router.push(
            "/product-service-list/" + this.type + "/" + this.code
          );
          return;
        } else {
          this.$router.go(-1);
          return;
        }
      }
      if (data) {
        var obj = data;
      }
      api
        .uploadImageAPI({
          _action: "company/logo",
          _key: "logo",
          _file: this.file,
          _body: {
            company_id: id,
          },
          _buttonId: "save-button",
        })
        .then(() => {
          if (obj == "edit") {
            this.$router.push(
              "/product-service-list/" + this.type + "/" + this.code
            );
          } else {
            this.$router.go(-1);
          }
        });
    },
  },
};
</script>