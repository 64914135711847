<template>
  <div>
    <div class="page-content">
      <div class="container md-5">
        <div class="mb-md-5 text-left">
          <h2 class="font-xs-18 font-22 font-medium">
            <a class="cursor-pointer" v-on:click="$router.go(-1)">
              <img
                src="/images/back-arrow.svg"
                width="17"
                class="mr-2"
                alt="" /></a
            >Reviews
          </h2>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <hr />
                <div
                  class="pb-3 border-bottom mb-3"
                  v-for="(data, index) in this.reviewList"
                  :key="index"
                  v-on:click="
                    this.$router.push('/member-info/' + data?.user.uuid)
                  "
                >
                  <div class="row">
                    <div class="col-md-9">
                      <div
                        class="d-flex align-items-center cursor-pointer"
                        v-on:click="userRoute(data.customer.uuid)"
                      >
                        <img
                          :src="
                            data?.user?.profile_img
                              ? data.user.profile_img
                              : '/images/profile-placeholder.jpg'
                          "
                          width="48"
                          height="48"
                          class="rounded-circle img-fit-cover mr-3"
                          alt=""
                        />
                        <div class="ms-2">
                          <p class="mb-0 font-14">
                            {{
                              data.user.first_name ? data.user.first_name : " "
                            }}
                            {{
                              data.user.last_name ? data.user.last_name : " "
                            }}
                          </p>
                          <div class="mb-0">
                            <star-rating
                              id="expert-list"
                              class="mb-1 text-left"
                              style="justify-content: center"
                              :increment="0.5"
                              :rating="data.rating"
                              :show-rating="false"
                              :star-size="30"
                              :read-only="true"
                            ></star-rating>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-2">
                      <p class="font-14">
                        {{ data.review }}
                      </p>
                    </div>
                  </div>
                </div>
                <ErrorComponent
                  @retry="getReviewList(0)"
                  ref="errorComponent"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
// import moment from "moment";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "ReviewRatings",
  components: { StarRating, ErrorComponent },
  data() {
    return {
      isLoader: false,
      slug: this.$route.params.slug,
      id: this.$route.params.id,
      reviewList: [],
      searchObj: {
        offset: "",
        keyword: "",
      },
      detail: {
        product_id: "",
      },
      uuid: "",
    };
  },
  mounted() {
    // this.uuid = localStorage.getItem("uuid");
    this.getReviewList(0);
  },
  methods: {
    getReviewList(offset) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.isLoader = true;
      if (offset) {
        this.searchObj.offset = offset;
      }
      this.$api
        .getAPI({
          _action: "/company/" + this.id + "/reviews",
          _body: this.searchObj,
        })
        .then((res) => {
          this.isLoader = false;
          //   this.$refs.sortModal.closeModal();
          //   this.$refs.filterModal.closeModal();
          //   for (var i in res.list) {
          //     res.list[i].created_at = moment(res.list[i].created_at).format(
          //       "DD/MM/YYYY hh:mm A"
          //     );
          //   }
          this.reviewList = res.list;

          // console.log("res", this.reviewList);
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "Review not available");
            this.$refs.errorComponent.updateLoader(false);
          }
          this.isLoader = false;
        })
        .catch(() => {
          this.isLoader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },

    userRoute(id) {
      if (this.role == "guest") {
        this.$router.push("/login");
      } else {
        // console.log("uuid",this.uuid);
        // console.log("id",id);
        if (this.uuid == id) {
          return;
        } else {
          this.$router.push("/customer-details/" + id);
        }
      }
    },
    showPopup() {
      if (this.role == "guest") {
        this.$router.push("/login");
      } else {
        this.$refs.reviewModal.showModal();
      }
    },
    showReview(id) {
      this.$router.push("/my-review/" + id);
    },
    showCurrentRating(rating) {
      this.currentSelectedRating =
        rating === 0
          ? this.currentSelectedRating
          : this.$i18n.t("review.Click to select") +
            rating +
            this.$i18n.t("review.stars");
    },
    setCurrentSelectedRating(rating) {
      this.currentSelectedRating =
        this.$i18n.t("review.You have Selected") +
        rating +
        this.$i18n.t("review.stars");
    },
  },
};
</script>
