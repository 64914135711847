import { createWebHistory, createRouter } from "vue-router";
import Login from "@/views/Onboarding/login.vue";
import Home from "@/views/Home/home.vue";
import MyEvent from "@/views/Event/event.vue";
import ForgotPassword from "@/views/Onboarding/forgot-password.vue";
import ResetPassword from "@/views/Onboarding/reset-password.vue";
import SignUp from "@/views/Onboarding/Signup/signup.vue";
import SelectPlan from "@/views/Onboarding/select-plan.vue";
import SignupStep1 from "@/views/Onboarding/Signup/signup-step-1.vue";
import AddNewBAddress from "@/views/Onboarding/Signup/add-new-business-address.vue";
import AddBAddress from "@/views/Onboarding/Signup/add-business-address.vue";
import AddProductService from "@/views/Onboarding/Signup/add-product-service.vue";
import AddService from "@/views/Onboarding/Signup/add-service.vue";
import ProductServiceList from "@/views/Onboarding/Signup/product-service-list.vue";
import SelectYourInterest from "@/views/Onboarding/Signup/select-your-interest.vue";
import SignupStep4 from "@/views/Onboarding/Signup/signup-step-4.vue";
import MainView from "@/views/main.vue";
import MyProfile from "@/views/Profile/my-profile.vue";
import CompanyProfile from "@/views/Profile/company-profile.vue";
import Exhibition from "@/views/Exhibition/exibition.vue";
import ExibitionDetail from "@/views/Exhibition/exibition-detail.vue";
import Collaboration from "@/views/Collaboration/collaboration.vue";
import TenxEvent from "@/views/Event/event.vue";
import EventDetails from "@/views/Event/event-detail.vue";
import AuthorProfile from "@/views/Event/author-profile.vue";
import FindCompanies from "@/views/SearchCompanies/search-company.vue";
import CompanyDetail from "@/views/SearchCompanies/company-detail.vue";
import BusinessGrowthGroup from "@/views/BusinessGrowthGroups/business-groups.vue";
import BusinessGrowthDetail from "@/views/BusinessGrowthGroups/business-group-detail.vue";
import BusinessConclave from "@/views/BusinessConclave/business-conclave.vue";
import BusinessConclaveDetails from "@/views/BusinessConclave/business-conclave-detail.vue";
import BusinessMember from "@/views/BusinessGrowthGroups/business-members.vue";
import CityMember from "@/views/BusinessGrowthGroups/city-member.vue";
import AboutTenx from "@/views/AboutTenX/aboutTenx.vue";
import HealthAndWellbeing from "@/views/HealthAndWellBeing/health&well-being.vue";
import HealthDetail from "@/views/HealthAndWellBeing/health-detail.vue";
import MyCompanyProfile from "@/views/Profile/my-company-profile.vue";
import AddCompanyProfile from "@/views/Profile/add-company-profile.vue";
import MembershipInfo from "@/views/MembershipInfo/membership-info.vue";
import ContactSupport from "@/views/Contact/contact.vue";
import ExpertProfile from "@/views/BusinessGrowthGroups/member-detail.vue";
import ExpertReview from "@/views/SearchExperts/review.vue";
import MyOffers from "@/views/Offers/offers.vue";
import ExpertCategory from "@/views/SearchExperts/expert-category.vue";
import ExpertList from "@/views/SearchExperts/expert-list.vue";
import ExpertInfo from "@/views/SearchExperts/expert-detail.vue";
import Meetings from "@/views/BusinessGrowthGroups/meetings.vue";
import Attendance from "@/views/BusinessGrowthGroups/attendance.vue";
import Payment from "@/views/Onboarding/Signup/payment.vue";
import MyRequirements from "@/views/My-Requirements/my-requirements.vue";
import MyLeads from "@/views/My-Leads/my-leads.vue";
import MyChat from "@/views/My-Leads/chat.vue";
import ReportPage from "@/views/Offers/report.vue";
import SpeakerProfile from "@/views/BusinessConclave/speakerProfile.vue";
import SpeakerDetail from "@/views/BusinessConclave/SpeakerDetail.vue";
import AdditionalTicket from "@/views/BusinessConclave/additionalTickets.vue";
import MemberInfo from "@/views/BusinessGrowthGroups/member-detail.vue";
import OfferView from "@/views/My-Requirements/offersView.vue";
import NotificationInfo from "@/views/Notifications/notification.vue";
import UpcomingEvents from "@/views/Event/upcoming-events.vue";
import GuestSignUp from "@/views/Onboarding/Signup/guestRegister.vue";
import Feed from "@/views/Home/shared-feed.vue";
import UserList from "@/views/SearchCompanies/user-list.vue";
import InviteMembers from "@/views/BusinessGrowthGroups/invite-member.vue";
import EMagazine from "@/views/E-Magzine/e-magzines.vue";
import Review from "@/views/SearchCompanies/review.vue";
import SharedEvent from "@/views/BusinessConclave/shared-conclave.vue";
import ReferFriend from "@/views/ReferFriend/referFriend.vue";
import ManageUser from "@/views/ManageUser/manageUser.vue";
import AddEditUser from "@/views/ManageUser/addEditUser.vue";
import ViewParticipant from "@/views/BusinessConclave/view_participant.vue";
import ViewBookedTicket from "@/views/BusinessConclave/bookedTicket.vue";

const routes = [
  {
    path: "/",
    redirect: "/upcoming-events",
  },
  {
    path: "/upcoming-events",
    name: "Upcoming Events",
    component: UpcomingEvents,
  },
  {
    path: "/shared-event",
    name: "Shared Event",
    component: SharedEvent,
  },
  {
    path: "/feed",
    name: "Feed",
    component: Feed,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password/:token",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/guest-signup",
    name: "GuestSignUp",
    component: GuestSignUp,
  },
  {
    path: "/select-plan",
    name: "Select Plan",
    component: SelectPlan,
  },

  {
    path: "/speaker-detail/:id",
    name: "Speaker Detail",
    component: SpeakerDetail,
  },

  {
    path: "/signup-step-4",
    name: "Signup Step4",
    meta: {
      requiresAuth: true,
    },
    component: SignupStep4,
  },
  {
    path: "/signup-step-1",
    name: "Signup Step1",
    component: SignupStep1,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/add-new-business-address",
    name: "Add New Business Address",
    component: AddNewBAddress,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/add-business-address",
    name: "Add Business Address",
    component: AddBAddress,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit-business-address/:id",
    name: "Edit Business Address",
    component: AddBAddress,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/add-product-service",
    name: "Add Product Service",
    component: AddProductService,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/add/:type",
    name: "Add Service",
    component: AddService,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit/:type/:id",
    name: "Edit Service",
    component: AddService,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/product-service-list/:type",
    name: "Product Service List",
    meta: {
      requiresAuth: true,
    },
    component: ProductServiceList,
  },
  {
    path: "/select-your-interest",
    name: "Select Your Interest",
    meta: {
      requiresAuth: true,
    },
    component: SelectYourInterest,
  },
  {
    path: "/add-business-address/:type",
    name: "Add Business Address New",
    component: AddBAddress,
  },
  {
    path: "",
    name: "Main",
    component: MainView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/event",
        name: "My Event",
        component: MyEvent,
      },
      {
        path: "/my-profile",
        name: "My profile",
        component: MyProfile,
      },
      {
        path: "/company-profile",
        name: "Company Profile",
        component: CompanyProfile,
      },
      {
        path: "/companyProfile",
        name: "My CompanyProfile",
        component: MyCompanyProfile,
      },
      {
        path: "/addCompanyProfile",
        name: "Add CompanyProfile",
        component: AddCompanyProfile,
      },
      {
        path: "/editCompanyProfile/:id",
        name: "Edit CompanyProfile",
        component: AddCompanyProfile,
      },
      {
        path: "/exibition",
        name: "Exhibition",
        component: Exhibition,
      },
      {
        path: "/exibition-detail/:id",
        name: "Exhibition detail",
        component: ExibitionDetail,
      },
      {
        path: "/collaboration",
        name: "Collaboration",
        component: Collaboration,
      },
      {
        path: "/webinar",
        name: "Event",
        component: TenxEvent,
      },
      {
        path: "/webinar-details/:id",
        name: "Event Details",
        component: EventDetails,
      },
      {
        path: "/author-profile/:id",
        name: "Author Profile",
        component: AuthorProfile,
      },
      {
        path: "/find-companies",
        name: "Find Companies",
        component: FindCompanies,
      },
      {
        path: "/companies-detail/:id",
        name: "Company Detail",
        component: CompanyDetail,
      },
      {
        path: "/business-growth-group",
        name: "TenX Growth Group",
        component: BusinessGrowthGroup,
      },
      {
        path: "/business-growth-detail/:id",
        name: "Business Growth Detail",
        component: BusinessGrowthDetail,
      },
      {
        path: "/business-conclave",
        name: "TenX BusinessConclave ",
        component: BusinessConclave,
      },
      {
        path: "/business-conclave-details/:id",
        name: "TenX BusinessConclave Details",
        component: BusinessConclaveDetails,
      },
      {
        path: "/health-well-being",
        name: "Health And Wellbeing",
        component: HealthAndWellbeing,
      },
      {
        path: "/health-well-being-details/:id",
        name: "Health Detail",
        component: HealthDetail,
      },
      {
        path: "/business-member/:id",
        name: "Business Member",
        component: BusinessMember,
      },
      {
        path: "/city-member/:id",
        name: "City Member",
        component: CityMember,
      },
      {
        path: "/membership-info",
        name: "Membership Info",
        component: MembershipInfo,
      },

      {
        path: "/about-tenx",
        name: "About TenX",
        component: AboutTenx,
      },
      {
        path: "/contact",
        name: "Contact Support",
        component: ContactSupport,
      },
      {
        path: "/member-detail/:id",
        name: "Expert profile",
        component: ExpertProfile,
      },
      {
        path: "/expert-review/:id",
        name: "Expert Review",
        component: ExpertReview,
      },
      {
        path: "/offers",
        name: "My Offers",
        component: MyOffers,
      },
      {
        path: "/expert-category",
        name: "Expert Category",
        component: ExpertCategory,
      },
      {
        path: "/category/:id/expert",
        name: "Expert List",
        component: ExpertList,
      },
      {
        path: "/expert/:id",
        name: "ExpertInfo",
        component: ExpertInfo,
      },

      {
        path: "/meetings/:id",
        name: "Meetings",
        component: Meetings,
      },
      {
        path: "/attendance/:id",
        name: "Attendance",
        component: Attendance,
      },
      {
        path: "/payment",
        name: "Payment",
        component: Payment,
      },
      {
        path: "/my-requirements",
        name: "My Requirements",
        component: MyRequirements,
      },
      {
        path: "/my-leads",
        name: "MyLeads",
        component: MyLeads,
      },
      {
        path: "/chat",
        name: "Chat",
        component: MyChat,
      },
      {
        path: "/select-plan/:type",
        name: "Upgrade Plan",
        component: SelectPlan,
      },
      {
        path: "/select-plan/:type?attribute&&redirect",
        name: "Upgrade Select Plan",
        component: SelectPlan,
      },
      {
        path: "/report/:id",
        name: "Report Page",
        component: ReportPage,
      },
      {
        path: "/speaker-profile/:id",
        name: "Speaker Profile",
        component: SpeakerProfile,
      },
      {
        path: "/product-service-list/:type/:code",
        name: " Update Product Service List",
        component: ProductServiceList,
      },
      {
        path: "/member-info/:id",
        name: "Member Info",
        component: MemberInfo,
      },
      {
        path: "/offer-view/:id",
        name: "Offer View",
        component: OfferView,
      },
      {
        path: "/chat/:type",
        name: "Offer Chat",
        component: MyChat,
      },
      {
        path: "/notifications",
        name: "Notification Info",
        component: NotificationInfo,
      },
      {
        path: "/users",
        name: "User List",
        component: UserList,
      },
      {
        path: "/invite",
        name: "Invite Members",
        component: InviteMembers,
      },
      {
        path: "/e-magazine",
        name: "E-Magazine",
        component: EMagazine,
      },
      {
        path: "/review/:id",
        name: "Review",
        component: Review,
      },
      {
        path: "/refer-friend",
        name: "Refer Friend",
        component: ReferFriend,
      },
      {
        path: "/manage-user",
        name: "Manage User",
        component: ManageUser,
      },
      {
        path: "/add-user",
        name: "Add User",
        component: AddEditUser,
      },
      {
        path: "/edit-user/:id",
        name: "Edit User",
        component: AddEditUser,
      },
      {
        path: "/add-ticket/:id",
        name: "Add Ticket",
        component: AdditionalTicket,
      },
      {
        path: "/view-participant/:employee_id",
        name: "View Participant",
        component: ViewParticipant,
      },
      {
        path: "/view-booked-ticket/:id",
        name: "View Booked Ticket",
        component: ViewBookedTicket,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token") == null) {
      next({
        path: "/upcoming-events",
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (localStorage.getItem("subscriptionId") == null) {
        if (localStorage.getItem("user_type") == "USER") {
          next();
        } else {
          next({
            path: "/select-plan",
            params: { nextUrl: to.fullPath },
          });
        }
      } else {
        next();
      }
    }
  } else {
    if (to.path === " ") {
      if (localStorage.getItem("access_token") != null) {
        next({
          path: "/home",
          params: { nextUrl: to.fullPath },
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
