<template>
  <div>
    <div class="page-content">
      <Form @submit="update">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-12">
            <div class="row mb-md-4 mb-2 align-items-center">
              <div class="col-md-6 text-md-left text-center">
                <h2 class="page-title mb-md-0">Company Profile</h2>
              </div>
              <div class="col-md-6 text-right d-flex justify-content-end"></div>
            </div>
            <div class="card mb-4 rounded-l border-0 shadow-sm">
              <div class="card-header p-0 border-0 mb-n5">
                <img
                  src="/images/contact-banner.png"
                  class="card-img-top rounded-l"
                  alt=""
                />
              </div>
              <div class="card-body px-md-5 -x3">
                <form class="form-style floating-form">
                  <div class="row justify-content-center mt-n5">
                    <div class="col-lg-4 col-md-5 mb-4 text-center">
                      <div class="mb-3">
                        <!-- <div class="col-lg-12 col-sm-12 mb-3">
                          <div class="text-center mb-4"> -->
                        <img
                          :src="
                            detail.logo && details.logo != null
                              ? detail.logo
                              : '/images/profile.svg'
                          "
                          id="preview"
                          class="img-thumbnail"
                          style="
                            border-radius: 100px;
                            width: 120px;
                            height: 120px;
                          "
                        /><br />
                      </div>
                      <div id="image-form" style="margin: 0 auto; width: 90px">
                        <input
                          type="file"
                          name="img[]"
                          class="file"
                          id="pickFile"
                          @change="selectedFile($event)"
                          accept="image/*"
                          style="display: none"
                        />
                        <div class="changePic" style="bottom: -25px">
                          <label for="pickFile" style="color: blue">
                            Change Photo</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-md-10">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              class="filled"
                              id="fname"
                              name="firstname"
                              v-model="detail.first_name"
                              rules="required:firstname"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="firstname"
                              class="text-danger"
                            />
                            <label for="FirstName">First Name</label>
                            <span class="text-danger">*</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              class="filled"
                              id="lname"
                              name="lastname"
                              v-model="detail.last_name"
                              rules="required:lastname"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="lastname" class="text-danger" />
                            <label for="LasttName">Last Name</label>
                            <span class="text-danger">*</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="floating-form-group">
                            <Field
                              type="email"
                              class="filled"
                              id="email"
                              name="email_name"
                              v-model="detail.email"
                              rules="required:email_name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="email" class="text-danger" />
                            <label for="email">Email</label>
                            <span class="text-danger">*</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              class="filled"
                              id="phone"
                              name="phoneNo"
                              v-model="detail.phone"
                              rules="required:phoneNo"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="phoneNo" class="text-danger" />
                            <label for="phone">Phone</label>
                            <span class="text-danger">*</span>
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="form-group">
                            <label for=" ">Company Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="companyName"
                              name="companyname"
                              rules="required:companyname"
                            >
                              <option selected>
                                {{ companyName }}
                              </option>
                              <option
                                v-for="(data, index) in companyList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.company_name }}
                              </option>
                            </Field>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for=" ">Industry</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="industryName"
                              name="industry"
                              rules="required:industry"
                            >
                              <option selected>
                                {{ industryName }}
                              </option>
                              <option
                                v-for="(data, index) in industryList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.name }}
                              </option>
                            </Field>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for=" ">No of Employes</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="detail.employee_id"
                              name="employee"
                              rules="required:employee"
                            >
                              <option value="0">Select</option>
                              <option value="0-10">0-10</option>
                              <option value="10-20">10-20</option>
                              <option value="20-50">20-50</option>
                              <option value="50-100">50-100</option>
                              <option value="100+">10-20</option>
                            </Field>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              class="filled"
                              id="city"
                              name="city"
                              v-model="detail.city_name"
                              rules="required:city"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="city" class="text-danger" />
                            <label for="city">City</label>
                            <span class="text-danger">*</span>
                          </div>
                        </div>

                        <div class="col-lg-12 mb-3">
                          <div class="floating-form-group">
                            <Field
                              id="about"
                              class="filled"
                              name="address"
                              v-model="detail.address"
                              rules="required:address"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="address" class="text-danger" />
                            <label for="about">Address</label>
                            <span class="text-danger">*</span>
                          </div>
                        </div>
                      </div>
                      <div class="text-center mt-3">
                        <button
                          type="button"
                          class="btn btn-brand-1 mb-3 px-5"
                          onclick="location.href='plan.php'"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import api from "../../services/WebService";
import { Form, Field, ErrorMessage } from "vee-validate";
// import TextModal from "@/components/TextModal";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    // TextModal,
  },
  name: "CompanyProfile",
  data() {
    return {
      id: this.$route.params.id,
      industryList: [],
      companyList: [],
      industryName: "",
      industryID: "",
      companyName: "",
      employeeList: [],
      file: undefined,
      detail: {},
    };
  },
  mounted() {
    this.getIndustryList();
    this.getCompanyList();
    this.getDetails();
  },
  methods: {
    getDetails() {
      api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.detail = res.response;
          for (var i in this.detail.companies) {
            this.companyName = this.detail.companies[i].company_name;
            this.industryID = this.detail.companies[i].industry_id;
          }
          for (var j in this.industryList) {
            if (this.industryID === this.industryList[j].id)
              this.industryName = this.industryList[j].name;
          }
        })
        .catch(() => {});
    },
    getIndustryList() {
      this.$api
        .getAPI({
          _action: "category/industry",
        })
        .then((res) => {
          this.industryList = res.list;
        })
        .catch(() => {});
    },
    getCompanyList() {
      this.$api
        .getAPI({
          _action: "companies",
        })
        .then((res) => {
          this.companyList = res.list;
        })
        .catch(() => {});
    },
    // save() {
    //   if (this.details.id) {
    //     api
    //       .putAPI({
    //         _action: "/user/update/" + this.details.id,
    //         _body: this.details,
    //         _buttonId: "save-button",
    //       })
    //       .then((res) => {
    //         if (res) {
    //           if (this.file) {
    //             this.uploadImage(this.details.id);
    //             return;
    //           }
    //           this.$router.go(-1);
    //         }
    //       })
    //       .catch(() => {});
    //   }
    // },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    // uploadImage(id) {
    //   if (!this.file) {
    //     this.$router.go(-1);
    //   }
    //   api
    //     .uploadImageAPI({
    //       _action: "/user/" + id + "/imageupload",
    //       _key: "image",
    //       _file: this.file,
    //       _body: {
    //         id: id,
    //       },
    //       _buttonId: "save-button",
    //     })
    //     .then(() => {
    //       this.$router.go(-1);
    //     });
    // },
  },
};
</script>