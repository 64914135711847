<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt=""
                /></a>
                Participant Booking Details
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card card-body events-card mb-4">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="text-center pb-4">
                  <img
                    :src="detail.business_conclave?.image"
                    class="img-fluid"
                    alt=""
                    style="width: 40% !important"
                  /><br />
                </div>

                <div class="card pt-3">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-3">
                        <h5 class="bolder">Name :</h5>
                      </div>
                      <div class="col-6">
                        <h6 class="bolder">
                          <span class="pl-3 text-success">{{
                            detail?.first_name + " " + detail?.last_name
                          }}</span>
                        </h6>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                        <h5 class="bolder">Event Name :</h5>
                      </div>
                      <div class="col-6">
                        <h6 class="bolder">
                          <span class="pl-3 text-success">{{
                            detail?.business_conclave?.name
                          }}</span>
                        </h6>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-3">
                        <h5 class="bolder">Timing:</h5>
                      </div>
                      <div class="col-6">
                        <h6 class="bolder">
                          <span class="pl-3 text-success">{{
                            this.$helperService.getFormatedDateAndTime(
                              detail?.business_conclave?.from_datetime
                            )
                          }}</span>
                        </h6>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                        <h5 class="bolder">Location:</h5>
                      </div>
                      <div class="col-8">
                        <a
                          :href="
                            'http://maps.google.com/?q=' +
                            detail?.business_conclave?.address
                          "
                          target="_blank"
                        >
                          <p class="bolder">
                            <span class="pl-3 text-success">{{
                              detail?.business_conclave?.address
                            }}</span>
                          </p>
                        </a>
                      </div>
                      <div class="col-1">
                        <a
                          :href="
                            'http://maps.google.com/?q=' +
                            detail?.business_conclave?.address
                          "
                          target="_blank"
                        >
                          <i class="fa fa-map-marker"></i>
                        </a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                        <h5 class="bolder">QR Code:</h5>
                      </div>
                      <div class="col-6">
                        <h6 class="bolder">
                          <span class="pl-3 text-success"
                            ><img :src="detail?.code" alt="" width="180"
                          /></span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BusinessConclaveDetails",
  components: {},
  data() {
    return {
      id: this.$route.params.employee_id,
      business_conclave_id: localStorage.getItem("business_conclave_id"),
      current_user_id: localStorage.getItem("user_id"),
      detail: {},
    };
  },
  mounted() {
    this.getInfo();
    this.getParticipant();
    this.$store.commit("showSidebar", true);
  },
  methods: {
    getInfo() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.userInfo = res.response;
          console.log(this.userInfo.id, "ME call");
          localStorage.setItem("user_id", this.userInfo.id);
          this.getList(0);
        })
        .catch(() => {});
    },
    getParticipant() {
      this.$api
        .getAPI({
          _action:
            "business-conclave/" + this.business_conclave_id + "/employees/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
  },
};
</script>
