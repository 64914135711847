<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a v-on:click="$router.go(-1)">
                <img
                  src="/images/back-arrow.svg"
                  class="mr-2 align-top"
                  alt=""
                  width="17"
              /></a>
              Offers
            </h2>
          </div>
        </div>
        <div class="row chatbox">
          <div class="col-md-4">
            <div class="user-list">
              <div class="card mb-3 bg-white p-0 border-0 shadow-sm">
                <div class="card-body py-2 px-md-3 px-2 border-bottom">
                  <div class="row align-items-center">
                    <div
                      class="col-12 card mb-3 bg-white p-0 border-0 shadow-sm"
                    >
                      <div style="height: 70vh; overflow: scroll">
                        <div
                          class="
                            card-body
                            py-2
                            px-md-3 px-2
                            border-bottom
                            cursor-pointer
                          "
                          v-for="(data, index) in offerList"
                          :key="index"
                          v-on:click="this.getMessage(0, data)"
                        >
                          <div class="row align-items-center">
                            <div class="col-lg-5 col-md-7 col-6 text-center">
                              <img
                                :src="
                                  data?.company?.logo
                                    ? data?.company?.logo
                                    : '/img/placeholder-image.jpg'
                                "
                                alt=""
                                class="rounded-circle"
                                width="80"
                                height="80"
                              />
                            </div>
                            <div class="col-lg-7 col-md-7 col-6">
                              <p class="mb-0 font-16 font-medium">
                                {{ data?.company?.company_name }}
                              </p>
                              <br />
                              <p class="text-primary font-medium mb-0">
                                ₹{{ data?.price }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card chating-box bg-white">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-md-6 mb-md-0 mb-2">
                    <div class="d-flex align-items-center">
                      <div class="position-relative">
                        <img
                          :src="
                            details?.company?.logo
                              ? details?.company?.logo
                              : '/img/placeholder-image.jpg'
                          "
                          class="mr-2 object-contain"
                          width="70"
                          height="60"
                        />
                      </div>
                      <div>
                        <p class="mb-0 font-medium">
                          {{ details?.company?.company_name }}
                        </p>
                        <p class="text-primary font-medium mb-0">
                          ₹ {{ details.price }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-md-0 mb-2 " style="text-align-last: end;">
                    <a
                      :href="'tel:' + details?.company?.phone"
                      v-if="details?.company?.phone"
                    >
                      <i class="fa fa-phone" aria-hidden="true"></i>
                      {{ details?.company?.phone }}
                    </a>
                    <br />
                    <a
                      :href="'mailto:' + details?.company?.email"
                      v-if="details?.company?.email"
                    >
                      <i class="fa fa-envelope-o" aria-hidden="true"></i>
                      {{ details?.company?.email }}
                    </a>
                  </div>
                </div>
              </div>
              <div
                v-on:scroll="handleScroll($event)"
                id="chat_div"
                style="height: 70vh; overflow: scroll"
              >
                <div
                  class="card-body msg-body border-success"
                  v-for="(data, index) in list"
                  :key="index"
                >
                  <div
                    class="mb-3"
                    :class="
                      userId == data.sender.id ? 'sent-msg' : 'receive-msg'
                    "
                  >
                    <div class="d-flex align-items-start">
                      <img
                        :src="
                          (data.sender.logo
                            ? data.sender.logo
                            : '/images/profile.svg') ||
                          (data.receiver.logo
                            ? data.receiver.logo
                            : '/images/profile.svg')
                        "
                        class="mr-2 rounded-circle object-cover"
                        width="30"
                        height="30"
                        v-if="userId != data.sender.id"
                      />
                      <div class="msg" v-if="data.message">
                        {{ data.message }}
                      </div>
                      <div v-if="data.document_url">
                        <a :href="data.document_url" target="blank">
                          <img
                            :src="getDocumentType(data.document_url)"
                            width="80"
                            height="80"
                            class="mr-2 object-cover cursor-pointer"
                          />
                        </a>
                      </div>
                      <img
                        :src="
                          (data.sender.logo
                            ? data.sender.logo
                            : '/images/profile.svg') ||
                          (data.receiver.logo
                            ? data.receiver.logo
                            : '/images/profile.svg')
                        "
                        width="30"
                        height="30"
                        class="mr-2 rounded-circle object-cover"
                        v-if="userId == data.sender.id"
                      />
                    </div>
                    <p class="text-secondary font-12 mb-0 ml-5 mt-1">
                      {{ data.created_at }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer type-msg">
              <div
                class="w-100 d-flex py-2 rounded-common"
                style="background: #f1f1f1"
              >
                <input
                  type="text"
                  v-model="message"
                  class="form-control rounded-pill input-box mr-2"
                  placeholder="Start Typing.."
                />

                <div class="attach-file-wrapper">
                  <div class="btn">
                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                  </div>
                  <input
                    type="file"
                    name="img[]"
                    id="pickFile"
                    v-on:change="selectedFile($event)"
                    accept="image/*"
                  />
                </div>
                <button
                  v-on:click="sendMessage()"
                  type="button"
                  class="btn-send-msg"
                >
                  <img src="/images/send.svg" width="20" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ErrorComponent @retry="getCompanyList(0)" ref="errorComponent" />
  <TextModal ref="uploadModal">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          v-on:click="this.$refs.uploadModal.closeModal()"
        >
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body px-4">
        <h5 class="modal-title font-22 mb-0 text-center mb-3">Upload File</h5>
        <form class="form-style floating-form">
          <Form @submit="sendFile()">
            <div class="row mb-md-0 mb-4">
              <div class="col-md-12 mt-2">
                <div class="floating-form-group">
                  <Field
                    type="text"
                    class="filled"
                    id="title"
                    rules="required:title"
                    name="title"
                    :validateOnInput="true"
                    v-model="file.name"
                  />
                  <!-- v-model="detail.tmppath" -->
                  <label for="title">File Name</label>
                  <span class="text-danger">*</span>
                </div>
                <ErrorMessage name="title" class="validation-msg" />

                <button class="btn btn-brand-1 w-100 mb-4" id="post-button">
                  Send
                </button>
                <button
                  type="button"
                  class="btn btn-brand-1 w-100 mb-4"
                  id="post-button"
                  v-on:click="this.$refs.uploadModal.closeModal()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </form>
      </div>
    </div>
  </TextModal>
</template>
<script>
import TextModal from "@/components/TextModal.vue";
import moment from "moment";
import ErrorComponent from "@/components/ErrorComponent";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "OffersView",
  components: {
    ErrorComponent,
    TextModal,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      userId: localStorage.getItem("companyId"),
      offerList: [],
      loader: false,
      offset: -1,
      chatOffset: -1,
      type: "offer",
      list: [],
      file: "",
      message: "",
      image: "",
      details: {},
      myDetail: {},
    };
  },
  mounted() {
    this.getData(0);
    this.getMyDetail();
  },
  methods: {
    getMyDetail() {
      this.$api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          this.myDetail = res.response;
        });
    },
    handleScroll(e) {
      if (e.target.scrollTop <= 10) {
        this.getMessage(this.chatOffset);
      }
    },
    getData(offset) {
      // console.log("inside");
      if (offset < 0 || this.loader) {
        return;
      }

      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.loader = true;
      if (offset == 0) {
        this.offerList = [];
      }
      this.offset = offset;
      this.$api
        .getAPI({
          _action: "offer/" + this.id,
          _body: this.offset,
        })
        .then((res) => {
          // console.log("res1", res);
          this.loader = false;
          this.chatOffset = res.next_offset;
          // console.log(("out"));
          if (res.list.length > 0) {
            // console.log("in");
            for (var i in res.list) {
              this.offerList.push(res.list[i]);
            }
            // console.log("offerList", this.offerList);
            if (offset == 0 && !this.details.company) {
              this.getMessage(0, this.offerList[0]);
            }
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res, "No offer available");
              this.$refs.errorComponent.updateLoader(false);
            }
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sendMessage() {
      var obj = {
        sender_id: localStorage.getItem("companyId"),
        receiver_id: this.details.company_id,
        request_id: this.details.request_id,
        message: this.message,
      };
      this.$api
        .postAPI({
          _action: "message",
          _body: obj,
        })
        .then(() => {
          var created_at = moment(new Date()).format("hh:mm A");
          this.list.push({
            message: this.message,
            created_at: created_at,
            sender_id: this.userId,
            sender: {
              logo: this.myDetail?.profile_img,
            },
          });
          this.message = "";
          this.goToBottom();
        })
        .catch(() => {});
    },
    sendFile() {
      console.log("file", this.file);
      var obj = {
        sender_id: this.userId,
        receiver_id: this.details.company_id,
        request_id: this.details.id,
        document: this.file,
      };
      this.$api
        .uploadImageAPI({
          _action: "upload/document",
          _body: obj,
        })
        .then(() => {
          var created_at = moment(new Date()).format("hh:mm A");
          this.list.push({
            document_url: this.file,
            created_at: created_at,
            sender_id: this.userId,
            sender: {
              logo: this.myDetail?.profile_img,
            },
          });
          this.goToBottom();
          this.file = "";
          this.$refs.uploadModal.closeModal();
        })
        .catch(() => {});
    },
    getMessage(offset, data) {
      //   if (offset < 0 || this.loader) {
      //     return;
      //   }
      if (offset == 0) {
        this.list = [];
      }
      if (data) {
        this.details = data;
        // console.log("details", this.details);
      }
      // console.log("detail", this.details);
      if (this.details) {
        this.loader = true;
        var obj = {};
        obj.offset = offset;
        obj.company_id = this.details.company_id;
        this.$api
          .getAPI({
            _action: "messages/" + this.details.request_id,
            _body: obj,
          })
          .then((res) => {
            // console.log("msg", res);
            this.loader = false;
            this.chatOffset = res.next_offset;
            if (res.list.length > 0) {
              for (var i in res.list) {
                res.list[i].created_at = moment(res.list[i].created_at).format(
                  "hh:mm A"
                );
                this.list.push(res.list[i]);
              }
            }
            this.goToBottom();
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    selectedFile(e) {
      let files = e.target.files;
      // if (
      //   files[0].type.toLowerCase() != "image/jpeg" &&
      //   files[0].type.toLowerCase() != "image/png"
      // ) {
      //   alert("Invalid file formate, please use jpeg or png file.");
      //   return;
      // }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      } else {
        this.$refs.uploadModal.showModal();
      }
      this.file = files[0];
      console.log("frtc", files[0]);
      // document.getElementById("preview").src = URL.createObjectURL(files[0]);;
    },
    getDocumentType(url) {
      var ext = url.split(".").pop();
      if (ext == "pdf") {
        return "/images/pdf.png";
      } else if (ext == "ppt") {
        return "/images/ppt.png";
      } else if (ext == "xls") {
        return "/images/xls.png";
      } else if (ext == "doc") {
        return "/images/doc.png";
      } else if (ext == "zip") {
        return "/images/zip.png";
      } else if (ext == "docx") {
        return "/images/doc.png";
      } else if (ext == "txt") {
        return "/images/txticon.png";
      } else if (ext == "jpeg") {
        return "/images/jpeg.png";
      } else if (ext == "csv") {
        return "/images/csv.png";
      } else if (ext == "mp4") {
        return "/images/mp4.png";
      } else if (ext == "gif") {
        return "/images/gif.png";
      }
      return url;
    },
    goToBottom() {
      setTimeout(() => {
        var element = document.getElementById("chat_div");
        element.scrollTop = element.scrollHeight;
      }, 200);
    },
  },
};
</script>