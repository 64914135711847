<template>
  <div>
    <header
      class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100"
      style="z-index: 1"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img
              src="/images/tenx-logo.svg"
              width="80"
              height="26"
              class="img-fluid"
            />
          </div>
          <!-- <div class="col-md-9 col-9 text-right">
            <a class="mr-3">Signup</a>
            <a class="header-btn" v-on:click="$router.push('/login')">Login</a>
          </div> -->
        </div>
      </div>
    </header>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center bg-white">
            <div class="col-md-6 bg-white">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <div class="px-lg-5 px-0">
                    <div class="card-body pb-0">
                      <form class="form-style floating-form">
                        <Form @submit="save">
                          <div
                            class="row mb-md-0 mb-4"
                            style="min-height: 86vh"
                          >
                            <div class="col-md-12 mt-2">
                              <div class="mb-4 mt-4">
                                <h3 class="signup-title mt-2">
                                  {{ this.id ? "Edit" : "Add" }}
                                  {{
                                    this.type == "product"
                                      ? "Product"
                                      : "Service"
                                  }}
                                </h3>
                              </div>
                              <div class="">
                                <div class="floating-form-group">
                                  <Field
                                    type="text"
                                    id="title"
                                    name="title"
                                    :class="id ? 'filled' : ''"
                                    v-model="this.detail.title"
                                    rules="required:title"
                                    :validateOnInput="true"
                                  />
                                  <label for="title">{{
                                    type == "product"
                                      ? " Product Name "
                                      : "Type of Service "
                                  }}</label>
                                  <span class="text-danger">*</span>
                                </div>
                                <ErrorMessage
                                  name="title"
                                  class="validation-msg"
                                />
                              </div>
                              <div class="">
                                <div class="floating-form-group">
                                  <Field
                                    type="text"
                                    id="description"
                                    :class="id ? 'filled' : ''"
                                    v-model="this.detail.description"
                                    rules="required:description"
                                    name="description"
                                    :validateOnInput="true"
                                  ></Field>
                                  <label for="description">Description</label>
                                  <span class="text-danger">*</span>
                                </div>
                                <ErrorMessage
                                  name="description"
                                  class="validation-msg"
                                />
                              </div>
                              <div class="custom-select-img">
                                <div class="file-upload">
                                  <div class="image-upload-wrap p-3 mb-1">
                                    <input
                                      type="file"
                                      name="img"
                                      id="pickFile"
                                      class="file-upload-input"
                                      v-on:change="selectedFile($event)"
                                      accept="image/*"
                                    />

                                    <div class="drag-text text-center">
                                      <img
                                        :src="
                                          this.detail.image &&
                                          this.detail.image != null
                                            ? this.detail.image
                                            : '/images/Imageplace-holder.svg'
                                        "
                                        id="preview"
                                        class="mb-2"
                                        width="60"
                                        height="50"
                                      />
                                      <h3
                                        for="pickFile"
                                        class="heading font-20"
                                      >
                                        Select Image
                                      </h3>
                                      <p class="font-12 text-secondary mb-1">
                                        PNG, GIF, JPEG. (Max 10 mb)
                                      </p>
                                      <p class="font-12 text-secondary mb-0">
                                        (Optional)
                                      </p>
                                    </div>
                                  </div>

                                  <div class="file-upload-content">
                                    <img
                                      class="file-upload-image mb-3"
                                      src="#"
                                      alt="your image"
                                    />
                                    <div class="image-title-wrap">
                                      <button
                                        type="button"
                                        onclick="removeUpload()"
                                        class="remove-image"
                                      >
                                        <i
                                          class="fa fa-times text-danger mr-2"
                                          aria-hidden="true"
                                        ></i>
                                        <span class="image-title"
                                          >Uploaded Image</span
                                        >
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row col-12 justify-content-center">
                                <h6>Preferred Image Size : 500px X 500 px</h6>
                              </div>
                              <div
                                class="col-12 d-flex justify-content-end mb-3"
                              >
                                <div class="text-right cursor-pointer">
                                  <a
                                    style="cursor-pointer"
                                    v-on:click="removeImage()"
                                    >Remove Image</a
                                  >
                                </div>
                              </div>
                              <div class="floating-form-group">
                                <Field
                                  type="text"
                                  id="website"
                                  name="website"
                                  :class="id ? 'filled' : ''"
                                  v-model="this.detail.website"
                                />
                                <label for="website">Website (Optional)</label>
                                <span class="text-danger">*</span>
                              </div>
                            </div>
                            <div class="col-12 d-flex align-self-end">
                              <button
                                type="button"
                                class="
                                  btn btn-outline-secondary btn-block
                                  mb-md-0
                                  mr-3
                                "
                                v-on:click="
                                  $router.push(
                                    '/product-service-list/' + this.type
                                  )
                                "
                              >
                                Cancel
                              </button>
                              <button
                                class="btn btn-brand-1 btn-block mt-0"
                                id="next-button"
                              >
                                {{ this.id ? "Update" : "Save" }}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 login-img-col"></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import api from "../../../services/WebService";
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
export default {
  name: "AddService",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      detail: {},
      url: "",
      file: "",
      data_Id: "",
      remove: false,
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
    //floating form
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
    this.$store.commit("showSidebar", false);
  },
  methods: {
    getDetail() {
      console.log(this.type);
      api
        .getAPI({
          _action:
            "company/" +
            localStorage.getItem("companyId") +
            "/" +
            this.type +
            "/" +
            this.id,
        })
        .then((res) => {
          this.detail = res.response;
        })
        .catch(() => {});
    },
    save() {
      if (this.type == "product") {
        this.url = "/product";
      } else {
        this.url = "/service";
      }
      if (this.id) {
        api
          .putAPI({
            _action:
              "company/" +
              localStorage.getItem("companyId") +
              this.url +
              "/" +
              this.id,
            _body: this.detail,
            _buttonId: "next-button",
          })
          .then(() => {
            this.uploadImage(this.id);
          })
          .catch(() => {});
      } else {
        api
          .postAPI({
            _action: "company/" + localStorage.getItem("companyId") + this.url,
            _body: this.detail,
            _buttonId: "next-button",
          })
          .then((res) => {
            if (this.type == "product") {
              this.uploadImage(res.product_id);
            } else {
              this.uploadImage(res.service_id);
            }
          })
          .catch(() => {});
      }
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      // var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      this.detail.image = URL.createObjectURL(files[0]);
      document.getElementById("preview").src;
    },
    uploadImage(id) {
      var obj = {};
      if (!this.file && this.remove == false) {
        this.$router.push("/product-service-list/" + this.type);
        return;
      } else if (!this.file && this.remove == true) {
        if (this.type == "product") {
          obj.product_id = id;
        } else if (this.type == "service") {
          obj.service_id = id;
        }
        api
          .deleteAPI({
            _action:
              "/company/" +
              localStorage.getItem("companyId") +
              this.url +
              "/image/" +
              id,
          })
          .then((res) => {
            if (res) {
              this.$notify({
                type: "success",
                text: res.message,
              });
              this.$router.push("/product-service-list/" + this.type);
            }
          })
          .catch(() => {
            return;
          });
      } else {
        if (this.type == "product") {
          obj.product_id = id;
        } else if (this.type == "service") {
          obj.service_id = id;
        }
        api
          .uploadImageAPI({
            _action:
              "/company/" +
              localStorage.getItem("companyId") +
              this.url +
              "/image",
            _key: "image",
            _file: this.file,
            _body: obj,
          })
          .then(() => {
            this.$router.push("/product-service-list/" + this.type);
          })
          .catch(() => {
            return;
          });
      }
    },
    removeImage() {
      this.file = "";
      document.getElementById("preview").src = "Imageplace-holder.svg";
      document.getElementById("preview").value = "";
      this.detail.image = "";
      this.remove = true;
    },
  },
};
</script>