<template>
  <div>
    <header
      class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100"
      style="z-index: 1"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img
              src="/images/tenx-logo.svg"
              width="80"
              height="26"
              class="img-fluid"
            />
          </div>
          <!-- <div class="col-md-9 col-9 text-right">
            <a class="mr-3">Signup</a>
            <a class="header-btn" v-on:click="$router.push('/login')">Login</a>
          </div> -->
        </div>
      </div>
    </header>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center bg-white">
            <div class="col-md-6 bg-white">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <div class="px-lg-5 px-0">
                    <div class="card-body pb-0">
                      <div class="form-style">
                        <div
                          class="row min-h-auto mb-md-0 mb-4"
                          style="min-height: 87vh; max-height: 87vh"
                        >
                          <div class="col-12 mb-4 mt-3">
                            <div class="mb-4 mt-3">
                              <h3 class="signup-title">Business address</h3>
                            </div>
                            <div
                              class=""
                              style="max-height: 60vh; overflow-y: auto"
                            >
                              <div
                                class="card card-body mb-3"
                                v-for="(data, index) in list"
                                :key="index"
                              >
                                <a
                                  class="text-secondary position-absolute"
                                  data-toggle="dropdown"
                                  aria-expanded="false"
                                  style="right: 15px"
                                  ><i
                                    class="fa fa-ellipsis-h"
                                    aria-hidden="true"
                                  ></i
                                ></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <button
                                    class="dropdown-item"
                                    type="button"
                                    v-on:click="
                                      $router.push(
                                        '/edit-business-address/' + data.id
                                      )
                                    "
                                  >
                                    <i
                                      class="fa fa-pencil mr-1"
                                      aria-hidden="true"
                                    ></i>
                                    Edit
                                  </button>
                                  <button
                                    class="dropdown-item"
                                    type="button"
                                    v-on:click="showDeletePopup(data)"
                                  >
                                    <i
                                      class="fa fa-trash mr-1"
                                      aria-hidden="true"
                                    ></i>
                                    Delete
                                  </button>
                                </div>
                                <p class="font-medium font-18 mb-2 pr-3">
                                  {{ data.address_1 }}
                                </p>
                                <p class="mb-0 font-14">
                                  {{ data.address_2 }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 align-self-end mb-md-4">
                            <p
                              class="
                                d-flex
                                align-content-center
                                justify-content-center
                              "
                            >
                              <a
                                class="text-dark"
                                v-on:click="
                                  $router.push(
                                    '/add-business-address/' + this.type
                                  )
                                "
                                ><img
                                  src="/images/plus-circle.svg"
                                  width="25"
                                  alt=""
                                  class="mr-2"
                                />
                                Add New business address</a
                              >
                            </p>
                            <div class="d-flex align-self-end">
                              <button
                                type="button"
                                class="btn btn-outline-secondary btn-block mr-3"
                                v-on:click="
                                  $router.push('/add-product-service')
                                "
                              >
                                Skip
                              </button>

                              <button
                                type="button"
                                class="btn btn-brand-1 btn-block mt-0"
                                v-on:click="
                                  $router.push('/add-product-service')
                                "
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 login-img-col"></div>
          </div>
        </div>
      </div>
    </main>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  </div>
</template>
<script>
import DeleteModal from "@/components/DeleteModal";
export default {
  name: "AddNewBAddress",
  components: {
    DeleteModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {
        state_id: "",
      },
      list: [],
      type: "new",
    };
  },
  mounted() {
    this.getList();
    this.$store.commit("showSidebar", false);
  },
  methods: {
    getList() {
      this.$api
        .getAPI({
          _action:
            "company/" + localStorage.getItem("companyId") + "/addresses",
        })
        .then((res) => {
          this.list = res.list;
        })
        .catch(() => {});
    },
    // save() {
    //   this.$api
    //     .putAPI({
    //       _action: "company/1/address/" + this.detail.id,
    //       _body: {state_id:this.detail.state_id},
    //     })
    //     .then(() => {
    //       this.$router.go(-1);
    //     })
    //     .catch(() => {});
    // },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action:
            "company/" +
            localStorage.getItem("companyId") +
            "/address/" +
            detail.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Address",
        "Are you sure you want to delete "
        + data.address_1,
        data
      );
    },
  },
};
</script>