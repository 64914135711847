<template>
  <div>
    <OuterHeader />

    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center bg-white">
            <div class="col-md-6 bg-white">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <div class="px-lg-5 px-0 py-4">
                    <div class="card-body pb-0 pt-lg-5">
                      <form class="form-style floating-form">
                        <Form @submit="signup">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field type="text" id="gst_number_id" v-model="detail.gst_number"
                                  v-on:keyup="getCompany(detail.gst_number)" name="gst_number" />
                                <label for="gst_number">GST</label>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="floating-form floating-form-error">
                                <div class="floating-form-group">
                                  <Field type="text" id="fname" name="firstname" v-model="detail.first_name"
                                    rules="required:first name|userName" :validateOnInput="true" />
                                  <label for="fname">First Name</label><span class="text-danger">*</span>
                                </div>
                              </div>
                              <ErrorMessage name="firstname" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <div class="floating-form-group">
                                <Field type="text" :class="detail.last_name ? 'filled' : ''" id="lname" name="lastname"
                                  v-model="detail.last_name" rules="required:last name|userName"
                                  :validateOnInput="true" />
                                <label for="lname">Last Name</label><span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="lastname" class="validation-msg" />
                            </div>
                            <div class="col-12">
                              <div class="floating-form-group">
                                <Field type="email" id="email" name="email" v-model="detail.email" rules="required:email"
                                  :validateOnInput="true" />
                                <label for="email">Email</label><span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="email" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <div class="floating-form-group">
                                <Field type="text" id="phone" name="phone" v-model="detail.phone"
                                  rules="required:contact number|phone" :validateOnInput="true" />
                                <label for="phone">Phone</label><span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="phone" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <div class="floating-form-group with-icon">
                                <vue-google-autocomplete ref="address" id="map" classname="form-control" placeholder=""
                                  v-model="detail.city_name" v-on:placechanged="getAddressData" types="(cities)"
                                  country="in">
                                </vue-google-autocomplete>
                                <img src="/images/location-icon.svg" width="20" class="icon" />
                                <label for="city">City</label><span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="city" class="validation-msg" />
                            </div>
                            <div class="col-md-12">
                              <div class="floating-form-group">
                                <Field type="text" id="compny_name" placeholder="" classname="form-control"
                                  :class="detail.company_name ? 'filled' : ''" v-model="detail.company_name"
                                  rules="required:company" name="company" :validateOnInput="true" />
                                <label for="compny_name">Company Name</label><span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="company" class="validation-msg" />
                            </div>
                          </div>

                          <div class="form-row mb-5">
                            <vue-recaptcha @verify="verify" sitekey="6LdHzdgkAAAAAPlZTSTvwJeq0Cuhz5gh_BPullAZ"
                              :loadRecaptchaScript="true">
                            </vue-recaptcha>
                          </div>
                          <label v-show="isError" class="text-danger">Please verify captcha</label>

                          <button class="btn btn-brand-1 btn-block mb-3" id="signup-button">
                            Continue
                          </button>
                        </Form>
                      </form>
                    </div>
                  </div>
                </div>
                <TextModal ref="textModal" class="modal-small">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
                        <img src="/images/close.svg" width="15" />
                      </button>
                    </div>
                    <div class="modal-body text-center px-md-4">
                      <h5 class="modal-title font-22 mb-4">Thank you</h5>
                      <img src="/images/claping.png" alt="" width="75" class="mb-3" />
                      <p class="font-18 mb-0">
                        Thanks for registering<br />
                        <!-- <span class="font-14"
                        >Please click on below link to join the webinar</span
                      > -->
                      </p>
                    </div>
                  </div>
                </TextModal>
                <TextModal ref="errorModal" class="modal-small">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" v-on:click="closeModal()">
                        <img src="/images/close.svg" width="15" />
                      </button>
                    </div>
                    <div class="modal-body px-md-4">
                      <div class="text-center">
                        <h5 class="modal-title font-22 mb-4">Alert</h5>

                        <p class="font-18 mb-0">{{ get_error_msg }}<br /></p>
                        <br /><br />
                        <!-- <button
                          style="background-color: #9cc338"
                          class="btn text-center"
                          v-on:click="goToLogin()"
                        >
                          Login
                        </button> -->
                      </div>
                    </div>
                  </div>
                </TextModal>
              </div>
            </div>
            <div class="col-md-6 login-img-col"></div>
          </div>
        </div>
      </div>
    </main>

    <TextModal ref="thankYouModal" class="modal-small" v-on:click="redirectToBookedTicket()">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" v-on:click="$refs.thankYouModal.closeModal">
            <img src="/images/close.svg" width="15" />
          </button>
        </div>
        <a href="" class="">
          <div class="modal-body text-center px-md-4">
            <h5 class="modal-title font-22 mb-4">Thank you</h5>
            <img src="/images/claping.png" alt="" width="75" class="mb-3" />
            <p class="font-18 mb-0">Thanks for registering<br /></p>
          </div>
        </a>
      </div>
    </TextModal>
  </div>
</template>
<script>
import TextModal from "@/components/TextModal.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import OuterHeader from "@/components/OuterHeader";
import { Form, Field, ErrorMessage } from "vee-validate";
import { VueRecaptcha } from "vue-recaptcha";
import $ from "jquery";
var md5 = require("md5");
export default {
  name: "GuestSignUp",
  components: {
    Form,
    Field,
    ErrorMessage,
    OuterHeader,
    VueGoogleAutocomplete,
    TextModal,
    VueRecaptcha,
  },
  data() {
    return {
      id: this.$route.params.id,
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      latitude: "",
      city: "",
      longitude: "",
      image: "/images/show.png",
      imageNew: "/images/show.png",

      detail: {
        event_id: this.$route.query.id,
        event_type: this.$route.query.type,
        signup_type: "EMAIL",
        dial_code: "+91",
        country_code: "IN",
      },
      event_detail: {},
      userInfo: {},
      isVerify: false,
      isError: false,
      get_error_msg: localStorage.getItem("get_error_code"),
    };
  },
  mounted() {
    var error = localStorage.getItem("get_error_code");
    if (error != null) {
      this.$refs.errorModal.showModal();
    }
    this.$refs.address.focus();
    console.log("type", this.detail.event_type);
    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
    this.event_detail = this.$sharedService.getMapValue("event_detail");

    if (this.event_detail == null && this.event_detail == undefined) {
      this.$router.go(-1);
    }
  },
  methods: {
    // goToLogin() {
    //   localStorage.removeItem("get_error_code");
    //   this.$router.push("/login");
    // },
    closeModal() {
      localStorage.removeItem("get_error_code");

      this.$refs.errorModal.closeModal();
      this.$router.push("/login");
    },
    verify() {
      this.isVerify = true;
      this.isError = false;
    },
    getAddressData: function (addressData, placeResultData) {
      this.detail.city_name = placeResultData.formatted_address;
      this.detail.latitude = addressData.latitude;
      this.detail.longitude = addressData.longitude;
    },
    signup() {
      if (!this.isVerify) {
        this.isError = true;
        return;
      }

      if (!this.detail.latitude || !this.detail.longitude) {
        this.$notify({
          type: "error",
          text: "Please select city from geo location",
        });
        return false;
      }

      this.$api
        .postAPI({
          _action: "guest/event/register",
          _body: this.detail,
          _buttonId: "signup-button",
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("access_token", res.access_token)
            this.subscribe(res);
          }
        })
        .catch(() => {
          if (this.get_error_msg != null) {
            this.$router.push(this.$route.fullPath);
            this.$refs.errorModal.showModal();
          }
        });
    },

    getCompany(e) {
      if (e.length == 15) {
        var ENCRYPTION_KEY = "Sph!nx@T@nX";
        var keygstmd5 = md5(e + "-" + ENCRYPTION_KEY);
        this.$api
          .getAPI({
            _action: "search/gst/" + e,
            _header: { "x-api-key": keygstmd5 },
          })
          .then((res) => {
            console.log("company", res);
            this.detail.company_name = res.company_name;
          })
          .catch(() => { });
      }
    },
    subscribe(data) {
      var $this = this;
      var options = {
        key: data.razorpay_sdk_key, // Enter the Key ID generated from the Dashboard
        amount: $this.event_detail.event_amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Tenx",
        description: "TenX Description",
        order_id: data.subscription,
        handler: function (response) {
          if (response.razorpay_payment_id) {
            $this.checkoutSuccess(response, data.transaction_token);
          }
        },
        prefill: {
          name: $this.detail.first_name,
          email: $this.detail.email,
          contact: $this.detail.phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function () {
        this.$noty.error("Payment Failed, Please try again");
      });
      rzp1.open();
    },
    getInfo() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.userInfo = res.response;
        })
        .catch(() => { });
    },
    checkoutSuccess(response, transaction_token) {
      var obj = {};
      obj.razorpay_payment_id = response.razorpay_payment_id;
      obj.transaction_token = transaction_token;
      obj.razorpay_signature = response.razorpay_signature;
      obj.razorpay_order_id = response.razorpay_order_id;
      this.$api
        .postAPI({
          _action: "checkout/success",
          _body: obj,
        })
        .then(() => {
          this.detail.has_register = 1;
          this.$helperService.getSummary();
          this.showThankyouPopup();
        });
    },

    showThankyouPopup() {
      this.$refs.thankYouModal.showModal();
    },

    redirectToBookedTicket() {
      localStorage.clear();
      this.$refs.errorModal.closeModal();
      this.$router.push("/login");
    },

  },
};
</script>
