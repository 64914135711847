<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                <img
                  src="/images/back-arrow.svg"
                  width="17"
                  class="mr-2 align-top"
                  alt=""
              /></a>
              Reviews
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end">
            <input
              type="text"
              class="search-click"
              name=""
              v-on:keyup.enter="getReviewList(0)"
              placeholder="search here..."
              v-model="searchObj.keyword"
            />
          </div>
        </div>
        <div class="card rounded-l border-0 shadow">
          <div
            v-show="!loader"
            v-for="(data, index) in reviewList"
            :key="index"
            class="card-body border-bottom expert-list"
            v-on:click="$router.push('/member-detail/' + data.user.uuid)"
          >
            <div class="row">
              <div class="col-md-12 d-flex align-items-center mb-3">
                <img
                  :src="
                    data.user.profile_img
                      ? data.user.profile_img
                      : '/images/profile.svg'
                  "
                  alt=""
                  class="img-fluid rounded-circle object-cover"
                  style="width: 80px; height: 80px"
                />
                <div class="pl-3">
                  <p class="font-medium mb-2 font-18 font-xs-16">
                    {{ data.user.first_name }}
                    {{ data.user.last_name }}
                  </p>
                  <p
                    class="
                      font-weight-light font-12
                      text-secondary
                      mb-2
                      font-xs-12
                    "
                  >
                    {{ data.ago_time }}
                  </p>

                  <star-rating
                    :increment="0.5"
                    v-if="data.rating"
                    :rating="data.rating"
                    :show-rating="false"
                    :star-size="15"
                    :read-only="true"
                  ></star-rating>
                </div>
              </div>
              <div class="col-12">
                <p class="text-secondary font-14 font-weight-light mb-0">
                  {{ data.review }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            text-align: center;
            background: white;
            margin-top: -2%;
            border-radius: 16px;
          "
          class="col-12 pb-3"
          v-if="offset != -1"
        >
          <a
            id="load-button"
            class="txt-success cursor-pointer mb-3"
            v-on:click="getReviewList(offset)"
            style="max-width: 110px"
          >
            Load More
          </a>
        </div>
        <div class="text-center mt-4">
          <button
            type="button"
            class="btn btn-brand-1 px-5"
            v-on:click="this.$refs.review.showModal()"
          >
            Write Review
          </button>
        </div>
      </div>
    </div>
  </div>
  <ErrorComponent @retry="getReviewList(0)" ref="errorComponent" />
  <!-- Modal Write Review-->
  <TextModal ref="review">
    <div class="modal-content">
      <Form @submit="submit">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            v-on:click="$refs.review.closeModal"
          >
            <img src="/images/close.svg" width="15" />
          </button>
        </div>
        <div class="modal-body form-style px-md-5">
          <h5
            class="font-22 modal-title text-center mb-4"
            id="ReviewModallLabel"
          >
            Write Review
          </h5>

          <div id="expert-review" class="star-rating__stars ml-0 mb-3 mx-auto">
            <star-rating
              :increment="0.5"
              :show-rating="false"
              v-model:rating="detail.rating"
            ></star-rating>
            <div class="star-rating__focus"></div>
          </div>
          <p class="font-14 text-center">Click a Star to Rate</p>
          <form action="" class="floating-form">
            <div class="row">
              <div class="col-12">
                <div class="floating-form-group mb-4">
                  <Field
                    type="text"
                    id="title"
                    name="title"
                    v-model="detail.title"
                    rules="required:title"
                    :validateOnInput="true"
                  />
                  <label for="title">Title</label>
                </div>
                <ErrorMessage name="title" class="validation-msg" />
              </div>
              <div class="col-12">
                <div class="floating-form-group">
                  <Field
                    type="text"
                    rows="4"
                    id="description"
                    name="review"
                    v-model="detail.review"
                    rules="required:review"
                    :validateOnInput="true"
                  ></Field>

                  <label for="description">Review</label>
                </div>
                <ErrorMessage name="review" class="validation-msg" />
              </div>
            </div>
          </form>
        </div>
        <div
          class="modal-footer text-center justify-content-center border-0 pt-0"
        >
          <button class="btn btn-brand-1 px-4 mb-3" id="submit-button">
            Submit
          </button>
        </div>
      </Form>
    </div>
  </TextModal>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal.vue";
// import vue3starRatings from "vue3-star-ratings";
import StarRating from "vue-star-rating";
import $ from "jquery";
export default {
  name: "ReviewComponent",
  components: {
    Form,
    Field,
    ErrorMessage,
    // vue3starRatings,
    TextModal,
    ErrorComponent,
    StarRating,
  },
  data() {
    return {
      rating: null,
      resetableRating: 2,
      currentRating: "No Rating",
      mouseOverRating: null,
      id: this.$route.params.id,
      reviewList: [],
      offset: -1,
      loader: true,
      searchObj: {
        keyword: "",
      },
      detail: {},
    };
  },
  computed: {
    currentRatingText() {
      return this.rating
        ? "You have selected " + this.rating + " stars"
        : "No rating selected";
    },
    mouseOverRatingText() {
      return this.mouseOverRating
        ? "Click to select " + this.mouseOverRating + " stars"
        : "No Rating";
    },
  },
  mounted() {
    this.getReviewList(0);
    this.$store.commit("showSidebar", true);
    //floating form
    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    showCurrentRating(rating) {
      this.currentSelectedRating =
        rating === 0
          ? this.currentSelectedRating
          : "Click to select " + rating + " stars";
    },
    setCurrentSelectedRating(rating) {
      this.currentSelectedRating = "You have Selected: " + rating + " stars";
    },
    submit() {
      this.$api
        .postAPI({
          _action: "expert/" + this.id + "/review",
          _body: this.detail,
          _buttonId: "submit-button",
        })
        .then(() => {
          this.$refs.review.closeModal();
          this.getReviewList(0);
        })
        .catch(() => {});
    },
    getReviewList(offset) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action: "expert/" + this.id + "/review",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          if (offset == 0) {
            this.reviewList = res.list;
          } else {
            for (var i in res.list) {
              this.reviewList.push(res.list[i]);
            }
          }
          this.offset = res.next_offset;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No review available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
  },
};
</script>