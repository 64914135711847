<template >
  <div>
    <OuterHeader />
    
      <div class="container-fluid h-100">
        <div
          class="
            row
            justify-content-md-center
            align-items-center
            h-100
            bg-white
          "
        >
          <div class="col-md-6 bg-white">
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <div class="px-lg-5 px-0 py-3">
                  <div
                    class="card-header bg-white rounded-10 text-center border-0"
                  >
                    <img
                      src="/images/tenx-logo.svg"
                      width="110"
                      height="35"
                      class="img-fluid mb-lg-5 mb-4"
                    />
                  </div>

                  <div class="card-body pt-1">
                    <Form @submit="resetPassword()" class="form-style floating-form">
                      <div class="floating-form-group mb-4">
                        <label for="password">New Password</label>
                        <Field
                          :type="newPasswordFieldType"
                          id="password"
                          name="password"
                          placeholder="New Password"
                          v-model="password"
                          v-on:keydown.space="$event.preventDefault()"
                          rules="required:password|minLength:6|password"
                          :validateOnInput="true"
                        />
                        
                        <a class="psw-icon" v-on:click="switchVisibilityNew"
                          ><img :src="imageNew" width="20"
                        /></a>
                      </div>
                      <ErrorMessage name="password" class="validation-msg" />
                      <div class="floating-form-group mb-4">
                        <label>Confirm Password</label>
                        <Field
                          :type="passwordFieldType"
                          id="conf_password"
                          placeholder="Confirm Password"
                          name="confirm_password"
                          v-on:keydown.space="$event.preventDefault()"
                          rules="required:confirm password|confirmed:@password"
                          :validateOnInput="true"
                        />
                        
                        <a class="psw-icon" v-on:click="switchVisibility"
                          ><img :src="image" width="20"
                        /></a>
                      </div>
                      <ErrorMessage
                          name="confirm_password"
                          class="validation-msg"
                        />
                      <button
                        class="btn btn-brand-1 btn-block mb-4"
                        id="submit-button"
                        style="cursor: pointer"
                      >
                        Submit
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
</template>
<script>
import OuterHeader from "@/components/OuterHeader";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "ResetPassword",
  components: {
    Form,
    Field,
    ErrorMessage,
    OuterHeader,
  },
  data() {
    return {
      password: "",
      token: this.$route.params.token,
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      image: "/images/show.png",
      imageNew: "/images/show.png",
    };
  },
  mounted() {},
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    resetPassword() {
      var obj = {};
      obj.token = this.token;
      obj.password = this.password;
      this.$api
        .postAPI({
          _action: "reset-password",
          _body: obj,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            this.$router.push('/login');
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
