<template>
  <div>
    <header
      class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100"
      style="z-index: 1"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img
              src="/images/tenx-logo.svg"
              width="80"
              height="26"
              class="img-fluid"
            />
          </div>
          <!-- <div class="col-md-9 col-9 text-right">
            <a class="mr-3">Signup</a>
            <a class="header-btn" v-on:click="$router.push('/login')">Login</a>
          </div> -->
        </div>
      </div>
    </header>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center bg-white">
            <div class="col-md-6 bg-white">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <div class="px-lg-5 px-0">
                    <div class="card-body pb-0">
                      <Form class="form-style" @submit="save">
                        <div class="row" style="min-height: 86vh">
                          <div class="col-12 mb-0 mt-4">
                            <h3 class="signup-title mt-2">
                              <a
                                class="cursor-pointer"
                                v-on:click="this.$router.go(-1)"
                              >
                                <img
                                  src="/images/back-arrow.svg"
                                  width="17"
                                  class="mr-2 align-top"
                                  alt=""
                              /></a>
                              Add product/ service
                            </h3>
                          </div>
                          <div class="col-12 mb-4">
                            <div class="steps-count">
                              <div class="step-no active">
                                <img
                                  src="/images/check-white.svg"
                                  width="20"
                                  height="20"
                                />
                              </div>
                              <div class="h-line"></div>
                              <div class="step-no active">2</div>
                              <div class="h-line"></div>
                              <div class="step-no">3</div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div
                              class="form-group"
                              v-on:click="$router.push('/add/product')"
                            >
                              <label for=" " class="font-medium"
                                >List your product/services</label
                              >
                              <div class="add-prod-serv-block py-md-5 py-4">
                                <div class="text-center">
                                  <img
                                    :src="
                                      details.image && details.image != null
                                        ? details.image
                                        : 'images/add-product.svg'
                                    "
                                    width="35"
                                    class="mb-3"
                                    alt=""
                                  />
                                  <p class="font-bold mb-0">Add Product</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mb-4">
                            <div
                              class="form-group"
                              v-on:click="$router.push('/add/service')"
                            >
                              <div class="add-prod-serv-block py-md-5 py-4">
                                <div class="text-center">
                                  <img
                                    :src="
                                      details.image && details.image != null
                                        ? details.image
                                        : 'images/add-service.svg'
                                    "
                                    width="35"
                                    class="mb-3"
                                    alt=""
                                  />
                                  <p class="font-bold mb-0">Add service</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <button
                              type="button"
                              class="
                                btn btn-outline-secondary btn-block
                                mb-md-0 mb-3
                              "
                              v-on:click="
                                $router.push('/product-service-list/product')
                              "
                            >
                              Skip
                            </button>
                          </div>
                          <div class="col-md-6">
                            <button
                              type="button"
                              class="btn btn-brand-1 btn-block mb-3"
                              v-on:click="$router.push('/select-your-interest')"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 login-img-col"></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template> 
 <script>
import { Form } from "vee-validate";
export default {
  name: "AddProductService",
  components: {
    Form,
  },
  data() {
    return {
      details: {},
    };
  },
  mounted() {
    this.$store.commit("showSidebar", false);
  },
  methods: {
    save() {
      this.$api
        .postAPI({
          _action: "company/product",
          _body: this.details,
        })
        .then((res) => {
          if (res) {
            this.details = res;
          }
        })
        .catch(() => {});
    },
  },
};
</script>