<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-10">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>
                TenX Business Conclave (Offline)
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card card-body events-card mb-4">
            <div class="row mb-md-0 mb-3">
              <div class="col-md-8">
                <p class="font-18 font-medium mb-2">{{ detail.name }}</p>
                <!-- <p class="font-14 mb-md-3 mb-1">
                  {{ detail.address }}
                </p> -->
              </div>
              <div class="col-md-4 text-md-right">
                <p class="font-14 mb-2 font-xs-12">{{ detail.ago_time }}</p>
                <span class="badge badge-light-yellow">{{ detail.type }}</span>
              </div>
            </div>
            <div class="position-relative mb-3">
              <img :src="detail.image ? detail.image : '/img/placeholder-image.jpg'" class="img-fluid card-img" alt="" />
              <span class="badge bage-brand position-absolute" style="left: 10px; top: 10px">{{
                detail.mode == "PHYSICAL"
                ? "Offline"
                : $helperService.getTitleCase(detail.mode)
              }}</span>
            </div>
            <div class="row">
              <div class="col-md-6 mb-md-0 mb-3">
                <p class="text-secondary mb-md-2 mb-1">
                  <img src="/images/calendar.svg" width="14" class="align-baseline mr-1" />
                  {{ detail.from_datetime }}
                </p>
              </div>

              <div class="row col-md-6 text-right" style="justify-content: end">
                <p class="font-24 font-bold mb-0" v-show="detail.type != 'FREE'">
                  ₹{{ detail.price }}
                </p>
                <h5 class="text-secondary mb-md-2 mb-1" v-show="detail.type != 'FREE'">
                  +GST
                </h5>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8 mb-md-0 mb-3 cursor-pointer" v-on:click="showLocation(detail)">
                <p class="text-secondary mb-0">
                  <img src="/images/location-icon.svg" width="14" class="align-baseline mr-1" />{{ detail.address }}
                </p>
              </div>
              <div class="col-md-12 text-md-right">
                <hr />
              </div>
              <div class="col-md-12">
                <p>
                  {{ detail.description }}
                </p>
              </div>
              <div>
                <div class="col-md-12 mb-md-0 mb-3">
                  <div class="mb-2 mt-2" v-show="speakerList.length">
                    <h5 class="">Speakers</h5>
                  </div>
                  <div v-for="(data, index) in speakerList" :key="index" v-on:click="
                    this.$router.push({
                      path: '/speaker-profile/' + data.speaker.id,
                      query: { event_id: detail.id },
                    })
                    " class="row align-items-center pb-2 px-2 mb-2 mx-0 cursor-pointer" v-show="speakerList.length">
                    <img :src="data.speaker.image ? data.speaker.image : '/images/profile.svg'
                      " width="45" height="45" class="rounded-circle object-cover mr-2" />
                    <div class="text-left">
                      <p class="mb-0 font-medium">
                        {{ data.speaker.name }}
                      </p>
                      <p class="font-14 mb-0 text-secondary">
                        {{ data.speaker.profession }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div v-show="detail?.participant_info?.code" class="col-md-12 text-center">
                <a :href="detail?.participant_info?.code" target="blank">
                  <img
                    :src="detail?.participant_info?.code"
                    alt=""
                    width="75"
                    class="mb-3"
                  />
                </a>
              </div> -->

              <div v-if="!detail.has_register && detail.isShow" class="col-md-12 text-center pt-4">
                <button class="btn btn-brand-1 w-xs-100" data-toggle="modal" data-target="#modal_thankyou"
                  id="register-button" v-on:click="register(detail)">
                  Register
                </button>
              </div>
              <div class="col-lg-12 col-md-12 text-center pt-4" v-if="detail.has_register">
                <div class="alert alert-success py-1 font-14 d-inline-block px-2" style="margin: 0 auto">
                  <span>You are already registered. View your digital pass.</span>
                </div>
                <br /><br />
                <div class="d-flex flex-row justify-content-center">
                  <button class="btn btn-brand-1 mx-2 w-xs-100" id="register-button" v-on:click="redirectToViewTicket()">
                    View Booked Ticket
                  </button>
                  <button class="btn btn-brand-1 mx-2 w-xs-100" id="register-button"
                    v-on:click="redirectToAdditionTicket()">
                    Book Additional Ticket
                  </button>
                </div>
              </div>
              <TextModal ref="textModal" class="modal-small">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
                      <img src="/images/close.svg" width="15" />
                    </button>
                  </div>
                  <div class="modal-body text-center px-md-4">
                    <h5 class="modal-title font-22 mb-4">Thank you</h5>
                    <img src="/images/claping.png" alt="" width="75" class="mb-3" />
                    <p class="font-18 mb-0">
                      Thanks for registering<br />
                      <!-- <span class="font-14"
                        >Please click on below link to join the webinar</span
                      > -->
                    </p>
                  </div>
                </div>
              </TextModal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextModal from "@/components/TextModal.vue";
import moment from "moment";
export default {
  name: "BusinessConclaveDetails",
  components: {
    TextModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      userInfo: {},
      detail: { participant_info: {}, speakerList: [] },
      speakerList: [],
    };
  },
  mounted() {
    this.getDetail();
    this.getInfo();
    this.$store.commit("showSidebar", true);
  },
  methods: {
    redirectToAdditionTicket() {
      this.$router.push("/add-ticket/" + this.id);
    },
    redirectToViewTicket() {
      this.$router.push("/view-booked-ticket/" + this.id);
    },
    showThankyouPopup() {
      this.getDetail();
      this.$refs.textModal.showModal();
    },
    getDetail() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "business-conclave/" + this.id,
        })
        .then((res) => {
          this.loader = false;
          this.detail = res;


          if (this.detail) {
            this.detail.isShow = false;
            if (
              moment(
                this.detail.from_datetime,
                "YYYY-MM-DD HH:mm:ss"
              ) > moment(new Date())
            ) {
              this.detail.isShow = true;
            }
          }
          
          this.detail.from_datetime = moment(this.detail.from_datetime).format(
            "DD/MM/YYYY HH:mm A"
          );

          this.speakerList = res.business_conclave_speakers;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No business-conclave available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    subscribe(detail, data) {
      var $this = this;
      var options = {
        key: data.razorpay_sdk_key, // Enter the Key ID generated from the Dashboard
        amount: detail.event_amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Tenx",
        description: "Tenx Description",
        order_id: data.subscription,
        handler: function (response) {
          if (response.razorpay_payment_id) {
            $this.checkoutSuccess(response, data.transaction_token);
          }
        },
        prefill: {
          name: $this.userInfo.first_name,
          email: $this.userInfo.email,
          contact: $this.userInfo.phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function () {
        this.$noty.error("Payment Failed, Please try again");
      });

      rzp1.open();
    },
    getInfo() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.userInfo = res.response;
          localStorage.setItem("current_user_id", res.response.id);
        })
        .catch(() => { });
    },
    checkout(detail) {
      var obj = {};
      obj.event_id = detail.id;
      obj.type = "BUSINESS_CONCLAVE";
      this.$api
        .postAPI({
          _action: "checkout",
          _body: obj,
        })
        .then((res) => {
          if (res) {
            console.log("response", res);
            this.subscribe(detail, res);
          }
        });
    },
    checkoutSuccess(response, transaction_token) {
      var obj = {};
      // console.log(response)
      (obj.razorpay_payment_id = response.razorpay_payment_id),
        (obj.transaction_token = transaction_token);
      (obj.razorpay_signature = response.razorpay_signature),
        (obj.razorpay_order_id = response.razorpay_order_id),
        //   (obj.amount = amount);
        this.$api
          .postAPI({
            _action: "checkout/success",
            _body: obj,
          })
          .then(() => {
            this.detail.has_register = 1;
            this.$helperService.getSummary();
            this.showThankyouPopup();
          });
    },
    register(detail) {
      console.log("detail", this.accessToken, detail);
      if (detail.price > 0.0) {
        this.checkout(detail);
      } else {
        var obj = {};
        obj.business_conclave_id = detail.id;
        this.$api
          .postAPI({
            _action: "business-conclave/participant",
            _body: obj,
            _buttonId: "register-button",
          })
          .then(() => {
            this.detail.has_register = 1;
            this.$helperService.getSummary();
            this.showThankyouPopup();
          })
          .catch(() => { });
      }
    },
    showLocation(detail) {
      console.log(detail);
      var url = 'http://maps.google.com/?q=' +
        detail?.address
        ;

      window.open(url);
    },
  },
};
</script>
