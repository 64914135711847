<template>
  <main role="main">
    <div class="page-content">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">Find Companies</h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end">
              <input
                type="text"
                class="search-click"
                name=""
                v-on:keyup.enter="getCompanyList(0)"
                placeholder="search here..."
                v-model="searchObj.keyword"
              />
              <button
                type="button"
                class="btn btn-filter ml-2"
                data-toggle="modal"
                data-target="#filter"
                v-on:click="showFilterPopup"
              >
                <img src="/images/filter.svg" width="30" alt="" />
              </button>
            </div>
          </div>
          <div class="card border-0">
            <div
              v-for="(data, index) in CompanyList"
              :key="index"
              class="card-body border-bottom expert-list"
              v-on:click="router(data.id)"
            >
              <div class="row">
                <div class="col-md-12 d-flex align-items-center mb-3">
                  <!-- <img
                    :src="
                      data.logo ? data.logo : 'images/placeholder-image.jpg'
                    "
                    alt=""
                    class="img-fluid"
                    width="100"
                    height="80"
                  /> -->
                  <div class="pl-3">
                    <p class="font-medium mb-2 font-18 font-xs-16">
                      {{ data.company_name }}
                    </p>
                    <p class="text-secondary font-14 mb-0">
                      {{ data.first_name }}  {{ data.last_name }}
                    </p>
                    <a style="color: #9CC338 !important;">
                  <i class="fa fa-phone" style="color: #9CC338 !important;" aria-hidden="true"></i>
                  {{ data.sales_dial_code ?  data.sales_dial_code : data.dial_code}}{{ data.sales_contact_no ? data.sales_contact_no : data.phone}}
                </a>
                <br />
                <a style="color: #9CC338 !important;">
                  <i class="fa fa-envelope-o" style="color: #9CC338 !important;" aria-hidden="true"></i>
                  {{ data.sales_email ? data.sales_email : data.email}}
                </a>
                  </div>
                </div>
                <!-- <div class="col-12">
                  <p class="text-secondary font-14 font-weight-light mb-0">
                    {{ data.description }}
                  </p>
                </div> -->
              </div>
            </div>

            <ErrorComponent @retry="getCompanyList(0)" ref="errorComponent" />
            <TextModal>
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    v-on:click="$refs.filterModal.closeModal()"
                  >
                    <img src="/images/close.svg" width="15" />
                  </button>
                </div>
                <div class="modal-body form-style pb-2 pt-2">
                  <h4 class="font-22 modal-tital text-center">Filter</h4>
                  <p class="font-medium mb-3">Category</p>
                  <div class="text-center interest-tags">
                    <button
                      type="button"
                      class="btn btn-outline-rounded-color mr-2 mb-2"
                      v-on:click="checked(data)"
                      v-for="(data, index) in CompanyList"
                      :key="index"
                      :class="data.isChecked == true ? 'active' : ''"
                    >
                      {{ data.company_name }}
                    </button>
                  </div>
                  <div><hr /></div>
                </div>
                <div class="modal-footer border-0 justify-content-center mb-3">
                  <button
                    type="button"
                    class="btn btn-outline-brand-1 px-4"
                    data-dismiss="modal"
                    v-on:click="
                      $refs.filterModal.closeModal();
                      getCompanyList(0);
                    "
                  >
                    Apply
                  </button>
                </div>
              </div>
            </TextModal>
            <TextModal ref="filterModal">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    v-on:click="this.$refs.filterModal.closeModal()"
                  >
                    <img src="images/close.svg" width="15" />
                  </button>
                </div>
                <div class="modal-body form-style pb-2 pt-2 floating-form">
                  <h4 class="font-22 modal-tital text-center">Filter</h4>
                  <div class="form-group">
                    <label for=" "> Industry</label>
                    <span class="text-danger">*</span>
                    <select
                      class="custom-select"
                      v-model="searchObj.industry_id"
                      name="industry"
                    >
                      <option value="">Select</option>
                      <option
                        v-for="(data, index) in industryList"
                        :key="index"
                        :value="data.id"
                      >
                        {{ data.name }}
                      </option>
                    </select>
                  </div>
                  <div class="floating-form-group with-icon">
                    <vue-google-autocomplete
                      ref="address"
                      id="map"
                      :classname="
                        searchObj.city ? 'form-control filled' : 'form-control'
                      "
                      placeholder=""
                      v-on:placechanged="getAddressData"
                      types=""
                      country="in"
                    >
                    </vue-google-autocomplete>
                    <label for="StartingPoint">Location</label>
                    <img
                      src="images/location-icon.svg"
                      width="20"
                      class="icon"
                    />
                  </div>
                  <div class="floating-form-group">
                    <input
                      type="text"
                      id="ContactNumber"
                      :class="searchObj.product_service ? 'filled' : ''"
                      v-model="searchObj.product_service"
                    />
                    <label for="ContactNumber">Product/Services</label>
                  </div>
                </div>
                <div class="modal-footer border-0 justify-content-center mb-3">
                  <button
                    type="button"
                    class="btn btn-outline-brand-1 px-4"
                    v-on:click="apply()"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </TextModal>
          </div>
        </div>
      </div>
    </div>
    <div
      style="text-align: center; background-color: #d6e0ea40"
      class="col-12 pb-3"
      v-show="offset != -1"
      v-if="!loader"
    >
      <a
        id="load-button"
        class="txt-success cursor-pointer mb-3"
        v-on:click="getCompanyList(offset)"
        style="max-width: 110px"
      >
        Load More
      </a>
    </div>
  </main>
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import ErrorComponent from "@/components/ErrorComponent";
import TextModal from "@/components/TextModal.vue";
export default {
  name: "SearchCompanies",
  components: {
    ErrorComponent,
    TextModal,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      type: this.$route.params.type,
      loader: true,
      offset: 0,
      searchObj: {
        keyword: "",
        city: "",
        product_service: "",
        industry_id: "",
      },
      CompanyList: [],
      industryList: [],
      detail: {},
    };
  },
  mounted() {
    if(this.$store.getters.keyword != null)
    {
    this.searchObj.keyword = this.$store.getters.keyword;
    }
    this.getCompanyList(0);
    this.getIndustryList();
  },
  methods: {
    getAddressData: function (addressData,placeResultData) {
      this.searchObj.city = placeResultData.name ? (placeResultData.name) : ' ' ;
      this.searchObj.latitude = addressData.latitude;
      this.searchObj.longitude = addressData.longitude;
    },
    apply() {
      this.$refs.filterModal.closeModal();
      this.getCompanyList(0);
    },
    getCompanyList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      if (offset == 0) {
        this.CompanyList = [];
      }
      this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action: "companies",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          
          for (var i in res.list) {
            this.CompanyList.push(res.list[i]);
          }
          this.offset = res.next_offset;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No companies available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    getIndustryList() {
      this.$api
        .getAPI({
          _action: "category/industry",
        })
        .then((res) => {
          this.industryList = res.list;
        })
        .catch(() => {});
    },
    showFilterPopup() {
      this.$refs.filterModal.showModal();
    },
    router(id){
      this.$store.commit("keyword", this.searchObj.keyword);
      this.$router.push('/companies-detail/' + id)
    }
  },
};
</script>