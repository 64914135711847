<template>
  <div>
    <header
      class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100"
      style="z-index: 1"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img
              src="/images/tenx-logo.svg"
              width="80"
              height="26"
              class="img-fluid"
            />
          </div>
          <!-- <div class="col-md-9 col-9 text-right">
            <a class="mr-3">Signup</a>
            <a class="header-btn" v-on:click="$router.push('/login')">Login</a>
          </div> -->
        </div>
      </div>
    </header>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center bg-white">
            <div class="col-md-6 bg-white">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <div class="px-lg-5 px-0">
                    <div class="card-body pb-0">
                      <div class="row" style="min-height: 87vh">
                        <div class="col-12 mb-4 mt-4 align-selft-start">
                          <h3 class="signup-title mt-2">
                            Select your interest
                          </h3>
                          <div class="steps-count">
                            <div class="step-no active">
                              <img
                                src="/images/check-white.svg"
                                width="20"
                                height="20"
                              />
                            </div>
                            <div class="h-line"></div>
                            <div class="step-no active">
                              <img
                                src="/images/check-white.svg"
                                width="20"
                                height="20"
                              />
                            </div>
                            <div class="h-line"></div>
                            <div class="step-no active">
                              <img
                                src="/images/check-white.svg"
                                width="20"
                                height="20"
                              />
                            </div>
                            <div class="h-line"></div>
                            <div class="step-no active">4</div>
                          </div>
                          <div class="text-center mt-5 interest-tags">
                            <button
                              type="button"
                              class="btn btn-outline-rounded-color mr-2 mb-2"
                              v-for="(data, index) in intrestList"
                              :key="index"
                            >
                              {{ data.name }}
                            </button>
                          </div>
                        </div>
                        <div class="col-12 align-self-end mb-4">
                          <div class="d-flex">
                            <button
                              type="button"
                              class="
                                btn btn-outline-secondary btn-block
                                mb-md-0
                                mr-3
                              "
                              v-on:click="$router.push('/home')"
                            >
                              Skip
                            </button>
                            <button
                              type="button"
                              class="btn btn-brand-1 btn-block mt-0"
                              v-on:click="$router.push('/home')"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 login-img-col"></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import api from "../../../services/WebService";
export default {
  name: "SignupStep4",
  components: {},
  data() {
    return {
      intrestList: [],
    };
  },
  mounted() {
    this.getIntrestList();
  },
  methods: {
    getIntrestList() {
      api
        .getAPI({
          _action: "category/event",
        })
        .then((res) => {
          this.intrestList = res.list;
        })
        .catch(() => {});
    },
  },
};
</script>