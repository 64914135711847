<template>
  <div ref="rzpform"></div>
</template>
<script>
export default {
  name: "PaymentGateway",
  data() {
    return {};
  },
  mounted() {
    var form = document.createElement("form");
    var rzpScript = document.createElement("script");
    rzpScript["src"] =
      "https://cdn.razorpay.com/static/widget/subscription-button.js";
    rzpScript.setAttribute(
      "data-subscription_button_id",
      "rzp_test_IGcFOAH6k2CGuz"
    );
    rzpScript.setAttribute("async", true);
    form.appendChild(rzpScript);
    this.$refs.rzpform.appendChild(form);
  },
};
</script>