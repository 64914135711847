<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-10">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">TenX Growth Group</h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end">
            <input
              type="text"
              class="search-click"
              name=""
              placeholder="search here..."
              v-model="searchObj.keyword"
              v-on:keyup.enter="getBusinessList(0)"
            />
          </div>
        </div>

        <div class="card mb-4">
          <div
            v-for="(data, index) in BusinessList"
            :key="index"
            class="card-body border-bottom expert-list border-bottom"
            v-on:click="$router.push('/business-growth-detail/' + data.id)"
          >
            <div class="row">
              <div class="col-md-12 d-flex align-items-center mb-3">
                <img
                  :src="data.logo ? data.logo : 'images/placeholder-image.jpg'"
                  alt=""
                  class="img rounded-circle object-cover"
                  width="80"
                  height="80"
                />
                <div class="pl-3">
                  <p class="font-medium mb-2 font-18 font-xs-16">
                    {{ data.name }}
                  </p>
                  <p class="text-secondary mb-md-2 mb-2 font-14 cursor-pointer">
                    <img
                      src="/images/location-icon.svg"
                      width="20"
                      class="mr-1"
                    />
                    {{ data.area }} ({{ data.no_of_member }}/{{
                      data.group_size
                    }})
                  </p>
                </div>
              </div>
              <div class="col-12">
                <p class="text-secondary font-14 font-weight-light mb-0">
                  {{ data.about }}
                </p>
              </div>
            </div>
          </div>
          <ErrorComponent @retry="getBusinessList(0)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "BusinessGrowthGroup",
  components: {
    ErrorComponent,
    Pagination,
  },
  data() {
    return {
      offset: -1,
      type: this.$route.params.type,
      loader: true,
      searchObj: {
        keyword: "",
      },
      BusinessList: [],
    };
  },
  mounted() {
    this.$store.commit("showSidebar", true);
    this.getBusinessList(0);
  },
  methods: {
    pageChange(offset) {
      this.getBusinessList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getBusinessList(0);
    },
    getBusinessList(offset) {
      this.$refs.errorComponent.updateLoader(true);
      if (offset == 0) {
        this.BusinessList = [];
      }
      this.searchObj.offset = offset;
      this.$api
        .getAPI({
          _action: "business-work-groups",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.BusinessList = res.list;

          this.offset = res.next_offset;
          if (offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No business-growth-group available"
            );
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
  },
};
</script>